import { css } from 'styled-components'
import styled from 'styled-components'
import FretWrapper from './FretWrapper'

const highlightCSS = ({ theme, color, border }) => {
  const radiusPerc = theme.skins.strings.highlightSize
  const radius = Math.floor((theme.dimensions.stringHeight / 100) * radiusPerc)
  return css`
    width: ${radius}px;
    height: ${radius}px;
    border-radius: 50%;
    background-color: ${color};
    border: ${border};
  `//
}

const defaultCSS = ({ theme }) =>
  css`
    padding: 0 5%;
    max-width: 60%;
    background-color: ${theme.background};
  `

const FretWrapperStrings = styled(FretWrapper)`
  ${props => (props.isHighlighted
    ? highlightCSS(props)
    : defaultCSS(props))} 
  `

/** @component */
export default FretWrapperStrings
