import './App.css';
import React from 'react';
import Fretboard, {intervalNotes} from 'react-fretboard';
import logo from './fret_ferret_logo.png';
import footer_logo from './footer_logo.png';
import {Note, Interval, Scale, Midi} from "@tonaljs/tonal";
import MIDISounds from 'midi-sounds-react';
import {api_key} from './api_key.js'

const default_headers = {
    'Content-Type': 'application/json',
    'x-api-key': api_key
}

// from https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
function iOS() {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}


function testAnyOverlap(arr1, arr2) {
    var output = false
    for (var i = 0; i < arr1.length; i++) {
        for (var j = 0; j < arr2.length; j++) {
            if (arr1[i] == arr2[j]) {
                output = true
            }
        }
    }
    return output
}

function testAll(arr_small, arr_large) {
    var output = true
    for (var i = 0; i < arr_small.length; i++) {
        if (!arr_large.includes(arr_small[i])) {
            output = false
        }
    }
    return output
}

function testMatch(arr1, arr2) {
    var output = true
    for (var i = 0; i < arr1.length; i++) {
        if (!arr2.includes(arr1[i])) {
            output = false
        }
    }
    for (var i = 0; i < arr2.length; i++) {
        if (!arr1.includes(arr2[i])) {
            output = false
        }
    }
    return output
}


// from https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

// from https://stackoverflow.com/questions/46622486/what-is-the-javascript-equivalent-of-numpy-argsort
function argSort(array) {

    const dsu = (arr1, arr2) => arr1
        .map((item, index) => [arr2[index], item]) // add the args to sort by
        .sort(([arg1], [arg2]) => arg2 - arg1) // sort by the args
        .map(([, item]) => item); // extract the sorted items

    return dsu([...Array(array.length).keys()], array)

}

// from https://www.w3schools.com/js/js_cookies.asp
function setCookie(cname, cvalue, exdays = 100) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

// from https://thecodersblog.com/increase-javascript-timoeout-accuracy/
const setExactTimeout = function (callback, duration, resolution) {
    const start = (new Date()).getTime();
    const timeout = setInterval(function () {
        if ((new Date()).getTime() - start > duration) {
            callback();
            clearInterval(timeout);
        }
    }, resolution);

    return timeout;
};

const clearExactTimeout = function (timeout) {
    clearInterval(timeout);
};

// from https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

// if you pass http://localhost:3000/?chord_type=Interval%20Find%203, this will plop you straight into "Interval Find 3" game
// from https://stackoverflow.com/questions/2405355/how-to-pass-a-parameter-to-a-javascript-through-a-url-and-display-it-on-a-page
// www.mysite.com/my_app.html?Use_Id=abc 

const endpoint_url = 'https://akn4hgmvuc.execute-api.us-west-2.amazonaws.com/staging/'
const log_and_retriever_endpoint_url = 'https://jugxw6z3l7.execute-api.us-west-2.amazonaws.com/staging'
const bandit_spread = 10
const url_sep = ','
const intervals = ['Unison ("do")', 'minor 2nd ("ra")', 'Major 2nd ("re")', 'minor 3rd ("me")', 'Major 3rd ("mi")',
    'perfect 4th ("fa")', 'tritone ("fi")', 'perfect 5th ("sol")', 'minor 6th ("le")', 'Major 6 ("la")',
    'minor 7 ("te")', 'Major 7 ("ti")']
const solfege = ['U "do"', 'm2 "ra"', 'M2 "re"', 'm3 "me"', 'M3 "mi"',
    'P4 "fa"', 'TT "fi"', 'P5 "sol"', 'm6 "le"', 'M6 "la"',
    'm7 "te"', 'M7 "ti"']
var map_interval_int_to_show = ['U', 'm2', 'M2', 'm3', 'M3', 'P4', 'TT', 'P5', 'm6', 'M6', 'm7', 'M7']
var map_interval_int_to_pretty_show = ['R', '♭2', '2', '♭3', '3', '4', '#4', '5', '♭6', '6', '♭7', '7']
var map_interval_int_to_roman_numeral = ['I', 'bII', 'II', 'bIII', 'III', 'IV', '#IV', 'V', 'bVI', 'VI', 'bVII', 'VII']

// Note that all enharmonic mappings require Note.simplify first, or bugs occur
var map_naturals = {
    'A': 'A',
    'B': 'B',
    'C': 'C',
    'D': 'D',
    'E': 'E',
    'F': 'F',
    'G': 'G'
}
var map_enharmonics = {
    'A#': 'Bb',
    'C#': 'Db',
    'D#': 'Eb',
    'Gb': 'F#',
    'G#': 'Ab',
    'Bb': 'Bb',
    'Db': 'Db',
    'Eb': 'Eb',
    'F#': 'F#',
    'Ab': 'Ab'
}
map_enharmonics = {
    ...map_naturals,
    ...map_enharmonics
}
var map_enharmonics_sharps = {
    'Bb': 'A#',
    'Db': 'C#',
    'Eb': 'D#',
    'Gb': 'F#',
    'Ab': 'G#',
    'A#': 'A#',
    'C#': 'C#',
    'D#': 'D#',
    'F#': 'F#',
    'G#': 'G#'
}
map_enharmonics_sharps = {
    ...map_naturals,
    ...map_enharmonics_sharps
}
var map_enharmonics_flats = {
    'A#': 'Bb',
    'C#': 'Db',
    'D#': 'Eb',
    'F#': 'Gb',
    'G#': 'Ab',
    'Bb': 'Bb',
    'Db': 'Db',
    'Eb': 'Eb',
    'Gb': 'Gb',
    'Ab': 'Ab'
}
map_enharmonics_flats = {
    ...map_naturals,
    ...map_enharmonics_flats
}
var map_tonic_to_interval_int_to_note_name = {
    //    I    bII   II   bIII  iii  IV   #IV   V    bVI   vi   bVII  viio
    'C': ['C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'],
    'C#': ['C#', 'D', 'D#', 'E', 'E#', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'B#'],
    'Db': ['Db', 'Ebb', 'Eb', 'Fb', 'F', 'Gb', 'G', 'Ab', 'Bbb', 'Bb', 'Cb', 'C'],
    'D': ['D', 'Eb', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb', 'B', 'C', 'C#'],
    'Eb': ['Eb', 'Fb', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb', 'C', 'Db', 'D'],
    'E': ['E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B', 'C', 'C#', 'D', 'D#'],
    'F': ['F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B', 'C', 'Db', 'D', 'Eb', 'E'],
    'F#': ['F#', 'G', 'G#', 'A', 'A#', 'B', 'B#', 'C#', 'D', 'D#', 'E', 'E#'],
    'G': ['G', 'Ab', 'A', 'Bb', 'B', 'C', 'C#', 'D', 'Eb', 'E', 'F', 'F#'],
    'G#': ['G#', 'A', 'A#', 'B', 'B#', 'C#', 'C##', 'D#', 'E', 'E#', 'F#', 'F##'],
    'Ab': ['Ab', 'Bbb', 'Bb', 'Cb', 'C', 'Db', 'D', 'Eb', 'Fb', 'F', 'Gb', 'G'],
    'A': ['A', 'Bb', 'B', 'C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#'],
    'Bb': ['Bb', 'Cb', 'C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A'],
    'B': ['B', 'C', 'C#', 'D', 'D#', 'E', 'E#', 'F#', 'G', 'G#', 'A', 'A#'],
}

var map_reference_chord_type_to_sequence_length = {
    'I-ii-V-I': 4,
    'I-ii-V': 3,
    'Imaj7-iim7-V7-Imaj7': 4,
    'Imaj7-iim7-V7': 3,
    'I-IV-V-I': 4,
    'I-IV-V': 3,
    'I-bVII-IV-I': 4,
    'I-bVII-IV': 3,
    'im-iio-V-im': 4,
    'im-iio-V': 3,
    'im7-iim7b5-V7-im7': 4,
    'im7-iim7b5-V7': 3,
    'im-ivm-V-im': 4,
    'im-ivm-V': 3,
    'im-bVII-IV-im': 4,
    'im-bVII-IV': 3,
    'Imaj7-II7-Imaj7': 3,
    'Imaj7-II7': 2
}
var reference_styles_to_choose_from = ['Major', 'Minor', 'Major7', 'Minor7', 'Dom7', 'Dom7 Shell']
reference_styles_to_choose_from.push(...Object.keys(map_reference_chord_type_to_sequence_length))
var statusMapStatic = {
    R: '#ff5555',
    m2: '#ffa500',
    M2: '#D3D3D3',
    s2: "#ffa500",
    m3: '#4CB1C6',
    M3: '#7CECD4',
    n3: "#ffa500",
    b4: "#ffa500",
    P4: "#D3D3D3",
    s4: "#ffa500",
    TT: "#ffa500",
    P5: '#add8e6',
    m6: "#ffa500",
    M6: "#D3D3D3",
    m7: '#add8e6',
    M7: '#7CECD4',
    correct: '#add8e6',
    winner: "#77BB77",
    current_click: '#aaaaaa',
    scale: '#D3D3D3',
    scale_root: '#D3D3D3',
    // scale_root: '#ff5555',
    chord: '#add8e6',
    chord_not_in_scale: "#ffa500",
    chord_root: '#add8e6',   //'#ceb8d6',
    avoid: '#ffffff',
    microphone_input: '#ff5555',
    root_when_microphone_present: '#add8e6',
    random_top_note: '#d6a8dd',
    ghost: '#ffffff00'
}
var n_fret_zones = 10
var guitarlele_tuning = ['A2', 'D3', 'G3', 'C4', 'E4', 'A4']
var guitar_tuning = ['E2', 'A2', 'D3', 'G3', 'B3', 'E4']
var default_tuning = [...guitar_tuning]
var map_tuning_name_to_tuning = {
    'guitar': guitar_tuning,
    'guitarlele': guitarlele_tuning,
    'guitarlele with guitar note names': guitarlele_tuning
}
var default_speakers_text = "[Played on Speakers]"
var default_interval_types = ['U', '2nds', '3rds', '4ths', 'TTs', '5ths', '6ths', '7ths']
var tunings_to_choose_from = {
    guitar: guitar_tuning,
    guitarlele: guitarlele_tuning,
    'guitarlele with guitar note names': guitarlele_tuning
}
var notes_to_choose_from = [
    "Ab", "A", "A#", "Bb", "B", "C", "C#", "Db", "D", "D#", "Eb", "E", "F", "F#", "Gb", "G", "G#"
];
var reference_notes_to_choose_from = ["A", "Bb", "B", "C", "Db", "D", "Eb", "E", "F", "F#", "G", "Ab"]
var sharps_to_choose_from = ["A#", "C#", "D#", "F#", "G#"];
var flats_to_choose_from = ["Ab", "Bb", "Db", "Eb", "Gb"];
var naturals_to_choose_from = ["A", "B", "C", "D", "E", "F", "G"];
var default_note = Note.simplify(Note.pitchClass(Note.transpose(default_tuning[default_tuning.length - 2], '2m'))) + '3'
var default_handleClick_payload = {
    loc: {str: 1, pos: 1}, note: 'undefined',
    notes_on_fret: [default_note, default_note, default_note, default_note, default_note, default_note],
    onRadioChange: true,
    current_click_location: {}
}

var interval_group_lengths_to_choose_from = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
var map_chord_quality_to_chord_type = {
    triads: ["Major", "Minor", "Dim", "Aug", "Major b5"],
    sevenths: ["Minor7", "Major7", "m7b5", "Dim7", "MinorMajor7", "Aug7", "Dom7", "Dom7 Shell"],
    ninths: ["Minor9", "Minorb9", "MinorAddb9", "Major9", "MinorAdd9", "MajorAdd9", "Dom9"],
    thirteenths: ['Minor13', "Major13", "Dom13", "Minor11", "Minor7b13"],
    sixths: ["Minor6", "Major6"],
    susses: ["Sus2", "Sus4", "Sus#4", "Sus24", "Sus2#4", "Dom7Sus2", "Dom7Sus4", "Dom7Sus#4"],
    altered: ["Dom7b9", "Dom7#9", "Dom7b5", "Dom7#5", "Dom7b13",
        "Dom7b9b13", "Dom7#9b13", "Dom7b9b5", "Dom7b9#11", "Dom7#9#11",
        "Major7#11", "Dom7#11", "Major7#11", "Dom7#11b13"],
    sixninths: ['Minor69', "Major69"]
}

var map_chord_quality2_to_chord_type = {
    major: ["Major", "Major7", "Major9", "MajorAdd9", "Major13", "Major6", "Major69", "Major7#11", "Major b5"],
    minor: ["Minor", "Minor7", "MinorMajor7", "Minor9", "Minorb9", "MinorAddb9", "MinorAdd9", "Minor11", 'Minor13', "Minor7b13", "Minor6", 'Minor69'],
    dominant: ["Dom7", 'Dom9', "Dom13", "Dom7b9", "Dom7#9", "Dom7b5", "Dom7#5", "Dom7b13",
        "Dom7b9b13", "Dom7#9b13", "Dom7b9b5", "Dom7b9#11", "Dom7#9#11",
        "Dom7#11", 'Dom7#11b13', 'Dom7 Shell'],
    susses: ["Sus2", "Susb2", "Sus4", "Sus24", "Dom7Sus2", "Dom7Sus4", "Dom7Sus#4"],
    dim: ["Dim", "m7b5", "Dim7"],
    aug: ["Aug", "Aug7"]
}
var chord_types_without_P5 = ['Root', 'm7b5', 'Dim', 'Aug', 'Dim7', 'Aug7', 'Dom7b5', "Dom7#5", "Major b5", "Majorb5", "Dom7 Shell"]
var chord_types_with_necessary_P5 = ['Major', 'Minor', 'Major7', 'Minor7', 'Major6', 'Minor6', 'Dom7', "Sus2", "Sus4", "Sus24", "Sus#4", "Susb2"]
var chord_types_to_choose_from = [
    "Major", "Minor", "Major6", "Minor6", "Major7", "Minor7", "Major b5",
    "Major69", "Major64",
    "Minor9", "Minorb9", "MinorAddb9", "Major9", "Minor11", "Major7#11", "Major13", "Minor13", "Minor7b13",
    "Dom7", "Dom9", "Dom11", "Dom13", "Dom7#11",
    "Dom7b9", "Dom7#9", "Dom7b5", "Dom7#5", "Dom7b13",
    "Dom7b9b13", "Dom7#9b13", "Dom7b9b5", "Dom7b9#11", "Dom7#9#11",
    "Dom7#11b13", "Sus4", "Sus2", "Susb2", "Sus24", "Sus#4", "Sus2#4",
    "Dom7Sus4", "Dom7Sus2", "Dom7Sus#4",
    "Dim", "Aug", "AugM7", "Dim7", "Aug7", "m7b5", "m9b5", "Major69", "Minor69", "MajorAdd9", "MinorAdd9",
    "MinorMajor7", "Dom7 Shell"
]
chord_types_to_choose_from.sort()
var scale_types_to_choose_from = [
    "Major Scale", "Aeolian", "Phrygian", "Dorian", "Mixolydian", "Lydian",
    "Lydian Dominant", "Harmonic Minor", "Melodic Minor", "Altered",
    "Mixolydian b6", "Phrygian Dominant", "Lydian Augmented",
    "Locrian", "Locrian n2", "Diminished Dominant", "Whole Tone", "Dorian #4",
    "Major Pentatonic", "Minor Pentatonic", "Blues", "Chromatic",
    "Just b3", "Aeolian b5", "Super Lydian", "Super-Super Lydian", "Augmented",
    "Phrygian b1"
]

var map_roman_numeral_to_info = {
    'I': {interval: 0, chord_type: 'Major', name: 'I'},
    'I6': {interval: 0, chord_type: 'Major6', name: 'I6'},
    'I69': {interval: 0, chord_type: 'Major69', name: 'I69'},
    'I64': {interval: 0, chord_type: 'Major64', name: 'I64'},
    'I+': {
        interval: 0, chord_type: 'Aug', name: 'I+',
        scale_name: "Augmented"
    },
    'I+M7': {
        interval: 0, chord_type: 'AugM7', name: 'I+M7',
        scale_name: "Augmented"
    },
    'I7': {
        interval: 0, chord_type: 'Dom7', name: 'I7',
        scale_name_when_major: 'Mixolydian'
    },
    'I9': {
        interval: 0, chord_type: 'Dom9', name: 'I9',
        scale_name_when_major: 'Mixolydian'
    },
    'i7': {
        interval: 0, chord_type: 'Minor7', name: 'i7',
        scale_name_when_major: 'Dorian'
    },
    'i9': {interval: 0, chord_type: 'MinorAdd9', name: 'i9'},
    'i': {
        interval: 0, chord_type: 'Minor', name: 'i',
        scale_name_when_major: 'Dorian'
    },
    'io': {
        interval: 0, chord_type: 'Dim', name: 'io',
        scale_name: "Dorian #4"
    },
    'io7': {
        interval: 0, chord_type: 'Dim7', name: 'io7',
        scale_name: "Dorian #4"
    },
    'IM7': {interval: 0, chord_type: 'Major7', name: 'IM7'},
    'IM9': {interval: 0, chord_type: 'MajorAdd9', name: 'IM9'},
    'bII': {
        interval: 1, chord_type: 'Major', name: 'bII',
        scale_name: 'Phrygian'
    },
    'bIIM7': {
        interval: 1, chord_type: 'Major7', name: 'bIIM7',
        scale_name: 'Phrygian'
    },
    'bII7': {
        interval: 1, chord_type: 'Dom7', name: 'bII7',
        scale_name: 'Phrygian b1'
    },
    'bII9': {
        interval: 1, chord_type: 'Dom9', name: 'bII9',
        scale_name: 'Phrygian b1'
    },
    'ii': {interval: 2, chord_type: 'Minor', name: 'ii'},
    'II': {
        interval: 2, chord_type: 'Major', name: 'II',
        scale_name: 'Lydian'
    },
    'ii7': {interval: 2, chord_type: 'Minor7', name: 'ii7'},
    'ii9': {interval: 2, chord_type: 'MinorAdd9', name: 'ii9'},
    'iio': {
        interval: 2, chord_type: 'Dim', name: 'iio',
        scale_name: "Aeolian"
    },
    'iim7b5': {
        interval: 2, chord_type: 'm7b5', name: 'iim7b5',
        scale_name: "Aeolian"
    },
    'iim9b5': {
        interval: 2, chord_type: 'm9b5', name: 'iim9b5',
        scale_name: "Aeolian"
    },
    'II7': {
        interval: 2, chord_type: 'Dom7', name: 'II7',
        scale_name: 'Lydian'
    },
    'II9': {
        interval: 2, chord_type: 'Dom9', name: 'II9',
        scale_name: 'Lydian'
    },
    'bIIIM7': {
        interval: 3, chord_type: 'Major7', name: 'bIIIM7',
        scale_name_when_major: 'Dorian'
    },
    'bIII': {
        interval: 3, chord_type: 'Major', name: 'bIII',
        scale_name_when_major: 'Dorian'
    },
    'biii': {interval: 3, chord_type: 'Minor', name: 'biii'},
    'bIII7': {
        interval: 3, chord_type: 'Dom7', name: 'bIII7',
        scale_name: 'Phrygian'
    },
    'bIII9': {
        interval: 3, chord_type: 'Dom9', name: 'bIII9',
        scale_name: 'Phrygian'
    },
    'bIIIM9': {
        interval: 3, chord_type: 'MajorAdd9', name: 'bIIIM9',
        scale_name: 'Aeolian'
    },
    'iii': {interval: 4, chord_type: 'Minor', name: 'iii'},
    'III': {interval: 4, chord_type: 'Major', name: 'III'},
    'iii7': {interval: 4, chord_type: 'Minor7', name: 'iii7'},
    'iii9': {interval: 4, chord_type: 'MinorAdd9', name: 'iii9'},
    'iiib9': {interval: 4, chord_type: 'Minorb9', name: 'iiib9'},
    'III7': {
        interval: 4, chord_type: 'Dom7', name: 'III7',
        scale_name: 'Augmented'
    },
    'III9': {
        interval: 4, chord_type: 'Dom9', name: 'III9',
        scale_name: 'Augmented'
    },
    'III+7': {interval: 4, chord_type: 'Aug7', name: 'III+7'},
    'III+': {interval: 4, chord_type: 'Aug', name: 'III+'},
    'IV': {interval: 5, chord_type: 'Major', name: 'IV'},
    'iv': {
        interval: 5, chord_type: 'Minor', name: 'iv',
        scale_name: "Aeolian"
    },
    'iv7': {
        interval: 5, chord_type: 'Minor7', name: 'iv7',
        scale_name: 'Aeolian'
    },
    'iv9': {
        interval: 5, chord_type: 'MinorAdd9', name: 'iv9',
        scale_name: 'Aeolian'
    },
    'IV7': {
        interval: 5, chord_type: 'Dom7', name: 'IV7',
        scale_name: 'Dorian' // old: 'Just b3' also see below
    },
    'IVM7#11': {interval: 5, chord_type: 'Major7#11', name: 'IVM7#11'},
    'IV6': {interval: 5, chord_type: 'Major6', name: 'IV6'},
    'IV9': {
        interval: 5, chord_type: 'Dom9', name: 'IV9',
        scale_degrees: [0, 2, 3, 5, 7, 9, 11],
        scale_name: 'Dorian' // old: 'Just b3' also see above
    },
    'IVM7': {interval: 5, chord_type: 'Major7', name: 'IVM7'},
    'IVM9': {interval: 5, chord_type: 'MajorAdd9', name: 'IVM9'},
    '#iv': {interval: 6, chord_type: 'Minor', name: '#iv'},
    '#iv7': {interval: 6, chord_type: 'Minor7', name: '#iv7'},
    '#ivo7': {
        interval: 6, chord_type: 'Dim7', name: '#ivo7',
        scale_name: "Dorian #4"
    },
    '#ivo': {
        interval: 6, chord_type: 'Dim', name: '#ivo',
        scale_name: "Dorian #4"
    },
    '#IV7': {
        interval: 6, chord_type: 'Dom7', name: '#IV7',
        scale_name: "Altered"
    },
    '#IV': {
        interval: 6, chord_type: 'Major', name: '#IV',
        scale_name: "Altered"
    },
    'V': {interval: 7, chord_type: 'Major', name: 'V'},
    'v': {
        interval: 7, chord_type: 'Minor', name: 'v',
        scale_name_when_major: "Mixolydian"
    },
    'v9': {
        interval: 7, chord_type: 'MinorAdd9', name: 'v9',
        scale_name_when_major: "Mixolydian"
    },
    'v7': {
        interval: 7, chord_type: 'Minor7', name: 'v7',
        scale_name_when_major: "Mixolydian"
    },
    'V6': {interval: 7, chord_type: 'Major6', name: 'V6'},
    'V7': {interval: 7, chord_type: 'Dom7', name: 'V7'},
    'V9': {interval: 7, chord_type: 'Dom9', name: 'V9'},
    'V13': {interval: 7, chord_type: 'Dom13', name: 'V13'},
    'V+7': {interval: 7, chord_type: 'Aug7', name: 'V+7'},
    'V+': {interval: 7, chord_type: 'Aug', name: 'V+'},
    'V7#11': {interval: 7, chord_type: 'Dom7#11', name: 'V7#11'},
    'V7#5': {interval: 7, chord_type: 'Dom7#5', name: 'V7#5'},
    'V7b5': {interval: 7, chord_type: 'Dom7b5', name: 'V7b5'},
    'V7b9': {interval: 7, chord_type: 'Dom7b9', name: 'V7b9'},
    'V7#9': {interval: 7, chord_type: 'Dom7#9', name: 'V7#9'},
    'V7b9b13': {interval: 7, chord_type: 'Dom7b9b13', name: 'V7b9b13'},
    'V7#9b13': {interval: 7, chord_type: 'Dom7#9b13', name: 'V7#9b13'},
    'V7#9#11': {interval: 7, chord_type: 'Dom7#9#11', name: 'V7#9#11'},
    'Vsus2': {interval: 7, chord_type: 'Sus2', name: 'Vsus2'},
    'Vsus': {interval: 7, chord_type: 'Sus4', name: 'Vsus'},
    'Vsus24': {interval: 7, chord_type: 'Sus24', name: 'Vsus24'},
    'Vsus4': {interval: 7, chord_type: 'Sus4', name: 'Vsus4'},
    'bVI': {
        interval: 8, chord_type: 'Major', name: 'bVI',
        scale_name: 'Aeolian'
    },
    'bVI7': {
        interval: 8, chord_type: 'Dom7', name: 'bVI7',
        scale_name: 'Aeolian b5'
    },
    'bVIM7': {
        interval: 8, chord_type: 'Major7', name: 'bVIM7',
        scale_name: 'Aeolian'
    },
    'bVI9': {
        interval: 8, chord_type: 'Dom9', name: 'bVI9',
        scale_name: 'Aeolian b5'
    },
    'bVIM9': {
        interval: 8, chord_type: 'MajorAdd9', name: 'bVIM9',
        scale_name: 'Aeolian'
    },
    'vi': {interval: 9, chord_type: 'Minor', name: 'vi'},
    'vi7': {interval: 9, chord_type: 'Minor7', name: 'vi7'},
    'VI7': {
        interval: 9, chord_type: 'Dom7', name: 'VI7',
        scale_name: 'Super Lydian'
    },
    'vi9': {interval: 9, chord_type: 'MinorAdd9', name: 'vi9'},
    'VI9': {
        interval: 9, chord_type: 'Dom9', name: 'VI9',
        scale_name: 'Super Lydian'
    },
    'VI': {
        interval: 9, chord_type: 'Major', name: 'VI',
        scale_name: 'Super Lydian'
    },
    'VI+7': {interval: 9, chord_type: 'Aug7', name: 'VI+7'},
    'VI+': {interval: 9, chord_type: 'Aug', name: 'VI+'},
    'bvii': {
        interval: 10, chord_type: 'Minor', name: 'bvii',
        scale_name: 'Phrygian'
    },
    'bvii7': {
        interval: 10, chord_type: 'Minor7', name: 'bvii7',
        scale_name: 'Phrygian'
    },
    'bVII': {
        interval: 10, chord_type: 'Major', name: 'bVII',
        scale_name_when_major: 'Dorian' // old: 'Mixolydian' also see below
    },
    'bVII7': {
        interval: 10, chord_type: 'Dom7', name: 'bVII7',
        scale_name: 'Aeolian'
    },
    'bVIIM7': {
        interval: 10, chord_type: 'Major7', name: 'bVIIM7',
        scale_name_when_major: 'Dorian' // old: 'Mixolydian' also see above and below
    },
    'bVII9': {
        interval: 10, chord_type: 'Dom9', name: 'bVII9',
        scale_name: 'Aeolian'
    },
    'bVIIM9': {
        interval: 10, chord_type: 'Major9', name: 'bVIIM9',
        scale_name_when_major: 'Dorian' // old: 'Mixolydian' also see above
    },
    'viio': {interval: 11, chord_type: 'Dim', name: 'viio'},
    'viim7b5': {interval: 11, chord_type: 'm7b5', name: 'viim7b5'},
    'viim9b5': {interval: 11, chord_type: 'm9b5', name: 'viim9b5'},
    'VII7': {
        interval: 11, chord_type: 'Dom7', name: 'VII7',
        scale_name: 'Super-Super Lydian'
    },
    'vii': {
        interval: 11, chord_type: 'Minor', name: 'vii',
        scale_name: 'Lydian'
    },
    'vii7': {
        interval: 11, chord_type: 'Minor7', name: 'vii7',
        scale_name: 'Lydian'
    },
    'VII9': {
        interval: 11, chord_type: 'Dom9', name: 'VII9',
        scale_name: 'Super-Super Lydian'
    },
    'vii9': {
        interval: 11, chord_type: 'MinorAdd9', name: 'vii9',
        scale_name: 'Lydian'
    },
    'VII': {
        interval: 11, chord_type: 'Major', name: 'VII',
        scale_name: 'Super-Super Lydian'
    }
}


var roman_numeral_sequences_to_choose_from = [
    '-- Two-Chord Vamps in Major and Minor --',
    'IM7-ii7',
    'IM7-IVM7',
    'IM7-V7',
    'i7-iim7b5',
    'i7-iv7',
    'i7-V7',
    '-- Two-Chord Vamps in Other Modes --',
    'IM7-II7',
    'I7-bVIIM7',
    'I7-IVM7',
    'i7-IV7',
    'i7-bVIIM7',
    'i7-bIIM7',
    'i7-vii7',
    'I7-#IV7',
    'I7-io7',
    '-- Chord Loops in Major --',
    'I-ii-V',
    'I-vi-ii-V',
    'I-iii-vi-ii-V',
    'I-viio-iii-vi-ii-V',
    'IM7-ii7-V7',
    'I-IV-V',
    'I-ii-IV',
    'I-ii-IV-V',
    'I-V-vi-IV',
    'I-ii-iii-IV',
    'I-ii-iii-IV-V-V',
    'I-V-IV',
    'I-V-bVII-IV',
    'I-V-bVII-IV-bVI-bIII',
    'I-bVI-bIII-bVII-IV',
    'I-bII-V',
    'I-ii-bII-V',
    'I-iii-ii-bII',
    'I-IV-bII',
    'I-II-IV-I',
    '-- Chord Loops in Minor --',
    'i-iio-V',
    'i-bVI-iio-V',
    'i-bIII-bVI-iio-V',
    'i-bVII-bIII-bVI-iio-V',
    'i7-iim7b5-V7',
    'i-iv-V',
    'i-iv-bII-V',
    '-- Chord Loops in Other Modes --',
    'I-vii-II7',
    'I-bVI-bIII-bVII-IV-V',
    'I-I-bVI-bVII',
    'I-I-bIII-IV',
    'I-I-bVII-IV',
    'i-i-bVII-IV',
    '-- Major Chord Loops w/ Key Mod. --',
    'I-bVI7-bII-V',
    'I-biii-bVI7-bII-V',
    'I-VI7-ii-V',
    'I-iii-VI7-ii-V',
    'I-III7-VI7-ii-V',
    'I-bVII7-bIII-IV',
    'I-iv-bVII7-bIII-IV',
    'I-VII7-iii-IV-V',
    'I-VII7-iii-IV-I-V',
    'I-#ivo-VII7-iii-IV-V',
    'I-I7-IV-V',
    'I-v-I7-IV-V',
    'I-I-II7-V',
    'I-vi-II7-V',
    'I-bIII7-bVI-V7',
    'I-bvii-bIII7-bVI-V7',
    'I-III7-vi-ii-V',
    'I-viio-III7-vi-ii-V',
    'I-#IV7-vii-III7-vi-II7',
    'I-i-IV7-bVII-V7',
    '-- Comprehensive Chord Loops --',
    'I-viio-iii-vi-ii-V',
    'i-bVII-bIII-bVI-iio-V',
    'I-VII7-iii7-VI7-ii7-V7',
    'I-VII7-III7-VI7-II7-V7',
    'I-bII-bVI-bIII-bVII-IV',
    'I7-bIII7-#IV7-VI7',
    'I7-VI7-#IV7-bIII7',
    'I7-III7-bVI7',
    'I7-bVI7-III7',
    'I7-#IV7'
]

var map_roman_numeral_sequence_to_scale_type = {
    'IM7-ii7': 'Major Scale',
    'IM7-IVM7': 'Major Scale',
    'IM7-V7': 'Major Scale',
    'i7-iim7b5': 'Aeolian',
    'i7-iv7': 'Aeolian',
    'i7-V7': 'Aeolian',
    'IM7-II7': 'Lydian',
    'I7-bVIIM7': 'Mixolydian',
    'I7-IVM7': 'Mixolydian',
    'i7-IV7': 'Dorian',
    'i7-bVIIM7': 'Dorian',
    'i7-bIIM7': 'Phrygian',
    'i7-vii7': 'Phrygian',
    'I7-#IV7': 'Altered',
    'I-ii-V': 'Major Scale',
    'IM7-ii7-V7': 'Major Scale',
    'I-IV-V': 'Major Scale',
    'I-IV': 'Major Scale',
    'I-ii-IV': 'Major Scale',
    'I-ii-IV-V': 'Major Scale',
    'I-V-vi-IV': 'Major Scale',
    'I-ii-iii-IV': 'Major Scale',
    'I-ii-iii-IV-V-V': 'Major Scale',
    'I-iii-vi-ii-V': 'Major Scale',
    'I-vi-ii-V': 'Major Scale',
    'I-viio-iii-vi-ii-V': 'Major Scale',
    'I-VII7-III7-VI7-II7-V7': 'Major Scale',
    'I-bII-bVI-bIII-bVII-IV': 'Major Scale',
    'I-IV-viio-iii-vi-ii-V': 'Major Scale',
    'I-V-IV': 'Major Scale',
    'I-bVI-bIII-bVII-IV': 'Dorian',
    'I-bVI-bIII-bVII-IV-V': 'Dorian',
    'IM7-II7-IM7': 'Lydian',
    'IM7-II7': 'Lydian',
    'I-bVII-IV': 'Mixolydian',
    'I-I-bVI-bVII': 'Mixolydian',
    'I-I-bIII-IV': 'Mixolydian',
    'I-I-bVII-IV': 'Mixolydian',
    'I-bVII-IV-IV': 'Mixolydian',
    'i-iio-V': 'Aeolian',
    'i7-iim7b5-V7': 'Aeolian',
    'i-iv-V': 'Aeolian',
    'i-iv-bVII-bIII-bVI-iio-V': 'Aeolian',
    'i-bVII-bIII-bVI-iio-V': 'Aeolian',
    'i-bIII-bVI-iio-V': 'Aeolian',
    'i-bVI-iio-V': 'Aeolian',
    'i-bVII-IV': 'Dorian',
    'i-i-bVII-IV': 'Dorian',
    'i-bVII-IV-IV': 'Dorian',
    'I-VI7-ii-V': 'Major Scale',
    'I-iii-VI7-ii-V': 'Major Scale',
    'I-III7-VI7-ii-V': 'Major Scale',
    'I-VII7-iii-IV-V': 'Major Scale',
    'I-VII7-iii-IV-I-V': 'Major Scale',
    'I-#ivo-VII7-iii-IV-V': 'Major Scale',
    'I-I7-IV-V': 'Major Scale',
    'I-I-II7-V': 'Major Scale',
    'I-vi-II7-V': 'Major Scale',
    'I-III7-vi-ii-V': 'Major Scale',
    'I-viio-III7-vi-ii-V': 'Major Scale',
    'I-#IV7-viio-V': 'Major Scale',
    'I-V-bVII-IV': 'Major Scale',
    'I-V-bVII-IV-bVI-bIII': 'Major Scale',
    'I-bIII7-bVI-V7': 'Major Scale',
    'I-bvii-bIII7-bVI-V7': 'Major Scale',
    'I-bVII7-bIII-IV': 'Major Scale',
    'I-iv-bVII7-bIII-IV': 'Major Scale',
    'I-v-I7-IV-V': 'Major Scale',
    'I-bII-V': 'Major Scale',
    'I-IV-bII-V': 'Major Scale',
    'I-V-bII': 'Major Scale',
    'i-iv-bII-V': 'Aeolian',
    'I-ii-bII-V': 'Major Scale',
    'I-iii-ii-bII': 'Major Scale',
    'I-bVI7-bII-V': 'Major Scale',
    'I-biii-bVI7-bII-V': 'Major Scale',
    'I7-bIII7-#IV7-VI7': 'Mixolydian',
    'I7-VI7-#IV7-bIII7': 'Mixolydian',
    'I7-III7-bVI7': 'Mixolydian',
    'I7-bVI7-III7': 'Mixolydian',
    'I7-#IV7': 'Altered',
    'I7-io7': 'Dorian #4',
    'I-II-IV-I': 'Major Scale',
    'I-vii-II7': 'Lydian',
    'I-VII7-iii7-VI7-ii7-V7': 'Major Scale',
}


var roman_numerals_to_choose_from = [
    'I',
    'I6',
    'I69',
    'I64',
    'I+',
    'I+M7',
    'I7',
    'I9',
    'i7',
    'i9',
    'i',
    'io',
    'io7',
    'IM7',
    'IM9',
    'bII',
    'bIIM7',
    'bII7',
    'ii',
    'II',
    'ii7',
    'ii9',
    'iio',
    'iim7b5',
    'iim9b5',
    'II7',
    'bIIIM7',
    'bIII',
    'biii',
    'bIII7',
    'bIII9',
    'bIIIM9',
    'iii',
    'III',
    'iii7',
    'iii9',
    'iiib9',
    'III7',
    'III9',
    'III+7',
    'III+',
    'IV',
    'iv',
    'iv7',
    'iv9',
    'IV7',
    'IVM7#11',
    'IV6',
    'IV9',
    'IVM7',
    'IVM9',
    '#iv',
    '#iv7',
    '#ivo7',
    '#ivo',
    '#IV7',
    '#IV',
    'V',
    'v',
    'v9',
    'v7',
    'V7',
    'V9',
    'V13',
    'V+7',
    'V+',
    'V7#11',
    'V7#5',
    'V7b5',
    'V7b9',
    'V7#9',
    'V7b9b13',
    'V7#9b13',
    'V7#9#11',
    'Vsus2',
    'Vsus',
    'Vsus24',
    'Vsus4',
    'bVI',
    'bVI7',
    'bVIM7',
    'bVI9',
    'bVIM9',
    'vi',
    'vi7',
    'VI7',
    'vi9',
    'VI9',
    'VI',
    'VI7',
    'VI+7',
    'VI+',
    'bvii',
    'bvii7',
    'bVII',
    'bVII7',
    'bVIIM7',
    'bVII9',
    'bVIIM7',
    'bVIIM9',
    'viio',
    'viim7b5',
    'viim9b5',
    'VII7',
    'vii',
    'VII9',
    'vii9',
    'VII'
]

var chord_scale_intervals_to_choose_from = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
var chord_scale_types_to_choose_from = []
var chord_types_to_choose_from_plus_root = ['Root']
for (var i = 0; i < chord_types_to_choose_from.length; i++) {
    chord_types_to_choose_from_plus_root.push(chord_types_to_choose_from[i])
}
for (var i = 0; i < chord_types_to_choose_from.length; i++) {
    for (var j = 0; j < scale_types_to_choose_from.length; j++) {
        for (var k = 0; k < chord_scale_intervals_to_choose_from.length; k++) {
            chord_scale_types_to_choose_from.push(
                chord_types_to_choose_from_plus_root[i] + '|' +
                scale_types_to_choose_from[j] + '|' +
                chord_scale_intervals_to_choose_from[k]
            )
        }
    }
}


// remove "Chromatic"
var scale_types_to_play_to_choose_from = [...scale_types_to_choose_from]
var index = scale_types_to_play_to_choose_from.indexOf('Chromatic');
if (index !== -1) {
    scale_types_to_play_to_choose_from.splice(index, 1);
}

var default_available_game_types = [
    "Interval Find", "Interval Find 1", "Interval Find Zone",
    "Interval Find Sequence", "Interval Find 3", "Note Find", "Note Find Zone", "Note Find 1", "Note Find 5",
    "Note + Interval Find", "Note + Interval Find Zone", "Note + Interval Find 1",
    "Note + Interval Find 5", "Interval Group Spell", "Chord Spell", "Scale Spell",
    "Interval Chord Spell", "Interval Scale Spell", "Show Chord", "Show Scale", "Show Chord & Scale"
]
var game_types_with_meta_mode = [
    "Interval Find", "Interval Find 1", "Interval Find Zone",
    //"Interval Find Sequence", 
    "Interval Find 3", "Note Find", "Note Find Zone", "Note Find 1", "Note Find 5",
    "Note + Interval Find", "Note + Interval Find Zone", "Note + Interval Find 1",
    "Note + Interval Find 5",
    //"Interval Group Spell", "Chord Spell", "Scale Spell",
    //"Interval Chord Spell", "Interval Scale Spell", "Show Chord", "Show Scale", "Show Chord & Scale"
]
var playable_game_types = [
    "Interval Find", "Interval Find 1", "Interval Find Zone",
    "Interval Find Sequence", "Interval Find 3", "Note Find", "Note Find Zone", "Note Find 1", "Note Find 5",
    "Note + Interval Find", "Note + Interval Find Zone", "Note + Interval Find 1",
    "Note + Interval Find 5", "Interval Group Spell", "Chord Spell", "Scale Spell",
    "Interval Chord Spell", "Interval Scale Spell"
]
var all_available_zone_sizes = [1, 2, 3]
var default_available_zone_sizes = [2]
var all_available_reference_note_frets = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
var default_available_reference_note_frets = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
var all_available_answer_note_frets = [...all_available_reference_note_frets]
var default_available_answer_note_frets = [...default_available_reference_note_frets]
var default_available_answer_note_strings = [0, 1, 2, 3, 4, 5]
var default_available_reference_note_strings = [0, 1, 2, 3, 4, 5]
var default_available_zones = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
var default_available_note_reference_note_distances = [-4, -3, -2, -1, 0, 1, 2, 3, 4]
var default_available_note_reference_note_string_distances = [-4, -3, -2, -1, 1, 0, 1, 2, 3, 4]
var default_available_octave_jumps = [-1, 0]
var default_available_interval_types = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
var all_available_octave_jumps = [-3, -2, -1, 0, 1, 2, 3]
var all_available_note_reference_note_distances = [-11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
var all_available_note_reference_note_string_distances = [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5]
var show_all_available_note_reference_note_distances = ['end thru -7', -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 6, '7 thru end']
var extremes_available_note_reference_note_distances = [-11, -10, -9, -8, -7, -6, -5, -4, 4, 5, 6, 7, 8, 9, 10, 11]
var extremes_available_note_reference_note_string_distances = [-5, -4, 4, 5]

var map_lesson_to_query_string = {
    'notes_anywhere': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Note%20Find%205&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=true&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'notes_by_string': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Note%20Find&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=true&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'notes_by_fret': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Note%20Find%20Zone&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=true&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'intervals_anywhere': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find%203&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=true&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'intervals_by_string_close': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'intervals_by_string_far': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-10%2C-11%2C-4%2C-5%2C-6%2C-7%2C-8%2C-9%2C10%2C11%2C4%2C5%2C6%2C7%2C8%2C9&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'notes_intervals_by_string': 'game_type=Note%20+%20Interval%20Find&tuning=guitar&tonic=undefined&reference_style=note&strum=true&show_mic=true&mic_hold=true&instrument=254&show_intervals=true&show_note_names=true&show_hints=true&cut_audio=true&volume=50&delay=50&freeze=false&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=true&interval_group_length=NaN&note_reference_note_fret_distances=-1,-10,-11,-2,-3,-4,-5,-6,-7,-8,-9,0,1,10,11,2,3,4,5,6,7,8,9&note_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&show_random_top_note_options=false&intervals_relative_to_scale_root=true&show_chord_loop=false&bandit_activated=true&show_bandit_results=true&chord_loop_tempo=90&beats_per_chord=4&bass_options_drone=false&bass_options_chord_roots=false&chord_loop=&game_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
    'notes_intervals_by_fret': 'game_type=Note%20+%20Interval%20Find%20Zone&tuning=guitar&tonic=undefined&reference_style=note&strum=true&show_mic=true&mic_hold=true&instrument=254&show_intervals=true&show_note_names=true&show_hints=true&cut_audio=true&volume=50&delay=50&freeze=false&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=true&interval_group_length=NaN&note_reference_note_fret_distances=-1,-10,-11,-2,-3,-4,-5,-6,-7,-8,-9,0,1,10,11,2,3,4,5,6,7,8,9&note_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&show_random_top_note_options=false&intervals_relative_to_scale_root=true&show_chord_loop=false&bandit_activated=true&show_bandit_results=true&chord_loop_tempo=90&beats_per_chord=4&bass_options_drone=false&bass_options_chord_roots=false&chord_loop=&game_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
    'intervals_by_fret_close': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find%20Zone&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'intervals_by_fret_far': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find%20Zone&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-10%2C-11%2C-4%2C-5%2C-6%2C-7%2C-8%2C-9%2C10%2C11%2C4%2C5%2C6%2C7%2C8%2C9&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'intervals_anywhere_by_ear': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find%203&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=true&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=false&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'intervals_by_string_close_by_ear': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=false&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'intervals_by_string_far_by_ear': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-10%2C-11%2C-4%2C-5%2C-6%2C-7%2C-8%2C-9%2C10%2C11%2C4%2C5%2C6%2C7%2C8%2C9&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=false&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'notes_intervals_by_string_by_ear': 'game_type=Note%20+%20Interval%20Find&tuning=guitar&tonic=undefined&reference_style=note&strum=true&show_mic=true&mic_hold=true&instrument=254&show_intervals=false&show_note_names=true&show_hints=true&cut_audio=true&volume=50&delay=50&freeze=false&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=true&interval_group_length=NaN&note_reference_note_fret_distances=-1,-10,-11,-2,-3,-4,-5,-6,-7,-8,-9,0,1,10,11,2,3,4,5,6,7,8,9&note_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&show_random_top_note_options=false&intervals_relative_to_scale_root=true&show_chord_loop=false&bandit_activated=true&show_bandit_results=true&chord_loop_tempo=90&beats_per_chord=4&bass_options_drone=false&bass_options_chord_roots=false&chord_loop=&game_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
    'notes_intervals_by_fret_by_ear': 'game_type=Note%20+%20Interval%20Find%20Zone&tuning=guitar&tonic=undefined&reference_style=note&strum=true&show_mic=true&mic_hold=true&instrument=254&show_intervals=false&show_note_names=true&show_hints=true&cut_audio=true&volume=50&delay=50&freeze=false&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=true&interval_group_length=NaN&note_reference_note_fret_distances=-1,-10,-11,-2,-3,-4,-5,-6,-7,-8,-9,0,1,10,11,2,3,4,5,6,7,8,9&note_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&show_random_top_note_options=false&intervals_relative_to_scale_root=true&show_chord_loop=false&bandit_activated=true&show_bandit_results=true&chord_loop_tempo=90&beats_per_chord=4&bass_options_drone=false&bass_options_chord_roots=false&chord_loop=&game_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
    'intervals_by_fret_close_by_ear': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find%20Zone&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=false&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'intervals_by_fret_far_by_ear': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find%20Zone&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-10%2C-11%2C-4%2C-5%2C-6%2C-7%2C-8%2C-9%2C10%2C11%2C4%2C5%2C6%2C7%2C8%2C9&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=false&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'intervals_in_sequence_by_ear': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Interval%20Find%20Sequence&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-10%2C-11%2C-4%2C-5%2C-6%2C-7%2C-8%2C-9%2C10%2C11%2C4%2C5%2C6%2C7%2C8%2C9&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=false&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'spell_major_triads': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Chord%20Spell&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_andomizer_settings=true&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'spell_minor_triads': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Chord%20Spell&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_andomizer_settings=true&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'spell_major_scales': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Scale%20Spell&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_andomizer_settings=true&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'spell_minor_scales': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=true&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Scale%20Spell&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_andomizer_settings=true&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'play_along_to_C_major_scale': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=false&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=false&game_type=Major%20Scale&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'play_along_to_G_major_over_C_major_scale': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=false&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=&chord_loop_tempo=90&chord_octave=3&cut_audio=true&delay=50&freeze=true&game_type=Dom7%7CMajor%20Scale%7C7&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=true&random_top_tension=true&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=false&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=true&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'play_along_to_I_V_vi_IV_in_C_major': 'answer_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&answer_note_strings=0%2C1%2C2%2C3%2C4%2C5&bandit_activated=false&bass_options_chord_roots=false&bass_options_drone=false&beats_per_chord=4&chord_loop=I-V-vi-IV&chord_loop_tempo=60&chord_octave=3&cut_audio=false&delay=50&freeze=true&game_type=Major%7CMajor%20Scale%7C5&game_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12%2C13%2C14%2C15%2C16%2C17%2C18%2C19%2C20&instrument=254&interval_group_length=NaN&interval_types=0%2C1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11&intervals_relative_to_scale_root=true&main_settings=true&maximum_interval_distance=17&meta_mode=false&meta_mode_repetitions=2&mic_hold=true&minimum_interval_distance=0&note_reference_note_fret_distances=-3%2C-2%2C-1%2C0%2C1%2C2%2C3&note_reference_note_string_distances=-4%2C-3%2C-2%2C-1%2C1%2C0%2C1%2C2%2C3%2C4&octave_jumps=-1%2C0&random_top_note=false&random_top_tension=false&randomizer_settings=true&reference_note_frets=1%2C2%2C3%2C4%2C5%2C6%2C7%2C8%2C9%2C10%2C11%2C12&reference_note_strings=0%2C1%2C2%2C3%2C4%2C5&reference_style=note&show_bandit_results=true&show_chord_loop=true&show_freeze=true&show_hints=true&show_intervals=true&show_main_settings=false&show_mic=true&show_note_names=true&show_random_top_note_options=false&show_randomizer_settings=false&show_roman_numeral_buttons=false&strum=false&tonic=undefined&tuning=guitar&volume=50&zone_sizes=2',
    'meta_mode': 'game_type=Interval%20Find%20Zone&tuning=guitar&tonic=C4&reference_style=note&strum=false&show_mic=true&mic_hold=true&instrument=254&show_intervals=false&show_note_names=true&show_hints=true&cut_audio=false&volume=50&delay=50&freeze=false&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=false&interval_group_length=NaN&note_reference_note_fret_distances=-4,-3,-2,-1,0,1,2,3,4&note_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=true&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_andomizer_settings=true&show_roman_numeral_buttons=false&show_random_top_note_options=true&intervals_relative_to_scale_root=true&show_chord_loop=true&bandit_activated=true&show_bandit_results=true&chord_loop_tempo=60&beats_per_chord=8&bass_options_drone=true&bass_options_chord_roots=false&chord_loop=i7-IV7&game_types=0,2,4,6,9,10,12',
    'two_chord_vamp_show_next_chord': 'game_type=Major%7CMajor%20Scale%7C0&tuning=guitar&tonic=C4&reference_style=note&strum=false&show_mic=true&mic_hold=true&instrument=254&show_intervals=true&show_note_names=true&show_hints=true&cut_audio=false&volume=50&delay=50&freeze=true&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=false&interval_group_length=NaN&note_reference_note_fret_distances=-4,-3,-2,-1,0,1,2,3,4&note_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_andomizer_settings=true&show_roman_numeral_buttons=false&show_random_top_note_options=true&intervals_relative_to_scale_root=true&show_chord_loop=true&bandit_activated=false&show_bandit_results=true&chord_loop_tempo=50&beats_per_chord=8&bass_options_drone=true&bass_options_chord_roots=false&chord_loop=IM7-V7&show_next_chord=truegame_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
    'two_chord_vamp_dont_show_next_chord': 'game_type=Major%7CMajor%20Scale%7C0&tuning=guitar&tonic=C4&reference_style=note&strum=false&show_mic=true&mic_hold=true&instrument=254&show_intervals=true&show_note_names=true&show_hints=true&cut_audio=false&volume=50&delay=50&freeze=true&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=false&interval_group_length=NaN&note_reference_note_fret_distances=-4,-3,-2,-1,0,1,2,3,4&note_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_andomizer_settings=true&show_roman_numeral_buttons=false&show_random_top_note_options=true&intervals_relative_to_scale_root=true&show_chord_loop=true&bandit_activated=false&show_bandit_results=true&chord_loop_tempo=50&beats_per_chord=8&bass_options_drone=true&bass_options_chord_roots=false&chord_loop=IM7-V7&show_next_chord=false&game_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
    'free_compose': 'game_type=Major|Major%20Scale|0&tuning=guitar&tonic=C4&reference_style=note&strum=false&show_mic=true&mic_hold=true&instrument=254&show_intervals=true&show_note_names=true&show_hints=true&cut_audio=false&volume=50&delay=50&freeze=true&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=false&interval_group_length=NaN&note_reference_note_fret_distances=-4,-3,-2,-1,0,1,2,3,4&note_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_andomizer_settings=true&show_roman_numeral_buttons=true&show_random_top_note_options=true&intervals_relative_to_scale_root=true&show_chord_loop=false&bandit_activated=false&show_bandit_results=true&chord_loop_tempo=60&beats_per_chord=8&bass_options_drone=true&bass_options_chord_roots=false&chord_loop=IM7-V7&game_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
    'circle_of_fifths_show_next_chord': 'game_type=Major%7CMajor%20Scale%7C0&tuning=guitar&tonic=C&reference_style=note&strum=false&show_mic=true&mic_hold=true&instrument=254&drone_instrument=160&show_intervals=true&show_note_names=true&show_hints=true&cut_audio=false&volume=50&drum_volume=25&drone_volume=10&delay=50&freeze=true&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=false&show_next_chord=true&interval_group_length=NaN¬e_reference_note_fret_distances=-4,-3,-2,-1,0,1,2,3,4¬e_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_randomizer_settings=false&show_bandit_settings=false&show_roman_numeral_buttons=false&show_random_top_note_options=true&intervals_relative_to_scale_root=false&show_chord_loop=true&bandit_activated=false&bandit_results=false&bandit_difficulty=90&chord_loop_tempo=50&beats_per_chord=4&bass_options_drone=false&bass_options_chord_roots=true&chord_loop=I-VII7-III7-VI7-II7-V7&game_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
    'circle_of_fourths_show_next_chord': 'game_type=Major|Major%20Scale|0&tuning=guitar&tonic=C&reference_style=note&strum=false&show_mic=true&mic_hold=true&instrument=254&drone_instrument=160&show_intervals=true&show_note_names=true&show_hints=true&cut_audio=false&volume=50&drum_volume=25&drone_volume=10&delay=50&freeze=true&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=false&show_next_chord=true&interval_group_length=NaN&note_reference_note_fret_distances=-4,-3,-2,-1,0,1,2,3,4&note_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_randomizer_settings=false&show_bandit_settings=false&show_roman_numeral_buttons=false&show_random_top_note_options=true&intervals_relative_to_scale_root=false&show_chord_loop=true&bandit_activated=false&bandit_results=false&bandit_difficulty=90&chord_loop_tempo=50&beats_per_chord=4&bass_options_drone=false&bass_options_chord_roots=true&chord_loop=I-bII-bVI-bIII-bVII-IV&game_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
    'circle_of_fifths_dont_show_next_chord': 'game_type=Major%7CMajor%20Scale%7C0&tuning=guitar&tonic=C&reference_style=note&strum=false&show_mic=true&mic_hold=true&instrument=254&drone_instrument=160&show_intervals=true&show_note_names=true&show_hints=true&cut_audio=false&volume=50&drum_volume=25&drone_volume=10&delay=50&freeze=true&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=false&show_next_chord=false&interval_group_length=NaN¬e_reference_note_fret_distances=-4,-3,-2,-1,0,1,2,3,4¬e_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_randomizer_settings=false&show_bandit_settings=false&show_roman_numeral_buttons=false&show_random_top_note_options=true&intervals_relative_to_scale_root=false&show_chord_loop=true&bandit_activated=false&bandit_results=false&bandit_difficulty=90&chord_loop_tempo=50&beats_per_chord=4&bass_options_drone=false&bass_options_chord_roots=true&chord_loop=I-VII7-III7-VI7-II7-V7&game_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
    'circle_of_fourths_dont_show_next_chord': 'game_type=Major|Major%20Scale|0&tuning=guitar&tonic=C&reference_style=note&strum=false&show_mic=true&mic_hold=true&instrument=254&drone_instrument=160&show_intervals=true&show_note_names=true&show_hints=true&cut_audio=false&volume=50&drum_volume=25&drone_volume=10&delay=50&freeze=true&show_freeze=true&minimum_interval_distance=0&maximum_interval_distance=17&interval_types=0,1,2,3,4,5,6,7,8,9,10,11&reference_note_strings=0,1,2,3,4,5&reference_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_frets=1,2,3,4,5,6,7,8,9,10,11,12&answer_note_strings=0,1,2,3,4,5&octave_jumps=-1,0&zone_sizes=2&chord_octave=3&random_top_note=true&random_top_tension=false&show_next_chord=false&interval_group_length=NaN&note_reference_note_fret_distances=-4,-3,-2,-1,0,1,2,3,4&note_reference_note_string_distances=-4,-3,-2,-1,1,0,1,2,3,4&meta_mode=false&meta_mode_repetitions=2&main_settings=true&randomizer_settings=true&bandit_settings=true&show_main_settings=false&show_randomizer_settings=false&show_bandit_settings=false&show_roman_numeral_buttons=false&show_random_top_note_options=true&intervals_relative_to_scale_root=false&show_chord_loop=true&bandit_activated=false&bandit_results=false&bandit_difficulty=90&chord_loop_tempo=50&beats_per_chord=4&bass_options_drone=false&bass_options_chord_roots=true&chord_loop=I-bII-bVI-bIII-bVII-IV&game_types=0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17',
}

if (window.location.href.indexOf('?') == -1)
    window.location.href = "https://www.koyotescience.com/fret-ferret";

var GET = {};
var query = window.location.search.substring(1).split("&");
for (var i = 0, max = query.length; i < max; i++) {
    if (query[i] === "") // check for trailing & with no param
        continue;

    var param = query[i].split("=");
    GET[decodeURIComponent(param[0])] = decodeURIComponent(param[1] || "");
}


var url_user_id = null
if (typeof (GET['user_id']) != 'undefined') {
    url_user_id = GET['user_id']
}
var url_lesson = null
if (typeof (GET['lesson']) != 'undefined') {
    url_lesson = GET['lesson']
}
var new_query_string = null
if (url_lesson != null && Object.keys(map_lesson_to_query_string).includes(url_lesson)) {
    new_query_string = map_lesson_to_query_string[url_lesson]
    if (url_user_id != null) {
        new_query_string += '&user_id=' + encodeURIComponent(url_user_id)
    }

    GET = {}
    query = new_query_string.split('&')
    for (var i = 0, max = query.length; i < max; i++) {
        if (query[i] === "") // check for trailing & with no param
            continue;

        var param = query[i].split("=");
        GET[decodeURIComponent(param[0])] = decodeURIComponent(param[1] || "");
    }
}


var url_tonic = "C"
if (typeof (GET['tonic']) != 'undefined') {
    url_tonic = GET['tonic']
}
if (url_tonic == '') {
    url_tonic = 'C'
}

var url_chord_type = "Root"
if (typeof (GET['game_type']) != 'undefined') {
    url_chord_type = GET['game_type']
} else if (typeof (GET['chord_type']) != 'undefined') {
    url_chord_type = GET['chord_type']
} else if (typeof (GET['scale_type']) != 'undefined') {
    url_chord_type = GET['scale_type']
}

var url_bass_options_drone = false
if (typeof (GET['bass_options_drone']) != 'undefined') {
    url_bass_options_drone = GET['bass_options_drone'] == 'true'
}

var url_bass_options_chord_roots = false
if (typeof (GET['bass_options_chord_roots']) != 'undefined') {
    url_bass_options_chord_roots = GET['bass_options_chord_roots'] == 'true'
}

var url_main_settings_exists = true
if (typeof (GET['main_settings']) != 'undefined') {
    url_main_settings_exists = GET['main_settings'] == 'true'
}

// var url_show_bandit_results = true
// if (typeof (GET['show_bandit_results']) != 'undefined') {
//     url_show_bandit_results = GET['show_bandit_results'] == 'true'
// }


var url_randomizer_settings_exists = true
if (typeof (GET['randomizer_settings']) != 'undefined') {
    url_randomizer_settings_exists = GET['randomizer_settings'] == 'true'
}
var url_bandit_settings_exists = true
if (typeof (GET['bandit_settings']) != 'undefined') {
    url_bandit_settings_exists = GET['bandit_settings'] == 'true'
}

var url_bandit_results_exists = false
if (typeof (GET['bandit_results']) != 'undefined') {
    url_bandit_results_exists = GET['bandit_results'] == 'true'
}

var url_show_main_settings = false
if (typeof (GET['main_settings']) != 'undefined') {
    url_show_main_settings = GET['show_main_settings'] == 'true'
}

var url_show_randomizer_settings = false
if (typeof (GET['randomizer_settings']) != 'undefined') {
    url_show_randomizer_settings = GET['show_randomizer_settings'] == 'true'
}

var url_show_bandit_settings = false
if (typeof (GET['show_bandit_settings']) != 'undefined') {
    url_show_bandit_settings = GET['show_bandit_settings'] == 'true'
}


var url_bandit_difficulty_level = 100 - bandit_spread // from -bandit_spread to 100+bandit_spread
if (typeof (GET['bandit_difficulty']) != 'undefined') {
    url_bandit_difficulty_level = parseInt(GET['bandit_difficulty'])
}

var url_tuning_name = "guitar"
if (typeof (GET['tuning_name']) != 'undefined') {
    url_tuning_name = GET['tuning_name']
} else if (typeof (GET['tuning']) != 'undefined') {
    url_tuning_name = GET['tuning']
}
var url_tuning = map_tuning_name_to_tuning[url_tuning_name]

var url_reference_style_strum = true
if (typeof (GET['strum']) != 'undefined') {
    url_reference_style_strum = GET['strum'] == 'true'
}

var url_show_mic = true
if (typeof (GET['show_mic']) != 'undefined') {
    url_show_mic = GET['show_mic'] == 'true'
}
var url_mic_hold = true
if (typeof (GET['show_mic']) != 'undefined') {
    url_mic_hold = GET['mic_hold'] == 'true'
}

var url_show_roman_numeral_buttons = false
if (typeof (GET['show_roman_numeral_buttons']) != 'undefined') {
    url_show_roman_numeral_buttons = GET['show_roman_numeral_buttons'] == 'true'
}


var url_show_random_top_note_options = false
if (typeof (GET['show_random_top_note_options']) != 'undefined') {
    url_show_random_top_note_options = GET['show_random_top_note_options'] == 'true'
}


var url_intervals_relative_to_scale_root = true
if (typeof (GET['intervals_relative_to_scale_root']) != 'undefined') {
    url_intervals_relative_to_scale_root = GET['intervals_relative_to_scale_root'] == 'true'
}


var url_bandit_activated = false
if (typeof (GET['bandit_activated']) != 'undefined') {
    url_bandit_activated = GET['bandit_activated'] == 'true'
}

var url_show_chord_loop = false
if (typeof (GET['show_chord_loop']) != 'undefined') {
    url_show_chord_loop = GET['show_chord_loop'] == 'true'
}

var url_random_top_note = false
if (typeof (GET['random_top_note']) != 'undefined') {
    url_random_top_note = GET['random_top_note'] == 'true'
}
var url_random_top_tension = false
if (typeof (GET['random_top_tension']) != 'undefined') {
    url_random_top_tension = GET['random_top_tension'] == 'true'
}
var url_chord_octave = 3
if (typeof (GET['chord_octave']) != 'undefined') {
    url_chord_octave = parseFloat(GET['chord_octave'])
}


var url_reference_style = "note"
if (typeof (GET['reference_style']) != 'undefined') {
    url_reference_style = GET['reference_style']
}
var url_interval_types = [...default_available_interval_types]
if (typeof (GET['interval_types']) != 'undefined') {
    url_interval_types = GET['interval_types'].split(url_sep).map(val => parseInt(val))
}
var url_reference_note_strings = [...default_available_reference_note_strings]
if (typeof (GET['reference_note_strings']) != 'undefined') {
    url_reference_note_strings = GET['reference_note_strings'].split(url_sep).map(val => parseInt(val))
}
var url_reference_note_frets = [...default_available_reference_note_frets]
if (typeof (GET['reference_note_frets']) != 'undefined') {
    url_reference_note_frets = GET['reference_note_frets'].split(url_sep).map(val => parseInt(val))
}
var url_answer_note_strings = [...default_available_answer_note_strings]
if (typeof (GET['answer_note_strings']) != 'undefined') {
    url_answer_note_strings = GET['answer_note_strings'].split(url_sep).map(val => parseInt(val))
}
var url_answer_note_frets = [...default_available_answer_note_frets]
if (typeof (GET['answer_note_frets']) != 'undefined') {
    url_answer_note_frets = GET['answer_note_frets'].split(url_sep).map(val => parseInt(val))
}
var url_note_reference_note_fret_distances = [...default_available_note_reference_note_distances]
if (typeof (GET['note_reference_note_fret_distances']) != 'undefined') {
    url_note_reference_note_fret_distances = GET['note_reference_note_fret_distances'].split(url_sep).map(val => parseInt(val))
}
var url_note_reference_note_string_distances = [...default_available_note_reference_note_string_distances]
if (typeof (GET['note_reference_note_string_distances']) != 'undefined') {
    url_note_reference_note_string_distances = GET['note_reference_note_string_distances'].split(url_sep).map(val => parseInt(val))
}
var url_octave_jumps = [...default_available_octave_jumps]
if (typeof (GET['octave_jumps']) != 'undefined') {
    url_octave_jumps = GET['octave_jumps'].split(url_sep).map(val => parseInt(val))
}
var url_game_types = [...Array(playable_game_types.length).keys()]
if (typeof (GET['game_types']) != 'undefined') {
    url_game_types = GET['game_types'].split(url_sep).map(val => parseInt(val))
}
url_game_types = url_game_types.map(val => playable_game_types[val])
url_game_types = url_game_types.filter(val => val != undefined)

var url_add_user_id_to_url = false
if (typeof (GET['user_id']) != 'undefined') {
    url_add_user_id_to_url = true
}

var url_show_next_chord = false
if (typeof (GET['show_next_chord']) != 'undefined') {
    url_show_next_chord = GET['show_next_chord'] == 'true'
}
var url_volume = 50
if (typeof (GET['volume']) != 'undefined') {
    url_volume = parseInt(parseInt(GET['volume']))
}
var url_drum_volume = Math.round(url_volume * 0.5)
if (typeof (GET['drum_volume']) != 'undefined') {
    url_drum_volume = parseInt(parseInt(GET['drum_volume']))
}
var url_drone_volume = Math.round(url_volume * 0.2)
if (typeof (GET['drone_volume']) != 'undefined') {
    url_drone_volume = parseInt(parseInt(GET['drone_volume']))
}
var url_echo = 20
if (typeof (GET['volume']) != 'undefined') {
    url_echo = parseInt(GET['echo'])
}
var url_beats_per_chord = 4
if (typeof (GET['beats_per_chord']) != 'undefined') {
    url_beats_per_chord = parseInt(GET['beats_per_chord'])
}
var url_chord_loop_tempo = 90
if (typeof (GET['chord_loop_tempo']) != 'undefined') {
    url_chord_loop_tempo = parseInt(GET['chord_loop_tempo'])
}
var url_instrument = 254
if (typeof (GET['instrument']) != 'undefined') {
    url_instrument = parseInt(GET['instrument'])
}
var url_drone_instrument = 160
if (typeof (GET['drone_instrument']) != 'undefined') {
    url_drone_instrument = parseInt(GET['drone_instrument'])
}

var url_delay = 50
if (typeof (GET['delay']) != 'undefined') {
    url_delay = parseInt(GET['delay'])
}
var url_meta_mode_repetitions = 2
if (typeof (GET['meta_mode_repetitions']) != 'undefined') {
    url_meta_mode_repetitions = parseInt(GET['meta_mode_repetitions'])
}
var url_freeze = true
if (typeof (GET['freeze']) != 'undefined') {
    url_freeze = GET['freeze'] == 'true'
}
var url_show_freeze = true
if (typeof (GET['show_freeze']) != 'undefined') {
    url_show_freeze = GET['show_freeze'] == 'true'
}
var url_zone_sizes = [...default_available_zone_sizes]
if (typeof (GET['zone_sizes']) != 'undefined') {
    url_zone_sizes = GET['zone_sizes'].split(url_sep).map(val => parseInt(val))
}
var url_show_intervals = true
if (typeof (GET['show_intervals']) != 'undefined') {
    url_show_intervals = GET['show_intervals'] == 'true'
}
var url_meta_mode = false
if (typeof (GET['meta_mode']) != 'undefined') {
    url_meta_mode = GET['meta_mode'] == 'true'
}
var url_show_hints = true
if (typeof (GET['show_hints']) != 'undefined') {
    url_show_hints = GET['show_hints'] == 'true'
}
var url_cut_audio = true
if (typeof (GET['cut_audio']) != 'undefined') {
    url_cut_audio = GET['cut_audio'] == 'true'
}
var url_show_note_names = true
if (typeof (GET['show_note_names']) != 'undefined') {
    url_show_note_names = GET['show_note_names'] == 'true'
}
var url_interval_group_length = true
if (typeof (GET['interval_group_length']) != 'undefined') {
    url_interval_group_length = parseInt(GET['interval_group_length'])
}

var url_available_minimum_interval_distance = 0
if (typeof (GET['minimum_interval_distance']) != 'undefined') {
    url_available_minimum_interval_distance = parseInt(GET['minimum_interval_distance'])
}
var url_available_maximum_interval_distance = 17
if (typeof (GET['maximum_interval_distance']) != 'undefined') {
    url_available_maximum_interval_distance = parseInt(GET['maximum_interval_distance'])
}

var url_chord_loop = [null, null, null, null, null, null]
if (typeof (GET['chord_loop']) != 'undefined') {
    var url_chord_loop_as_string = GET['chord_loop']
    var chord_loop_as_strings = url_chord_loop_as_string.split('-')
    console.log('url loading chord_loop_as_strings: ' + chord_loop_as_strings)
    for (var i = 0; i < 6; i++) {
        if (i < chord_loop_as_strings.length) {
            url_chord_loop[i] = map_roman_numeral_to_info[chord_loop_as_strings[i]]
        } else {
            url_chord_loop[i] = null
        }
    }
    console.log('url loading url_chord_loop: ' + url_chord_loop)
}

var url_chord_type_to_play = 'Major'
if (url_chord_type == 'Scale Spell') {
    url_chord_type_to_play = "Major Scale"
}
var url_message = "Get started! Either change the root by clicking on the fretboard, or select a game below.";
if (url_chord_type != "Root") {
    url_message = "Get started, or select a game below."
}


//
// End of URL pass-through
//


function convertChordTypeToMidi(getSelectedNotes, midi1, lower_midi, upper_midi, tmp_chord_type, midi_offset = 0, inversion = 0) {

    var base_note = Note.transpose(Midi.midiToNoteName(midi1), Interval.fromSemitones(midi_offset))

    var notes_full = getSelectedNotes(Note.pitchClass(base_note), tmp_chord_type)
    var notes_full_normalized = notes_full.map(val => {
        var distance = mod(Note.midi(val.note + '8') - Note.midi(base_note), 12)
        var new_note = Note.transpose(base_note, Interval.fromSemitones(distance))
        return (Note.midi(new_note))
    })
    notes_full_normalized = notes_full_normalized.map(val => {
        if (val < lower_midi || val >= upper_midi) {
            val = mod(val - lower_midi, 12) + lower_midi
        }
        return (val)
    })

    return (notes_full_normalized)

}

var fs = require('fs')

function mod(x, m) {
    return (x % m + m) % m;
}

function deepEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = isObject(val1) && isObject(val2);
        if (
            areObjects && !deepEqual(val1, val2) ||
            !areObjects && val1 !== val2
        ) {
            return false;
        }
    }

    return true;
}

function isObject(object) {
    return object != null && typeof object === 'object';
}

// from https://amjustsam.medium.com/how-to-find-most-frequent-item-of-an-array-12015df68c65
function getModeOfArray(array) {
    var counts = {};
    var compare = 0;
    var mostFrequent;
    for (var i = 0; i < array.length; i++) {
        var word = array[i];

        if (counts[word] === undefined) {
            counts[word] = 1;
        } else {
            counts[word] = counts[word] + 1;
        }
        if (counts[word] > compare) {
            compare = counts[word];
            mostFrequent = array[i];
        }
    }
    return mostFrequent;
}

var chord_show_msg = "Click on the fretboard to change the root, or use Freeze mode to play intervals instead"
var map_chord_type_to_message = {
    "Interval Find": "Play the given interval relative to the red note on the red string",
    "Interval Find 1": "Play the given interval relative to the red note anywhere",
    "Interval Find Zone": "Play the given interval relative to the red note in the red zone",
    "Interval Find Sequence": "Play the given interval relative to the red note anywhere",
    "Interval Find 3": "Play the given interval relative to the red note anywhere three times",
    "Note Find": "Play the given note on the red string",
    "Note Find Zone": "Play the given note in the red zone",
    "Note Find 1": "Play the given note anywhere",
    "Note Find 5": "Play the given note anywhere five times",
    "Note + Interval Find": "Play the given note on the red string",
    "Note + Interval Find Zone": "Play the given note in the red zone",
    "Note + Interval Find 1": "Play the given note anywhere",
    "Note + Interval Find 5": "Play the given note anywhere five times",
    "Interval Group Spell": "Play the notes of the given interval group anywhere",
    "Chord Spell": "Play the notes of the given chord anywhere",
    "Scale Spell": "Play the notes of the given scale anywhere",
    "Interval Chord Spell": "Play the notes of the given chord relative to the red note anywhere",
    "Interval Scale Spell": "Play the notes of the given scale relative to the red note anywhere",
    "Show Chord": chord_show_msg,
    "Show Scale": chord_show_msg,
    "Show Chord & Scale": chord_show_msg,
    "Root": chord_show_msg,
}

var map_game_type_internal_to_external = {
    "Interval Find": "Interval on String",
    "Interval Find 1": "Interval Anywhere",
    "Interval Find Zone": "Interval in Zone",
    "Interval Find Sequence": "Interval Anywhere in Sequence",
    "Interval Find 3": "Interval Anywhere 3x",
    "Note Find": "Note on String",
    "Note Find Zone": "Note in Zone",
    "Note Find 1": "Note Anywhere",
    "Note Find 5": "Note Anywhere 5x",
    "Note + Interval Find": "Note+Interval on String",
    "Note + Interval Find Zone": "Note+Interval in Zone",
    "Note + Interval Find 1": "Note+Interval Anywhere",
    "Note + Interval Find 5": "Note+Interval Anywhere 5x",
    "Interval Group Spell": "Interval Group Spell",
    "Chord Spell": "Chord Spell",
    "Scale Spell": "Scale Spell",
    "Interval Chord Spell": "Interval Chord Spell",
    "Interval Scale Spell": "Interval Scale Spell",
    "Show Chord": "Show Chord",
    "Show Scale": "Show Scale",
    "Show Chord & Scale": "Show Chord & Scale"
}
var chord_types_without_resets = ['Interval Find Sequence']

var i;
for (i = 0; i < chord_types_to_choose_from.length; i++) {
    map_chord_type_to_message[chord_types_to_choose_from[i]] = chord_show_msg
}
for (i = 0; i < scale_types_to_choose_from.length; i++) {
    map_chord_type_to_message[scale_types_to_choose_from[i]] = chord_show_msg
}
for (i = 0; i < chord_scale_types_to_choose_from.length; i++) {
    map_chord_type_to_message[chord_scale_types_to_choose_from[i]] = chord_show_msg
}

function getPitchClassInt(input_str) {
    var map_pitch_class_to_int = {
        "Ab": 12,
        "A": 1,
        "A#": 2,
        "Bb": 2,
        "B": 3,
        "C": 4,
        "C#": 5,
        "Db": 5,
        "D": 6,
        "D#": 7,
        "Eb": 7,
        "E": 8,
        "F": 9,
        "F#": 10,
        "Gb": 10,
        "G": 11,
        "G#": 12
    }
    return map_pitch_class_to_int[Note.simplify(Note.pitchClass(input_str))]
}

//
// START pitch detection section
//


var source;
var analyser;
var buffer;
var audioContext;
var microphone;
var microphone_input_frequency_trailing_number_of_samples = 0;
var microphone_input_frequency_trailing_average = 0;
var microphone_input_frequency = null;
var microphone_input_frequency_trailing_array = [];
var microphone_input_frequency_trailing_note_array = [];
var microphone_input_note = null
var microphone_input_cents_offset = null
var microphone_input_cents_offset_trailing_average = null
var microphone_works = false
const note_off_delay_in_ms = 500
const long_note_detection_interval = 100
const short_note_detection_interval = 50
if (url_bandit_activated) {
    var note_detection_interval = long_note_detection_interval
} else {
    var note_detection_interval = short_note_detection_interval
}
var previous_note_detection_timestamp = -1
var show_microphone_input = false
var microphone_pitch_detection_interval_handle = null

const Pitchfinder = require("pitchfinder");
const detectPitch = new Pitchfinder.AMDF(); //AMDF //YIN

function initializeMicrophone() {

    function callback_after_initializing_microphone(stream) {

        audioContext = new (window.AudioContext || window.webkitAudioContext)();
        console.log('audioContext.state: ' + audioContext.state); //suspended
        audioContext.resume();
        audioContext.onstatechange = () => console.log('audioContext.state onstatechange: ' + audioContext.state); // running

        analyser = audioContext.createAnalyser();
        analyser.fftSize = 4096 / 2;
        var bufferLength = analyser.frequencyBinCount;
        console.log('bufferLength: ' + bufferLength);
        var floatDataArrayFrequency = new Float32Array(bufferLength);
        var floatDataArrayTimeDomain = new Float32Array(bufferLength);
        var byteDataArrayTimeDomain = new Uint8Array(bufferLength);

        analyser.minDecibels = -90;
        analyser.maxDecibels = 2;
        analyser.smoothingTimeConstant = 0.0;

        microphone = audioContext.createMediaStreamSource(stream);
        microphone.connect(analyser);

        microphone_works = true


        if (microphone_pitch_detection_interval_handle !== null) {
            clearInterval(microphone_pitch_detection_interval_handle)
        }

        microphone_pitch_detection_interval_handle = setInterval(() => {

            analyser.getFloatFrequencyData(floatDataArrayFrequency);
            // analyser.getFloatTimeDomainData(floatDataArrayTimeDomain);
            analyser.getByteTimeDomainData(byteDataArrayTimeDomain);
            floatDataArrayTimeDomain = new Float32Array(byteDataArrayTimeDomain)
            // console.log('floatDataArrayFrequency.length: ' + floatDataArrayFrequency.length)
            // console.log('floatDataArrayTimeDomain.length: ' + floatDataArrayTimeDomain.length)

            microphone_input_frequency = detectPitch(floatDataArrayTimeDomain);

            // crude hack to account for roughly one semitone lower results on my iphone
            if (['iPhone'].includes(navigator.platform)) {
                microphone_input_frequency *= 1.08 // 1.059 is semitone, 1.120 is whole time
            }

            if (microphone_input_frequency_trailing_array.length > 2) {
                microphone_input_frequency_trailing_array.shift()
                microphone_input_frequency_trailing_array.push(microphone_input_frequency)
                microphone_input_frequency_trailing_note_array.shift()
                microphone_input_frequency_trailing_note_array.push(microphone_input_note)

                microphone_input_frequency_trailing_number_of_samples = 0;
                var microphone_input_frequency_trailing_sum = 0;
                for (var i = 0; i < microphone_input_frequency_trailing_array.length; i++) {
                    if (microphone_input_frequency_trailing_array[i] != null) {
                        microphone_input_frequency_trailing_sum += microphone_input_frequency_trailing_array[i]
                        microphone_input_frequency_trailing_number_of_samples += 1
                    }
                }
                microphone_input_frequency_trailing_average = microphone_input_frequency_trailing_sum * 1.0 / microphone_input_frequency_trailing_number_of_samples

            } else {
                microphone_input_frequency_trailing_array.push(microphone_input_frequency)
                microphone_input_frequency_trailing_note_array.push(microphone_input_note)
            }
        }, note_detection_interval)

    }

    navigator.getUserMedia = (navigator.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.msGetUserMedia);

    if (navigator.getUserMedia) {

        console.log('getUserMedia supported.');
        navigator.getUserMedia(
            // constraints - only audio needed for this app
            {audio: true, video: false},

            // Success callback,
            callback_after_initializing_microphone,

            // Error callback
            function (err) {
                console.log('The following getUserMedia error occured: ' + err);
            }
        );

    } else if (typeof (navigator.getUserMedia) == 'undefined' && typeof (navigator.mediaDevices.getUserMedia) !== 'undefined') {

        var constraints = {audio: true, video: false}
        var promise = navigator.mediaDevices.getUserMedia(constraints).then(callback_after_initializing_microphone).catch((err) => console.log('The following gUM error occured: ' + err))
    } else {
        console.log('getUserMedia not supported on your browser!');
    }
}

//
// END pitch detection section
//


// from https://stackoverflow.com/questions/7128675/from-green-to-red-color-depend-on-percentage
function hsl_col_perc(percent, start, end) {
    var a = percent / 100,
        b = (end - start) * a,
        c = b + start;

    // Return a CSS HSL string
    return 'hsl(' + c + ', 100%, 50%)';
}

//Change the start and end values to reflect the hue map
//Refernece : http://www.ncl.ucar.edu/Applications/Images/colormap_6_3_lg.png

/*
Quick ref:
    0 – red
    60 – yellow
    120 – green
    180 – turquoise
    240 – blue
    300 – pink
    360 – red
*/

var time_to_correct_guess_output_metadata = {
    "linear_logistic_or_categorical": "linear",
    "distribution": "log_transformed_uniform",
    "additive_offset": 1,
    "original_minimum_value": 0,
    "original_maximum_value": 50,
    "minimum_value": 0,
    "maximum_value": Math.log10(50),
}

var possible_string_values = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5"
]
var possible_fret_values = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12"
]

var possible_fret_zone_values = [
    "0",
    "1-3",
    "4-6",
    "7-9",
    "10-12"
]

var possible_interval_values = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11"
]

var possible_fret_distance_values = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "-1",
    "-2",
    "-3",
    "-4",
    "-5",
    "-6",
    "-7",
    "-8",
    "-9",
    "-10",
    "-11",
    "-12"
]

var possible_pitch_class_values = [
    "A",
    "A#",
    "Bb",
    "B",
    "C",
    "C#",
    "Db",
    "D",
    "D#",
    "Eb",
    "E",
    "F",
    "F#",
    "Gb",
    "G",
    "G#",
    "Ab"
]

var possible_pitch_class_values_simplified = [
    "A",
    "Bb",
    "B",
    "C",
    "Db",
    "D",
    "Eb",
    "E",
    "F",
    "F#",
    "G",
    "Ab"
]
var possible_pitch_class_as_int_values = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11"
]
var possible_string_and_fret_values = [
    "0-0",
    "0-1",
    "0-2",
    "0-3",
    "0-4",
    "0-5",
    "0-6",
    "0-7",
    "0-8",
    "0-9",
    "0-10",
    "0-11",
    "0-12",
    "1-0",
    "1-1",
    "1-2",
    "1-3",
    "1-4",
    "1-5",
    "1-6",
    "1-7",
    "1-8",
    "1-9",
    "1-10",
    "1-11",
    "1-12",
    "2-0",
    "2-1",
    "2-2",
    "2-3",
    "2-4",
    "2-5",
    "2-6",
    "2-7",
    "2-8",
    "2-9",
    "2-10",
    "2-11",
    "2-12",
    "3-0",
    "3-1",
    "3-2",
    "3-3",
    "3-4",
    "3-5",
    "3-6",
    "3-7",
    "3-8",
    "3-9",
    "3-10",
    "3-11",
    "3-12",
    "4-0",
    "4-1",
    "4-2",
    "4-3",
    "4-4",
    "4-5",
    "4-6",
    "4-7",
    "4-8",
    "4-9",
    "4-10",
    "4-11",
    "4-12",
    "5-0",
    "5-1",
    "5-2",
    "5-3",
    "5-4",
    "5-5",
    "5-6",
    "5-7",
    "5-8",
    "5-9",
    "5-10",
    "5-11",
    "5-12",
]
var possible_string_and_string_values = [
    "0-0",
    "0-1",
    "0-2",
    "0-3",
    "0-4",
    "0-5",
    "1-0",
    "1-1",
    "1-2",
    "1-3",
    "1-4",
    "1-5",
    "2-0",
    "2-1",
    "2-2",
    "2-3",
    "2-4",
    "2-5",
    "3-0",
    "3-1",
    "3-2",
    "3-3",
    "3-4",
    "3-5",
    "4-0",
    "4-1",
    "4-2",
    "4-3",
    "4-4",
    "4-5",
    "5-0",
    "5-1",
    "5-2",
    "5-3",
    "5-4",
    "5-5"
]

var note_find_zone_feature_metadata = [
    {
        "index": 0, // redundant, just here for convenience
        "name": "answer_string",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_string_values
    },
    {
        "index": 1, // redundant, just here for convenience
        "name": "answer_fret",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_fret_values
    },
    // {
    //   "index": 2, // redundant, just here for convenience
    //   "name": "pitch_class",
    //   "categorical_or_continuous": "categorical",
    //   "possible_values": possible_pitch_class_values
    // },
    // {
    //     "index": 3, // redundant, just here for convenience
    //     "name": "answer_string_and_fret",
    //     "categorical_or_continuous": "categorical",
    //     "possible_values": possible_string_and_fret_values
    // },
    {
        "index": 4, // redundant, just here for convenience
        "name": "answer_fret_zone",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_fret_zone_values
    },
    {
        "index": 8, // redundant, just here for convenience
        "name": "answer_note_is_natural",
        "categorical_or_continuous": "categorical",
        "possible_values": [
            "0", "1"
        ]
    },
    {
        "index": 9, // redundant, just here for convenience
        "name": "distance_between_subsequent_answer_notes",
        "categorical_or_continuous": "continuous"
    }
]

var interval_find_zone_feature_metadata = [
    {
        "index": 0, // redundant, just here for convenience
        "name": "reference_string",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_string_values
    },
    // {
    //   "index": 1, // redundant, just here for convenience
    //   "name": "reference_fret",
    //   "categorical_or_continuous": "categorical",
    //   "possible_values": possible_fret_values
    // },
    {
        "index": 2, // redundant, just here for convenience
        "name": "interval",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_interval_values
    },
    // {
    //   "index": 3, // redundant, just here for convenience
    //   "name": "reference_string_and_fret",
    //   "categorical_or_continuous": "categorical",
    //   "possible_values": possible_string_and_fret_values
    // },
    // {
    //   "index": 4, // redundant, just here for convenience
    //   "name": "reference_string_and_interval",
    //   "categorical_or_continuous": "categorical",
    //   "possible_values": possible_string_and_interval_values
    // },
    // {
    //   "index": 3, // redundant, just here for convenience
    //   "name": "answer_fret",
    //   "categorical_or_continuous": "categorical",
    //   "possible_values": possible_fret_values
    // },
    {
        "index": 4, // redundant, just here for convenience
        "name": "absolute_fret_distance",
        "categorical_or_continuous": "continuous"
    },
    {
        "index": 5, // redundant, just here for convenience
        "name": "answer_string",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_string_values
    },
    // {
    //     "index": 6, // redundant, just here for convenience
    //     "name": "reference_and_answer_string",
    //     "categorical_or_continuous": "categorical",
    //     "possible_values": possible_string_and_string_values
    // },
    {
        "index": 4, // redundant, just here for convenience
        "name": "answer_fret_zone",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_fret_zone_values
    },
    {
        "index": 4, // redundant, just here for convenience
        "name": "reference_fret_zone",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_fret_zone_values
    },
    {
        "index": 9, // redundant, just here for convenience
        "name": "distance_between_subsequent_reference_notes",
        "categorical_or_continuous": "continuous"
    }
]

var note_interval_find_zone_feature_metadata = [
    {
        "index": 0, // redundant, just here for convenience
        "name": "reference_pitch_class",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_pitch_class_values
    },
    {
        "index": 2, // redundant, just here for convenience
        "name": "interval",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_interval_values
    },
    // {
    //   "index": 4, // redundant, just here for convenience
    //   "name": "reference_pitch_class_and_interval",
    //   "categorical_or_continuous": "categorical",
    //   "possible_values": possible_pitch_class_and_interval_values
    // },
    {
        "index": 4, // redundant, just here for convenience
        "name": "answer_fret_zone",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_fret_zone_values
    },
    // {
    //   "index": 4, // redundant, just here for convenience
    //   "name": "answer_fret",
    //   "categorical_or_continuous": "categorical",
    //   "possible_values": possible_fret_values
    // },
    {
        "index": 5, // redundant, just here for convenience
        "name": "answer_string",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_string_values
    },
    {
        "index": 9, // redundant, just here for convenience
        "name": "distance_between_subsequent_answer_notes",
        "categorical_or_continuous": "continuous"
    }
]

var note_anywhere_feature_metadata = [
    {
        "index": 0, // redundant, just here for convenience
        "name": "answer_pitch_class",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_pitch_class_values
    }
]

var note_interval_anywhere_feature_metadata = [
    {
        "index": 0, // redundant, just here for convenience
        "name": "reference_pitch_class",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_pitch_class_values
    },
    {
        "index": 1, // redundant, just here for convenience
        "name": "interval",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_interval_values
    }
]


var interval_anywhere_feature_metadata = [
    {
        "index": 0, // redundant, just here for convenience
        "name": "reference_string",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_string_values
    },
    // {
    //   "index": 1, // redundant, just here for convenience
    //   "name": "reference_fret",
    //   "categorical_or_continuous": "categorical",
    //   "possible_values": possible_fret_values
    // },
    {
        "index": 4, // redundant, just here for convenience
        "name": "reference_fret_zone",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_fret_zone_values
    },
    {
        "index": 2, // redundant, just here for convenience
        "name": "interval",
        "categorical_or_continuous": "categorical",
        "possible_values": possible_interval_values
    }
]


function getModelIdFromState(state) {
    if (['Note Find Zone', 'Note Find'].includes(state.chord_type)) {
        return "game_type=" + state.chord_type +
            "&user_id=" + state.user_id +
            "&available_answer_strings=" + state.available_answer_note_strings +
            "&available_answer_frets=" + state.available_answer_note_frets +
            "&available_answer_notes=" + state.available_answer_notes +
            "&tuning=" + state.tuning_name +
            "&app_id=fret_ferret"
    } else if (['Interval Find Zone', 'Interval Find'].includes(state.chord_type)) {
        return "game_type=" + state.chord_type +
            "&user_id=" + state.user_id +
            "&show_intervals=" + state.show_intervals +
            "&available_reference_strings=" + state.available_reference_note_strings +
            "&available_reference_frets=" + state.available_reference_note_frets +
            "&available_reference_notes=" + state.available_reference_notes +
            "&available_answer_strings=" + state.available_answer_note_strings +
            "&available_answer_frets=" + state.available_answer_note_frets +
            "&available_answer_notes=" + state.available_answer_notes +
            "&available_intervals=" + state.available_interval_types +
            "&available_fret_distances=" + state.available_note_reference_note_distances +
            "&available_string_distances=" + state.available_note_reference_note_string_distances +
            "&tuning=" + state.tuning_name +
            "&app_id=fret_ferret"
    } else if ('Interval Find Sequence' == state.chord_type) {
        return "game_type=" + state.chord_type +
            "&user_id=" + state.user_id +
            "&show_intervals=" + state.show_intervals +
            "&available_intervals=" + state.available_interval_types +
            "&app_id=fret_ferret"
    } else if (['Note + Interval Find Zone', 'Note + Interval Find'].includes(state.chord_type)) {
        return "game_type=" + state.chord_type +
            "&user_id=" + state.user_id +
            "&show_intervals=" + state.show_intervals +
            "&available_reference_notes=" + state.available_reference_notes +
            "&available_answer_strings=" + state.available_answer_note_strings +
            "&available_answer_frets=" + state.available_answer_note_frets +
            "&available_answer_notes=" + state.available_answer_notes +
            "&available_intervals=" + state.available_interval_types +
            "&tuning=" + state.tuning_name +
            "&app_id=fret_ferret"
    } else if (['Note Find 1', 'Note Find 5'].includes(state.chord_type)) {
        return "game_type=" + state.chord_type +
            "&user_id=" + state.user_id +
            "&available_answer_notes=" + state.available_answer_notes +
            "&tuning=" + state.tuning_name +
            "&app_id=fret_ferret"
    } else if (['Note + Interval Find 1', 'Note + Interval Find 5'].includes(state.chord_type)) {
        return "game_type=" + state.chord_type +
            "&user_id=" + state.user_id +
            "&show_intervals=" + state.show_intervals +
            "&available_answer_notes=" + state.available_answer_notes +
            "&available_intervals=" + state.available_interval_types +
            "&tuning=" + state.tuning_name +
            "&app_id=fret_ferret"
    } else if (['Interval Find 1', 'Interval Find 3'].includes(state.chord_type)) {
        return "game_type=" + state.chord_type +
            "&user_id=" + state.user_id +
            "&show_intervals=" + state.show_intervals +
            "&available_reference_strings=" + state.available_reference_note_strings +
            "&available_reference_frets=" + state.available_reference_note_frets +
            "&available_reference_notes=" + state.available_reference_notes +
            "&available_intervals=" + state.available_interval_types +
            "&tuning=" + state.tuning_name +
            "&app_id=fret_ferret"
    }

}

var game_types_with_bandits = [
    'Note Find Zone',
    'Note Find',
    'Interval Find Zone',
    'Interval Find',
    //'Interval Find Sequence',
    'Note + Interval Find Zone',
    'Note + Interval Find',
    'Note Find 1',
    'Note Find 5',
    'Note + Interval Find 1',
    'Note + Interval Find 5',
    'Interval Find 1',
    'Interval Find 3'
]


function getFeatureVectorFromState(state, train_or_pull = 'train') {

    console.log('number of unique feature_vectors: ran getFeatureVectorFromState')

    function getFretZone(fret_as_string) {

        if (["0"].includes(fret_as_string)) {
            return "0"
        }
        if (["1", "2", "3"].includes(fret_as_string)) {
            return "1-3"
        }
        if (["4", "5", "6"].includes(fret_as_string)) {
            return "4-6"
        }
        if (["7", "8", "9"].includes(fret_as_string)) {
            return "7-9"
        }
        if (["10", "11", "12"].includes(fret_as_string)) {
            return "10-12"
        }
    }

    function getPossibleFretZoneValues(available_frets) {
        var return_val = []
        if (testAnyOverlap([0], available_frets)) {
            return_val.push("0")
        }
        if (testAnyOverlap([1, 2, 3], available_frets)) {
            return_val.push("1-3")
        }
        if (testAnyOverlap([4, 5, 6], available_frets)) {
            return_val.push("4-6")
        }
        if (testAnyOverlap([7, 8, 9], available_frets)) {
            return_val.push("7-9")
        }
        if (testAnyOverlap([10, 11, 12], available_frets)) {
            return_val.push("10-12")
        }
        return return_val
    }

    function getFeatureVector(reference_string, reference_fret, answer_string, answer_fret, answer_pitch_class, interval) {


        var reference_fret_zone = getFretZone(reference_fret)
        var answer_fret_zone = getFretZone(answer_fret)
        var answer_note_is_natural = "1"

        if (answer_pitch_class != null) {
            if (answer_pitch_class.includes('#') || answer_pitch_class.includes('b')) {
                answer_note_is_natural = "0"
            }
        }

        var reference_string_fret_note = Note.transpose(state.tuning.slice().reverse()[reference_string], Interval.fromSemitones(reference_fret))
        var reference_pitch_class = Note.simplify(Note.pitchClass(reference_string_fret_note))
        reference_pitch_class = map_enharmonics[reference_pitch_class]

        if (reference_fret != null && answer_fret != null) {
            var fret_distance = (parseInt(answer_fret) - parseInt(reference_fret)).toString()
        }

        if (['Note Find Zone', 'Note Find'].includes(state.chord_type)) {
            var feature_metadata = note_find_zone_feature_metadata

            // adjust possible_values by available_values
            feature_metadata[0].possible_values = possible_string_values.filter(
                val => state.available_answer_note_strings.map(
                    val => val.toString()
                ).includes(val)
            )
            feature_metadata[1].possible_values = possible_fret_values.filter(
                val => state.available_answer_note_frets.map(
                    val => val.toString()
                ).includes(val)
            )
            feature_metadata[2].possible_values = getPossibleFretZoneValues(state.available_answer_note_frets)


            var output_metadata = time_to_correct_guess_output_metadata
            var feature_vector = [
                answer_string,
                answer_fret,
                // pitch_class,
                // answer_string + '-' + answer_fret,
                answer_fret_zone,
                answer_note_is_natural
            ]
        } else if (['Interval Find Zone', 'Interval Find', 'Interval Find Sequence'].includes(state.chord_type)) {
            var feature_metadata = interval_find_zone_feature_metadata

            // adjust possible_values by available_values
            feature_metadata[0].possible_values = possible_string_values.filter(
                val => state.available_reference_note_strings.map(
                    val => val.toString()
                ).includes(val)
            )
            feature_metadata[1].possible_values = possible_interval_values.filter(
                val => state.available_interval_types.map(
                    val => val.toString()
                ).includes(val)
            )
            feature_metadata[3].possible_values = possible_string_values.filter(
                val => state.available_answer_note_strings.map(
                    val => val.toString()
                ).includes(val)
            )
            feature_metadata[4].possible_values = getPossibleFretZoneValues(state.available_answer_note_frets)
            feature_metadata[5].possible_values = getPossibleFretZoneValues(state.available_reference_note_frets)

            var output_metadata = time_to_correct_guess_output_metadata
            var feature_vector = [
                reference_string,
                // reference_fret,
                interval,
                // reference_string + '-' + reference_fret,
                // reference_string + '-' + interval,  
                // answer_fret,
                Math.abs(fret_distance),
                answer_string,
                // reference_string + '-' + answer_string,
                answer_fret_zone,
                reference_fret_zone
            ]
        } else if (['Note + Interval Find Zone', 'Note + Interval Find'].includes(state.chord_type)) {
            var feature_metadata = note_interval_find_zone_feature_metadata

            // adjust possible_values by available_values
            feature_metadata[0].possible_values = possible_pitch_class_values_simplified.filter(
                val => state.available_reference_notes.map(
                    val => map_enharmonics[val]
                ).includes(val)
            )
            feature_metadata[1].possible_values = possible_interval_values.filter(
                val => state.available_interval_types.map(
                    val => val.toString()
                ).includes(val)
            )
            feature_metadata[2].possible_values = getPossibleFretZoneValues(state.available_answer_note_frets)
            feature_metadata[3].possible_values = possible_string_values.filter(
                val => state.available_answer_note_strings.map(
                    val => val.toString()
                ).includes(val)
            )

            var output_metadata = time_to_correct_guess_output_metadata
            var feature_vector = [
                reference_pitch_class,
                interval,
                answer_fret_zone,
                answer_string
            ]

        } else if (['Note Find 1', 'Note Find 5'].includes(state.chord_type)) {
            var feature_metadata = note_anywhere_feature_metadata

            // adjust possible_values by available_values
            feature_metadata[0].possible_values = possible_pitch_class_values.filter(
                val => state.available_answer_notes.map(
                    val => val.toString()
                ).includes(val)
            )

            var output_metadata = time_to_correct_guess_output_metadata
            var feature_vector = [
                answer_pitch_class
            ]

        } else if (['Note + Interval Find 1', 'Note + Interval Find 5'].includes(state.chord_type)) {
            var feature_metadata = note_interval_anywhere_feature_metadata

            // adjust possible_values by available_values
            feature_metadata[0].possible_values = possible_pitch_class_values_simplified.filter(
                val => state.available_reference_notes.map(
                    val => map_enharmonics[val]
                ).includes(val)
            )
            feature_metadata[1].possible_values = possible_interval_values.filter(
                val => state.available_interval_types.map(
                    val => val.toString()
                ).includes(val)
            )

            var output_metadata = time_to_correct_guess_output_metadata
            var feature_vector = [
                reference_pitch_class,
                interval
            ]

        } else if (['Interval Find 1', 'Interval Find 3'].includes(state.chord_type)) {
            var feature_metadata = interval_anywhere_feature_metadata

            // adjust possible_values by available_values
            feature_metadata[0].possible_values = possible_string_values.filter(
                val => state.available_reference_note_strings.map(
                    val => val.toString()
                ).includes(val)
            )
            feature_metadata[1].possible_values = getPossibleFretZoneValues(state.available_reference_note_frets)
            feature_metadata[2].possible_values = possible_interval_values.filter(
                val => state.available_interval_types.map(
                    val => val.toString()
                ).includes(val)
            )

            var output_metadata = time_to_correct_guess_output_metadata
            var feature_vector = [
                reference_string,
                reference_fret_zone,
                // pitch_class,
                interval
            ]
        }

        if (feature_metadata[feature_metadata.length - 1]['name'] == 'distance_between_subsequent_answer_notes') {
            var prev_answer_string = state.prev_loc.str
            var prev_answer_fret = state.prev_loc.pos
            var distance_between_subsequent_answer_notes = Math.abs(answer_string - prev_answer_string) + Math.abs(answer_fret - prev_answer_fret)
            feature_vector.push(distance_between_subsequent_answer_notes)
        }
        if (feature_metadata[feature_metadata.length - 1]['name'] == 'distance_between_subsequent_reference_notes') {
            var prev_reference_string = state.selected_locations[0].loc.str
            var prev_reference_fret = state.selected_locations[0].loc.pos
            var distance_between_subsequent_reference_notes = Math.abs(reference_string - prev_reference_string) + Math.abs(reference_fret - prev_reference_fret)
            feature_vector.push(distance_between_subsequent_reference_notes)
        }

        return {
            'feature_vector': feature_vector,
            'feature_metadata': feature_metadata,
            'output_metadata': output_metadata
        }
    }

    // "Reference note"
    //     random_fret
    //     random_string
    //     --> derivative
    //         random_string_fret_note
    //         random_string_fret_pitch_class
    //
    // "Answer note"
    //     random_string2
    //     random_pitch_class
    //     --> derivative
    //         random_pitch_class_fret
    //         random_pitch_class_with_octave (fed also by random_octave for some games)
    //         random_zone (fed also by random_zone_size, available_random_zones has no impact)
    //

    // if (Object.keys(state).includes('selected_locations')) {
    //     var reference_string = state.render_candidate_random_string // state.selected_locations[0].loc.str.toString()
    //     var reference_fret = state.render_candidate_random_fret // state.selected_locations[0].loc.pos.toString()
    // } else {
    //     var reference_string = null
    //     var reference_fret = null
    // }
    //
    // if (Object.keys(state).includes('prev_loc')) {
    //     var answer_string = state.render_candidate_random_string2 //state.prev_loc.str.toString()
    //     var answer_fret = state.render_candidate_random_pitch_class_fret// state.prev_loc.pos.toString()
    // } else {
    //     var answer_string = null
    //     var answer_fret = null
    // }

    function robustToString(val) {
        if (val != undefined && val != null) {
            return val.toString()
        } else {
            if (typeof (val) == 'string') {
                return val
            } else {
                return null
            }
        }
    }

    var interval_as_int = mod(Interval.semitones(Interval.distance(
        state.prev_loc_question.random_string_fret_pitch_class + '3',
        state.prev_loc_question.random_pitch_class + '3'
    )), 12)

    if (interval_as_int == undefined) {
        debugger
    }

    var feature_info = getFeatureVector(
        robustToString(state.prev_loc_question.random_string), // state.selected_locations[0].loc.str
        robustToString(state.prev_loc_question.random_fret), // state.selected_locations[0].loc.pos
        robustToString(state.prev_loc_question.random_string2), // state.prev_loc.str
        robustToString(state.prev_loc_question.random_pitch_class_fret), // state.prev_loc.pos
        robustToString(state.prev_loc_question.random_pitch_class), // state.pitch_class_to_play
        robustToString(interval_as_int)
    )

    var feature_vector = feature_info.feature_vector
    var feature_metadata = feature_info.feature_metadata
    var output_metadata = feature_info.output_metadata

    if (train_or_pull == 'train') {
        return {
            'feature_vector': feature_vector,
            'feature_metadata': feature_metadata,
            'output_metadata': output_metadata
        }
    }

    function getCartesianFeatureVectorsForStringsNotesAndFrets(
        list_of_strings, list_of_notes, list_of_frets, tuning, answer_or_reference = 'answer'
    ) {

        // from https://stackoverflow.com/questions/15298912/javascript-generating-combinations-from-n-arrays-with-m-elements
        function cartesian(...args) {
            var r = [], max = args.length - 1;

            function helper(arr, i) {
                for (var j = 0, l = args[i].length; j < l; j++) {
                    var a = arr.slice(0); // clone arr
                    a.push(args[i][j]);
                    if (i == max)
                        r.push(a);
                    else
                        helper(a, i + 1);
                }
            }

            helper([], 0);
            return r;
        }

        var feature_vectors_for_answer_strings_and_notes = cartesian(
            list_of_strings,
            list_of_notes
        )
        var feature_vectors_for_answer_strings_notes_and_frets = []
        var human_readable_feature_vectors = []

        for (i = 0; i < feature_vectors_for_answer_strings_and_notes.length; i++) {

            var random_string = feature_vectors_for_answer_strings_and_notes[i][0]
            var random_note = feature_vectors_for_answer_strings_and_notes[i][1]
            var random_pitch_class_fret = mod(Interval.semitones(Interval.distance(tuning.slice().reverse()[random_string], random_note + '8')), 12)
            if (random_pitch_class_fret == 0) {
                random_pitch_class_fret = 12
            }

            var note_is_natural = "1"
            if (random_note.includes('#') || random_note.includes('b')) {
                note_is_natural = "0"
            }

            if (
                list_of_frets.includes(random_pitch_class_fret) &&
                list_of_strings.includes(random_string) &&
                list_of_notes.includes(random_note)
            ) {
                human_readable_feature_vectors.push({
                    'string': random_string.toString(),
                    'fret': random_pitch_class_fret.toString(),
                    'note': random_note
                })
            }
        }

        return human_readable_feature_vectors

    }

    var answer_note_cartesian_human_readable_feature_vectors = getCartesianFeatureVectorsForStringsNotesAndFrets(
        state.available_answer_note_strings,
        state.available_answer_notes,
        state.available_answer_note_frets,
        state.tuning,
        'answer'
    )
    var reference_note_cartesian_human_readable_feature_vectors = getCartesianFeatureVectorsForStringsNotesAndFrets(
        state.available_reference_note_strings,
        state.available_reference_notes,
        state.available_reference_note_frets,
        state.tuning,
        'reference'
    )


    var feature_vectors = []
    var human_readable_feature_vectors = []
    if ([
        'Note Find Zone',
        'Note Find',
        'Note Find 1',
        'Note Find 5'
    ].includes(state.chord_type)) {
        for (var i = 0; i < answer_note_cartesian_human_readable_feature_vectors.length; i++) {
            feature_vectors.push(
                getFeatureVector(
                    null,
                    null,
                    answer_note_cartesian_human_readable_feature_vectors[i].string,
                    answer_note_cartesian_human_readable_feature_vectors[i].fret,
                    answer_note_cartesian_human_readable_feature_vectors[i].note,
                    null
                ).feature_vector
            )
            human_readable_feature_vectors.push(
                {
                    'reference_string': null,
                    'reference_fret': null,
                    'reference_note': null,
                    'answer_string': answer_note_cartesian_human_readable_feature_vectors[i].string,
                    'answer_fret': answer_note_cartesian_human_readable_feature_vectors[i].fret,
                    'answer_note': answer_note_cartesian_human_readable_feature_vectors[i].note,
                    'interval': null
                }
            )
        }
    } else if ([
        'Interval Find Zone',
        'Interval Find',
        'Interval Find Sequence',
        'Note + Interval Find Zone',
        'Note + Interval Find',
        'Note + Interval Find 5',
        'Interval Find 1',
        'Interval Find 3'
    ].includes(state.chord_type)) {
        for (var i = 0; i < answer_note_cartesian_human_readable_feature_vectors.length; i++) {
            for (var j = 0; j < reference_note_cartesian_human_readable_feature_vectors.length; j++) {
                var answer_note = answer_note_cartesian_human_readable_feature_vectors[i].note
                var reference_note = reference_note_cartesian_human_readable_feature_vectors[j].note
                var interval = mod(Note.midi(answer_note + '3') - Note.midi(reference_note + '3'), 12)

                var answer_fret = answer_note_cartesian_human_readable_feature_vectors[i].fret
                var reference_fret = reference_note_cartesian_human_readable_feature_vectors[j].fret
                var answer_string = answer_note_cartesian_human_readable_feature_vectors[i].string
                var reference_string = reference_note_cartesian_human_readable_feature_vectors[j].string
                var fret_distance = Math.abs(reference_fret - answer_fret)
                var string_distance = Math.abs(reference_string - answer_string)

                if (
                    state.available_interval_types.includes(interval) &&
                    state.available_note_reference_note_distances.includes(fret_distance) &&
                    state.available_note_reference_note_string_distances.includes(string_distance)
                ) {
                    feature_vectors.push(
                        getFeatureVector(
                            reference_note_cartesian_human_readable_feature_vectors[j].string,
                            reference_note_cartesian_human_readable_feature_vectors[j].fret,
                            answer_note_cartesian_human_readable_feature_vectors[i].string,
                            answer_note_cartesian_human_readable_feature_vectors[i].fret,
                            answer_note_cartesian_human_readable_feature_vectors[i].note,
                            interval.toString()
                        ).feature_vector
                    )
                    human_readable_feature_vectors.push(
                        {
                            'reference_string': reference_note_cartesian_human_readable_feature_vectors[j].string,
                            'reference_fret': reference_note_cartesian_human_readable_feature_vectors[j].fret,
                            'reference_note': reference_note_cartesian_human_readable_feature_vectors[j].note,
                            'answer_string': answer_note_cartesian_human_readable_feature_vectors[i].string,
                            'answer_fret': answer_note_cartesian_human_readable_feature_vectors[i].fret,
                            'answer_note': answer_note_cartesian_human_readable_feature_vectors[i].note,
                            'interval': interval.toString()
                        }
                    )
                }
            }
        }
    }

    // var uniquefying_time0 = new Date().getTime()
    // // only return unique feature vectors
    // function arraysEqual(a, b) {
    //   if (a === b) return true;
    //   if (a == null || b == null) return false;
    //   if (a.length !== b.length) return false;
    //
    //   // If you don't care about the order of the elements inside
    //   // the array, you should sort both arrays here.
    //   // Please note that calling sort on an array will modify that array.
    //   // you might want to clone your array first.
    //
    //   for (var i = 0; i < a.length; ++i) {
    //     if (a[i] !== b[i]) return false;
    //   }
    //   return true;
    // }
    //
    //
    // var unique_indices = []
    // var prior_array = []
    // var unique_feature_vectors = []
    // var unique_human_readable_feature_vectors = []
    // for (var i=0; i < feature_vectors.length; i++) {
    //     var ever_equal = false
    //     for (var j=0; j < prior_array.length; j++) {
    //         if (arraysEqual(prior_array[j], feature_vectors[i])) {
    //             ever_equal = true
    //         }
    //     }
    //     if (!ever_equal) {
    //         prior_array.push(feature_vectors[i])
    //         unique_indices.push(i)
    //         unique_feature_vectors.push(feature_vectors[i])
    //         unique_human_readable_feature_vectors.push(human_readable_feature_vectors[i])
    //     }
    // }

    console.log('number of unique feature_vectors: completed run')

    var unique_feature_vectors = feature_vectors
    var unique_human_readable_feature_vectors = human_readable_feature_vectors

    // console.log('number of unique feature_vectors: ' + unique_feature_vectors.length )
    // console.log('number of unique feature_vectors:  time to calculate: ' + ( new Date().getTime() - uniquefying_time0 ) )

    return {
        'feature_vector': unique_feature_vectors,
        'human_readable_feature_vectors': unique_human_readable_feature_vectors,
        'feature_metadata': feature_metadata,
        'output_metadata': output_metadata
    }

}

async function banditRestart(state) {

    if (!state.bandit_activated || !game_types_with_bandits.includes(state.chord_type)) {
        return
    }

    console.log('banditRestart')

    var model_id = getModelIdFromState(state)


    var feature_info = getFeatureVectorFromState(state, 'pull')
    var original_feature_vectors = feature_info.feature_vector
    var feature_metadata = feature_info.feature_metadata
    var output_metadata = feature_info.output_metadata
    var bandit_metadata = {'source_url': window.location.href + '&user_id=' + state.user_id}


    var bandit_payload = {

        "model_id": model_id,
        "model_type_name": "BayesLinearRegressor",
        "bandit_mode": "restart",
        "action_feature_metadata": feature_metadata,
        "output_metadata": output_metadata,
        "bandit_metadata": bandit_metadata
    }

    console.log('banditRestart payload')
    console.dir(bandit_payload)

    var log_obj = {
        payload: bandit_payload
    }

    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: 'POST',
        headers: default_headers,
        body: JSON.stringify(log_obj)
    };

    const response = await fetch(endpoint_url, requestOptions).then(val => {
        return val.json()
    }).catch(
        err => {
            return {'errorMessage': 'Failure during fetch in javascript', err: err}
        }
    );

    // debugger 

    if (Object.keys(response).includes('body')) {
        response.body = JSON.parse(response.body)
    } else {
        // debugger
        return {
            'response': response,
            'success': false
        }
    }

    console.log('response after await for training:')
    console.dir(response)

}

async function simpleLogAndRetrieve(state) {
    // TODO: Implement this: right now, it goes to banditTest
    return
    var model_id = getModelIdFromState(state)

    var simple_log_and_retrieve_payload = {
        "log_id": model_id,
        "mode": "log_and_retrieve_from_list_on_DynamoDB",
        "log_element": {
            "time_to_completion": Math.round(new Date().getTime() - state.last_correct_timestamp),
            "difficulty_level": state.bandit_difficulty_level,
            "timestamp_of_payload_creation": Math.round(new Date().getTime())
        },
        "log_metadata": {'source_url': window.location.href + '&user_id=' + state.user_id}
    }

    var requestOptions = {
        method: 'POST',
        headers: default_headers,
        body: JSON.stringify({
            payload: simple_log_and_retrieve_payload
        })
    };

    try {
        const response = await fetch(log_and_retriever_endpoint_url, requestOptions).then(val => {
            return val.json()
        }).catch(
            err => {
                return {'errorMessage': 'Failure during fetch in javascript', err: err}
            }
        );
    } catch {
        // debugger
    }


}

async function banditTrain(state) {

    if (!state.bandit_activated || !game_types_with_bandits.includes(state.chord_type)) {
        return
    }

    console.log('banditTrain')

    simpleLogAndRetrieve(state)

    var model_id = getModelIdFromState(state)

    var feature_info = getFeatureVectorFromState(state, 'train')
    var feature_vector = feature_info.feature_vector
    var feature_metadata = feature_info.feature_metadata
    var bandit_metadata = {'source_url': window.location.href + '&user_id=' + state.user_id}

    var bandit_payload = {

        "model_id": model_id,
        "model_type_name": "BayesLinearRegressor",
        "bandit_mode": "train",
        "action_feature_vectors": [feature_vector],
        "action_feature_metadata": feature_metadata,
        "output_value": Math.log10(((new Date().getTime() - state.last_correct_timestamp) * 1.0) / 1000.0 + 1),
        "timestamp_of_payload_creation": new Date().getTime(),
        "bandit_metadata": bandit_metadata
    }

    console.log('banditTrain payload')
    console.dir(bandit_payload)

    var log_obj = {
        payload: bandit_payload
    }

    // Simple POST request with a JSON body using fetch
    var requestOptions = {
        method: 'POST',
        headers: default_headers,
        body: JSON.stringify({
            payload: bandit_payload
        })
    };


    const response = await fetch(endpoint_url, requestOptions).then(val => {
        return val.json()
    }).catch(
        err => {
            return {'errorMessage': 'Failure during fetch in javascript', err: err}
        }
    );

    if (Object.keys(response).includes('body')) {
        response.body = JSON.parse(response.body)
    } else if (Object.keys(response).includes('alias')) {
        response.body = {...response}
    }

    if (Object.keys(response).includes('body')) {
        if (typeof response.body == "string") {
            response.body = JSON.parse(response.body)
        }
        console.log('bandittrain error did not happen for action_feature_vectors ' + bandit_payload.action_feature_vectors)

    } else {
        console.log('bandittrain error happened from call that started ' + (new Date().getTime() - bandit_payload.timestamp_of_payload_creation) + ' ms ago')
        console.log('bandittrain error happened for action_feature_vectors ' + bandit_payload.action_feature_vectors)
        debugger
        return {
            'response': response,
            'success': false
        }
    }

    console.log('response after await for training:')
    console.dir(response)
    console.log("banditTrain lambda footprint in ms: " + response.body.time_to_run_in_sec)

}


async function banditPull(state, predict_on_all_models = true, attempt_restart = true) {

    try {
        document.getElementById("userIdInput").value = state.user_id;
    } catch (err) {

    }

    console.log('ran banditPull')
    if (!state.bandit_activated || !game_types_with_bandits.includes(state.chord_type)) {
        return null
    }

    var time0 = Math.round(new Date().getTime())

    var model_id = getModelIdFromState(state)

    var feature_info = getFeatureVectorFromState(state, 'pull')

    var original_feature_vectors = feature_info.feature_vector
    var original_human_readable_feature_vectors = feature_info.human_readable_feature_vectors
    var feature_metadata = feature_info.feature_metadata
    var output_metadata = feature_info.output_metadata
    var bandit_metadata = {'source_url': window.location.href + '&user_id=' + state.user_id}

    // randomly select 100 of the viable feature_vectors
    if (original_feature_vectors.length <= 20) {
        var feature_vectors = original_feature_vectors
        var human_readable_feature_vectors = original_human_readable_feature_vectors
    } else {
        feature_vectors = []
        human_readable_feature_vectors = []
        for (var i = 0; i < 20; i++) {
            var index_to_push = Math.floor(Math.random() * original_feature_vectors.length)
            feature_vectors.push(
                original_feature_vectors[index_to_push]
            )
            human_readable_feature_vectors.push(
                original_human_readable_feature_vectors[index_to_push]
            )
        }
    }

    // TODO: put this stuff into its own lambda
    try {
        var extra_data_to_store_for_pull = {
            "difficulty_level": state.bandit_difficulty_level,
            "time_to_completion": Math.round(new Date().getTime() - state.last_correct_timestamp),
            "timestamp": Math.round(new Date().getTime()),
            "prediction": state.bandit_results.map_action_index_to_predictions[state.bandit_results.action_index_with_highest_score].average
        }
    } catch {
        var extra_data_to_store_for_pull = null
    }

    var bandit_payload = {

        "model_id": model_id,
        "model_type_name": "BayesLinearRegressor", // "EmpiricalRegressor" // "SGDRegressor" // "BayesLinearRegressor" // "TrailingBayesLinearRegressor
        "bandit_mode": "pull",
        "predict_on_all_models": predict_on_all_models,
        "action_feature_vectors": feature_vectors,
        "action_feature_metadata": feature_metadata,
        "bandit_metadata": bandit_metadata,
        "should_we_return_complete_payload": true
    }


    var log_obj = {
        payload: bandit_payload
    }

    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: 'POST',
        headers: default_headers,
        body: JSON.stringify(log_obj)
    };

    var time1 = Math.round(new Date().getTime())
    var response = await fetch(endpoint_url, requestOptions).then(val => {
        return val.json()
    }).catch(
        err => {
            return {'errorMessage': 'Failure during fetch in javascript', 'err': err}
        }
    );
    var time2 = Math.round(new Date().getTime())

    if (Object.keys(response).includes('body')) {
        response.body = JSON.parse(response.body)
    } else if (Object.keys(response).includes('alias')) {
        response.body = {...response}
    } else {
        if (attempt_restart && response.errorMessage.includes("We recommend a restart")) {

            var bandit_restart_response = await banditRestart(state)
            var time1 = Math.round(new Date().getTime())
            var response = await fetch(endpoint_url, requestOptions).then(val => {
                return val.json()
            }).catch(
                err => {
                    return {'errorMessage': 'Failure during fetch in javascript', 'err': err}
                }
            );
            
            var time2 = Math.round(new Date().getTime())

            if (Object.keys(response).includes('body')) {
                response.body = JSON.parse(response.body)
            } else if (Object.keys(response).includes('alias')) {
                response.body = {...response}
            } else {
                debugger
                return {
                    'response': response,
                    'success': false
                }
            }
        } else {
            debugger
            return {
                'response': response,
                'success': false
            }
        }
    }
    console.log('response after await for pulling:')
    console.dir(response)

    if (!Object.keys(response).includes('body')) {
        debugger
    }

    // debugger
    var prediction = response.body.prediction.slice()
    var arg_sorted_model_prediction = []
    for (var tmp_prediction of prediction) {
        var array_of_matching_action_indices = []
        for (var action_index = 0; action_index < prediction.length; action_index++) {
            if (arg_sorted_model_prediction.includes(action_index)) {
                continue
            }
            if (tmp_prediction == prediction[action_index]) {
                array_of_matching_action_indices.push(action_index)
            }
        }
        shuffleArray(array_of_matching_action_indices)
        arg_sorted_model_prediction.push(...array_of_matching_action_indices)
    }

    var array_of_action_indices_used_in_selection_pool = []
    var array_of_action_indices_with_score_for_unknown = []
    for (var action_index = 0; action_index < response.body.should_we_assign_unknown_score_by_action_index.length; action_index++) {
        if (response.body.should_we_assign_unknown_score_by_action_index[action_index]) {
            array_of_action_indices_with_score_for_unknown.push(action_index)
        }
    }

    shuffleArray(array_of_action_indices_with_score_for_unknown)

    if (array_of_action_indices_with_score_for_unknown.length == 0) {
        var subset_of_arg_sorted_model_prediction = arg_sorted_model_prediction.slice(
            Math.max(0, Math.floor((state.bandit_difficulty_level - 10) * arg_sorted_model_prediction.length * 1.0 / 100.0)),
            Math.min(arg_sorted_model_prediction.length, Math.ceil((state.bandit_difficulty_level + 10) * arg_sorted_model_prediction.length * 1.0 / 100.0))
        )
        if (subset_of_arg_sorted_model_prediction.length == 0) {
            if (state.bandit_difficulty_level > 50) {
                subset_of_arg_sorted_model_prediction = [arg_sorted_model_prediction[arg_sorted_model_prediction.length - 1]]
            } else if (state.bandit_difficulty_level <= 50) {
                subset_of_arg_sorted_model_prediction = [arg_sorted_model_prediction[0]]
            }
        }
        shuffleArray(subset_of_arg_sorted_model_prediction)
        var action_index_with_highest_score = subset_of_arg_sorted_model_prediction[subset_of_arg_sorted_model_prediction.length - 1]
        array_of_action_indices_used_in_selection_pool = subset_of_arg_sorted_model_prediction
    } else {
        var action_index_with_highest_score = array_of_action_indices_with_score_for_unknown[array_of_action_indices_with_score_for_unknown.length - 1]
        array_of_action_indices_used_in_selection_pool = array_of_action_indices_with_score_for_unknown
    }


    var saved_response = Object()
    saved_response.body = response.body

    return {
        'model_id': model_id,
        'success': true,
        'javascript_method_time_to_run_in_sec': ((Math.round(new Date().getTime()) - time0) * 1.0) / 1000.0,
        'action_index_with_highest_score': action_index_with_highest_score,
        'action_feature_vector_with_highest_score': feature_vectors[action_index_with_highest_score],
        'reference_string': parseInt(human_readable_feature_vectors[action_index_with_highest_score].reference_string),
        'reference_fret': parseInt(human_readable_feature_vectors[action_index_with_highest_score].reference_fret),
        'interval_to_play': parseInt(human_readable_feature_vectors[action_index_with_highest_score].interval),
        'answer_string': parseInt(human_readable_feature_vectors[action_index_with_highest_score].answer_string),
        'answer_fret': parseInt(human_readable_feature_vectors[action_index_with_highest_score].answer_fret),
        'answer_note': human_readable_feature_vectors[action_index_with_highest_score].answer_note,
        'human_readable_feature_vectors': human_readable_feature_vectors,
        'response': saved_response,
        'bandit_payload': bandit_payload,
        'lambda_time_to_run_in_sec': response.body.time_to_run_in_sec,
        'await_time_to_run_in_sec': ((time2 - time1) * 1.0) / 1000.0,
        'timestamp_of_previous_bandit_pull': Math.round(new Date().getTime()),
        'array_of_action_indices_used_in_selection_pool': array_of_action_indices_used_in_selection_pool,
    }

}

class FretboardClick extends React.Component {

    constructor(props) {

        super(props);

        //doing the bindings here allow me to pass callbacks to the children
        this.handleClick = this.handleClick.bind(this);
        this.handleOffClick = this.handleOffClick.bind(this);
        this.handleNewSungNote = this.handleNewSungNote.bind(this);
        this.handleTouchMove = this.handleTouchMove.bind(this);
        this.onGameTypeChange = this.onGameTypeChange.bind(this);
        this.onTuningChange = this.onTuningChange.bind(this);
        this.onAvailableStringsChange = this.onAvailableStringsChange.bind(this);
        this.onAvailableIntervalTypesChange = this.onAvailableIntervalTypesChange.bind(this);
        this.onAvailableOctaveJumpsChange = this.onAvailableOctaveJumpsChange.bind(this);
        this.onAvailableChordTypesChange = this.onAvailableChordTypesChange.bind(this);
        this.onAvailableAnswerZonesChange = this.onAvailableAnswerZonesChange.bind(this);
        this.onAvailableZoneSizesChange = this.onAvailableZoneSizesChange.bind(this);
        this.onAvailableAnswerNotesChange = this.onAvailableAnswerNotesChange.bind(this);
        this.onAvailableGameTypesChange = this.onAvailableGameTypesChange.bind(this);
        this.onAvailableReferenceNotesChange = this.onAvailableReferenceNotesChange.bind(this);
        this.onAvailableReferenceNoteStringsChange = this.onAvailableReferenceNoteStringsChange.bind(this);
        this.onAvailableReferenceNoteFretsChange = this.onAvailableReferenceNoteFretsChange.bind(this);
        this.onAvailableAnswerNoteFretsChange = this.onAvailableAnswerNoteFretsChange.bind(this);
        this.onAvailableNoteReferenceNoteDistancesChange = this.onAvailableNoteReferenceNoteDistancesChange.bind(this);
        this.onAvailableNoteReferenceNoteStringDistancesChange = this.onAvailableNoteReferenceNoteStringDistancesChange.bind(this);
        this.onAvailableScalesChange = this.onAvailableScalesChange.bind(this);
        this.onMetaModeRepetitionsChange = this.onMetaModeRepetitionsChange.bind(this);
        this.onIntervalGroupLengthChange = this.onIntervalGroupLengthChange.bind(this);
        this.onReferenceStyleChange = this.onReferenceStyleChange.bind(this);
        this.onReferenceStyleStrumChange = this.onReferenceStyleStrumChange.bind(this);
        this.onRandomTopNoteChange = this.onRandomTopNoteChange.bind(this);
        this.onRandomTopTensionChange = this.onRandomTopTensionChange.bind(this);
        this.onRandomTopBothChange = this.onRandomTopBothChange.bind(this);
        this.onShowMicrophoneInputChange = this.onShowMicrophoneInputChange.bind(this);
        this.onMuteChange = this.onMuteChange.bind(this);
        this.onMicrophoneHoldChange = this.onMicrophoneHoldChange.bind(this);
        this.onPlaySoundsChange = this.onPlaySoundsChange.bind(this);
        this.onShowIntervalsChange = this.onShowIntervalsChange.bind(this);
        this.onShowNoteNamesChange = this.onShowNoteNamesChange.bind(this);
        this.onShowHintsChange = this.onShowHintsChange.bind(this);
        this.onCutAudioChange = this.onCutAudioChange.bind(this);
        this.onVolumeChange = this.onVolumeChange.bind(this);
        this.onDrumVolumeChange = this.onDrumVolumeChange.bind(this);
        this.onDroneVolumeChange = this.onDroneVolumeChange.bind(this);
        this.onDelayChange = this.onDelayChange.bind(this);
        this.onEchoChange = this.onEchoChange.bind(this);
        this.onMetaModeToggle = this.onMetaModeToggle.bind(this);
        this.onShowRomanNumeralButtonsChange = this.onShowRomanNumeralButtonsChange.bind(this);
        this.onShowRandomTopNoteOptionsChange = this.onShowRandomTopNoteOptionsChange.bind(this);
        this.onShowChordLoopChange = this.onShowChordLoopChange.bind(this);
        this.onRandomizerSettingsToggle = this.onRandomizerSettingsToggle.bind(this);
        this.onBanditSettingsToggle = this.onBanditSettingsToggle.bind(this);
        this.onMainSettingsToggle = this.onMainSettingsToggle.bind(this);
        this.onRandomizerSettingsObliterate = this.onRandomizerSettingsObliterate.bind(this);
        this.onBanditSettingsObliterate = this.onBanditSettingsObliterate.bind(this);
        this.onMainSettingsObliterate = this.onMainSettingsObliterate.bind(this);
        this.onSkipButton = this.onSkipButton.bind(this);
        this.onFullSkipButton = this.onFullSkipButton.bind(this);
        this.onRandomRootButton = this.onRandomRootButton.bind(this);
        this.onFreezeToggle = this.onFreezeToggle.bind(this);
        this.updateURL = this.updateURL.bind(this);
        this.onMinimumDistanceChange = this.onMinimumDistanceChange.bind(this);
        this.onMaximumDistanceChange = this.onMaximumDistanceChange.bind(this);
        this.onRootChange = this.onRootChange.bind(this);
        this.getTonicInput = this.getTonicInput.bind(this);
        this.getScaleTypeInput = this.getScaleTypeInput.bind(this);
        this.onTonicInputChange = this.onTonicInputChange.bind(this);
        this.onOctaveChange = this.onOctaveChange.bind(this);
        this.onScaleTypeInputChange = this.onScaleTypeInputChange.bind(this);
        this.onRomanNumeralPreLoadedChange = this.onRomanNumeralPreLoadedChange.bind(this);
        this.onRomanNumeralButton = this.onRomanNumeralButton.bind(this);
        this.onRomanNumeralDropDownChange = this.onRomanNumeralDropDownChange.bind(this);
        this.onChordLoopActivatedChange = this.onChordLoopActivatedChange.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.playChordLoop = this.playChordLoop.bind(this);
        this.playNextChordInLoop = this.playNextChordInLoop.bind(this);
        this.onChordLoopTempoChange = this.onChordLoopTempoChange.bind(this);
        this.onIntervalsRelativeChange = this.onIntervalsRelativeChange.bind(this);
        this.onBassOptionsChange = this.onBassOptionsChange.bind(this);
        this.onBanditToggleChange = this.onBanditToggleChange.bind(this);
        this.onBanditRestartButton = this.onBanditRestartButton.bind(this);
        this.onShowBanditResultsChange = this.onShowBanditResultsChange.bind(this);
        this.onChordLoopShift = this.onChordLoopShift.bind(this);
        this.onBeatsPerChordChange = this.onBeatsPerChordChange.bind(this);
        this.onBanditDifficultyLevelChange = this.onBanditDifficultyLevelChange.bind(this);
        this.onBanditResultsToggle = this.onBanditResultsToggle.bind(this);
        this.onUserIdUrlChange = this.onUserIdUrlChange.bind(this);
        this.onShowNextChordChange = this.onShowNextChordChange.bind(this);

        var default_prev_loc_question = {
            random_string: 1,
            random_fret: 1,
            random_string2: 1,
            random_pitch_class_fret: 1,
            random_pitch_class: 'C',
            random_string_fret_note: 'C3',
            random_string_fret_pitch_class: 'C',
            random_reference_chord_type: 'Major',
            random_zone_size: 2,
            random_zone: 0
        }

        // me want cookie!
        if (url_user_id == null) {
            var user_id = getCookie('user_id')
            var generated_new_user_id = false
            if (user_id == '') {
                generated_new_user_id = true
                user_id = '' + Date.now() + '_' + Math.floor(Math.random() * 1000)
                setCookie('user_id', user_id)
            }
        } else {
            user_id = url_user_id
        }

        this.state = {
            //api_key: `${process.env.REACT_APP_API_KEY}`,
            add_user_id_to_url: url_add_user_id_to_url,
            user_id: user_id,
            generated_new_user_id: generated_new_user_id,
            lesson: url_lesson,
            prev_note: url_tonic,
            prev_loc: {'pos': 1, 'str': 1},
            prev_loc_question: default_prev_loc_question,
            prev_interval: 'undefined',
            prev_time: Math.round(new Date().getTime()),
            prev_correct_guess_prev_note: 'undefined',
            prev_correct_guess_prev_interval: 'undefined',
            prev_correct_guess_time_diff: 0,
            prev_correct_guess_timestamp: 0,
            time_diff: -1,
            chord_type: url_chord_type,
            string_to_play: '1',
            interval_to_play: intervals[0],
            interval_to_play_raw: 0,
            zone_to_play: 1,
            zone_size_to_play: 1,
            chord_type_to_play: url_chord_type_to_play,
            selected_locations: [{loc: {str: 1, pos: 1}}],
            selected_notes: [Note.simplify(Note.pitchClass(Note.transpose(default_tuning[default_tuning.length - 2], '2m')))],
            message: url_message,
            pitch_class_to_play: Note.simplify(Note.pitchClass(Note.transpose(default_tuning[default_tuning.length - 2], '2m'))),
            pitch_class_to_play_with_octave: Note.transpose(default_tuning[default_tuning.length - 2], '2m'),
            time_diff_since_correct: -1,
            show_time_diff_since_correct: -1,
            last_correct_timestamp: Math.round(new Date().getTime()),
            last_last_correct_timestamp: Math.round(new Date().getTime()),
            number_correct_guesses: 0,
            number_bad_guesses_on_try: 0,
            number_good_guesses_on_try: 0,
            number_bad_guesses: 0,
            current_timestamp: Math.round(new Date().getTime()),
            find_3_trial_prev_correct: [],
            find_3_trial_prev_correct_full: [],
            find_3_trial_prev_correct_pitch_classes: [],
            initialized: false,
            reset_after_won_sequence: false,
            will_reset_after_won_sequence: false,
            winning_location: {},
            winning_label: "",
            onRadioChange: false,
            tuning: url_tuning,
            tuning_name: url_tuning_name,
            interval_types: default_interval_types,
            available_scales: scale_types_to_play_to_choose_from,
            available_answer_note_strings: url_answer_note_strings,
            available_answer_note_zones: default_available_zones,
            available_answer_notes: notes_to_choose_from,
            available_reference_notes: reference_notes_to_choose_from,
            available_chord_types: chord_types_to_choose_from,
            available_answer_note_frets: url_answer_note_frets,
            available_reference_note_frets: url_reference_note_frets,
            available_reference_note_strings: url_reference_note_strings,
            available_note_reference_note_distances: url_note_reference_note_fret_distances,
            available_note_reference_note_string_distances: url_note_reference_note_string_distances,
            available_octave_jumps: url_octave_jumps,
            available_interval_types: url_interval_types,
            available_game_types: url_game_types,
            available_maximum_interval_distance: url_available_maximum_interval_distance,
            available_minimum_interval_distance: url_available_minimum_interval_distance,
            available_zone_sizes: url_zone_sizes,
            show_randomizer_settings: url_show_randomizer_settings,
            show_main_settings: url_show_main_settings,
            selectedInstrument: url_instrument,
            selectedDroneInstrument: url_drone_instrument,
            cached: true,
            play_sounds: true,
            show_intervals: url_show_intervals,
            show_note_names: url_show_note_names,
            show_hints: url_show_hints,
            volume: url_volume, // out of 100
            drum_volume: url_drum_volume,
            drone_volume: url_drone_volume,
            volume_before_muting: url_volume,
            delay_between_notes: url_delay,
            echo: url_echo,
            freeze: url_freeze,
            show_freeze: url_show_freeze,
            failed_to_get_candidate: false,
            success: false,
            interval_group_length: url_interval_group_length,
            interval_group_array: [],
            current_click_location: {},
            scale_type_input_answer_note: 'Chromatic',
            scale_type_input_reference_note: 'Chromatic',
            tonic_input_answer_note: 'C',
            tonic_input_reference_note: 'C',
            meta_mode: url_meta_mode,
            meta_mode_repetitions: url_meta_mode_repetitions,
            first_question_for_game_in_meta_mode: true,
            reference_style: url_reference_style,
            reference_chord_type: 'Major',
            reference_style_strum: url_reference_style_strum,
            main_settings_exists: url_main_settings_exists,
            randomizer_settings_exists: url_randomizer_settings_exists,
            bandit_settings_exists: url_bandit_settings_exists,
            cut_audio: url_cut_audio,
            show_microphone_input: url_show_mic,
            microphone_input_cents_offset: null,
            microphone_input_cents_offset_trailing_average: null,
            microphone_input_note: null,
            microphone_input_frequency: null,
            microphone_hold: url_mic_hold,
            microphone_works: false,
            chord_octave: url_chord_octave,
            random_top_note: url_random_top_note,
            random_top_tension: url_random_top_tension,
            random_top_interval_being_played_against_chord_root: null,
            random_top_interval_being_played_against_scale_root: null,
            random_top_pitch_class: null,
            chord_loop_activated: false,
            chord_loop: url_chord_loop,
            roman_numeral_pre_loaded_sequence: null,
            chord_loop_tempo: url_chord_loop_tempo,
            show_roman_numeral_buttons: url_show_roman_numeral_buttons,
            show_chord_loop: url_show_chord_loop,
            show_random_top_note_options: url_show_random_top_note_options,
            intervals_relative_to_scale_root: url_intervals_relative_to_scale_root,
            bandit_activated: url_bandit_activated,
            bandit_results: null,
            bandit_results_exists: url_bandit_results_exists,
            show_bandit_results: true,
            show_bandit_settings: url_show_bandit_settings,
            bandit_difficulty_level: url_bandit_difficulty_level,
            beats_per_chord: url_beats_per_chord,
            bass_options_drone: url_bass_options_drone,
            bass_options_chord_roots: url_bass_options_chord_roots,
            played_a_question_yet: false,
            drumSnare: 15,
            drumBass: 5,
            extraDrumBass: 1,
            drumHiHat: 35,
            drumClap: 24,
            drums_to_play: null,
            original_scale_type: 'Major Scale',
            show_next_chord: url_show_next_chord
        }

        this.handleClick(default_handleClick_payload)
        setTimeout(() => {
            try {
                document.getElementById("userIdInput").value = user_id;
            } catch (err) {
            }

        }, 500)
        // setTimeout(() => this.handleClick(default_handleClick_payload), 100) // set to new candidate
        // setTimeout(() => this.handleOffClick(default_handleClick_payload), 200) // set to new candidate

        setInterval(() => {
            this.setState({show_time_diff_since_correct: Math.round(Math.floor(Math.round(new Date().getTime()) - this.state.last_correct_timestamp) / 1000) * 1000})

            // sometimes we need to restart the chord loop, I don't know why
            if ((new Date().getTime()) - this.state.prev_chord_loop_play_time_ms > 2 * 60000.0 / (this.state.chord_loop_tempo * 1.0)) {
                if (this.state.chord_loop_activated) {
                    console.log('restarting chord_loop')
                    this.playChordLoop()
                }
            }
        }, 1000)

        // make sure we update the internal state of the microphone working, needed for rendering purposes
        setInterval(() => {
            // initializeMicrophone();
            this.setState({microphone_works: microphone_works})
        }, 1000)

        // check for changes in the microphone responses, which are held as global variables
        setInterval(this.handleNewSungNote, short_note_detection_interval)
    }

    handleNewSungNote() {

        if (!this.state.microphone_works || !this.state.show_microphone_input) {
            return
        }

        microphone_input_note = null
        microphone_input_cents_offset = null
        var microphone_input_cents_offset_trailing_sum = 0;
        var microphone_input_cents_offset_trailing_number_of_samples = 0;
        show_microphone_input = this.state.show_microphone_input

        var microphone_input_baseline_frequency_trailing_average = Note.freq(Note.fromFreq(microphone_input_frequency_trailing_average))
        if (microphone_input_frequency_trailing_number_of_samples > microphone_input_frequency_trailing_array.length * 3.0 / 4.0) {
            microphone_input_cents_offset_trailing_average = 1200.0 * Math.log2(microphone_input_frequency_trailing_average / microphone_input_baseline_frequency_trailing_average)
        } else {
            microphone_input_cents_offset_trailing_average = null
        }
        this.setState({microphone_input_cents_offset_trailing_average: microphone_input_cents_offset_trailing_average})

        // console.log('microphone_input_frequency_trailing_average: ' + microphone_input_frequency_trailing_average)
        // console.log('microphone_input_cents_offset_trailing_average: ' + microphone_input_cents_offset_trailing_average)

        if (microphone_input_frequency !== null) {
            microphone_input_note = Note.fromFreq(microphone_input_frequency)
            var freqency_from_microphone_input_note = Note.freq(microphone_input_note)
            microphone_input_cents_offset = 1200.0 * Math.log2(microphone_input_frequency / freqency_from_microphone_input_note)
        }


        if (this.state.microphone_works && this.state.show_microphone_input && !this.state.reset_after_won_sequence) {

            if (!(
                scale_types_to_choose_from.includes(this.state.chord_type) ||
                chord_types_to_choose_from.includes(this.state.chord_type) ||
                this.state.chord_type == 'Root' ||
                chord_scale_types_to_choose_from.includes(this.state.chord_type)
            )) {

                // console.log('microphone_input_frequency: ' + microphone_input_frequency)
                // console.log('this.state.microphone_input_frequency: ' + this.state.microphone_input_frequency)

                // console.log('microphone_input_frequency_trailing_note_array: ' + microphone_input_frequency_trailing_note_array)
                // console.log('getModeOfArray(microphone_input_frequency_trailing_note_array): ' + getModeOfArray(microphone_input_frequency_trailing_note_array))


                if (
                    (microphone_input_frequency !== null && this.state.microphone_input_frequency === null) ||
                    (Note.fromFreq(microphone_input_frequency) != Note.fromFreq(this.state.microphone_input_frequency))
                ) {

                    if (
                        (new Date().getTime() - previous_note_detection_timestamp) > 50 &&
                        microphone_input_frequency_trailing_number_of_samples > (microphone_input_frequency_trailing_array.length * 3.0 / 4.0) &&
                        microphone_input_note == getModeOfArray(microphone_input_frequency_trailing_note_array)
                    ) {

                        console.log('activated microphone click with note ' + microphone_input_note)

                        previous_note_detection_timestamp = new Date().getTime()

                        var note = microphone_input_note
                        if (note !== null) {
                            this.handleClick({
                                note: note,
                                notes_on_fret: [
                                    note,
                                    note,
                                    note,
                                    note,
                                    note,
                                    note
                                ],
                                loc: {str: '', pos: ''},
                                onRadioChange: false,
                                fromMidi: true,
                                fromMic: true,
                                microphone_input_cents_offset: microphone_input_cents_offset,
                                microphone_input_cents_offset_trailing_average: microphone_input_cents_offset_trailing_average,
                                microphone_input_note: microphone_input_note,
                                microphone_input_frequency: microphone_input_frequency

                            })
                        }
                    }

                } else if (
                    microphone_input_frequency == null &&
                    microphone_input_frequency_trailing_number_of_samples < (microphone_input_frequency_trailing_array.length * 1.0 / 4.0)
                ) {
                    this.handleOffClick(false);
                }
            } else {

                if (
                    (
                        microphone_input_frequency !== null &&
                        (microphone_input_note != this.state.microphone_input_note) &&
                        microphone_input_frequency_trailing_number_of_samples > (microphone_input_frequency_trailing_array.length * 3.0 / 4.0) &&
                        microphone_input_note == getModeOfArray(microphone_input_frequency_trailing_note_array)
                    ) || (
                        (microphone_input_frequency === null && this.state.microphone_input_frequency !== null) &&
                        microphone_input_frequency_trailing_number_of_samples < (microphone_input_frequency_trailing_array.length * 1.0 / 4.0)
                    )
                ) {

                    if (
                        (
                            (this.state.microphone_hold && microphone_input_frequency !== null) &&
                            ((new Date().getTime() - previous_note_detection_timestamp) > 50)
                        ) ||
                        (
                            (!this.state.microphone_hold) &&
                            (
                                microphone_input_frequency_trailing_number_of_samples < (microphone_input_frequency_trailing_array.length * 1.0 / 4.0) &&
                                ((new Date().getTime() - previous_note_detection_timestamp) > note_off_delay_in_ms)
                            ) || (
                                microphone_input_frequency_trailing_number_of_samples > (microphone_input_frequency_trailing_array.length * 3.0 / 4.0) &&
                                ((new Date().getTime() - previous_note_detection_timestamp) > 50)
                            )
                        )
                    ) {

                        console.log('microphone_input_cents_offset: ' + microphone_input_cents_offset)
                        console.log('microphone_input_note: ' + microphone_input_note)
                        console.log('microphone_input_frequency: ' + microphone_input_frequency)

                        previous_note_detection_timestamp = new Date().getTime()

                        this.setState({
                            microphone_input_cents_offset: microphone_input_cents_offset,
                            microphone_input_cents_offset_trailing_average: microphone_input_cents_offset_trailing_number_of_samples,
                            microphone_input_note: microphone_input_note,
                            microphone_input_frequency: microphone_input_frequency
                        })
                    }

                }
            }
        }
    }

    playQuestionMidi(first_note_for_interval_find_sequence = true, instrument = 'undefined', reference_or_answer = 'both') {

        if (!this.state.played_a_question_yet && iOS()) {
            this.setState({'played_a_question_yet': true})
            return
        }

        if (chord_types_to_choose_from.includes(this.state.chord_type) ||
            scale_types_to_choose_from.includes(this.state.chord_type) ||
            'Root' == this.state.chord_type) {
            return
        }

        var start_time = new Date().getTime()
        if (this.state.cut_audio) {
            this.midiSounds.cancelQueue()
            this.midiSounds2.cancelQueue()
        }
        this.midiSounds3.cancelQueue()

        if (!this.state.play_sounds) {
            return
        }

        var use_instrument = this.state.selectedInstrument
        if (!instrument == 'undefined') {
            use_instrument = instrument
        }

        var lower_midi = 0
        var upper_midi = 1000
        var chord_type = this.state.chord_type
        var prev_note = this.state.prev_note
        var possible_array_of_info_from_state_chord_type = this.state.chord_type.split('|')
        if (possible_array_of_info_from_state_chord_type.length == 3) {
            chord_type = possible_array_of_info_from_state_chord_type[0]
            var scale_type = possible_array_of_info_from_state_chord_type[1]
            var chord_scale_interval = parseInt(possible_array_of_info_from_state_chord_type[2])
            if (
                typeof (this.state.prev_note) == 'undefined' ||
                this.state.prev_note == 'undefined' ||
                this.state.prev_note == ''
            ) {
                var scale_root_pitch_class = 'C'
            } else {
                var scale_root_pitch_class = Note.pitchClass(this.state.prev_note)
            }
            console.log('scale_root_pitch_class: ' + scale_root_pitch_class)
            var tmp_octave = Math.floor(this.state.chord_octave)
            if ((this.state.chord_octave - Math.floor(this.state.chord_octave)) == 0.5) {
                lower_midi = Note.midi('C' + tmp_octave) + 6
                upper_midi = Note.midi('C' + tmp_octave) + 6 + 12
            } else {
                lower_midi = Note.midi('C' + tmp_octave)
                upper_midi = Note.midi('C' + tmp_octave) + 12
            }
            console.log('lower_midi: ' + lower_midi)
            console.log('upper_midi: ' + upper_midi)
            var scale_root_pitch_class_with_octave = scale_root_pitch_class + '1'
            var chord_root_pitch_class_with_octave = Note.transpose(
                scale_root_pitch_class_with_octave,
                Interval.fromSemitones(chord_scale_interval)
            )
            if (Note.midi(chord_root_pitch_class_with_octave) <= lower_midi || Note.midi(chord_root_pitch_class_with_octave) > upper_midi) {
                chord_root_pitch_class_with_octave = Note.fromMidi(
                    mod(Note.midi(chord_root_pitch_class_with_octave) - lower_midi, 12) + lower_midi
                )
            }
            prev_note = chord_root_pitch_class_with_octave
        }

        var midi1 = Note.midi(this.state.selected_notes)
        if (scale_types_to_choose_from.includes(this.state.chord_type) ||
            chord_types_to_choose_from.includes(this.state.chord_type) ||
            this.state.chord_type == 'Root' ||
            chord_scale_types_to_choose_from.includes(this.state.chord_type)
        ) {
            midi1 = Note.midi(prev_note)
        }
        if (['Chord Spell', 'Scale Spell'].includes(this.state.chord_type)) {
            midi1 = Note.midi(this.state.pitch_class_to_play_with_octave)
        }

        var midi2 = Note.midi(this.state.pitch_class_to_play_with_octave)

        var tmp_delay = this.state.delay_between_notes * 1.0 / 100.0;

        var reference_note = [midi1]
        var tmp_chord_type = this.state.reference_style
        if (tmp_chord_type == 'Random') {
            tmp_chord_type = this.state.reference_chord_type
        }
        var reference_note_as_single_chord = convertChordTypeToMidi.bind(this)(this.getSelectedNotes, midi1, lower_midi, upper_midi, tmp_chord_type)
        console.log('lower_midi upper_midi reference_note_as_single_chord: ' + reference_note_as_single_chord)
        var sequence_length = 1
        if (Object.keys(map_reference_chord_type_to_sequence_length).includes(tmp_chord_type)) {
            sequence_length = map_reference_chord_type_to_sequence_length[tmp_chord_type]
        }
        if (reference_or_answer == 'answer') {
            sequence_length = 0
        }
        if (this.state.freeze && !first_note_for_interval_find_sequence) {
            sequence_length = 0
        }

        var when = this.midiSounds.contextTime();

        var map_reference_chord_type_to_sequence = {
            'note': [midi1],
            'I-ii-V-I': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor', 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 7, 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major')
            ],
            'I-ii-V': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor', 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 7, 2)
            ],
            'Imaj7-iim7-V7-Imaj7': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major7'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor7', 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Dom7', 7, 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major7')
            ],
            'Imaj7-iim7-V7': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major7'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor7', 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Dom7', 7, 2)
            ],
            'I-IV-V-I': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 5, 1),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 7, 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major')
            ],
            'I-IV-V': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 5, 1),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 7, 2)
            ],
            'I-IV-I': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 5, 1),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major')
            ],
            'I-IV': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 5, 1),
            ],
            'Ima7-II7-Imaj7': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major7'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Dom7', 2, 1),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major7')
            ],
            'Imaj7-II7': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major7'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Dom7', 2, 1),
            ],
            'I-bVII-IV-I': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 10, 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 5, 1),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major')
            ],
            'I-bVII-IV': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 10, 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 5, 1)
            ],
            'im-iio-V-im': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Dim', 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 7, 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor')
            ],
            'im-iio-V': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Dim', 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 7, 2)
            ],
            'im7-iim7b5-V7-im7': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor7'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'm7b5', 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Dom7', 7, 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor7')
            ],
            'im7-iim7b5-V7': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor7'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'm7b5', 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Dom7', 7, 2)
            ],
            'im-ivm-V-im': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor', 5, 1),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 7, 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor')
            ],
            'im-ivm-V': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor', 5, 1),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 7, 2)
            ],
            'im-bVII-IV-im': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 10, 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 5, 1),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major')
            ],
            'im-bVII-IV': [
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Minor'),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 10, 2),
                convertChordTypeToMidi(this.getSelectedNotes, midi1, lower_midi, upper_midi, 'Major', 5, 1)
            ]
        }

        function playReferenceNote() {
            if (['note', 'Major', 'Minor', 'Major7', 'Minor7', 'Dom7', 'Dom7 Shell'].includes(tmp_chord_type)) {
                if (!reference_note_as_single_chord.includes(undefined)) {
                    this.midiSounds.playChordAt(when, use_instrument, reference_note_as_single_chord, tmp_delay);
                }
            } else {

                var i_internal = 0;
                for (var chord_midi_ind in map_reference_chord_type_to_sequence[tmp_chord_type]) {
                    this.midiSounds.playChordAt(
                        when + i_internal * tmp_delay,
                        use_instrument,
                        map_reference_chord_type_to_sequence[tmp_chord_type][chord_midi_ind],
                        tmp_delay
                    );
                    i_internal += 1
                }

            }
        }


        if (isFinite(when)) {

            if ([
                'Note + Interval Find Zone',
                'Note + Interval Find',
                'Note + Interval Find 1',
                'Note + Interval Find 5',
                'Interval Find Zone',
                'Interval Find',
                'Interval Find 1',
                'Interval Find 3'
            ].includes(this.state.chord_type)) {

                if (this.state.freeze) {
                    if (first_note_for_interval_find_sequence) {
                        playReferenceNote.bind(this)()
                        this.midiSounds.playChordAt(when + tmp_delay * sequence_length, use_instrument, [midi2], tmp_delay);
                    } else {
                        if (reference_or_answer == 'reference') {
                            playReferenceNote.bind(this)()
                        }
                        if (reference_or_answer == 'answer') {
                            this.midiSounds.playChordAt(when, use_instrument, [midi2], tmp_delay);
                        }
                        if (reference_or_answer == 'both') {
                            this.midiSounds.playChordAt(when, use_instrument, [midi2], tmp_delay);
                        }
                    }
                } else {
                    if (reference_or_answer != 'answer') {
                        playReferenceNote.bind(this)()
                    }
                    if (reference_or_answer != 'reference') {
                        this.midiSounds.playChordAt(when + tmp_delay * sequence_length, use_instrument, [midi2], tmp_delay);
                    }

                }
            } else if (this.state.chord_type == 'Interval Find Sequence') {
                if (first_note_for_interval_find_sequence) {
                    this.midiSounds.playChordAt(when, use_instrument, [midi1], tmp_delay);
                    this.midiSounds.playChordAt(when + tmp_delay, use_instrument, [midi2], tmp_delay);
                } else {
                    this.midiSounds.playChordAt(when + tmp_delay, use_instrument, [midi2], tmp_delay);
                }
            } else if (['Note Find Zone', 'Note Find', 'Note Find 1', 'Note Find 5'].includes(this.state.chord_type)) {
                this.midiSounds.playChordAt(when, use_instrument, [midi2], tmp_delay);
            } else if (
                ['Interval Group Spell', 'Chord Spell', 'Scale Spell', 'Interval Chord Spell', 'Interval Scale Spell', 'Root'].includes(this.state.chord_type) ||
                scale_types_to_choose_from.includes(this.state.chord_type) ||
                chord_types_to_choose_from.includes(this.state.chord_type) ||
                chord_scale_types_to_choose_from.includes(this.state.chord_type)
            ) {


                var tmp_delay = this.state.delay_between_notes * 1.0 / 100.0
                if (['Chord Spell', 'Scale Spell'].includes(this.state.chord_type)) {
                    var note_objs_to_play = this.getSelectedNotes(this.state.pitch_class_to_play, this.state.chord_type_to_play)
                } else if (['Interval Chord Spell', 'Interval Scale Spell'].includes(this.state.chord_type)) {
                    var note_objs_to_play = this.getSelectedNotes(Note.pitchClass(this.state.selected_notes), this.state.chord_type_to_play)
                } else if (['Interval Group Spell'].includes(this.state.chord_type)) {
                    var note_objs_to_play = this.getSelectedNotes(Note.pitchClass(this.state.selected_notes), this.state.interval_group_array)
                } else {
                    var note_objs_to_play = this.getSelectedNotes(chord_root_pitch_class_with_octave, chord_type)
                }

                if (['Chord Spell', 'Scale Spell'].includes(this.state.chord_type)) {
                    var base_midi_value = Note.midi(this.state.pitch_class_to_play_with_octave)
                } else if (['Interval Group Spell', 'Interval Chord Spell', 'Interval Scale Spell'].includes(this.state.chord_type)) {
                    var base_midi_value = Note.midi(this.state.selected_notes)
                } else {
                    var base_midi_value = Note.midi(prev_note)
                }

                if (base_midi_value < lower_midi || base_midi_value >= upper_midi) {
                    base_midi_value = mod(base_midi_value - lower_midi, 12) + lower_midi
                }
                var distance;
                var tmp_midi2;


                var tensions_to_play = []
                if (typeof (scale_type) != 'undefined') {
                    var scale_note_objs_to_play = this.getSelectedNotes(scale_root_pitch_class_with_octave, scale_type)
                    for (var i = 0; i < scale_note_objs_to_play.length; i++) {
                        distance = mod(Note.midi(scale_note_objs_to_play[i].note + '3') - base_midi_value, 12)
                        tmp_midi2 = base_midi_value + distance
                        tensions_to_play.push(tmp_midi2)
                    }
                }

                var midi_chord_to_play = []
                for (var i = 0; i < note_objs_to_play.length; i++) {
                    if (note_objs_to_play[i] == "") {
                        continue
                    }
                    distance = mod(Note.midi(note_objs_to_play[i].note + '3') - base_midi_value, 12)
                    tmp_midi2 = base_midi_value + distance

                    if (tmp_midi2 < lower_midi || tmp_midi2 >= upper_midi) {
                        tmp_midi2 = mod(tmp_midi2 - lower_midi, 12) + lower_midi
                    }
                    console.log('tmp_midi2: ' + tmp_midi2)

                    if (['Interval Group Spell'] == this.state.chord_type) {
                        if (i == 0) {
                            tmp_midi2 = base_midi_value
                        } else {
                            tmp_midi2 = this.state.interval_group_array[i - 1] + base_midi_value
                        }
                    }

                    midi_chord_to_play.push(tmp_midi2)


                    if (
                        isFinite(when) &&
                        typeof (when) != 'undefined' &&
                        isFinite(reference_note) &&
                        typeof (reference_note) != 'undefined' &&
                        reference_note != '' &&
                        isFinite(when) &&
                        typeof (sequence_length) != 'undefined' &&
                        isFinite(sequence_length) &&
                        typeof (tmp_midi2) != 'undefined' &&
                        isFinite(tmp_delay) &&
                        typeof (tmp_delay) != 'undefined' &&
                        this.state.reference_style_strum
                    ) {

                        if (this.state.reference_style != 'note') {

                            if (i == 0) {
                                if (reference_or_answer != 'answer') {
                                    if (!(this.state.freeze && !first_note_for_interval_find_sequence)) {
                                        playReferenceNote.bind(this)()
                                    } else if (reference_or_answer == 'reference') {
                                        playReferenceNote.bind(this)()
                                    }
                                }
                                if (reference_or_answer != 'reference') {
                                    this.midiSounds.playChordAt(when + tmp_delay * sequence_length, use_instrument, [midi1], tmp_delay);
                                }
                            } else {
                                if (reference_or_answer != 'reference') {
                                    this.midiSounds.playChordAt(when + tmp_delay * (sequence_length + i), use_instrument, [tmp_midi2], tmp_delay);
                                }
                            }
                        } else {
                            if (i == 0) {
                                if (reference_or_answer != 'answer') {
                                    if (!(this.state.freeze && !first_note_for_interval_find_sequence)) {
                                        playReferenceNote.bind(this)()
                                    } else if (reference_or_answer == 'reference') {
                                        playReferenceNote.bind(this)()
                                    }

                                }
                            } else {

                                if (reference_or_answer != 'reference') {
                                    this.midiSounds.playChordAt(when + tmp_delay * (sequence_length + i - 1), use_instrument, [tmp_midi2], tmp_delay);
                                }
                            }
                        }
                    }


                }

                if (this.state.random_top_note || this.state.random_top_tension) {
                    var available_random_top_notes = []
                    if (this.state.random_top_note) {
                        available_random_top_notes.push(...midi_chord_to_play)
                    }
                    if (this.state.random_top_tension) {
                        var avoid_notes_for_comparison = midi_chord_to_play.map(val => val + 1)
                        avoid_notes_for_comparison.push(...midi_chord_to_play.map(val => val + 1 + 12))
                        avoid_notes_for_comparison.push(...midi_chord_to_play.map(val => val + 1 - 12))
                        console.log('new_midi_note_to_play tensions_to_play: ' + tensions_to_play)
                        console.log('new_midi_note_to_play midi_chord_to_play: ' + midi_chord_to_play)
                        console.log('new_midi_note_to_play avoid_notes_for_comparison: ' + avoid_notes_for_comparison)
                        for (var j = 0; j < tensions_to_play.length; j++) {
                            if (
                                !midi_chord_to_play.includes(tensions_to_play[j]) &&
                                !avoid_notes_for_comparison.includes(tensions_to_play[j])
                            ) {
                                available_random_top_notes.push(tensions_to_play[j])
                            }
                        }
                    }


                    available_random_top_notes = available_random_top_notes.filter(onlyUnique)
                    var use_available_random_top_notes = []

                    // ensure I keep changing the note, since repeating is kind of a waste
                    if (available_random_top_notes.length > 1) {
                        for (var available_random_top_notes_ind = 0; available_random_top_notes_ind < available_random_top_notes.length; available_random_top_notes_ind++) {
                            if (Note.pitchClass(Note.fromMidi(available_random_top_notes[available_random_top_notes_ind])) != this.state.random_top_pitch_class) {
                                use_available_random_top_notes.push(available_random_top_notes[available_random_top_notes_ind])
                            }
                        }
                    } else {
                        use_available_random_top_notes = available_random_top_notes
                    }
                    available_random_top_notes = [...use_available_random_top_notes]

                    var available_random_top_notes_ind_to_add_an_octave_above = Math.floor(Math.random() * available_random_top_notes.length)
                    var new_midi_note_to_play = available_random_top_notes[available_random_top_notes_ind_to_add_an_octave_above] + 12
                    midi_chord_to_play.push(new_midi_note_to_play)
                    console.log('new_midi_note_to_play: ' + new_midi_note_to_play)
                    console.log('original new_midi_note_to_play: ' + (new_midi_note_to_play - 12))

                    var random_top_distance_being_played_against_chord_root = mod(new_midi_note_to_play - Note.midi(chord_root_pitch_class_with_octave), 12)
                    var random_top_distance_being_played_against_scale_root = mod(new_midi_note_to_play - Note.midi(scale_root_pitch_class_with_octave), 12)

                    console.log('new_midi_note_to_play random_top_distance_being_played_against_chord_root: ' + random_top_distance_being_played_against_chord_root)
                    console.log('new_midi_note_to_play random_top_distance_being_played_against_scale_root: ' + random_top_distance_being_played_against_scale_root)
                    this.setState({
                        random_top_pitch_class: Note.pitchClass(Note.fromMidi(new_midi_note_to_play)),
                        random_top_interval_being_played_against_chord_root: map_interval_int_to_pretty_show[random_top_distance_being_played_against_chord_root],
                        random_top_interval_being_played_against_scale_root: map_interval_int_to_pretty_show[random_top_distance_being_played_against_scale_root],
                    })
                }

                // add bass note
                var bass_note = []
                if (this.state.chord_loop_activated) {
                    if (this.state.bass_options_chord_roots) {
                        bass_note.push(midi_chord_to_play[0] - 12)
                    } else if (this.state.bass_options_drone) {
                        var scale_root_distance = mod(Note.midi(scale_root_pitch_class_with_octave) - lower_midi, 12)
                        bass_note.push(lower_midi + scale_root_distance - 12)
                    }
                }

                if (
                    isFinite(tmp_midi2) &&
                    typeof (tmp_midi2) != 'undefined' &&
                    isFinite(tmp_delay) &&
                    typeof (tmp_delay) != 'undefined' &&
                    !this.state.reference_style_strum
                ) {


                    if (this.state.reference_style == 'note') {
                        if (reference_or_answer != 'reference') {
                            this.midiSounds.playChordNow(use_instrument, midi_chord_to_play, tmp_delay);
                        }
                    } else {
                        if (reference_or_answer != 'answer') {
                            playReferenceNote.bind(this)()
                        }
                        if (reference_or_answer != 'reference') {
                            this.midiSounds.playChordAt(when + tmp_delay * sequence_length, use_instrument, midi_chord_to_play, tmp_delay);
                        }
                    }

                }

                console.log('base_midi_value: ' + base_midi_value)

                // add one note an octave above
                if (
                    'Interval Group Spell' != this.state.chord_type &&
                    'Root' != this.state.chord_type &&
                    isFinite(base_midi_value) &&
                    typeof (base_midi_value) != 'undefined' &&
                    isFinite(tmp_delay) &&
                    typeof (tmp_delay) != 'undefined' &&
                    this.state.reference_style_strum
                ) {
                    if (reference_or_answer != 'reference') {
                        if (this.state.reference_style != 'note') {
                            this.midiSounds.playChordAt(when + tmp_delay * (note_objs_to_play.length + sequence_length), use_instrument, [base_midi_value + 12], tmp_delay);
                        } else {
                            this.midiSounds.playChordAt(when + tmp_delay * (note_objs_to_play.length + sequence_length - 1), use_instrument, [base_midi_value + 12], tmp_delay);
                        }
                    }
                }

            }
        }

        if (this.state.drums_to_play != null &&
            this.state.drums_to_play != undefined &&
            this.state.chord_loop_activated &&
            chord_scale_types_to_choose_from.includes(this.state.chord_type)
        ) {
            this.midiSounds2.playDrumsNow(this.state.drums_to_play)
        }

        if (this.state.chord_loop_activated &&
            chord_scale_types_to_choose_from.includes(this.state.chord_type)
        ) {
            this.midiSounds3.playChordNow(this.state.selectedDroneInstrument, bass_note, tmp_delay)
        }

        this.setState({drums_to_play: null})
        var end_time = new Date().getTime()
        console.log('PlayQuestion took ' + (end_time - start_time) + ' ms to run')
    }

    handleTouchMove(payload) {
        console.log('handleTouchMove')
//    this.midiSounds.playChordNow(this.selectedInstrument, [60], 0.5);

//    this.setState({current_click_location: {...payload}})
    }


    handleOffClick(notFromMidi = true) {
        if (notFromMidi) {
            this.setState({current_click_location: {}})
        } else {
            if (this.state.chord_type == 'Interval Find Sequence') {
                this.setState({current_click_location: {}, winning_location: {}})
            } else {
                this.setState({current_click_location: {}})
            }
        }
    }

    async handleClick(payload, new_state_entries = {}) {

        // debugger

        var start_time = new Date().getTime()

        // need to put this code into a callback to avoid issues with setState's async nature
        function fetch_state() {

            if (typeof (this) == 'undefined') {
                return
            }

            var log_obj = {
                success: this.state.success,
                payload: payload,
                state: this.state
            }

            // console.log('look for response')

            // Simple POST request with a JSON body using fetch
            const requestOptions = {
                method: 'POST',
                headers: default_headers,
                body: JSON.stringify(log_obj)
            };
            // https://746ce9edb554.ngrok.io
            //fetch('http://127.0.0.1:5000/postmethod/data', requestOptions)
            fetch('https://6a7b0e04d7ec.ngrok.io/postmethod/data', requestOptions)
                .then(response => response.json())
                .then(data => {
                    // console.log('Response from flask: ')
                    // console.dir(data)
                })

        }

        // first we do an internal setState
        var new_state = {...this.state}
        for (var key in new_state_entries) {
            new_state[key] = new_state_entries[key]
        }

        if (!payload.onRadioChange && !payload.fromMic) {

            var note_to_play = payload.note
            if (['Note Find', 'Note + Interval Find', 'Interval Find'].includes(new_state.chord_type)) {
                note_to_play = payload.notes_on_fret[new_state.string_to_play]
            }

            if (new_state.play_sounds) {
                if (typeof (new_state.delay_between_notes) == 'undefined') {
                    var use_delay = 50
                } else {
                    var use_delay = new_state.delay_between_notes
                }
                if (this.state.cut_audio) {
                    this.midiSounds.cancelQueue()
                }
                this.midiSounds.playChordNow(new_state.selectedInstrument, [Note.midi(note_to_play)], use_delay * 1.0 / 100.0);

                this.updateURL({prev_note: note_to_play})
            }

        }

        function getRandomGameType(input_state_available_game_types) {
            var tmp_list_of_game_types_to_sample_from = [...input_state_available_game_types]
            tmp_list_of_game_types_to_sample_from = tmp_list_of_game_types_to_sample_from.filter(val => val != new_state.chord_type)
            for (var i = 0; i < new_state.meta_mode_repetitions; i++) {
                for (var j = 0; j < new_state.available_game_types.length - 1; j++) {
                    tmp_list_of_game_types_to_sample_from.push(new_state.chord_type)
                }
            }
            var random_game_type = tmp_list_of_game_types_to_sample_from[Math.floor(Math.random() * tmp_list_of_game_types_to_sample_from.length)]
            return random_game_type
        }

        if (!(payload.onRadioChange || payload.skip)) {
            new_state['first_question_for_game_in_meta_mode'] = false
        }

        var show_game_type = false
        if (
            chord_types_to_choose_from.includes(new_state.chord_type) ||
            'Root' == new_state.chord_type ||
            scale_types_to_choose_from.includes(new_state.chord_type) ||
            chord_scale_types_to_choose_from.includes(new_state.chord_type)
        ) {
            show_game_type = true
        }

        var should_we_switch_games_for_meta_mode = false
        if ((!new_state.freeze && show_game_type) || !show_game_type || payload.onRadioChange) {

            // we don't want to render candidates for when the skip button is hit during meta-mode, unless 
            //   we aren't changing the game type
            var render_candidate = true
            if (payload.skip && new_state.meta_mode) {
                render_candidate = false
            }

            // then we process the payload with our internally updated state, and update the internally updated state again
            var new_state_entries2 = await this.processPayload(payload, new_state, render_candidate)

            for (var key in new_state_entries2) {
                new_state[key] = new_state_entries2[key]
            }

            var default_delay = new_state.delay_between_notes * 1.0 / 100.0 * 1000
            var use_delay = 1.5 * default_delay
            if (new_state.chord_type.includes('Spell')) {
                use_delay = 2 * default_delay
            } else if (new_state.chord_type.includes('Sequence')) {
                use_delay = default_delay
            }

            if (payload.skip) {
                use_delay = 0
            }

            console.log('use_delay: ' + use_delay)

            // debugger


            if (payload.skip && new_state.meta_mode && !payload.skip_reset) {

                var random_game_type = getRandomGameType(new_state.available_game_types)

                var tmp_payload = {...default_handleClick_payload}
                tmp_payload['reset_after_won_sequence'] = true
                tmp_payload['onRadioChange'] = true
                tmp_payload['first_question_for_game_in_meta_mode'] = true
                tmp_payload['skip_reset'] = true

                this.handleClick.bind(this)(tmp_payload, {
                    chord_type: random_game_type,
                    first_question_for_game_in_meta_mode: true
                })

            } else {

                this.setState(new_state, () => {
                    setTimeout(async () => {
                        // console.log('pre-time-out state: ')
                        // console.dir(new_state)
                        // console.log('---------> timeout! <-------------')
                        if (new_state.reset_after_won_sequence) {

                            banditTrain({...this.state})

                            var random_game_type = getRandomGameType(new_state.available_game_types)
                            if (new_state.meta_mode && random_game_type != new_state.chord_type) {

                                var tmp_payload = {...default_handleClick_payload}
                                tmp_payload['reset_after_won_sequence'] = true
                                tmp_payload['onRadioChange'] = true
                                tmp_payload['first_question_for_game_in_meta_mode'] = true

                                //var tmp_state = {...new_state_after_won_sequence}
                                var tmp_state = {...new_state}
                                tmp_state['first_question_for_game_in_meta_mode'] = true
                                tmp_state['reset_after_won_sequence'] = true
                                tmp_state['chord_type'] = random_game_type
                                if (!payload.onRadioChange && !payload.skip) {
                                    tmp_state['first_question_for_game_in_meta_mode'] = true
                                }
                                this.handleClick.bind(this)(tmp_payload, tmp_state)

                            } else {

                                var new_state_after_won_sequence = await this.processPayload(payload, new_state)
                                new_state_after_won_sequence['reset_after_won_sequence'] = false

                                this.setState(new_state_after_won_sequence, () => {
                                    fetch_state.bind(this)()
                                })
                                this.playQuestionMidi(false)
                                this.updateURL()
                            }
                            should_we_switch_games_for_meta_mode = true
                        } else {
                            fetch_state.bind(this)() // this is where we log the state

                            if ('Interval Find Sequence' == new_state.chord_type && new_state.success) {

                                banditTrain(new_state)

                                if (new_state.meta_mode) {

                                    tmp_payload = {...default_handleClick_payload}
                                    tmp_payload['reset_after_won_sequence'] = true
                                    tmp_payload['onRadioChange'] = true
                                    tmp_payload['first_question_for_game_in_meta_mode'] = true
                                    var random_game_type = getRandomGameType(new_state.available_game_types)
                                    if (!(random_game_type == 'Interval Find Sequence' && new_state.chord_type == 'Interval Find Sequence')) {
                                        this.handleClick.bind(new_state)(tmp_payload, {
                                            chord_type: random_game_type,
                                            first_question_for_game_in_meta_mode: true
                                        })
                                    } else {
                                        this.playQuestionMidi(false)
                                        this.updateURL()
                                    }
                                } else {
                                    this.playQuestionMidi(false)
                                    this.updateURL()
                                }
                                should_we_switch_games_for_meta_mode = true

                            } else if (payload.onRadioChange) {
                                if (payload.skip && new_state.freeze && !payload.full_skip) {
                                    this.playQuestionMidi(false) // false for first_note_in_interval_find_sequence
                                } else {
                                    this.playQuestionMidi(true)
                                }
                                this.updateURL()
                            }

                        }
                    }, use_delay);
                });
            }
        }


        if (
            chord_types_to_choose_from.includes(this.state.chord_type) ||
            'Root' == this.state.chord_type ||
            scale_types_to_choose_from.includes(this.state.chord_type) ||
            chord_scale_types_to_choose_from.includes(new_state.chord_type)
        ) {
            if (!payload.onRadioChange) {
                this.setState({current_click_location: {...payload}})
            }
        }

        var end_time = new Date().getTime()
        console.log('HandleClick took ' + (end_time - start_time) + ' ms to run')

    }

    async processPayload(payload, state, render_candidate = true) {

        var bandit_results = state.bandit_results
        var start_time = new Date().getTime()
        const map_semitones_to_interval_type = {
            0: 'U',
            1: '2nds',
            2: '2nds',
            3: '3rds',
            4: '3rds',
            5: '4ths',
            6: 'TTs',
            7: '5ths',
            8: '6ths',
            9: '6ths',
            10: '7ths',
            11: '7ths'
        }

        console.log('-----------------')
        console.log('--- clickAction ---')
        console.log('-----------------')
        console.log("this.state:")
        console.dir(this.state)

        var old_state_selected_notes = this.state.selected_notes
        var old_state_prev_note = this.state.prev_note
        var old_state_prev_interval = this.state.prev_interval
        var old_state_pitch_class_to_play = this.state.pitch_class_to_play
        var old_state_interval_to_play = this.state.interval_to_play
        var old_state_time_diff_since_correct = this.state.time_diff_since_correct
        var old_state_last_correct_timestamp = this.state.last_correct_timestamp
        var old_state_last_last_correct_timestamp = this.state.last_last_correct_timestamp
        var old_state_chord_type = this.state.chord_type
        var new_prev_interval = 'undefined'
        var fret_distance = 100
        var string_distance = 0
        var random_pitch_class_fret = 0
        var matching_strings = true
        var random_fret = 0
        var random_string = 0
        var random_string2 = 0
        var random_pitch_class = 'undefined'
        var random_pitch_class_with_octave = 'C4'
        var random_string_fret_note = 0
        var random_pitch_class_fret = 0
        var random_reference_chord_type = 'Major'
        var random_octave = 0
        var new_find_3_trial_prev_correct = []
        var new_find_3_trial_prev_correct_full = []
        var new_find_3_trial_prev_correct_pitch_classes = []
        var random_zone = 'none'
        var selected_location_in_zone = false
        var n_choices_in_trial = -1
        var random_chord_type = "Root"
        var interval_type = "undefined"
        var fret_distance = 0
        var random_string_fret_pitch_class = 'undefined'
        var random_interval_group_array = []
        var string_distance = 0
        var random_zone_size = 3
        var bandit_pull_propensity = null
        const matching_strings_array = [0, 5]

        var distance_in_semitones = 100;
        var failed_to_get_candidate = false

        async function renderCandidate(fret = 'undefined', string = 'undefined', state) {

            var start_time = new Date().getTime()
            interval_type = 'undefined'

            async function generate_candidate_sub(fret = 'undefined', string = 'undefined') {

                var calls = 0;
                var fret_distance = 100
                var matching_strings = true


                while (true) {

                    var while_condition = !state.available_note_reference_note_distances.includes(fret_distance) ||
                        !state.available_note_reference_note_string_distances.includes(string_distance) ||
                        Math.abs(distance_in_semitones) > state.available_maximum_interval_distance ||
                        Math.abs(distance_in_semitones) < state.available_minimum_interval_distance ||
                        Note.midi(random_pitch_class_with_octave) - Note.midi(state.tuning[0]) < 0 ||
                        Note.midi(random_pitch_class_with_octave) - Note.midi(state.tuning[state.tuning.length - 1]) > 12 ||
                        !state.available_interval_types.includes(mod(distance_in_semitones, 12)) ||
                        !state.available_answer_notes.includes(Note.simplify(Note.pitchClass(random_pitch_class))) ||
                        !state.available_answer_note_frets.includes(random_pitch_class_fret) ||
                        !state.available_reference_notes.map(val => getPitchClassInt(val)).includes(getPitchClassInt(random_string_fret_note)) ||
                        (getPitchClassInt(state.pitch_class_to_play) == getPitchClassInt(random_pitch_class) && state.available_answer_notes.length > 1) || // avoid repetition
                        (!state.freeze && getPitchClassInt(state.selected_notes) == getPitchClassInt(random_string_fret_note) && state.available_reference_notes.length > 1) // avoid repetition
                    // || matching_strings

                    if (state.bandit_activated && game_types_with_bandits.includes(state.chord_type)) {
                        var valid_random_string_fret_note = random_string_fret_note != "" && random_string_fret_note != undefined
                        var valid_random_pitch_class = random_pitch_class != "" && random_pitch_class != undefined
                        while_condition = false
                        if (valid_random_pitch_class) {
                            while_condition = while_condition || getPitchClassInt(state.pitch_class_to_play) == getPitchClassInt(random_pitch_class) && state.available_answer_notes.length > 1
                        }
                        if (valid_random_string_fret_note) {
                            while_condition = while_condition || getPitchClassInt(state.selected_notes) == getPitchClassInt(random_string_fret_note) && state.available_reference_notes.length > 1
                        }
                        if (valid_random_pitch_class && valid_random_string_fret_note) {
                            while_condition = while_condition || (mod(distance_in_semitones, 12) == mod(state.interval_to_play_raw)) && state.available_interval_types.length > 1
                        }
                        if (calls > 3) {
                            while_condition = false
                            failed_to_get_candidate = true
                        }
                    }

                    if (!while_condition && calls > 0) {
                        break
                    }

                    if (state.freeze &&
                        !payload.full_skip &&
                        !(
                            typeof (state.render_candidate_random_fret) == 'undefined' &&
                            typeof (state.render_candidate_random_string) == 'undefined'
                        ) &&
                        state.available_reference_note_frets.includes(state.render_candidate_random_fret) &&
                        state.available_reference_note_strings.includes(state.render_candidate_random_string)
                    ) {
                        random_fret = state.render_candidate_random_fret
                        random_string = state.render_candidate_random_string
                    } else if (fret == 'undefined' || string == 'undefined') {
                        random_fret = state.available_reference_note_frets[Math.floor((Math.random() * state.available_reference_note_frets.length))];
                        random_string = state.available_reference_note_strings[Math.floor((Math.random() * state.available_reference_note_strings.length))];
                    } else {
                        random_fret = fret;
                        random_string = string;
                    }

                    random_string2 = state.available_answer_note_strings[Math.floor((Math.random() * state.available_answer_note_strings.length))];
                    random_pitch_class = Note.pitchClass(state.available_answer_notes[Math.floor(Math.random() * state.available_answer_notes.length)])

                    // Randomized variables:
                    //
                    // "Reference note"
                    //     random_fret
                    //     random_string
                    //     --> derivative
                    //         random_string_fret_note
                    //         random_string_fret_pitch_class
                    //
                    // "Answer note"
                    //     random_string2
                    //     random_pitch_class
                    //     --> derivative
                    //         random_pitch_class_fret
                    //         random_pitch_class_with_octave (fed also by random_octave for some games)
                    //         random_zone (fed also by random_zone_size, available_random_zones has no impact)
                    //
                    // Else
                    //     random_zone_size
                    //     random_chord_type (conflation with scales as well)

                    bandit_results = await banditPull(state)

                    if (state.bandit_activated && game_types_with_bandits.includes(state.chord_type) && bandit_results != null && bandit_results != undefined && bandit_results.success) {
                        random_string = bandit_results.reference_string
                        random_fret = bandit_results.reference_fret
                        random_string2 = bandit_results.answer_string
                        random_pitch_class = bandit_results.answer_note
                        bandit_pull_propensity = bandit_results.propensity
                        console.log('bandit_results:')
                        console.dir(bandit_results)
                    }

                    random_string_fret_note = Note.transpose(state.tuning.slice().reverse()[random_string], Interval.fromSemitones(random_fret))
                    random_string_fret_pitch_class = Note.simplify(Note.pitchClass(random_string_fret_note))
                    random_pitch_class_fret = mod(Interval.semitones(Interval.distance(state.tuning.slice().reverse()[random_string2], random_pitch_class + '8')), 12)
                    if (random_pitch_class_fret == 0) {
                        random_pitch_class_fret = 12
                    }

                    // if (
                    //     state.chord_type == 'Interval Find' &&
                    //     (
                    //         (random_string == 0 && random_string2 == 5) ||
                    //         (random_string == 5 && random_string2 == 0)
                    //     )
                    // ) {
                    //     matching_strings = true
                    // } else {
                    //     matching_strings = false
                    // }

                    var random_reference_chord_type_ind = Math.floor(Math.random() * reference_styles_to_choose_from.length)
                    random_reference_chord_type = reference_styles_to_choose_from[random_reference_chord_type_ind]

                    random_zone_size = state.available_zone_sizes[Math.floor((Math.random() * state.available_zone_sizes.length))];

                    if (random_zone_size == 3) {
                        var available_zone_offsets = [-2, -1, 0]
                        if (random_pitch_class_fret == 1) {
                            available_zone_offsets = [0]
                        } else if (random_pitch_class_fret == 12) {
                            available_zone_offsets = [-2]
                        } else if (random_pitch_class_fret == 11) {
                            available_zone_offsets = [-2, -1]
                        } else if (random_pitch_class_fret == 2) {
                            available_zone_offsets = [-1, 0]
                        }
                        var random_zone_offset = available_zone_offsets[Math.floor(Math.random() * available_zone_offsets.length)]
                        random_zone = random_pitch_class_fret + random_zone_offset
                    } else if (random_zone_size == 2) {
                        var available_zone_offsets = [-1, 0]
                        if (random_pitch_class_fret == 1) {
                            available_zone_offsets = [0]
                        } else if (random_pitch_class_fret == 12) {
                            available_zone_offsets = [-1]
                        }
                        var random_zone_offset = available_zone_offsets[Math.floor(Math.random() * available_zone_offsets.length)]
                        random_zone = random_pitch_class_fret + random_zone_offset
                    } else if (random_zone_size == 1) {
                        random_zone = random_pitch_class_fret
                    }

                    // add in an octave jump if we should
                    if ([
                        'Note Find',
                        'Note Find Zone',
                        'Note Find 1',
                        'Note Find 5'
                    ].includes(state.chord_type)) {
                        random_pitch_class_with_octave = Note.transpose(state.tuning.slice().reverse()[random_string2], Interval.fromSemitones(random_pitch_class_fret))
                    } else {
                        var closest_octave_above = Note.octave(random_string_fret_note)
                        if (closest_octave_above == undefined) {
                            random_pitch_class_with_octave = Note.transpose(state.tuning.slice().reverse()[random_string2], Interval.fromSemitones(random_pitch_class_fret))
                        } else {
                            if (Note.midi(random_pitch_class + closest_octave_above) < Note.midi(random_string_fret_note)) {
                                closest_octave_above += 1
                            }
                            random_octave = closest_octave_above + state.available_octave_jumps[Math.floor(Math.random() * state.available_octave_jumps.length)]
                            random_pitch_class_with_octave = random_pitch_class + random_octave
                        }
                    }


                    distance_in_semitones = Interval.semitones(Interval.distance(
                        random_string_fret_note, random_pitch_class_with_octave))

                    fret_distance = Math.abs(random_pitch_class_fret - random_fret)
                    string_distance = Math.abs(random_string2 - random_string)

                    if (calls % 100 == 0) {
                        console.log('calls: ' + calls)
                        console.log('renderCandidate 1: ' + (!state.available_note_reference_note_distances.includes(fret_distance)))
                        console.log('renderCandidate 2: ' + (!state.available_note_reference_note_string_distances.includes(string_distance)))
                        console.log('renderCandidate 3: ' + (Math.abs(distance_in_semitones) > state.available_maximum_interval_distance))
                        console.log('renderCandidate 4: ' + (Math.abs(distance_in_semitones) < state.available_minimum_interval_distance))
                        console.log('renderCandidate 5: ' + (Note.midi(random_pitch_class_with_octave) - Note.midi(state.tuning[0]) < 0))
                        console.log('renderCandidate 6: ' + (Note.midi(random_pitch_class_with_octave) - Note.midi(state.tuning[state.tuning.length - 1]) > 12))
                        console.log('renderCandidate 7: ' + (!state.available_interval_types.includes(mod(distance_in_semitones, 12))))
                        console.log('renderCandidate 8: ' + (!state.available_answer_notes.includes(Note.simplify(Note.pitchClass(random_pitch_class)))))
                        console.log('renderCandidate 9: ' + (!state.available_answer_note_frets.includes(random_pitch_class_fret)))
                        console.log('renderCandidate 10: ' + (!state.available_reference_notes.map(val => getPitchClassInt(val)).includes(getPitchClassInt(random_string_fret_note))))
                        console.log('renderCandidate 11: ' + (!state.freeze && getPitchClassInt(state.pitch_class_to_play) == getPitchClassInt(random_pitch_class) && state.available_answer_notes.length > 1))
                        console.log('renderCandidate 12: ' + (!state.freeze && getPitchClassInt(state.selected_notes) == getPitchClassInt(random_string_fret_note) && state.available_reference_notes.length > 1))
                    }

                    calls += 1;

                    if (calls > 1000) {
                        failed_to_get_candidate = true
                        // debugger
                        break;
                    }

                }

                if (['Chord Spell', 'Interval Chord Spell'].includes(state.chord_type)) {
                    while (!state.available_chord_types.includes(random_chord_type)) {
                        random_chord_type = state.available_chord_types[Math.floor(Math.random() * state.available_chord_types.length)]
                    }
                } else if (['Scale Spell', 'Interval Scale Spell'].includes(state.chord_type)) {
                    while (!state.available_scales.includes(random_chord_type)) {
                        random_chord_type = state.available_scales[Math.floor(Math.random() * state.available_scales.length)]
                    }
                }

            }

            var calls = 0;
            if ('Interval Group Spell' == state.chord_type) {

                await generate_candidate_sub(fret = fret, string = string)
                var same_fret = random_fret
                var same_string = random_string
                var first_interval_note = random_pitch_class_with_octave
                var same_root_note = random_string_fret_note
                var same_root_pitch_class = random_string_fret_pitch_class
                var same_root_midi = Note.midi(same_root_note)
                var first_interval_midi = Note.midi(first_interval_note)
                random_interval_group_array.push(first_interval_midi - same_root_midi)


                for (var i = 1; i < state.interval_group_length; i++) {
                    var not_in_array = true
                    var already_in_array_calls = 0
                    while (not_in_array) {
                        generate_candidate_sub(fret = same_fret, string = same_string)
                        var new_interval_note = random_pitch_class_with_octave
                        var new_interval_midi = Note.midi(new_interval_note)
                        if (!random_interval_group_array.includes(new_interval_midi - same_root_midi)) {
                            not_in_array = false
                        }
                        already_in_array_calls += 1
                        if (already_in_array_calls > 100) {
                            failed_to_get_candidate = true
                        }
                    }
                    if (!random_interval_group_array.includes(new_interval_midi - same_root_midi)) {
                        random_interval_group_array.push(new_interval_midi - same_root_midi)
                    }
                }

                random_fret = same_fret
                random_string = same_string
                random_string_fret_note = same_root_note
                random_string_fret_pitch_class = same_root_pitch_class

            } else {

                console.log('fret: ' + fret)
                console.log('string: ' + string)
                await generate_candidate_sub(fret = fret, string = string)

            }


            var end_time = new Date().getTime()
            console.log('RenderCandidate took ' + (end_time - start_time) + ' ms to run')

        }


        var new_prev_note = 'undefined'
        var new_pitch_class = 'undefined'
        var new_pitch_class_with_octave = 'undefined'
        var message = '-'
        var new_string_to_play = 'undefined'
        var new_interval = 'undefined'
        var new_interval_raw = 'undefined'
        var new_zone_to_play = 'none'
        var new_zone_size_to_play = 'none'
        var new_chord_type_to_play = 'none'
        var success = false
        var new_selected_locations = []
        var new_selected_notes = []
        var new_find_3_trial = {}
        var new_last_correct_timestamp = state.last_correct_timestamp
        var new_last_last_correct_timestamp = state.last_last_correct_timestamp
        var new_number_bad_guesses_on_try = 0
        var new_number_good_guesses_on_try = 0
        var new_number_correct_guesses = 0
        var new_number_bad_guesses = 0
        var new_time_diff_since_correct = state.time_diff_since_correct
        if (!state.reset_after_won_sequence) {
            new_time_diff_since_correct = Math.round(new Date().getTime()) - old_state_last_correct_timestamp
        }
        var time_diff = Math.round(new Date().getTime()) - state.current_timestamp
        var new_initialized = false
        var new_reset_after_won_sequence = false
        var new_winning_location = {}
        var new_winning_label = ""
        var notes_to_play_full;
        var notes_to_play;
        var add_redundant_P5;
        var pitch_class_ints_to_play;
        var pitch_class_int_P5;
        var new_interval_group_array;
        var new_reference_chord_type = 'Major'
        var new_bandit_pull_propensity = null
        var new_question = {}

        // Deal with Find 3 trials
        function isEmpty(obj) {
            return Object.keys(obj).length === 0;
        }

        function general_pass_through() {

            new_pitch_class = state.pitch_class_to_play
            new_pitch_class_with_octave = state.pitch_class_to_play_with_octave
            new_string_to_play = state.string_to_play
            new_zone_to_play = state.zone_to_play
            new_zone_size_to_play = state.zone_size_to_play
            new_chord_type_to_play = state.chord_type_to_play
            new_reference_chord_type = state.reference_chord_type
            new_interval = state.interval_to_play
            new_interval_raw = state.interval_to_play_raw
            new_selected_locations = state.selected_locations
            new_selected_notes = state.selected_notes
            new_last_correct_timestamp = state.last_correct_timestamp
            new_last_last_correct_timestamp = state.last_last_correct_timestamp
            new_question = state.prev_loc_question
            new_number_correct_guesses = state.number_correct_guesses
            new_number_bad_guesses = state.number_bad_guesses
            new_number_bad_guesses_on_try = state.number_bad_guesses_on_try
            new_number_good_guesses_on_try = state.number_good_guesses_on_try
            new_find_3_trial_prev_correct = state.find_3_trial_prev_correct
            new_find_3_trial_prev_correct_full = state.find_3_trial_prev_correct_full
            new_find_3_trial_prev_correct_pitch_classes = state.find_3_trial_prev_correct_pitch_classes
            new_interval_group_array = state.interval_group_array
            bandit_pull_propensity = state.bandit_pull_propensity
        }

        async function general_initialize(fret = 'undefined', string = 'undefined') {

            if (render_candidate) {
                await renderCandidate(fret, string, state, true)
                console.log('== Right After Running renderCandidate ==')
                console.log('Reference note: ')
                console.log('    random_fret: ' + random_fret)
                console.log('    random_string: ' + random_string)
                console.log('Answer note: ')
                console.log('    random_string2: ' + random_string2)
                console.log('    random_pitch_class: ' + random_pitch_class)
                console.log('    random_pitch_class_fret: ' + random_pitch_class_fret)
                console.log('    random_pitch_class_with_octave: ' + random_pitch_class_with_octave)
                console.log('    random_zone: ' + random_zone)
            } else {
                general_pass_through()
            }

            new_pitch_class = random_pitch_class
            new_pitch_class_with_octave = random_pitch_class_with_octave
            new_string_to_play = random_string2
            new_chord_type_to_play = random_chord_type
            new_reference_chord_type = random_reference_chord_type
            new_selected_locations = [{loc: {str: random_string, pos: random_fret}}]
            new_selected_notes = random_string_fret_note
            new_question = {
                random_string: random_string,
                random_fret: random_fret,
                random_string2: random_string2,
                random_pitch_class_fret: random_pitch_class_fret,
                random_pitch_class: random_pitch_class,
                random_string_fret_note: random_string_fret_note,
                random_string_fret_pitch_class: random_string_fret_pitch_class,
                random_reference_chord_type: random_reference_chord_type,
                random_zone_size: random_zone_size,
                random_zone: random_zone
            }
            var new_interval_ind = mod(Interval.semitones(Interval.distance(new_selected_notes, new_pitch_class + '8')), 12)
            new_interval = intervals[new_interval_ind]
            new_interval_raw = new_interval_ind
            new_zone_to_play = random_zone
            new_zone_size_to_play = random_zone_size
            new_last_correct_timestamp = state.last_correct_timestamp // same as pass_through, use later logic to reset
            new_last_last_correct_timestamp = state.last_last_correct_timestamp // same as pass_through, use later logic to reset
            new_number_bad_guesses_on_try = 0
            new_number_good_guesses_on_try = 0
            new_initialized = true
            new_find_3_trial_prev_correct_full = []
            new_find_3_trial_prev_correct = []
            new_find_3_trial_prev_correct_pitch_classes = []
            new_interval_group_array = random_interval_group_array
            new_bandit_pull_propensity = bandit_pull_propensity
        }

        function general_get_notes_to_play(notes_to_play_full, chord_type_to_play, pitch_class_to_play) {
            notes_to_play = notes_to_play_full.map((x) => Note.simplify(x.note))
            notes_to_play = notes_to_play.filter(function (item, pos) {
                return notes_to_play.indexOf(item) == pos;
            });
            add_redundant_P5 = false
            if (['Chord Spell', 'Interval Chord Spell'].includes(state.chord_type) && !(chord_types_without_P5.includes(chord_type_to_play))) {
                add_redundant_P5 = true
            }
            pitch_class_ints_to_play = notes_to_play.map(getPitchClassInt)
            pitch_class_int_P5 = getPitchClassInt(Note.pitchClass(Note.transpose(pitch_class_to_play + '8', 'P5')))
        }

        general_pass_through()

        var payload_already_in_find_3 = false
        var i;
        for (i = 0; i < state.find_3_trial_prev_correct.length; i++) {
            if (state.find_3_trial_prev_correct[i].str == payload.loc.str &&
                state.find_3_trial_prev_correct[i].pos == payload.loc.pos) {
                payload_already_in_find_3 = true
            }
        }

        // load up initial state and winning state (same one for these two)
        if (["Note Find", "Interval Find", "Note + Interval Find"].includes(state.chord_type)) {
            if (
                getPitchClassInt(payload.notes_on_fret[state.string_to_play]) == getPitchClassInt(state.pitch_class_to_play) ||
                payload.onRadioChange ||
                (
                    payload.fromMidi &&
                    getPitchClassInt(payload.note) == getPitchClassInt(state.pitch_class_to_play)
                )
            ) {
                if (!state.reset_after_won_sequence && !payload.onRadioChange) {
                    new_reset_after_won_sequence = true
                    var tmp_payload = {...payload}
                    tmp_payload.note = payload.notes_on_fret[state.string_to_play]
                    tmp_payload.loc.str = state.string_to_play
                    new_winning_location = tmp_payload
                } else {
                    await general_initialize()
                    success = true
                    if (!payload.onRadioChange) {
                        new_number_correct_guesses = state.number_correct_guesses + 1
                        new_number_good_guesses_on_try = state.number_good_guesses_on_try + 1
                    }
                    new_reset_after_won_sequence = false
                    new_winning_location = {}
                }
            } else {

                new_number_bad_guesses_on_try = state.number_bad_guesses_on_try + 1
                new_number_bad_guesses = state.number_bad_guesses + 1
            }
        } else if (state.chord_type == "Interval Find 3") {

            // load up the initial state
            if (payload.onRadioChange) {
                await general_initialize()
                new_string_to_play = -1
            } else if (
                getPitchClassInt(payload.note) == getPitchClassInt(state.pitch_class_to_play) &&
                typeof (payload.note) !== 'undefined' &&
                !payload_already_in_find_3
            ) {
                if (state.find_3_trial_prev_correct.length < 2) {
                    new_find_3_trial_prev_correct.push(payload.loc)
                } else if (state.find_3_trial_prev_correct.length == 2 && !state.reset_after_won_sequence && !payload.onRadioChange) {
                    new_winning_location = payload
                    new_reset_after_won_sequence = true
                } else if (state.find_3_trial_prev_correct.length == 2) {
                    await general_initialize()
                    new_string_to_play = -1
                    new_find_3_trial_prev_correct = []
                    success = true
                    new_number_correct_guesses = state.number_correct_guesses + 1
                    new_number_good_guesses_on_try = state.number_good_guesses_on_try + 1
                }
            } else {
                new_string_to_play = -1
                new_number_bad_guesses_on_try = state.number_bad_guesses_on_try + 1
                new_number_bad_guesses = state.number_bad_guesses + 1
            }
        } else if (["Interval Group Spell", "Chord Spell", "Scale Spell", 'Interval Chord Spell', "Interval Scale Spell"].includes(state.chord_type)) {

            if (["Chord Spell", "Scale Spell"].includes(state.chord_type)) {
                notes_to_play_full = this.getSelectedNotes(state.pitch_class_to_play, state.chord_type_to_play)
            } else if (["Interval Group Spell"].includes(state.chord_type)) {
                notes_to_play_full = this.getSelectedNotes(Note.pitchClass(state.selected_notes), state.interval_group_array)
            } else if (['Interval Chord Spell', "Interval Scale Spell"].includes(state.chord_type)) {
                notes_to_play_full = this.getSelectedNotes(Note.pitchClass(state.selected_notes), state.chord_type_to_play)
            }


            // this ends up not being used for Interval Group Spell
            var chord_type_to_play = state.chord_type_to_play

            if (["Chord Spell", "Scale Spell"].includes(state.chord_type)) {
                var pitch_class_to_play = state.pitch_class_to_play
            } else if (["Interval Group Spell", "Interval Chord Spell", "Interval Scale Spell"].includes(state.chord_type)) {
                var pitch_class_to_play = Note.pitchClass(state.selected_notes)
            }

            general_get_notes_to_play(notes_to_play_full, chord_type_to_play, pitch_class_to_play)

            var have_we_done_this_location_before = false
            var i;
            for (i = 0; i < state.find_3_trial_prev_correct_full.length; i++) {
                if (state.find_3_trial_prev_correct_full[i].loc.pos == payload.loc.pos &&
                    state.find_3_trial_prev_correct_full[i].loc.str == payload.loc.str) {
                    have_we_done_this_location_before = true
                }
            }

            var matching_note_full_to_push;
            if (["Interval Group Spell", "Interval Chord Spell", "Interval Scale Spell"].includes(state.chord_type)) {
                matching_note_full_to_push = {
                    loc: {str: random_string, pos: random_fret},
                    status: 'R',
                    label: 'R'
                }
            }

            var payload_loc = {...payload.loc}
            if (payload.fromMidi) {
                var tmp_str = Math.floor(Math.random() * 6)
                var tmp_pos = mod(Note.midi(payload.note) - Note.midi(state.tuning[state.tuning.length - 1 - tmp_str]), 12)
                payload_loc = {str: tmp_str, pos: tmp_pos}

            }

            // get metadata about the note we're pushing, since it's not just correct, it also has interval info
            if ((pitch_class_ints_to_play.includes(getPitchClassInt(payload.note)) || (getPitchClassInt(payload.note) == pitch_class_int_P5 && add_redundant_P5)) && typeof (payload.note) !== 'undefined') {
                var matching_note_full_ind = pitch_class_ints_to_play.findIndex((x) => x == getPitchClassInt(payload.note))
                if (matching_note_full_ind > -1) {
                    var matching_note_full = notes_to_play_full[matching_note_full_ind]
                } else {
                    var matching_note_full = {loc: payload_loc, status: 'P5', label: 'P5'}
                }
                var matching_note_full_array = [payload_loc.str, payload_loc.pos]
                matching_note_full_to_push = {
                    loc: payload_loc,
                    status: matching_note_full.status,
                    label: matching_note_full.label
                }
            }

            // load up the initial state
            if (payload.onRadioChange) {
                // console.log(' INITIALIZE! ')
                await general_initialize()

                if (["Chord Spell", "Scale Spell"].includes(state.chord_type)) {
                    notes_to_play_full = this.getSelectedNotes(random_pitch_class, random_chord_type)
                    general_get_notes_to_play(notes_to_play_full, random_chord_type, random_pitch_class)
                } else if (["Interval Group Spell", "Interval Chord Spell", "Interval Scale Spell"].includes(state.chord_type)) {
                    if ("Interval Group Spell" == state.chord_type) {
                        notes_to_play_full = this.getSelectedNotes(random_string_fret_pitch_class, random_interval_group_array)
                    } else {
                        notes_to_play_full = this.getSelectedNotes(random_string_fret_pitch_class, random_chord_type)
                    }
                    general_get_notes_to_play(notes_to_play_full, random_chord_type, random_string_fret_pitch_class)
                    new_find_3_trial_prev_correct = [{str: random_string, pos: random_fret}]
                    new_find_3_trial_prev_correct_full = [matching_note_full_to_push]
                    new_find_3_trial_prev_correct_pitch_classes = [Note.pitchClass(payload.selected_notes)]
                }


                new_string_to_play = -1
            } else if (
                (pitch_class_ints_to_play.includes(getPitchClassInt(payload.note)) || (getPitchClassInt(payload.note) == pitch_class_int_P5 && add_redundant_P5)) &&
                typeof (payload.note) !== 'undefined' &&
                !have_we_done_this_location_before
            ) {

                if (getPitchClassInt(payload.note) == pitch_class_int_P5 && !pitch_class_ints_to_play.includes(pitch_class_int_P5)) {
                    // "We don't count the fifth for this chord type! You've got " + (state.find_3_trial_prev_correct_pitch_classes.length) + " out of " + notes_to_play.length + "!"
                    new_find_3_trial_prev_correct_full.push(matching_note_full_to_push)
                    new_number_good_guesses_on_try = state.number_good_guesses_on_try + 1

                } else if (state.find_3_trial_prev_correct_pitch_classes.includes(Note.pitchClass(payload.note))) {
                    // "You've already gotten that pitch class! You've got " + (state.find_3_trial_prev_correct_pitch_classes.length) + " out of " + notes_to_play.length + "!"
                    new_find_3_trial_prev_correct_full.push(matching_note_full_to_push)
                } else {
                    if (state.find_3_trial_prev_correct_pitch_classes.length < notes_to_play.length - 1) {
                        // console.log(' IT WORKED! ')
                        new_find_3_trial_prev_correct.push(payload.loc)
                        new_find_3_trial_prev_correct_full.push(matching_note_full_to_push)
                        new_find_3_trial_prev_correct_pitch_classes.push(Note.pitchClass(payload.note))
                        new_number_good_guesses_on_try = state.number_good_guesses_on_try + 1
                    } else if (state.find_3_trial_prev_correct_pitch_classes.length == notes_to_play.length - 1 && !state.reset_after_won_sequence && !payload.onRadioChange) {
                        // console.log(' IT WORKED A LOT! ')
                        new_winning_location = payload
                        new_reset_after_won_sequence = true
                    } else if (state.find_3_trial_prev_correct_pitch_classes.length == notes_to_play.length - 1) {
                        // console.log(' IT WORKED A LOT AGAIN! ')
                        await general_initialize()

                        if (["Chord Spell", "Scale Spell"].includes(state.chord_type)) {
                            new_find_3_trial_prev_correct = []
                            new_find_3_trial_prev_correct_full = []
                            new_find_3_trial_prev_correct_pitch_classes = []
                        } else if (["Interval Group Spell", "Interval Chord Spell", "Interval Scale Spell"].includes(state.chord_type)) {
                            if ("Interval Group Spell" == state.chord_type) {
                                notes_to_play_full = this.getSelectedNotes(random_string_fret_pitch_class, random_interval_group_array)
                            } else {
                                notes_to_play_full = this.getSelectedNotes(random_string_fret_pitch_class, random_chord_type)
                            }

                            general_get_notes_to_play(notes_to_play_full, random_chord_type, random_string_fret_pitch_class)

                            matching_note_full_to_push = {
                                loc: {str: random_string, pos: random_fret},
                                status: 'R',
                                label: 'R'
                            }
                            new_find_3_trial_prev_correct = [{str: random_string, pos: random_fret}]
                            new_find_3_trial_prev_correct_full = [matching_note_full_to_push]
                            new_find_3_trial_prev_correct_pitch_classes = [random_string_fret_pitch_class]
                        }
                        new_string_to_play = -1
                        success = true
                        if (!payload.onRadioChange) {
                            new_number_correct_guesses = state.number_correct_guesses + 1
                            new_number_good_guesses_on_try = state.number_good_guesses_on_try + 1
                        }
                    }
                }
            } else {
                // console.log(' IT DID NOT WORK! ')
                new_string_to_play = -1
                new_number_bad_guesses_on_try = state.number_bad_guesses_on_try + 1
                new_number_bad_guesses = state.number_bad_guesses + 1
            }
        } else if (["Note + Interval Find 1", "Note Find 1", "Interval Find 1"].includes(state.chord_type)) {

            // load up the initial state
            if (payload.onRadioChange) {
                // console.log(' INITIALIZE! ')
                await general_initialize()
                new_string_to_play = -1
            } else if (
                getPitchClassInt(payload.note) == getPitchClassInt(state.pitch_class_to_play) &&
                typeof (payload.note) !== 'undefined'
            ) {

                if (!state.reset_after_won_sequence && !payload.onRadioChange) {
                    new_string_to_play = -1
                    new_winning_location = payload
                    new_reset_after_won_sequence = true
                } else {
                    success = true
                    await general_initialize()
                    if (!payload.onRadioChange) {
                        new_number_correct_guesses = state.number_correct_guesses + 1
                        new_number_good_guesses_on_try = state.number_good_guesses_on_try + 1
                    }
                    new_string_to_play = -1
                }
            } else {
                // console.log(' IT DID NOT WORK! ')
                new_string_to_play = -1
                new_number_bad_guesses_on_try = state.number_bad_guesses_on_try + 1
                new_number_bad_guesses = state.number_bad_guesses + 1
            }
        } else if (state.chord_type == "Interval Find Sequence") {

            // load up the initial state
            if (payload.onRadioChange) {
                // (' INITIALIZE! ')
                await general_initialize()
                new_string_to_play = -1
            } else if (
                getPitchClassInt(payload.note) == getPitchClassInt(state.pitch_class_to_play) &&
                typeof (payload.note) !== 'undefined'
            ) {
                // console.log('IT WORKED A LOT!')
                if (payload.fromMidi) {
                    var tmp_str = Math.floor(Math.random() * 6)
                    var tmp_pos = mod(Note.midi(payload.note) - Note.midi(state.tuning[state.tuning.length - 1 - tmp_str]), 12)
                    new_selected_locations = [{loc: {str: tmp_str, pos: tmp_pos}}]
                    new_winning_location = {...payload}
                    new_winning_location.loc = {str: tmp_str, pos: tmp_pos}
                } else {
                    new_selected_locations = [{loc: payload.loc}]
                }
                await general_initialize(payload.loc.pos, payload.loc.str)
                if (!payload.onRadioChange) {
                    new_number_correct_guesses = state.number_correct_guesses + 1
                    new_number_good_guesses_on_try = state.number_good_guesses_on_try + 1
                }
                new_string_to_play = -1
                success = true
            } else {
                // console.log(' IT DID NOT WORK! ')
                new_string_to_play = -1
                new_number_bad_guesses_on_try = state.number_bad_guesses_on_try + 1
                new_number_bad_guesses = state.number_bad_guesses + 1
            }
        } else if (["Note Find 5", "Note + Interval Find 5"].includes(state.chord_type)) {

            // load up the initial state
            if (payload.onRadioChange) {
                // console.log(' INITIALIZE! ')
                await general_initialize()
                new_string_to_play = -1
            } else if (
                getPitchClassInt(payload.note) == getPitchClassInt(state.pitch_class_to_play) &&
                typeof (payload.note) !== 'undefined' &&
                !payload_already_in_find_3
            ) {
                if (state.find_3_trial_prev_correct.length < 4) {
                    // console.log(' IT WORKED! ')
                    new_find_3_trial_prev_correct.push(payload.loc)
                } else if (state.find_3_trial_prev_correct.length == 4 && !state.reset_after_won_sequence && !payload.onRadioChange) {
                    // console.log(' IT WORKED A LOT! ')
                    new_winning_location = payload
                    new_reset_after_won_sequence = true
                } else if (state.find_3_trial_prev_correct.length == 4) {
                    // console.log(' IT WORKED A LOT AGAIN! ')
                    await general_initialize()
                    new_string_to_play = -1
                    new_find_3_trial_prev_correct = []
                    if (!payload.onRadioChange) {
                        new_number_correct_guesses = state.number_correct_guesses + 1
                        new_number_good_guesses_on_try = state.number_good_guesses_on_try + 1
                    }
                    success = true
                }
            } else {
                // console.log(' IT DID NOT WORK! ')
                new_string_to_play = -1
                new_number_bad_guesses_on_try = state.number_bad_guesses_on_try + 1
                new_number_bad_guesses = state.number_bad_guesses + 1
            }
        } else if (["Note Find Zone", "Note + Interval Find Zone", "Interval Find Zone"].includes(state.chord_type)) {

            // load up the initial state
            if (payload.onRadioChange) {
                console.log(' INITIALIZE! ')
                await general_initialize()
                new_string_to_play = -1
            } else if (
                getPitchClassInt(payload.note) == getPitchClassInt(state.pitch_class_to_play) &&
                (
                    payload.fromMidi ||
                    payload.loc.pos >= state.zone_to_play && payload.loc.pos <= state.zone_to_play + (state.zone_size_to_play - 1)
                ) &&
                typeof (payload.note) !== 'undefined'
            ) {
                if (!state.reset_after_won_sequence && !payload.onRadioChange) {
                    console.log(' IT WORKED A LOT! ')
                    new_winning_location = payload
                    new_reset_after_won_sequence = true
                    new_zone_to_play = state.zone_to_play
                    new_zone_size_to_play = state.zone_size_to_play
                } else {
                    // console.log(' IT WORKED A LOT AGAIN! ')
                    await general_initialize()
                    new_string_to_play = -1
                    success = true
                    new_reset_after_won_sequence = false
                    new_number_correct_guesses = state.number_correct_guesses + 1
                    new_number_good_guesses_on_try = state.number_good_guesses_on_try + 1
                }
            } else {
                // console.log('ran bad guess code')
                general_pass_through()
                new_number_bad_guesses_on_try = state.number_bad_guesses_on_try + 1
                new_number_bad_guesses = state.number_bad_guesses + 1
                new_zone_to_play = state.zone_to_play
                new_zone_size_to_play = state.zone_size_to_play
            }
        } else {

            general_pass_through()
            if (!
                (
                    chord_types_to_choose_from.includes(this.state.chord_type) ||
                    'Root' == this.state.chord_type ||
                    scale_types_to_choose_from.includes(this.state.chord_type) ||
                    chord_scale_types_to_choose_from.includes(this.state.chord_type)
                )
            ) {
                new_number_bad_guesses_on_try = state.number_bad_guesses_on_try + 1
                new_number_bad_guesses = state.number_bad_guesses + 1
            }
        }

        var last_attempt_timestamp = state.current_timestamp


//    if (!(state.first_question_for_game_in_meta_mode || payload.first_question_for_game_in_meta_mode)) {
        if (!(state.reset_after_won_sequence || state.first_question_for_game_in_meta_mode)) {
            if (typeof (payload.note) == 'undefined') {
                new_prev_note = 'undefined'
                new_prev_interval = 'undefined'
            } else {
                new_prev_note = payload.note
                if (['Note Find', 'Note + Interval Find', 'Interval Find'].includes(old_state_chord_type)) {
                    new_prev_note = payload.notes_on_fret[state.string_to_play]
                }
                if (!old_state_chord_type.includes("Note") || old_state_chord_type.includes("Note + Interval")) {
                    new_prev_interval = solfege[mod(Note.midi(new_prev_note) - Note.midi(state.selected_notes), 12)]
                } else if (!old_state_chord_type.includes("Interval")) {
                    new_prev_interval = solfege[mod(Note.midi(new_prev_note) - Note.midi(state.pitch_class_to_play + '8'), 12)]
                }
            }
        } else {
            new_prev_note = old_state_prev_note
            new_prev_interval = old_state_prev_interval
        }

        //current_interval = solfege[mod(Note.midi(new_prev_note) - Note.midi(state.selected_notes), 12)]
        if ((!state.chord_type.includes("Note") && !state.chord_type.includes("Spell")) || state.chord_type.includes("Note + Interval")) {
            new_winning_label = map_interval_int_to_pretty_show[mod(Note.midi(new_winning_location.note) - Note.midi(state.selected_notes), 12)]
            if (typeof (new_winning_label) == 'undefined') {
                new_winning_label = state.winning_label
            }
        } else if ((!state.chord_type.includes("Interval") && !state.chord_type.includes("Spell")) && state.show_note_names) {
            new_winning_label = state.pitch_class_to_play //Note.pitchClass(new_winning_location.note)

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                new_winning_label = Note.transpose(state.pitch_class_to_play, 'P5')
            }
        } else if (state.chord_type.includes("Spell") && !state.chord_type.includes("Interval")) {
            new_winning_label = map_interval_int_to_pretty_show[mod(Note.midi(new_winning_location.note) - Note.midi(state.pitch_class_to_play_with_octave), 12)]
        } else if (state.chord_type.includes("Spell") && state.chord_type.includes("Interval")) {
            new_winning_label = map_interval_int_to_pretty_show[mod(Note.midi(new_winning_location.note) - Note.midi(state.selected_notes), 12)]
        }

        var new_current_click_location = {...payload}
        if (payload.onRadioChange || state.reset_after_won_sequence) {
            new_current_click_location = {}
        }

        if (success || payload.onRadioChange) {
            // console.log('I just reset the last_correct_timestamp!')
            new_last_correct_timestamp = Math.round(new Date().getTime())
            new_last_last_correct_timestamp = old_state_last_correct_timestamp
        }
        if (success && !payload.skip && !payload.skip_reset || payload.reset_after_won_sequence && !payload.skip_reset) {
            var new_prev_correct_guess_prev_note = new_prev_note
            var new_prev_correct_guess_prev_interval = new_prev_interval
            var new_prev_correct_guess_timestamp = Math.round(new Date().getTime())
            var new_prev_correct_guess_time_diff = Math.round(new Date().getTime()) - state.last_correct_timestamp
        } else {
            var new_prev_correct_guess_prev_note = state.prev_correct_guess_prev_note
            var new_prev_correct_guess_prev_interval = state.prev_correct_guess_prev_interval
            var new_prev_correct_guess_timestamp = state.prev_correct_guess_timestamp
            var new_prev_correct_guess_time_diff = state.prev_correct_guess_time_diff
        }

        var new_state_vals = {
            prev_note: new_prev_note,
            prev_interval: new_prev_interval,
            prev_correct_guess_prev_note: new_prev_correct_guess_prev_note,
            prev_correct_guess_prev_interval: new_prev_correct_guess_prev_interval,
            prev_correct_guess_timestamp: new_prev_correct_guess_timestamp,
            prev_correct_guess_time_diff: new_prev_correct_guess_time_diff,
            prev_loc: payload.loc,
            prev_loc_question: new_question,
            time_diff: time_diff,
            time_diff_since_correct: new_time_diff_since_correct,
            last_correct_timestamp: new_last_correct_timestamp,
            last_last_correct_timestamp: new_last_correct_timestamp,
            time_diff_since_last_last_correct: new_time_diff_since_correct,
            string_to_play: new_string_to_play,
            interval_to_play: new_interval,
            interval_to_play_raw: new_interval_raw,
            zone_to_play: new_zone_to_play,
            zone_size_to_play: new_zone_size_to_play,
            chord_type_to_play: new_chord_type_to_play,
            selected_locations: new_selected_locations,
            selected_notes: new_selected_notes,
            message: message,
            pitch_class_to_play: new_pitch_class,
            pitch_class_to_play_with_octave: new_pitch_class_with_octave,
            number_correct_guesses: new_number_correct_guesses,
            number_bad_guesses_on_try: new_number_bad_guesses_on_try,
            number_good_guesses_on_try: new_number_good_guesses_on_try,
            number_bad_guesses: new_number_bad_guesses,
            current_timestamp: Math.round(new Date().getTime()),
            last_attempt_timestamp: last_attempt_timestamp,
            find_3_trial_prev_correct: new_find_3_trial_prev_correct,
            find_3_trial_prev_correct_full: new_find_3_trial_prev_correct_full,
            find_3_trial_prev_correct_pitch_classes: new_find_3_trial_prev_correct_pitch_classes,
            initialized: new_initialized,
            reset_after_won_sequence: new_reset_after_won_sequence,
            winning_location: new_winning_location,
            winning_label: new_winning_label,
            failed_to_get_candidate: failed_to_get_candidate,
            success: success,
            interval_group_array: new_interval_group_array,
            current_click_location: new_current_click_location,
            reference_chord_type: new_reference_chord_type,
            render_candidate_random_fret: random_fret,
            render_candidate_random_string: random_string,
            render_candidate_random_string2: random_string2,
            render_candidate_random_pitch_class: random_pitch_class,
            render_candidate_random_string_fret_note: random_string_fret_note,
            render_candidate_random_string_fret_pitch_class: random_string_fret_pitch_class,
            render_candidate_random_pitch_class_fret: random_pitch_class_fret,
            render_candidate_random_reference_chord_type: random_reference_chord_type,
            render_candidate_random_zone_size: random_zone_size,
            render_candidate_random_zone: random_zone,
            bandit_results: bandit_results,
            processed_bandit_results: this.get_processed_bandit_results(bandit_results)
        }

        var end_time = new Date().getTime()
        console.log('ProcessPayload took ' + (end_time - start_time) + ' ms to run')

        console.log('== Right at End of ProcessPayload ==')
        console.log('Reference note: ')
        console.log('    random_fret: ' + random_fret)
        console.log('    random_string: ' + random_string)
        console.log('Answer note: ')
        console.log('    random_string2: ' + random_string2)
        console.log('    random_pitch_class: ' + random_pitch_class)
        console.log('    random_pitch_class_fret: ' + random_pitch_class_fret)
        console.log('    random_pitch_class_with_octave: ' + random_pitch_class_with_octave)
        console.log('    random_zone: ' + random_zone)

        return new_state_vals

    }

    getSelectedLocations() {

        var selected_loc = []
        var i;
        var current_click_location;

        if (['Interval Find', 'Interval Find 1'].includes(this.state.chord_type)) {
            for (var i = 0; i < this.state.selected_locations.length; i++) {
                selected_loc.push({loc: this.state.selected_locations[i].loc, status: "R"})
            }
        } else if (["Note Find 5", "Note + Interval Find 5"].includes(this.state.chord_type)) {
            for (var i = 0; i < this.state.find_3_trial_prev_correct.length; i++) {
                selected_loc.push({loc: this.state.find_3_trial_prev_correct[i], status: "correct"})
            }
        } else if (['Interval Find 3', 'Interval Find Zone', 'Interval Find Sequence'].includes(this.state.chord_type)) {
            for (var i = 0; i < this.state.find_3_trial_prev_correct.length; i++) {
                selected_loc.push({loc: this.state.find_3_trial_prev_correct[i], status: "correct"})
            }

            // this has to go afterwards so we see the big red note
            for (var i = 0; i < this.state.selected_locations.length; i++) {
                selected_loc.push({loc: this.state.selected_locations[i].loc, status: "R"})
            }
        } else if (['Chord Spell', 'Scale Spell'].includes(this.state.chord_type)) {

            for (var i = 0; i < this.state.find_3_trial_prev_correct_full.length; i++) {
                selected_loc.push(this.state.find_3_trial_prev_correct_full[i])
            }


        } else if (['Interval Group Spell', 'Interval Chord Spell', 'Interval Scale Spell'].includes(this.state.chord_type)) {


            for (var i = 0; i < this.state.find_3_trial_prev_correct_full.length; i++) {
                var tmp_object = this.state.find_3_trial_prev_correct_full[i]
                if (['Interval Group Spell', 'Interval Chord Spell', 'Interval Scale Spell'].includes(this.state.chord_type)) {
                    tmp_object['status'] = 'correct'
                }
                selected_loc.push(tmp_object)

            }

            // this has to go first so we see the root first
            for (var i = 0; i < this.state.selected_locations.length; i++) {
                selected_loc.push({loc: this.state.selected_locations[i].loc, status: "R", label: "R"})
            }

        }

        // add in current click, as visual feedback, but do this first so the other status stuff can over-ride
        if (!this.state.onRadioChange && !this.state.reset_after_won_sequence) {

            if (typeof (this.state.current_click_location.loc) != 'undefined') {
                if (['Note Find', 'Note + Interval Find', 'Interval Find'].includes(this.state.chord_type)) {
                    current_click_location = {
                        pos: this.state.current_click_location.loc.pos,
                        str: this.state.string_to_play
                    }
                } else {
                    current_click_location = this.state.current_click_location.loc
                }

                var new_current_click_label = ""

                if (this.state.show_hints) {
                    var current_click_note = this.state.current_click_location.note
                    if (['Note Find', 'Note + Interval Find', 'Interval Find'].includes(this.state.chord_type)) {
                        current_click_note = this.state.current_click_location.notes_on_fret[this.state.string_to_play]
                    }

                    if (!(this.state.chord_type.includes("Note") || this.state.chord_type.includes('Spell')) || this.state.chord_type.includes("Note + Interval")) {

                        new_current_click_label = map_interval_int_to_pretty_show[mod(Note.midi(current_click_note) - Note.midi(this.state.selected_notes), 12)]
                    } else if (!(this.state.chord_type.includes("Interval") || this.state.chord_type.includes('Spell')) && this.state.show_note_names) {

                        if (this.state.tuning_name == 'guitarlele with guitar note names') {
                            new_current_click_label = Note.pitchClass(Note.transpose(current_click_note, 'P5'))
                        } else {
                            new_current_click_label = Note.pitchClass(current_click_note)
                        }
                    } else if (this.state.chord_type.includes('Spell') && !this.state.chord_type.includes("Interval")) {
                        new_current_click_label = map_interval_int_to_pretty_show[mod(Note.midi(current_click_note) - Note.midi(this.state.pitch_class_to_play_with_octave), 12)]

                    } else if (this.state.chord_type.includes('Spell') && this.state.chord_type.includes("Interval")) {
                        new_current_click_label = map_interval_int_to_pretty_show[mod(Note.midi(current_click_note) - Note.midi(this.state.selected_notes), 12)]

                    }
                    if (typeof (new_current_click_label) == 'undefined') {
                        new_current_click_label = this.state.winning_label
                    }
                }
                if (!(this.state.chord_type == 'Interval Find Sequence' && this.state.success)) {
                    selected_loc.push({
                        loc: current_click_location,
                        status: "current_click",
                        label: new_current_click_label
                    })
                }
            }
        }

        if (typeof (this.state.winning_location.loc) != 'undefined') {
            selected_loc.push({
                loc: this.state.winning_location.loc,
                status: "winner",
                label: this.state.winning_label
            })
        }

        // show temporary interval when clicking
        if (
            chord_types_to_choose_from.includes(this.state.chord_type) ||
            'Root' == this.state.chord_type ||
            scale_types_to_choose_from.includes(this.state.chord_type) ||
            chord_scale_types_to_choose_from.includes(this.state.chord_type)
        ) {
            var use_prev_note = this.state.prev_note
            if (use_prev_note == 'undefined') {
                use_prev_note = 'C4'
            }


            if (typeof (this.state.current_click_location.loc) != 'undefined') {
                var current_click_location = this.state.current_click_location.loc
                var current_click_note = this.state.current_click_location.note

                var new_current_click_label = map_interval_int_to_pretty_show[mod(Note.midi(current_click_note) - Note.midi(use_prev_note), 12)]
                console.log('doing it new_current_click_label: ' + new_current_click_label)
                console.log('doing it current_click_note: ' + current_click_note)
                selected_loc.push({
                    loc: current_click_location,
                    status: "current_click",
                    label: new_current_click_label
                })
            }

        }

        return (selected_loc)
    }

    getSelectedNotesChordScale() {

        console.log("getSelectedNotesChordScale")


        var ghost_notes = []

        if (
            typeof (this.state.prev_note) == 'undefined' ||
            this.state.prev_note == 'undefined' ||
            this.state.prev_note == ''
        ) {
            var scale_root_pitch_class = 'C'
        } else {
            var scale_root_pitch_class = Note.pitchClass(this.state.prev_note)
        }
        // console.log('scale_root_pitch_class: ' + scale_root_pitch_class)

        if (
            scale_types_to_choose_from.includes(this.state.chord_type) ||
            chord_types_to_choose_from.includes(this.state.chord_type) ||
            this.state.chord_type == 'Root'
        ) {
            return_val = this.getSelectedNotes()

            // scrub coloring from the scale notes
            if (scale_types_to_choose_from.includes(this.state.chord_type)) {
                return_val = return_val.map((val) => {
                    return {
                        note: val.note,
                        label: val.label,
                        status: (val.status == 'R' ? 'R' : 'scale')
                    }
                })
            }

            if (this.state.microphone_works && this.state.show_microphone_input) {
                return_val = return_val.map(val => {
                    val['status'] = (val.status == 'R' ? 'root_when_microphone_present' : val.status)
                    return (val)
                })
            }
        } else {


            var possible_array_of_info_from_state_chord_type = this.state.chord_type.split('|')
            if (possible_array_of_info_from_state_chord_type.length == 3) {
                var chord_type = possible_array_of_info_from_state_chord_type[0]
                var scale_type = possible_array_of_info_from_state_chord_type[1]
                var chord_scale_interval = parseInt(possible_array_of_info_from_state_chord_type[2])
            }

            var chord_root_pitch_class = Note.transpose(
                scale_root_pitch_class,
                Interval.fromSemitones(chord_scale_interval)
            )
            var scale_notes_for_comparison = this.getSelectedNotes(scale_root_pitch_class, scale_type).map(val => getPitchClassInt(val.note))

            if (this.state.intervals_relative_to_scale_root) {
                var reference_pitch_class = scale_root_pitch_class
            } else {
                var reference_pitch_class = chord_root_pitch_class
            }
            // console.log('this.state.intervals_relative_to_scale_root: ' + this.state.intervals_relative_to_scale_root)

            var chord_notes = this.getSelectedNotes(chord_root_pitch_class, chord_type).map(val => {
                if (this.state.intervals_relative_to_scale_root) {
                    var label = map_interval_int_to_pretty_show[
                        mod(
                            Note.midi(val.note + '3') -
                            Note.midi(reference_pitch_class + '3'),
                            12
                        )
                        ]
                } else {
                    label = val.label
                }
                return ({
                    note: val.note,
                    label: label,
                    status: (
                        scale_notes_for_comparison.includes(getPitchClassInt(val.note)) ?
                            (
                                val.status == 'R' ?
                                    'chord_root' :
                                    (
                                        val.status == 'm3' ?
                                            'm3' :
                                            (
                                                val.status == 'M3' ?
                                                    'M3' :
                                                    'chord'
                                            )
                                    )
                            ) : 'chord_not_in_scale'
                    )
                })
            })

            // add p5 note
            if (!chord_types_without_P5.includes(chord_type)) {
                for (var j = 0; j < chord_notes.length; j++) {
                    if (chord_notes[j].status == 'chord_root') { // find the chord root
                        var new_note = Note.pitchClass(Note.transpose(chord_notes[j].note + '3', 'P5'))
                        var distance = mod(Note.midi(new_note + '3') - Note.midi(reference_pitch_class + '3'), 12)
                        var note_to_push = {
                            note: new_note,
                            status: 'chord',
                            label: map_interval_int_to_pretty_show[distance]
                        }
                        chord_notes.push(note_to_push)
                    }
                }
            }

            // add m7 note
            // if (!chord_types_without_P5.includes(chord_type)) {
            //     for (var j=0; j < chord_notes.length; j++) {
            //         if (chord_notes[j].status == 'chord_root') { // find the chord root
            //             chord_notes.push(
            //                 {
            //                     note: Note.pitchClass(Note.transpose(chord_notes[j].note + '3', 'p5')),
            //                     status: 'chord',
            //                     label: '5'
            //                 }
            //             )
            //         }
            //     }
            // }

            // add M2/M9
            for (var j = 0; j < chord_notes.length; j++) {
                if (mod(Note.midi(chord_notes[j].note + '3') - Note.midi(chord_root_pitch_class + '3'), 12) == 2) { // find the major ninth
                    var new_note = Note.pitchClass(Note.transpose(chord_notes[j].note + '3', 'm7'))
                    var distance = mod(Note.midi(new_note + '3') - Note.midi(reference_pitch_class + '3'), 12)
                    chord_notes.push(
                        {
                            note: new_note,
                            status: 'chord',
                            label: map_interval_int_to_pretty_show[distance]
                        }
                    )
                }
            }

            var chord_notes_for_comparison = chord_notes.map(val => val.note)
            var m2_notes_for_comparison = chord_notes_for_comparison.map(val => getPitchClassInt(Note.pitchClass(Note.transpose(val + '3', Interval.fromSemitones(1)))))
            // var TT_notes_for_comparison = chord_notes_for_comparison.map(val => getPitchClassInt(Note.pitchClass(Note.transpose(val + '3', Interval.fromSemitones(6)))))
            var avoid_notes_for_comparison = [...m2_notes_for_comparison]
            avoid_notes_for_comparison.push(...m2_notes_for_comparison.map(val => val + 12))
            avoid_notes_for_comparison.push(...m2_notes_for_comparison.map(val => val - 12))
            // avoid_notes_for_comparison.push(...TT_notes_for_comparison)

            var scale_notes = this.getSelectedNotes(scale_root_pitch_class, scale_type).map(val => {

                var label = val.label
                if (!this.state.intervals_relative_to_scale_root) {
                    label = map_interval_int_to_pretty_show[
                        mod(
                            Note.midi(val.note + '3') -
                            Note.midi(reference_pitch_class + '3'),
                            12
                        )
                        ]
                }
                return ({
                    note: val.note,
                    label: label,
                    status: (
                        avoid_notes_for_comparison.includes(getPitchClassInt(val.note)) ?
                            'avoid' :
                            (
                                val.status == 'R' ?
                                    'scale_root' :
                                    'scale'
                            )
                    )
                })
            })

            var return_val = [...scale_notes]

            // Line up labels of notes from chord to reflect any changes to the underlying scale
            if (this.state.intervals_relative_to_scale_root) {
                for (var chord_note of chord_notes) {
                    for (var note of return_val) {
                        if (getPitchClassInt(note.note) == getPitchClassInt(chord_note.note)) {
                            chord_note.label = note.label
                        }
                    }
                }
            }

            return_val.push(...chord_notes)

            // Add "ghosts" of upcoming chord
            var valid_chord_loop = []
            for (var i = 0; i < this.state.chord_loop.length; i++) {
                if (this.state.chord_loop[i] != null) {
                    valid_chord_loop.push(this.state.chord_loop[i])
                }
            }
            var next_valid_chord_loop_index = 0
            if (typeof (this.state.valid_chord_loop_index) != 'undefined') {
                var valid_chord_loop_index = Math.floor((this.state.valid_chord_loop_index * 1.0) / (this.state.beats_per_chord * 1.0))
                next_valid_chord_loop_index = mod(valid_chord_loop_index + 1, valid_chord_loop.length)
            } else {
                next_valid_chord_loop_index = 1
            }
            var next_chord = valid_chord_loop[next_valid_chord_loop_index]

            if (this.state.show_next_chord) {
                var chord_root_pitch_class = Note.transpose(
                    scale_root_pitch_class,
                    Interval.fromSemitones(next_chord.interval)
                )
                var chord_type = next_chord.chord_type

                ghost_notes = this.getSelectedNotes(chord_root_pitch_class, chord_type).map(val => {
                    if (['m3', 'M3'].includes(val.status)) {
                        var border = "2px dotted black"
                    } else {
                        var border = "2px dotted grey"
                    }
                    return ({
                        note: val.note,
                        label: ' ',
                        status: 'ghost',
                        border: border
                        // see dependencies/react-fretboard/components/Fretboard/Neck/Position/Fret/index.js
                    })
                })
            }


        }

        // debugger

        // add random_top_note
        if (
            this.state.random_top_pitch_class !== null &&
            this.state.random_top_pitch_class !== null &&
            (this.state.random_top_note || this.state.random_top_tension) &&
            chord_scale_types_to_choose_from.includes(this.state.chord_type) &&
            ((new Date().getTime()) - this.state.prev_chord_loop_play_time_ms) < 1500
        ) {
            var label = this.state.random_top_interval_being_played_against_scale_root
            if (!this.state.intervals_relative_to_scale_root) {
                label = this.state.random_top_interval_being_played_against_chord_root
            }

            var status = 'random_top_note'
            for (note of return_val) {
                if (getPitchClassInt(note.note) == getPitchClassInt(this.state.random_top_pitch_class)) {
                    label = note.label
                }
            }


            return_val.push(
                {
                    note: Note.pitchClass(this.state.random_top_pitch_class),
                    label: label,
                    status: status
                }
            )
        }

        // add microphone
        if (this.state.microphone_input_note !== null && this.state.show_microphone_input) {
            return_val.push(
                {
                    note: Note.pitchClass(this.state.microphone_input_note),
                    label: map_interval_int_to_pretty_show[
                        mod(
                            Note.midi(this.state.microphone_input_note) -
                            Note.midi(scale_root_pitch_class + '3'),
                            12
                        )
                        ],
                    status: 'microphone_input'
                }
            )
        }

        // Line up labels of notes from chord to reflect any changes to the underlying scale
        for (var ghost_note of ghost_notes) {
            for (var note of return_val) {
                if (getPitchClassInt(note.note) == getPitchClassInt(ghost_note.note)) {
                    ghost_note.label = note.label
                    ghost_note.status = note.status
                }
            }
        }

        return_val.push(...ghost_notes)

        console.log('return_val: ' + return_val)
        console.dir(return_val)

        return (return_val)

    }

    getSelectedNotes(root_pitch_class, chord_type) {

        // chord_type can either be a string or an array of integers (semitones from root)

        if (typeof (root_pitch_class) == 'undefined') {
            root_pitch_class = this.state.prev_note
        }
        if (root_pitch_class == 'undefined') {
            root_pitch_class = 'C'
        }

        var prev_pc = {note: Note.simplify(Note.pitchClass(root_pitch_class)), label: 'R', status: 'R'}
        var prev_s1 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '2m'))),
            label: '#1',
            status: 's1'
        }
        var prev_m2 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '2m'))),
            label: '♭2',
            status: 'm2'
        }
        var prev_M2 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '2M'))),
            label: '2',
            status: 'M2'
        }
        var prev_M3 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '3M'))),
            label: '3',
            status: 'M3'
        }
        var prev_n3 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '3M'))),
            label: '♮3',
            status: 'n3'
        }
        var prev_s2 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '3m'))),
            label: '#2',
            status: 's2'
        }
        var prev_m3 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '3m'))),
            label: '♭3',
            status: 'm3'
        }
        var prev_b4 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '3M'))),
            label: '♭4',
            status: 'b4'
        }
        var prev_P4 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '4P'))),
            label: '4',
            status: 'P4'
        }
        var prev_s4 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '4A'))),
            label: '#4',
            status: 's4'
        }
        var prev_b5 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '4A'))),
            label: '♭5',
            status: 'b5'
        }
        var prev_P5 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '5P'))),
            label: '5',
            status: 'P5'
        }
        var prev_TT = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '4A'))),
            label: '♭5',
            status: 'TT'
        }
        var prev_s5 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, 'm6'))),
            label: '#5',
            status: 's5'
        }
        var prev_m6 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, 'm6'))),
            label: '♭6',
            status: 'm6'
        }
        var prev_M6 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, 'M6'))),
            label: '6',
            status: 'M6'
        }
        var prev_m7 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '7m'))),
            label: '♭7',
            status: 'm7'
        }
        var prev_M7 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '7M'))),
            label: '7',
            status: 'M7'
        }
        var prev_b1 = {
            note: Note.simplify(Note.pitchClass(Note.transpose(root_pitch_class, '7M'))),
            label: '♭1',
            status: 'b1'
        }

        var map_int_to_note_obj = [prev_pc, prev_m2, prev_M2, prev_m3, prev_M3, prev_P4, prev_TT, prev_P5, prev_m6, prev_M6, prev_m7, prev_M7]


        var return_val = []

        if (typeof (chord_type) == 'undefined') {
            chord_type = this.state.chord_type
        } else if (typeof (chord_type) == 'object') {
            return_val = [prev_pc]
            for (var i = 0; i < chord_type.length; i++) {
                return_val.push(map_int_to_note_obj[mod(chord_type[i], 12)])
            }
        }


        if (chord_type == "Root") {
            return_val = [prev_pc]
        } else if (chord_type == "note") {
            return_val = [prev_pc]
        } else if (chord_type == "Major") {
            return_val = [prev_pc, prev_M3, prev_P5]
        } else if (chord_type == "Major") {
            return_val = [prev_pc, prev_M3, prev_P5]
        } else if (chord_type == "Minor") {
            return_val = [prev_pc, prev_m3, prev_P5]
        } else if (chord_type == "Dim") {
            return_val = [prev_pc, prev_m3, prev_TT]
        } else if (chord_type == "Aug") {
            return_val = [prev_pc, prev_M3, prev_s5]
        } else if (chord_type == "Aug7") {
            return_val = [prev_pc, prev_M3, prev_s5, prev_m7]
        } else if (chord_type == "AugM7") {
            return_val = [prev_pc, prev_M3, prev_s5, prev_M7]
        } else if (chord_type == "Majorb5") {
            return_val = [prev_pc, prev_M3, prev_b5]
        } else if (chord_type == "Major b5") {
            return_val = [prev_pc, prev_M3, prev_b5]
        } else if (chord_type == "Major6") {
            return_val = [prev_pc, prev_M3, prev_P5, prev_M6]
        } else if (chord_type == "Major69") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_P5, prev_M6]
        } else if (chord_type == "Major64") {
            return_val = [prev_pc, prev_M3, prev_P5]
        } else if (chord_type == "Minor6") {
            return_val = [prev_pc, prev_m3, prev_P5, prev_M6]
        } else if (chord_type == "Major7") {
            return_val = [prev_pc, prev_M3, prev_P5, prev_M7]
        } else if (chord_type == "Minor7") {
            return_val = [prev_pc, prev_m3, prev_P5, prev_m7]
        } else if (chord_type == "MinorMajor7") {
            return_val = [prev_pc, prev_m3, prev_P5, prev_M7]
        } else if (chord_type == "Minor6") {
            return_val = [prev_pc, prev_m3, prev_P5, prev_M6]
        } else if (chord_type == "Major9") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_M7]
        } else if (chord_type == "MinorAdd9") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_P5]
        } else if (chord_type == "MajorAdd9") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_P5]
        } else if (chord_type == "Minor69") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_M6]
        } else if (chord_type == "Major69") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_M6]
        } else if (chord_type == "Major11") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_P4, prev_M7]
        } else if (chord_type == "Major13") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_M6, prev_M7]
        } else if (chord_type == "Minor9") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_m7]
        } else if (chord_type == "Minorb9") {
            return_val = [prev_pc, prev_m2, prev_m3, prev_m7]
        } else if (chord_type == "MinorAddb9") {
            return_val = [prev_pc, prev_m2, prev_m3, prev_P5]
        } else if (chord_type == "Minor11") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_P4, prev_m7]
        } else if (chord_type == "Minor7b13") {
            return_val = [prev_pc, prev_m3, prev_m6, prev_m7]
        } else if (chord_type == "Minor13") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_P4, prev_M6, prev_m7]
        } else if (chord_type == "Major7#11") {
            return_val = [prev_pc, prev_M3, prev_s4, prev_M7]
        } else if (chord_type == "Dom7") {
            return_val = [prev_pc, prev_M3, prev_P5, prev_m7]
        } else if (chord_type == "Dom7 Shell") {
            return_val = [prev_pc, prev_M3, prev_m7]
        } else if (chord_type == "Dom9") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_m7]
        } else if (chord_type == "Dom11") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_P4, prev_m7]
        } else if (chord_type == "Dom13") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_M6, prev_m7]
        } else if (chord_type == "Dom7b5") {
            return_val = [prev_pc, prev_M3, prev_b5, prev_m7]
        } else if (chord_type == "Dom7#5") {
            return_val = [prev_pc, prev_M3, prev_s5, prev_m7]
        } else if (chord_type == "Dom7#11") {
            return_val = [prev_pc, prev_M3, prev_s4, prev_m7]
        } else if (chord_type == "Dom7b9") {
            return_val = [prev_pc, prev_m2, prev_M3, prev_m7]
        } else if (chord_type == "Dom7b13") {
            return_val = [prev_pc, prev_M3, prev_m6, prev_m7]
        } else if (chord_type == "Dom7#9") {
            return_val = [prev_pc, prev_s2, prev_M3, prev_m7]
        } else if (chord_type == "Dom7#9#11") {
            return_val = [prev_pc, prev_s2, prev_M3, prev_s4, prev_m7]
        } else if (chord_type == "Dom7b9b13") {
            return_val = [prev_pc, prev_m2, prev_M3, prev_m6, prev_m7]
        } else if (chord_type == "Dom7b9b5") {
            return_val = [prev_pc, prev_m2, prev_M3, prev_b5, prev_m7]
        } else if (chord_type == "Dom7b9#11") {
            return_val = [prev_pc, prev_m2, prev_M3, prev_s4, prev_m7]
        } else if (chord_type == "Dom7#9b13") {
            return_val = [prev_pc, prev_s2, prev_M3, prev_m6, prev_m7]
        } else if (chord_type == "Dom7#11b13") {
            return_val = [prev_pc, prev_M3, prev_s4, prev_m6, prev_m7]
        } else if (chord_type == "m7b5") {
            return_val = [prev_pc, prev_m3, prev_b5, prev_m7]
        } else if (chord_type == "m9b5") {
            return_val = [prev_pc, prev_m2, prev_m3, prev_TT]
        } else if (chord_type == "Dim7") {
            return_val = [prev_pc, prev_m3, prev_b5, prev_M6]
        } else if (chord_type == "Aug7") {
            return_val = [prev_pc, prev_M3, prev_m6, prev_m7]
        } else if (chord_type == "Sus2") {
            return_val = [prev_pc, prev_M2, prev_P5]
        } else if (chord_type == "Sus4") {
            return_val = [prev_pc, prev_P4, prev_P5]
        } else if (chord_type == "Dom7Sus2") {
            return_val = [prev_pc, prev_M2, prev_P5, prev_m7]
        } else if (chord_type == "Dom7Sus4") {
            return_val = [prev_pc, prev_P4, prev_P5, prev_m7]
        } else if (chord_type == "Sus24") {
            return_val = [prev_pc, prev_M2, prev_P4, prev_P5]
        } else if (chord_type == "Dom7Sus#4") {
            return_val = [prev_pc, prev_TT, prev_P5, prev_m7]
        } else if (chord_type == "Sus2#4") {
            return_val = [prev_pc, prev_M2, prev_s4, prev_P5]
        } else if (chord_type == "Sus#4") {
            return_val = [prev_pc, prev_TT, prev_P5]
        } else if (chord_type == "Major Scale") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_P4, prev_P5, prev_M6, prev_M7]
        } else if (chord_type == "Major (Ionian)") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_P4, prev_P5, prev_M6, prev_M7]
        } else if (chord_type == "Lydian") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_s4, prev_P5, prev_M6, prev_M7]
        } else if (chord_type == "Mixolydian") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_P4, prev_P5, prev_M6, prev_m7]
        } else if (chord_type == "Aeolian") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_P4, prev_P5, prev_m6, prev_m7]
        } else if (chord_type == "Dorian") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_P4, prev_P5, prev_M6, prev_m7]
        } else if (chord_type == "Phrygian") {
            return_val = [prev_pc, prev_m2, prev_m3, prev_P4, prev_P5, prev_m6, prev_m7]
        } else if (chord_type == "Locrian") {
            return_val = [prev_pc, prev_m2, prev_m3, prev_P4, prev_b5, prev_m6, prev_m7]
        } else if (chord_type == "Locrian n2") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_P4, prev_b5, prev_m6, prev_m7]
        } else if (chord_type == "Altered") {
            return_val = [prev_pc, prev_m2, prev_m3, prev_b4, prev_b5, prev_m6, prev_m7]
        } else if (chord_type == "Melodic Minor") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_P4, prev_P5, prev_M6, prev_M7]
        } else if (chord_type == "Harmonic Minor") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_P4, prev_P5, prev_m6, prev_M7]
        } else if (chord_type == "Phrygian Dominant") {
            return_val = [prev_pc, prev_m2, prev_M3, prev_P4, prev_P5, prev_m6, prev_m7]
        } else if (chord_type == "Phrygian b1") {
            return_val = [prev_b1, prev_m2, prev_m3, prev_P4, prev_P5, prev_m6, prev_m7]
        } else if (chord_type == "Lydian Dominant") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_s4, prev_P5, prev_M6, prev_m7]
        } else if (chord_type == "Lydian Augmented") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_s4, prev_m6, prev_M6, prev_M7]
        } else if (chord_type == "Mixolydian b6") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_P4, prev_P5, prev_m6, prev_m7]
        } else if (chord_type == "Dorian #4") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_s4, prev_P5, prev_M6, prev_m7]
        } else if (chord_type == "Diminished Dominant") {
            return_val = [prev_pc, prev_m2, prev_s2, prev_M3, prev_TT, prev_P5, prev_M6, prev_m7]
        } else if (chord_type == "Whole Tone") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_TT, prev_m6, prev_m7]
        } else if (chord_type == "Major Pentatonic") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_P5, prev_M6]
        } else if (chord_type == "Minor Pentatonic") {
            return_val = [prev_pc, prev_m3, prev_P4, prev_P5, prev_m7]
        } else if (chord_type == "Blues") {
            return_val = [prev_pc, prev_m3, prev_P4, prev_TT, prev_P5, prev_m7]
        } else if (chord_type == "Chromatic") {
            return_val = [prev_pc, prev_m2, prev_M2, prev_m3, prev_M3, prev_P4, prev_TT, prev_P5, prev_m6, prev_M6, prev_m7, prev_M7]
        } else if (chord_type == "Just b3") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_P4, prev_P5, prev_M6, prev_M7]
        } else if (chord_type == "Aeolian b5") {
            return_val = [prev_pc, prev_M2, prev_m3, prev_P4, prev_b5, prev_m6, prev_m7]
        } else if (chord_type == "Super Lydian") {
            return_val = [prev_s1, prev_M2, prev_M3, prev_s4, prev_P5, prev_M6, prev_M7]
        } else if (chord_type == "Super-Super Lydian") {
            return_val = [prev_s1, prev_s2, prev_M3, prev_s4, prev_s5, prev_M6, prev_M7]
        } else if (chord_type == "Augmented") {
            return_val = [prev_pc, prev_M2, prev_M3, prev_P4, prev_s5, prev_M6, prev_M7]
        }

        return (return_val)
    }

    getSelectedNotesRender() {
        var return_val = []

        if (
            this.state.current_click_location.fromMidi &&
            !(
                scale_types_to_choose_from.includes(this.state.chord_type) ||
                chord_types_to_choose_from.includes(this.state.chord_type) ||
                this.state.chord_type == 'Root' ||
                chord_scale_types_to_choose_from.includes(this.state.chord_type)
            )
        ) {
            var new_current_click_label = ''
            if (this.state.show_hints) {
                var current_click_note = this.state.current_click_location.note
                if (['Note Find', 'Note + Interval Find', 'Interval Find'].includes(this.state.chord_type)) {
                    current_click_note = this.state.current_click_location.notes_on_fret[this.state.string_to_play]
                }
                if (!(this.state.chord_type.includes("Note") || this.state.chord_type.includes('Spell')) || this.state.chord_type.includes("Note + Interval")) {
                    new_current_click_label = map_interval_int_to_pretty_show[mod(Note.midi(current_click_note) - Note.midi(this.state.selected_notes), 12)]
                } else if (!(this.state.chord_type.includes("Interval") || this.state.chord_type.includes('Spell')) && this.state.show_note_names) {
                    new_current_click_label = Note.pitchClass(current_click_note)
                } else if (this.state.chord_type.includes('Spell') && !this.state.chord_type.includes("Interval")) {
                    new_current_click_label = map_interval_int_to_pretty_show[mod(Note.midi(current_click_note) - Note.midi(this.state.pitch_class_to_play_with_octave), 12)]

                } else if (this.state.chord_type.includes('Spell') && this.state.chord_type.includes("Interval")) {
                    new_current_click_label = map_interval_int_to_pretty_show[mod(Note.midi(current_click_note) - Note.midi(this.state.selected_notes), 12)]

                }
                if (typeof (new_current_click_label) == 'undefined') {
                    new_current_click_label = this.state.winning_label
                }
            }
            return_val = [{
                note: Note.pitchClass(this.state.current_click_location.note),
                label: new_current_click_label,//''//Note.pitchClass(this.state.current_click_location.note),
                status: 'current_click'
            }]
        }

        if (
            this.state.winning_location.fromMidi &&
            !(
                scale_types_to_choose_from.includes(this.state.chord_type) ||
                chord_types_to_choose_from.includes(this.state.chord_type) ||
                this.state.chord_type == 'Root' ||
                chord_scale_types_to_choose_from.includes(this.state.chord_type)
            )
        ) {

            var new_winning_label
            if (
                (
                    !this.state.chord_type.includes("Note") &&
                    !this.state.chord_type.includes("Spell")
                ) ||
                this.state.chord_type.includes("Note + Interval")
            ) {
                new_winning_label = map_interval_int_to_pretty_show[mod(Note.midi(this.state.current_click_location.note) - Note.midi(this.state.selected_notes), 12)]
                if (typeof (new_winning_label) == 'undefined') {
                    new_winning_label = this.state.winning_label
                }
            } else if ((!this.state.chord_type.includes("Interval") && !this.state.chord_type.includes("Spell")) && this.state.show_note_names) {
                new_winning_label = this.state.pitch_class_to_play //Note.pitchClass(new_winning_location.note)
            } else if (this.state.chord_type.includes("Spell") && !this.state.chord_type.includes("Interval")) {
                new_winning_label = map_interval_int_to_pretty_show[mod(Note.midi(this.state.winning_location.note) - Note.midi(this.state.pitch_class_to_play_with_octave), 12)]
            } else if (this.state.chord_type.includes("Spell") && this.state.chord_type.includes("Interval")) {
                new_winning_label = map_interval_int_to_pretty_show[mod(Note.midi(this.state.winning_location.note) - Note.midi(this.state.selected_notes), 12)]
            }

            return_val = [{
                note: Note.pitchClass(this.state.winning_location.note),
                label: new_winning_label,//''//Note.pitchClass(this.state.winning_location.note),
                status: 'winner'
            }]
        }
        return (return_val)
    }

    getSelectedStrings() {
        return this.state.string_to_play
    }

    onGameTypeChange(payload) {

        if (!game_types_with_bandits.includes(payload.target.value)) {
            this.setState({'bandit_activated': false})
        }

        var new_chord_type = payload.target.value
        var new_tonic = this.state.prev_note
        var new_chord_scale_interval = 'undefined'
        var new_scale_type = null

        var possible_array_of_info_from_state_chord_type = this.state.chord_type.split('|')
        if (
            possible_array_of_info_from_state_chord_type.length == 3 &&
            (
                chord_types_to_choose_from.includes(payload.target.value) ||
                payload.target.value == 'Root' ||
                scale_types_to_choose_from.includes(payload.target.value) ||
                chord_scale_intervals_to_choose_from.includes(parseInt(payload.target.value))
            )
        ) {
            new_chord_type = possible_array_of_info_from_state_chord_type[0]
            new_scale_type = possible_array_of_info_from_state_chord_type[1]
            new_chord_scale_interval = parseInt(possible_array_of_info_from_state_chord_type[2])
            if (chord_types_to_choose_from.includes(payload.target.value) || payload.target.value == 'Root') {
                new_chord_type = payload.target.value
            } else if (scale_types_to_choose_from.includes(payload.target.value)) {
                new_scale_type = payload.target.value
            } else if (chord_scale_intervals_to_choose_from.includes(parseInt(payload.target.value))) {
                new_chord_scale_interval = parseInt(payload.target.value)
            }

            //TODO: sorry I made "chord_type" equivalent to "game_type" -- technical debt to resolve
            new_chord_type = new_chord_type + "|" + new_scale_type + "|" + new_chord_scale_interval

        }

        if (payload.target.value == 'Show Chord') {
            new_chord_type = 'Major'
            new_tonic = 'C'
        } else if (payload.target.value == 'Show Scale') {
            new_chord_type = 'Major Scale'
            new_tonic = 'C'
        } else if (payload.target.value == 'Show Chord & Scale') {
            new_chord_type = 'Major|Major Scale|0'
            new_tonic = 'C'
        }

        var tmp_payload = {
            note: new_tonic,
            notes_on_fret: [
                new_tonic,
                new_tonic,
                new_tonic,
                new_tonic,
                new_tonic,
                new_tonic
            ],
            loc: {str: '', pos: ''},
            onRadioChange: true
        }

        this.setState({
            chord_loop_activated: false,
            original_scale_type: (new_scale_type == null ? this.state.original_scale_type : new_scale_type)
        })

        this.handleClick(tmp_payload, {
            prev_note: new_tonic,
            chord_type: new_chord_type,
            initialized: false,
            find_3_trial_prev_correct: [],
            reset_after_won_sequence: false,
            number_correct_guesses: 0,
            number_good_guesses_on_try: 0,
            freeze: false,
            original_scale_type: (new_scale_type == null ? this.state.original_scale_type : new_scale_type)
        })

        this.updateURL({chord_type: new_chord_type})
    }


    onReferenceStyleChange(payload) {
        function post_set_state() {
            this.handleClick(default_handleClick_payload)
        }

        this.handleClick(default_handleClick_payload, {
            reference_style: payload.target.value
        })

        this.updateURL({reference_style: payload.target.value})
    }


    onOctaveChange(payload) {

        this.setState({chord_octave: payload.target.value}, this.playQuestionMidi)
        this.updateURL({chord_octave: payload.target.value})

    }


    onMinimumDistanceChange(payload) {
        var new_max_dist = this.state.available_maximum_interval_distance
        var new_min_dist = payload.target.value
        if (new_min_dist > new_max_dist) {
            new_max_dist = new_min_dist
        }
        this.setState({
            available_minimum_interval_distance: new_min_dist,
            available_maximum_interval_distance: new_max_dist
        })
        this.handleClick(default_handleClick_payload, {
            available_minimum_interval_distance: new_min_dist,
            available_maximum_interval_distance: new_max_dist
        })
    }

    onMaximumDistanceChange(payload) {
        var new_min_dist = this.state.available_minimum_interval_distance
        var new_max_dist = payload.target.value
        if (new_min_dist > new_max_dist) {
            new_max_dist = new_min_dist
        }
        this.setState({
            available_minimum_interval_distance: new_min_dist,
            available_maximum_interval_distance: new_max_dist
        })
        this.handleClick(default_handleClick_payload, {
            available_maximum_interval_distance: new_max_dist,
            available_maximum_interval_distance: new_max_dist
        })
    }


    onTonicInputChange(payload, note_type) {


        if (note_type == 'reference_note') {
            var scale_type = this.state.scale_type_input_reference_note
        } else if (note_type == 'reference_note') {
            var scale_type = this.state.scale_type_input_answer_note
        }

        var notes_to_change_array_of_objects = this.getSelectedNotes(payload.target.value, scale_type)
        var new_available_notes_without_enharmonics = notes_to_change_array_of_objects.map(val => val.note)
        var new_available_notes = [...new_available_notes_without_enharmonics]
        for (var i = 0; i < new_available_notes_without_enharmonics.length; i++) {
            if (Note.enharmonic(new_available_notes_without_enharmonics[i]) != new_available_notes_without_enharmonics[i]) {
                new_available_notes.push(Note.enharmonic(new_available_notes_without_enharmonics[i]))
            }
        }


        if (new_available_notes.length == 0) {
            new_available_notes = ['C']
        }
        if (note_type == 'answer_note' && this.state.scale_type_input_answer_note_type == 'Unknown') {
            new_available_notes = [payload.target.value]
        }
        if (payload.target.value == 'Unknown') {
            return
        }

        if (note_type == 'answer_note') {

            this.setState({
                tonic_input_reference_note: this.state.tonic_input_reference_note,
                tonic_input_answer_note: payload.target.value
            })
            this.handleClick(default_handleClick_payload, {
                available_answer_notes: new_available_notes
            })

        } else if (note_type == 'reference_note') {

            this.setState({
                tonic_input_reference_note: payload.target.value,
                tonic_input_answer_note: this.state.tonic_input_answer_note
            })
            this.handleClick(default_handleClick_payload, {
                available_reference_notes: new_available_notes
            })

        }

    }


    onScaleTypeInputChange(payload, note_type) {

        if (note_type == 'reference_note') {
            var root_note = this.state.tonic_input_reference_note
        } else if (note_type == 'reference_note') {
            var root_note = this.state.tonic_input_answer_note
        }

        var notes_to_change_array_of_objects = this.getSelectedNotes(root_note, payload.target.value)
        var new_available_notes_without_enharmonics = notes_to_change_array_of_objects.map(val => val.note)
        var new_available_notes = [...new_available_notes_without_enharmonics]
        for (var i = 0; i < new_available_notes_without_enharmonics.length; i++) {
            if (Note.enharmonic(new_available_notes_without_enharmonics[i]) != new_available_notes_without_enharmonics[i]) {
                new_available_notes.push(Note.enharmonic(new_available_notes_without_enharmonics[i]))
            }
        }

        if (new_available_notes.length == 0) {
            new_available_notes = ['C']
        }
        if (
            (note_type == 'answer_note' && this.state.tonic_input_answer_note == 'Unknown') ||
            (note_type == 'reference_note' && this.state.tonic_input_reference_note == 'Unknown')
        ) {
            return
        }
        if (payload.target.value == 'Unknown') {
            return
        }

        if (note_type == 'answer_note') {

            this.setState({
                scale_type_input_reference_note: this.state.scale_type_input_reference_note,
                scale_type_input_answer_note: payload.target.value
            })
            this.handleClick(default_handleClick_payload, {
                available_answer_notes: new_available_notes
            })

        } else if (note_type == 'reference_note') {

            this.setState({
                scale_type_input_reference_note: payload.target.value,
                scale_type_input_answer_note: this.state.scale_type_input_answer_note
            })
            this.handleClick(default_handleClick_payload, {
                available_reference_notes: new_available_notes
            })

        }

    }


    getTonicInput(note_type) {
//    this.setState({})
//    this.handleClick(default_handleClick_payload, {})
    }

    getScaleTypeInput(note_type) {
//    this.setState({})
//    this.handleClick(default_handleClick_payload, {})
    }


    onMetaModeRepetitionsChange(payload) {
        var new_meta_mode_repetitions = payload.target.value
        this.setState({
            meta_mode_repetitions: new_meta_mode_repetitions
        })
    }

    onIntervalGroupLengthChange(payload) {
        var new_interval_group_length = parseInt(payload.target.value)
        this.setState({
            interval_group_length: new_interval_group_length
        })
        this.handleClick(default_handleClick_payload, {
            interval_group_length: new_interval_group_length
        })
    }

    updateURL(input_state = 'undefined') {
        var use_state = {...this.state}

        if (input_state != 'undefined') {
            for (var key in input_state) {
                use_state[key] = input_state[key]
            }
        }

        var url = '?'
        url += 'game_type=' + encodeURIComponent(use_state.chord_type)
        url += '&tuning=' + encodeURIComponent(use_state.tuning_name)
        url += '&tonic=' + encodeURIComponent(use_state.prev_note)
        url += '&reference_style=' + encodeURIComponent(use_state.reference_style)
        url += '&strum=' + encodeURIComponent(use_state.reference_style_strum)
        url += '&show_mic=' + encodeURIComponent(use_state.show_microphone_input)
        url += '&mic_hold=' + encodeURIComponent(use_state.microphone_hold)
        url += '&instrument=' + encodeURIComponent(use_state.selectedInstrument)
        url += '&drone_instrument=' + encodeURIComponent(use_state.selectedDroneInstrument)
        url += '&show_intervals=' + encodeURIComponent(use_state.show_intervals)
        url += '&show_note_names=' + encodeURIComponent(use_state.show_note_names)
        url += '&show_hints=' + encodeURIComponent(use_state.show_hints)
        url += '&cut_audio=' + encodeURIComponent(use_state.cut_audio)
        url += '&volume=' + encodeURIComponent(use_state.volume_before_muting)
        url += '&drum_volume=' + encodeURIComponent(use_state.drum_volume)
        url += '&drone_volume=' + encodeURIComponent(use_state.drone_volume)
        url += '&delay=' + encodeURIComponent(use_state.delay_between_notes)
        url += '&freeze=' + encodeURIComponent(use_state.freeze)
        url += '&show_freeze=' + encodeURIComponent(use_state.show_freeze)
        url += '&minimum_interval_distance=' + encodeURIComponent(use_state.available_minimum_interval_distance)
        url += '&maximum_interval_distance=' + encodeURIComponent(use_state.available_maximum_interval_distance)
        url += '&interval_types=' + encodeURIComponent(use_state.available_interval_types.join(url_sep))
        url += '&reference_note_strings=' + encodeURIComponent(use_state.available_reference_note_strings.join(url_sep))
        url += '&reference_note_frets=' + encodeURIComponent(use_state.available_reference_note_frets.join(url_sep))
        url += '&answer_note_frets=' + encodeURIComponent(use_state.available_answer_note_frets.join(url_sep))
        url += '&answer_note_strings=' + encodeURIComponent(use_state.available_answer_note_strings.join(url_sep))
        url += '&octave_jumps=' + encodeURIComponent(use_state.available_octave_jumps.join(url_sep))
        url += '&zone_sizes=' + encodeURIComponent(use_state.available_zone_sizes.join(url_sep))
        url += '&chord_octave=' + encodeURIComponent(use_state.chord_octave)
        url += '&random_top_note=' + encodeURIComponent(use_state.random_top_note)
        url += '&random_top_tension=' + encodeURIComponent(use_state.random_top_tension)
        url += '&show_next_chord=' + encodeURIComponent(use_state.show_next_chord)

        var tmp_interval_group_length = use_state.interval_group_length
        if (!isFinite(tmp_interval_group_length)) {
            tmp_interval_group_length = 'NaN'
        }

        url += '&interval_group_length=' + encodeURIComponent(tmp_interval_group_length)
        url += '&note_reference_note_fret_distances=' + encodeURIComponent(use_state.available_note_reference_note_distances.join(url_sep))
        url += '&note_reference_note_string_distances=' + encodeURIComponent(use_state.available_note_reference_note_string_distances.join(url_sep))
        url += '&meta_mode=' + encodeURIComponent(use_state.meta_mode)
        url += '&meta_mode_repetitions=' + encodeURIComponent(use_state.meta_mode_repetitions)
        url += '&main_settings=' + encodeURIComponent(use_state.main_settings_exists)
        url += '&randomizer_settings=' + encodeURIComponent(use_state.randomizer_settings_exists)
        url += '&bandit_settings=' + encodeURIComponent(use_state.bandit_settings_exists)
        url += '&show_main_settings=' + encodeURIComponent(use_state.show_main_settings)
        url += '&show_randomizer_settings=' + encodeURIComponent(use_state.show_randomizer_settings)
        url += '&show_bandit_settings=' + encodeURIComponent(use_state.show_bandit_settings)
        url += '&show_roman_numeral_buttons=' + encodeURIComponent(use_state.show_roman_numeral_buttons)
        url += '&show_random_top_note_options=' + encodeURIComponent(use_state.show_random_top_note_options)
        url += '&intervals_relative_to_scale_root=' + encodeURIComponent(use_state.intervals_relative_to_scale_root)
        url += '&show_chord_loop=' + encodeURIComponent(use_state.show_chord_loop)
        url += '&bandit_activated=' + encodeURIComponent(use_state.bandit_activated)
        // url += '&show_bandit_results=' + encodeURIComponent(use_state.show_bandit_results)
        url += '&bandit_results=' + encodeURIComponent(use_state.bandit_results_exists)
        url += '&bandit_difficulty=' + encodeURIComponent(use_state.bandit_difficulty_level)
        url += '&chord_loop_tempo=' + encodeURIComponent(use_state.chord_loop_tempo)
        url += '&beats_per_chord=' + encodeURIComponent(use_state.beats_per_chord)
        url += '&bass_options_drone=' + encodeURIComponent(use_state.bass_options_drone)
        url += '&bass_options_chord_roots=' + encodeURIComponent(use_state.bass_options_chord_roots)

        if (use_state.add_user_id_to_url) {
            url += '&user_id=' + encodeURIComponent(use_state.user_id)
        }

        function getRomanNumeralSequenceFromState(state) {
            var valid_chord_loop = []
            for (var i = 0; i < state.chord_loop.length; i++) {
                if (state.chord_loop[i] != null) {
                    valid_chord_loop.push(state.chord_loop[i])
                }
            }
            var valid_chord_loop_as_string = valid_chord_loop.map(val => val.name).join('-')
            console.log('render valid_chord_loop_as_string: ' + valid_chord_loop_as_string)
            return (valid_chord_loop_as_string)
        }

        url += '&chord_loop=' + encodeURIComponent(getRomanNumeralSequenceFromState(use_state))

        var game_types = []
        for (i = 0; i < use_state.available_game_types.length; i++) {
            var tmp_el = default_available_game_types.indexOf(use_state.available_game_types[i])
            if (tmp_el != -1) {
                game_types.push(tmp_el)
            }
        }

        url += '&game_types=' + game_types

        window.history.replaceState({}, '', url);
    }

    onSkipButton() {
        var tmp_payload = {...default_handleClick_payload}
        tmp_payload['skip'] = true
        this.handleClick(tmp_payload)
        if (iOS()) {
            this.midiSounds.playChordNow(this.state.selectedInstrument, [0], 0.1);
        }
    }

    onFullSkipButton(payload) {
        var tmp_payload = {...default_handleClick_payload}
        tmp_payload['skip'] = true
        tmp_payload['full_skip'] = true
        if (iOS()) {
            this.midiSounds.playChordNow(this.state.selectedInstrument, [0], 0.1);
        }
    }

    onRandomRootButton(payload) {
        var random_pitch_class = this.state.prev_note
        while (getPitchClassInt(random_pitch_class) == getPitchClassInt(this.state.prev_note)) {
            var random_pitch_class = Note.pitchClass(this.state.available_answer_notes[Math.floor(Math.random() * this.state.available_answer_notes.length)])
        }
        this.setState({prev_note: random_pitch_class + '4'}, () => this.playQuestionMidi(true))
        this.updateURL({tonic: random_pitch_class + '4'})
    }

    onRomanNumeralButton(payload) {

        var chord_type = this.state.chord_type
        var scale_type = this.state.scale_type
        var chord_scale_interval = 0
        var possible_array_of_info_from_state_chord_type = this.state.chord_type.split('|')
        if (possible_array_of_info_from_state_chord_type.length == 3) {

            chord_type = possible_array_of_info_from_state_chord_type[0]
            scale_type = possible_array_of_info_from_state_chord_type[1]
            //chord_scale_interval = parseInt(possible_array_of_info_from_state_chord_type[2])
            chord_scale_interval = map_roman_numeral_to_info[payload.target.value].interval

            if ('scale_name' in map_roman_numeral_to_info[payload.target.value]) {
                var new_scale_type = map_roman_numeral_to_info[payload.target.value].scale_name
            } else if ('scale_name_when_major' in map_roman_numeral_to_info[payload.target.value] && (this.state.original_scale_type == undefined || this.state.original_scale_type == 'Major Scale' || this.state.original_scale_type == 'Mixolydian')) {
                var new_scale_type = map_roman_numeral_to_info[payload.target.value].scale_name_when_major
            } else {
                var new_scale_type = this.state.original_scale_type
            }

            var new_chord_type = map_roman_numeral_to_info[payload.target.value].chord_type + '|' +
                new_scale_type + '|' +
                map_roman_numeral_to_info[payload.target.value].interval

            this.setState({
                chord_type: new_chord_type,
                prev_chord_loop_play_time_ms: new Date().getTime()
            }, this.playQuestionMidi)
            this.updateURL({chord_type: new_chord_type})

        }
    }

    onRomanNumeralDropDownChange(payload, chord_loop_index) {

        console.log('onRomanNumeralDropDownChange payload.target.value: ' + payload.target.value)

        var new_chord_loop = [...this.state.chord_loop]

        if (Object.keys(map_roman_numeral_to_info).includes(payload.target.value)) {
            var chord_type = map_roman_numeral_to_info[payload.target.value].chord_type
            var chord_scale_interval = map_roman_numeral_to_info[payload.target.value].interval
            new_chord_loop[chord_loop_index] = {
                chord_type: chord_type,
                interval: chord_scale_interval,
                name: payload.target.value
            }
            if ('scale_name' in map_roman_numeral_to_info[payload.target.value]) {
                new_chord_loop[chord_loop_index].scale_name = map_roman_numeral_to_info[payload.target.value].scale_name
            }
        } else {
            new_chord_loop[chord_loop_index] = null
        }
        console.log('onRomanNumeralDropDownChange new_chord_loop: ' + new_chord_loop)
        console.log('onRomanNumeralDropDownChange chord_loop_index: ' + chord_loop_index)


        this.setState({chord_loop: new_chord_loop, roman_numeral_pre_loaded_sequence: null})
        this.updateURL({chord_loop: new_chord_loop, roman_numeral_pre_loaded_sequence: null})

    }

    onRootChange(payload) {

        this.setState({prev_note: payload.target.value})
        this.updateURL({prev_note: payload.target.value})

    }

    onChordLoopTempoChange(payload) {


        if (this.state.chord_loop_activated) {

            this.setState(
                {
                    chord_loop_tempo: payload.target.value,
                }
            )


        }
        this.setState({chord_loop_tempo: payload.target.value})
        this.updateURL({chord_loop_tempo: payload.target.value})

    }

    onRomanNumeralPreLoadedChange(payload) {

        console.log('onRomanNumeralPreLoadedChange payload.target.value: ' + payload.target.value)
        var chord_loop = [null, null, null, null, null, null]
        var return_roman_numeral_pre_loaded_sequence = null

        if (roman_numeral_sequences_to_choose_from.includes(payload.target.value)) {
            var chord_loop_as_strings = payload.target.value.split('-')
            return_roman_numeral_pre_loaded_sequence = payload.target.value
            console.log('onRomanNumeralPreLoadedChange chord_loop_as_strings: ' + chord_loop_as_strings)
            for (var i = 0; i < 6; i++) {
                if (i < chord_loop_as_strings.length) {
                    chord_loop[i] = map_roman_numeral_to_info[chord_loop_as_strings[i]]
                } else {
                    chord_loop[i] = null
                }
            }

        }

        var new_chord_type = this.state.chord_type
        var original_scale_type = this.state.original_scale_type
        var possible_array_of_info_from_state_chord_type = this.state.chord_type.split('|')
        if (possible_array_of_info_from_state_chord_type.length == 3) {
            var chord_type = possible_array_of_info_from_state_chord_type[0]
            var scale_type = possible_array_of_info_from_state_chord_type[1]
            var chord_scale_interval = parseInt(possible_array_of_info_from_state_chord_type[2])
            if (Object.keys(map_roman_numeral_sequence_to_scale_type).includes(payload.target.value)) {
                var scale_type_from_mapping = map_roman_numeral_sequence_to_scale_type[payload.target.value]
            } else {
                var scale_type_from_mapping = 'Major Scale'
            }
            new_chord_type = chord_type + '|' + scale_type_from_mapping + '|' + chord_scale_interval
            original_scale_type = scale_type_from_mapping

        }

        console.log('onRomanNumeralPreLoadedChange chord_loop: ' + chord_loop)
        this.setState(
            {
                chord_type: new_chord_type,
                chord_loop: chord_loop,
                roman_numeral_pre_loaded_sequence: return_roman_numeral_pre_loaded_sequence,
                original_scale_type: original_scale_type
            },
            this.playChordLoop
        )

        this.updateURL({
            chord_type: new_chord_type,
            chord_loop: chord_loop,
            roman_numeral_pre_loaded_sequence: return_roman_numeral_pre_loaded_sequence
        })

    }

    onChordLoopActivatedChange(payload) {

        var new_val = true
        if (this.state.chord_loop_activated) {
            new_val = false
        }

        this.updateURL({chord_loop_activated: new_val})

        if (new_val) {

            var valid_chord_loop = []
            for (var i = 0; i < this.state.chord_loop.length; i++) {
                if (this.state.chord_loop[i] != null) {
                    valid_chord_loop.push(this.state.chord_loop[i])
                }
            }

            var list_of_chord_loop_interval_indices = []
            this.setState({
                chord_loop_activated: new_val,
                freeze: true,
                reference_style_strum: false,
                cut_audio: false,
            }, this.playChordLoop)

        } else {
            this.setState({chord_loop_activated: new_val}, this.endChordLoop)


        }

    }

    playNextChordInLoop(prev_timestamp = 0, trailing_array_of_chord_time_offsets = []) {

        var valid_chord_loop_length = 0
        for (j = 0; j < this.state.chord_loop.length; j++) {
            if (this.state.chord_loop[j] != null) {
                valid_chord_loop_length += 1
            }
        }
        if (valid_chord_loop_length == 0) {
            return
        }


        var current_timestamp = new Date().getTime()

        var start_timestamp = new Date().getTime()

        if (!this.state.chord_loop_activated) {
            this.endChordLoop()
            return
        }

        console.log('playNextChordInLoop')
        if (typeof (this.state.next_chord_in_chord_loop_timeout_handle) != 'undefined') {
            // clearTimeout(this.state.next_chord_in_chord_loop_timeout_handle)
            clearExactTimeout(this.state.next_chord_in_chord_loop_timeout_handle)
        }


        var valid_chord_loop = []
        console.log('playNextChordInLoop this.state.chord_loop: ' + this.state.chord_loop)
        for (var i = 0; i < this.state.chord_loop.length; i++) {
            if (this.state.chord_loop[i] != null) {
                for (var j = 0; j < this.state.beats_per_chord; j++) {
                    valid_chord_loop.push(this.state.chord_loop[i])
                }
            }
        }

        var next_valid_chord_loop_index = 0
        if (typeof (this.state.valid_chord_loop_index) != 'undefined') {
            next_valid_chord_loop_index = mod(this.state.valid_chord_loop_index + 1, valid_chord_loop.length)

        }

        this.setState({
            valid_chord_loop_index: next_valid_chord_loop_index,
            prev_chord_loop_play_time_ms: new Date().getTime()
        })

        var expected_delay_ms = 60000.0 / (this.state.chord_loop_tempo * 1.0)

        if (prev_timestamp == 0) {
            prev_timestamp = current_timestamp - expected_delay_ms
        }

        var delay_to_next_chord = expected_delay_ms - trailing_average_of_chord_time_offsets


        // debugger
        var drums = []

        if ([1, 2, 4, 8].includes(this.state.beats_per_chord)) {
            if (next_valid_chord_loop_index % 2 == 0) {
                drums = [this.state.drumBass]
            } else if (next_valid_chord_loop_index % 2 == 1) {
                drums = [this.state.drumSnare, this.state.drumHiHat]
            }
            if ([4, 8].includes(this.state.beats_per_chord) && next_valid_chord_loop_index % 4 == 0) {
                drums.push(this.state.drumClap)
            }
        } else if ([3, 6].includes(this.state.beats_per_chord)) {
            if (next_valid_chord_loop_index % 3 == 0) {
                drums = [this.state.drumBass]
            } else if (next_valid_chord_loop_index % 3 != 0) {
                drums = [this.state.drumHiHat]
            }
        }

        // var when = this.midiSounds.contextTime();
        // this.midiSounds2.playDrumsAt(when + 0.025, drums);
        this.setState({drums_to_play: drums}, () =>
            this.onRomanNumeralButton({target: {value: valid_chord_loop[next_valid_chord_loop_index].name}})
        )

        // debugger


        // var next_chord_in_chord_loop_timeout_handle = setTimeout(
        //     () => {this.playNextChordInLoop(current_timestamp, [...trailing_array_of_chord_time_offsets])},
        //     delay_to_next_chord
        // )

        var end_timestamp = new Date().getTime()


        var next_chord_in_chord_loop_timeout_handle = setExactTimeout(
            () => {
                this.playNextChordInLoop(end_timestamp, [...trailing_array_of_chord_time_offsets])
            },
            expected_delay_ms - (end_timestamp - start_timestamp),
            10
        )

        var current_offset = end_timestamp - (prev_timestamp + expected_delay_ms)

        if (typeof (trailing_array_of_chord_time_offsets) != 'undefined') {
            if (trailing_array_of_chord_time_offsets > 4) {
                trailing_array_of_chord_time_offsets.shift()
            }
            trailing_array_of_chord_time_offsets.push(current_offset)
        } else {
            trailing_array_of_chord_time_offsets = [current_offset]
        }
        var trailing_average_of_chord_time_offsets = trailing_array_of_chord_time_offsets.reduce((a, b) => a + b, 0) / (1.0 * trailing_array_of_chord_time_offsets.length)

        console.log('playNextChordInLoop next_valid_chord_loop_index: ' + next_valid_chord_loop_index)
        console.log('playNextChordInLoop trailing_average_of_chord_time_offsets: ' + trailing_average_of_chord_time_offsets)
        console.log('playNextChordInLoop end_timestamp - start_timestamp: ' + end_timestamp - start_timestamp)
        console.log('playNextChordInLoop current_offset: ' + current_offset)


        this.setState({
            trailing_array_of_chord_time_offsets: trailing_array_of_chord_time_offsets,
            next_chord_in_chord_loop_timeout_handle: next_chord_in_chord_loop_timeout_handle
        })
    }


    onBanditToggleChange() {

        var new_bandit_activated = true

        if (this.state.bandit_activated) {
            new_bandit_activated = false // turning off bandit
            note_detection_interval = short_note_detection_interval
        } else {
            note_detection_interval = long_note_detection_interval
        }

        if (microphone_pitch_detection_interval_handle !== null) {
            clearInterval(microphone_pitch_detection_interval_handle)
            initializeMicrophone()
        }

        this.setState({
            bandit_activated: new_bandit_activated
        }, () => this.onSkipButton())
        this.updateURL({bandit_activated: new_bandit_activated})
    }


    onBanditResultsToggle() {

        this.updateURL({bandit_results_exists: !this.state.bandit_results_exists})
        this.setState({bandit_results_exists: !this.state.bandit_results_exists})
    }


    onUserIdUrlChange() {

        this.updateURL({add_user_id_to_url: !this.state.add_user_id_to_url})
        this.setState({add_user_id_to_url: !this.state.add_user_id_to_url})
    }


    async onBanditRestartButton(explicit = false) {
        var confirm_val = true
        if (explicit) {
            confirm_val = window.confirm("Are you sure you want to clear and restart the bandit? All previous model information will be lost.")
        }
        if (confirm_val) {
            await banditRestart({...this.state})
            this.onSkipButton()
        }

    }


    async onChordLoopShift(payload) {

        var old_chord_loop = [...this.state.chord_loop]
        // var truncated_chord_loop = []
        // for (var i=0; i<old_chord_loop.length; i++) {
        //     if (old_chord_loop[i] != null) {
        //         truncated_chord_loop.push(old_chord_loop[i])
        //     }
        // }

        var truncated_chord_loop = old_chord_loop

        var new_chord_loop = []
        if (payload.target.value == 'left') {
            for (var i = 0; i < truncated_chord_loop.length; i++) {
                new_chord_loop.push(truncated_chord_loop[mod(i + 1, truncated_chord_loop.length)])
            }
        } else if (payload.target.value == 'right') {
            for (var i = 0; i < truncated_chord_loop.length; i++) {
                new_chord_loop.push(truncated_chord_loop[mod(i - 1, truncated_chord_loop.length)])
            }
        }

        this.setState({chord_loop: new_chord_loop, roman_numeral_pre_loaded_sequence: null},
            this.playChordLoop)
        this.updateURL({chord_loop: new_chord_loop, roman_numeral_pre_loaded_sequence: null})

    }

    playChordLoop() {

        this.endChordLoop()
        this.setState(
            {
                valid_chord_loop_index: -1,
                prev_chord_loop_play_time_ms: null
            },
            this.playNextChordInLoop
        )
    }

    endChordLoop() {

        clearInterval(this.state.next_chord_in_chord_loop_timeout_handle)

        this.setState(
            {next_chord_in_chord_loop_timeout_handle: null}
        )
    }


    onTuningChange(payload) {

        var new_tuning = tunings_to_choose_from[payload.target.value]

        this.handleClick(default_handleClick_payload, {
            tuning: new_tuning,
            tuning_name: payload.target.value
        })

    }

    onFreezeToggle(payload) {
        var new_freeze = true
        if (this.state.freeze) {
            new_freeze = false
        }
        this.setState({freeze: new_freeze})
        this.updateURL({freeze: new_freeze})

    }

    onShowBanditResultsChange(payload) {
        var new_val = true
        if (this.state.show_bandit_results) {
            new_val = false
        }
        this.setState({show_bandit_results: new_val})
        this.updateURL({show_bandit_results: new_val})

    }

    onShowRomanNumeralButtonsChange(payload) {
        var new_show_roman_numeral_buttons = true
        if (this.state.show_roman_numeral_buttons) {
            new_show_roman_numeral_buttons = false
        }
        this.setState({show_roman_numeral_buttons: new_show_roman_numeral_buttons})
        this.updateURL({show_roman_numeral_buttons: new_show_roman_numeral_buttons})

    }

    onBassOptionsChange(payload) {
        var new_state = {}

        var two_options = [
            'bass_options_drone',
            'bass_options_chord_roots'
        ]

        if (payload.target.value == 'bass_options_drone') {
            var other_option = 'bass_options_chord_roots'
        } else {
            var other_option = 'bass_options_drone'
        }

        if (this.state[payload.target.value] == true) {
            new_state['bass_options_drone'] = false
            new_state['bass_options_chord_roots'] = false
        } else {
            new_state[payload.target.value] = true
            new_state[other_option] = false
        }

        this.setState(new_state)
        this.updateURL(new_state)
    }

    onIntervalsRelativeChange(payload) {
        var new_intervals_relative_to_scale_root = true
        if (this.state.intervals_relative_to_scale_root) {
            new_intervals_relative_to_scale_root = false
        }
        this.setState({intervals_relative_to_scale_root: new_intervals_relative_to_scale_root})
        this.updateURL({intervals_relative_to_scale_root: new_intervals_relative_to_scale_root})

    }

    onShowRandomTopNoteOptionsChange(payload) {
        var new_show_random_top_note_options = true
        if (this.state.show_random_top_note_options) {
            new_show_random_top_note_options = false
        }
        this.setState({show_random_top_note_options: new_show_random_top_note_options})
        this.updateURL({show_random_top_note_options: new_show_random_top_note_options})

    }

    onBeatsPerChordChange(payload) {
        this.setState({beats_per_chord: parseInt(payload.target.value)})
    }

    onShowChordLoopChange(payload) {
        var new_show_chord_loop = true
        if (this.state.show_chord_loop) {
            new_show_chord_loop = false
        }
        this.setState({show_chord_loop: new_show_chord_loop})
        this.updateURL({show_chord_loop: new_show_chord_loop})

    }

    onMetaModeToggle(payload) {
        var new_meta_mode = true
        if (this.state.meta_mode) {
            new_meta_mode = false
        }
        this.setState({meta_mode: new_meta_mode})
        this.updateURL({meta_mode: new_meta_mode})

    }


    onMainSettingsToggle(payload) {
        if (this.state.show_main_settings) {
            this.setState({show_main_settings: false}, this.updateURL)
        } else {
            this.setState({show_main_settings: true}, this.updateURL)
        }
    }

    onRandomizerSettingsToggle(payload) {
        if (this.state.show_randomizer_settings) {
            this.setState({show_randomizer_settings: false}, this.updateURL)
        } else {
            this.setState({show_randomizer_settings: true}, this.updateURL)
        }
    }

    onBanditSettingsToggle(payload) {
        if (this.state.show_bandit_settings) {
            this.setState({show_bandit_settings: false}, this.updateURL)
        } else {
            this.setState({show_bandit_settings: true}, () => {
                try {
                    document.getElementById("userIdInput").value = this.state.user_id;
                } catch (err) {

                }
                this.updateURL()
            })
        }
    }

    onMainSettingsObliterate(payload) {
        this.setState({main_settings_exists: false}, this.updateURL)
    }

    onRandomizerSettingsObliterate(payload) {
        this.setState({randomizer_settings_exists: false}, this.updateURL)
    }

    onBanditSettingsObliterate(payload) {
        this.setState({bandit_settings_exists: false}, this.updateURL)
    }

    onAvailableStringsChange(payload) {

        var string_to_change = parseInt(payload.target.value)
        var turning_off = !payload.target.checked
        var new_available_strings = []
        if (turning_off) {
            new_available_strings = this.state.available_answer_note_strings.filter(val => val !== string_to_change);
        } else {
            new_available_strings = this.state.available_answer_note_strings
            new_available_strings.push(string_to_change)
        }

        if (new_available_strings.length == 0) {
            new_available_strings = [0]
        }
        if (payload.target.value == 'All') {
            new_available_strings = default_available_answer_note_strings
        }
        if (payload.target.value == 'None') {
            new_available_strings = [0]
        }


        this.handleClick(default_handleClick_payload, {available_answer_note_strings: new_available_strings})
    }


    onAvailableGameTypesChange(payload) {

        var game_type_to_change = payload.target.value
        var turning_off = !payload.target.checked
        var new_available_game_types = []
        if (turning_off) {
            new_available_game_types = this.state.available_game_types.filter(val => val !== game_type_to_change);
        } else {
            new_available_game_types = this.state.available_game_types
            new_available_game_types.push(game_type_to_change)
        }

        if (new_available_game_types.length == 0) {
            new_available_game_types = ['Note Find']
        }
        if (payload.target.value == 'All') {
            new_available_game_types = default_available_game_types
        }
        if (payload.target.value == 'None') {
            new_available_game_types = ['Note Find']
        }


        this.handleClick(default_handleClick_payload, {available_game_types: new_available_game_types})
    }


    onAvailableOctaveJumpsChange(payload) {

        var octave_jump_to_change = parseInt(payload.target.value)
        var turning_off = !payload.target.checked
        var new_available_octave_jumps = []
        if (turning_off) {
            new_available_octave_jumps = this.state.available_octave_jumps.filter(val => val !== octave_jump_to_change);
        } else {
            new_available_octave_jumps = this.state.available_octave_jumps
            new_available_octave_jumps.push(octave_jump_to_change)
        }

        if (new_available_octave_jumps.length == 0) {
            new_available_octave_jumps = [0]
        }
        if (payload.target.value == 'All') {
            new_available_octave_jumps = all_available_octave_jumps
        }
        if (payload.target.value == 'None') {
            new_available_octave_jumps = [0]
        }

        this.handleClick(default_handleClick_payload, {available_octave_jumps: new_available_octave_jumps})
    }

    onAvailableAnswerZonesChange(payload) {

        var zone_to_change = parseInt(payload.target.value)
        var turning_off = !payload.target.checked
        var new_available_zones = []
        if (turning_off) {
            new_available_zones = this.state.available_answer_note_zones.filter(val => val !== zone_to_change);
        } else {
            new_available_zones = this.state.available_answer_note_zones
            if (default_available_zones.includes(zone_to_change)) {
                new_available_zones.push(zone_to_change)
            }
        }
        if (new_available_zones.length == 0) {
            new_available_zones = [1]
        }
        if (payload.target.value == 'All') {
            new_available_zones = [...default_available_zones]
        }
        if (payload.target.value == 'None') {
            new_available_zones = [1]
        }

        this.handleClick(default_handleClick_payload, {available_answer_note_zones: new_available_zones})
    }


    onAvailableZoneSizesChange(payload) {

        var zone_size_to_change = parseInt(payload.target.value)
        var turning_off = !payload.target.checked
        var new_available_zone_sizes = []
        if (turning_off) {
            new_available_zone_sizes = this.state.available_zone_sizes.filter(val => val !== zone_size_to_change);
        } else {
            new_available_zone_sizes = this.state.available_zone_sizes
            if (all_available_zone_sizes.includes(zone_size_to_change)) {
                new_available_zone_sizes.push(zone_size_to_change)
            }
        }
        if (new_available_zone_sizes.length == 0) {
            new_available_zone_sizes = [3]
        }

        this.handleClick(default_handleClick_payload, {available_zone_sizes: new_available_zone_sizes})
    }

    onAvailableNoteReferenceNoteDistancesChange(payload) {


        var list_of_note_reference_note_distance_to_change = [];
        var new_available_note_reference_note_distances = this.state.available_note_reference_note_distances
        var note_reference_note_distance_to_change = []
        var turning_off = !payload.target.checked

        if (payload.target.value == '7 thru end') {
            for (var i = 7; i < 12; i++) {
                list_of_note_reference_note_distance_to_change.push(i)
            }
        } else if (note_reference_note_distance_to_change == 'end thru -7') {
            for (var i = -11; i < -6; i++) {
                list_of_note_reference_note_distance_to_change.push(i)
            }
        } else {
            list_of_note_reference_note_distance_to_change = [parseInt(payload.target.value)]
        }

        for (var i = 0; i < list_of_note_reference_note_distance_to_change.length; i++) {
            note_reference_note_distance_to_change = list_of_note_reference_note_distance_to_change[i]
            if (turning_off) {
                new_available_note_reference_note_distances = new_available_note_reference_note_distances.filter(val => val !== note_reference_note_distance_to_change);
            } else {
                new_available_note_reference_note_distances.push(note_reference_note_distance_to_change)
            }
        }

        if (new_available_note_reference_note_distances.length == 0) {
            new_available_note_reference_note_distances = [0]
        }
        if (payload.target.value == 'All') {
            new_available_note_reference_note_distances = [...all_available_note_reference_note_distances]
        }
        if (payload.target.value == 'In The Zone') {
            new_available_note_reference_note_distances = [-1, 0, 1]
        }
        if (payload.target.value == 'Default') {
            new_available_note_reference_note_distances = [...default_available_note_reference_note_distances]
        }
        if (payload.target.value == 'Extremes') {
            new_available_note_reference_note_distances = [...extremes_available_note_reference_note_distances]
        }
        if (payload.target.value == 'None') {
            new_available_note_reference_note_distances = [0]
        }

        new_available_note_reference_note_distances.sort()

        this.handleClick(default_handleClick_payload, {available_note_reference_note_distances: new_available_note_reference_note_distances})

    }

    onAvailableNoteReferenceNoteStringDistancesChange(payload) {


        var list_of_note_reference_note_string_distance_to_change = [parseInt(payload.target.value)]
        var new_available_note_reference_note_string_distances = this.state.available_note_reference_note_string_distances
        var note_reference_note_string_distance_to_change = []
        var turning_off = !payload.target.checked

        for (var i = 0; i < list_of_note_reference_note_string_distance_to_change.length; i++) {
            note_reference_note_string_distance_to_change = list_of_note_reference_note_string_distance_to_change[i]
            if (turning_off) {
                new_available_note_reference_note_string_distances = new_available_note_reference_note_string_distances.filter(val => val !== note_reference_note_string_distance_to_change);
            } else {
                new_available_note_reference_note_string_distances.push(note_reference_note_string_distance_to_change)
            }
        }

        if (new_available_note_reference_note_string_distances.length == 0) {
            new_available_note_reference_note_string_distances = [0]
        }
        if (payload.target.value == 'All') {
            new_available_note_reference_note_string_distances = [...all_available_note_reference_note_string_distances]
        }
        if (payload.target.value == 'Default') {
            new_available_note_reference_note_string_distances = [...default_available_note_reference_note_string_distances]
        }
        if (payload.target.value == 'Extremes') {
            new_available_note_reference_note_string_distances = [...extremes_available_note_reference_note_string_distances]
        }
        if (payload.target.value == 'None') {
            new_available_note_reference_note_string_distances = [0]
        }

        new_available_note_reference_note_string_distances.sort()

        this.handleClick(default_handleClick_payload, {available_note_reference_note_string_distances: new_available_note_reference_note_string_distances})
    }


    onAvailableAnswerNoteFretsChange(payload) {

        if (payload.target.value == 'Middle 6') {
            var answer_note_frets_to_change = [4, 5, 6, 7, 8, 9]
        } else if (payload.target.value == 'Without Open') {
            var answer_note_frets_to_change = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        } else {
            var answer_note_frets_to_change = [parseInt(payload.target.value)]
        }
        var turning_off = !payload.target.checked
        var new_available_answer_note_frets = [...this.state.available_answer_note_frets]
        for (var i = 0; i < answer_note_frets_to_change.length; i++) {
            var answer_note_fret_to_change = answer_note_frets_to_change[i]
            if (turning_off) {
                new_available_answer_note_frets = new_available_answer_note_frets.filter(val => parseInt(val) !== parseInt(answer_note_fret_to_change));
            } else {
                if (all_available_answer_note_frets.includes(answer_note_fret_to_change)) {
                    new_available_answer_note_frets.push(parseInt(answer_note_fret_to_change))
                }
            }
        }

        if (new_available_answer_note_frets.length == 0) {
            new_available_answer_note_frets = [1]
        }
        if (payload.target.value == 'All') {
            new_available_answer_note_frets = default_available_answer_note_frets
        }
        if (payload.target.value == 'None') {
            new_available_answer_note_frets = [1]
        }

        this.handleClick(default_handleClick_payload, {available_answer_note_frets: new_available_answer_note_frets})
    }

    onAvailableReferenceNoteFretsChange(payload) {


        if (payload.target.value == 'Middle 6') {
            var reference_note_frets_to_change = [4, 5, 6, 7, 8, 9]
        } else if (payload.target.value == 'Without Open') {
            var reference_note_frets_to_change = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        } else {
            var reference_note_frets_to_change = [parseInt(payload.target.value)]
        }
        var turning_off = !payload.target.checked
        var new_available_reference_note_frets = [...this.state.available_reference_note_frets]
        for (var i = 0; i < reference_note_frets_to_change.length; i++) {
            var reference_note_fret_to_change = reference_note_frets_to_change[i]
            if (turning_off) {
                new_available_reference_note_frets = new_available_reference_note_frets.filter(val => parseInt(val) !== parseInt(reference_note_fret_to_change));
            } else {
                if (all_available_reference_note_frets.includes(reference_note_fret_to_change)) {
                    new_available_reference_note_frets.push(parseInt(reference_note_fret_to_change))
                }
            }
        }

        if (new_available_reference_note_frets.length == 0) {
            new_available_reference_note_frets = [1]
        }
        if (payload.target.value == 'All') {
            new_available_reference_note_frets = default_available_reference_note_frets
        }
        if (payload.target.value == 'None') {
            new_available_reference_note_frets = [1]
        }

        this.handleClick(default_handleClick_payload, {available_reference_note_frets: new_available_reference_note_frets})
    }

    onAvailableReferenceNoteStringsChange(payload) {


        var reference_note_string_to_change = parseInt(payload.target.value)
        var turning_off = !payload.target.checked
        var new_available_reference_note_strings = []
        if (turning_off) {
            new_available_reference_note_strings = this.state.available_reference_note_strings.filter(val => val !== reference_note_string_to_change);
        } else {
            new_available_reference_note_strings = this.state.available_reference_note_strings
            if (default_available_reference_note_strings.includes(reference_note_string_to_change)) {
                new_available_reference_note_strings.push(reference_note_string_to_change)
            }
        }

        if (new_available_reference_note_strings.length == 0) {
            new_available_reference_note_strings = [0]
        }
        if (payload.target.value == 'All') {
            new_available_reference_note_strings = default_available_reference_note_strings
        }
        if (payload.target.value == 'None') {
            new_available_reference_note_strings = [0]
        }


        this.handleClick(default_handleClick_payload, {available_reference_note_strings: new_available_reference_note_strings})

    }


    onAvailableAnswerNotesChange(payload) {

        var note_to_change = payload.target.value
        var turning_off = !payload.target.checked
        var new_available_notes = this.state.available_answer_notes

        var notes_to_change = [payload.target.value]
        if (payload.target.value == 'Accidentals') {
            notes_to_change = sharps_to_choose_from
        } else if (payload.target.value == 'Flats') {
            notes_to_change = flats_to_choose_from
        } else if (payload.target.value == 'Naturals') {
            notes_to_change = naturals_to_choose_from
        }

        for (var i = 0; i < notes_to_change.length; i++) {
            var note_to_change = notes_to_change[i]
            if (turning_off) {
                new_available_notes = new_available_notes.filter(val => val !== note_to_change);
            } else {
                if (!new_available_notes.includes(note_to_change) && notes_to_choose_from.includes(note_to_change)) {
                    new_available_notes.push(note_to_change)
                }
            }
        }
        if (new_available_notes.length == 0) {
            new_available_notes = ['C']
        }
        if (payload.target.value == 'All') {
            new_available_notes = [...notes_to_choose_from]
        }
        if (payload.target.value == 'None') {
            new_available_notes = ['C']
        }

        this.setState({
            tonic_input_answer_note: 'Unknown',
            tonic_input_reference_note: this.state.tonic_input_reference_note,
            scale_type_input_answer_note: 'Unknown',
            scale_type_input_reference_note: this.state.scale_type_input_reference_note,
        })
        this.handleClick(default_handleClick_payload, {
            available_answer_notes: new_available_notes
        })
    }

    onAvailableIntervalTypesChange(payload) {

        var interval_type_to_change;
        var turning_off = !payload.target.checked
        var new_available_interval_types = [...this.state.available_interval_types]
        var interval_types_to_change;
        if (payload.target.value == '3rds & 6ths') {
            interval_types_to_change = []
        } else if (payload.target.value == 'Major') {
            interval_types_to_change = []
        } else if (payload.target.value == 'Minor') {
            interval_types_to_change = []
        } else {
            interval_types_to_change = [parseInt(payload.target.value)]
        }

        for (var i = 0; i < interval_types_to_change.length; i++) {
            var interval_type_to_change = parseInt(interval_types_to_change[i])
            if (turning_off) {
                new_available_interval_types = new_available_interval_types.filter(val => parseInt(val) !== parseInt(interval_type_to_change));
            } else {
                if (!new_available_interval_types.includes(interval_type_to_change) && default_available_interval_types.includes(interval_type_to_change)) {
                    new_available_interval_types.push(interval_type_to_change)
                }
            }
        }
        if (new_available_interval_types.length == 0) {
            new_available_interval_types = [0]
        }
        if (payload.target.value == 'All') {
            new_available_interval_types = [...default_available_interval_types]
        }
        if (payload.target.value == 'None') {
            new_available_interval_types = [0]
        }
        if (payload.target.value == '3rds & 6ths') {
            new_available_interval_types = [3, 4, 8, 9]
        }
        if (payload.target.value == 'Major') {
            new_available_interval_types = [0, 2, 4, 5, 7, 9, 11]
        }
        if (payload.target.value == 'Minor') {
            new_available_interval_types = [0, 2, 3, 5, 7, 8, 10]
        }

        this.handleClick(default_handleClick_payload, {available_interval_types: new_available_interval_types})
    }


    onPlaySoundsChange(payload) {

        var turning_off = !payload.target.checked
        var new_play_sounds = true
        if (turning_off) {
            new_play_sounds = false
        }

        this.handleClick(default_handleClick_payload, {play_sounds: new_play_sounds})
    }

    onShowIntervalsChange(payload) {

        var turning_off = !payload.target.checked
        var new_show_intervals = true
        if (turning_off) {
            new_show_intervals = false
        }
        this.setState({show_intervals: new_show_intervals})
        this.updateURL({show_intervals: new_show_intervals})
    }

    onShowNoteNamesChange(payload) {

        var turning_off = !payload.target.checked
        var new_show_note_names = true
        if (turning_off) {
            new_show_note_names = false
        }
        this.setState({show_note_names: new_show_note_names})
        this.updateURL({show_note_names: new_show_note_names})
    }

    onShowHintsChange(payload) {

        var turning_off = !payload.target.checked
        var new_show_hints = true
        if (turning_off) {
            new_show_hints = false
        }
        this.setState({show_hints: new_show_hints})
        this.updateURL({show_hints: new_show_hints})
    }

    onCutAudioChange(payload) {

        //logic is reversed from onShowHintsChange bc legato is opposite of cut_audio
        var turning_off = payload.target.checked
        var new_cut_audio = true
        if (turning_off) {
            new_cut_audio = false
        }
        this.setState({cut_audio: new_cut_audio})
        this.updateURL({cut_audio: new_cut_audio})
    }

    onReferenceStyleStrumChange(payload) {

        var turning_off = !payload.target.checked
        var new_val = true
        if (turning_off) {
            new_val = false
        }
        this.updateURL({reference_style_strum: new_val})
        this.setState({reference_style_strum: new_val}, () => this.playQuestionMidi(false))

    }

    onShowNextChordChange(payload) {

        var turning_off = !payload.target.checked
        var new_val = true
        if (turning_off) {
            new_val = false
        }
        this.updateURL({show_next_chord: new_val})
        this.setState({show_next_chord: new_val})

    }

    onRandomTopNoteChange(payload) {

        var turning_off = !payload.target.checked
        var new_val = true
        if (turning_off) {
            new_val = false
        }
        this.updateURL({random_top_note: new_val})
        if (this.state.chord_loop_activated) {
            this.setState({random_top_note: new_val})
        } else {
            this.setState({random_top_note: new_val}, () => this.playQuestionMidi(false))
        }

    }


    onRandomTopTensionChange(payload) {

        var turning_off = !payload.target.checked
        var new_val = true
        if (turning_off) {
            new_val = false
        }
        this.updateURL({random_top_tension: new_val})
        this.setState({random_top_tension: new_val}, () => this.playQuestionMidi(false))

    }

    onRandomTopBothChange(payload) {

        var turning_off = !payload.target.checked
        var new_val = true
        if (turning_off) {
            new_val = false
        }
        this.updateURL({random_top_note: new_val, random_top_tension: new_val})
        this.setState(
            {random_top_note: new_val, random_top_tension: new_val},
            () => this.playQuestionMidi(false)
        )

    }

    onShowMicrophoneInputChange(payload) {

        var new_val = true
        if (this.state.show_microphone_input) {
            new_val = false
        }
        if (new_val) {
            initializeMicrophone()
        } else {
            if (microphone_pitch_detection_interval_handle !== null) {
                clearInterval(microphone_pitch_detection_interval_handle)
            }
        }
        this.updateURL({show_microphone_input: new_val})
        this.setState({show_microphone_input: new_val})

    }

    onMuteChange(payload) {


        var turning_off = !payload.target.checked
        var new_val = true
        if (turning_off) {
            new_val = false
        }

        if (new_val) {
            this.midiSounds.setMasterVolume(0);
            this.setState({volume: 0, volume_before_muting: this.state.volume})
        } else {
            this.midiSounds.setMasterVolume(this.state.volume_before_muting * 1.0 / 100.0);
            this.updateURL({volume: this.state.volume_before_muting})
            this.setState({volume: this.state.volume_before_muting})
        }
    }


    onMicrophoneHoldChange(payload) {

        var turning_off = !payload.target.checked
        var new_val = true
        if (turning_off) {
            new_val = false
        }
        this.updateURL({microphone_hold: new_val})
        this.setState({microphone_hold: new_val})

    }

    onBanditDifficultyLevelChange(payload, run_skip_button = false) {


        if (run_skip_button) {
            this.setState({bandit_difficulty_level: parseInt(payload.target.value)}, this.onSkipButton)
        } else {
            this.setState({bandit_difficulty_level: parseInt(payload.target.value)})
        }
        this.updateURL({bandit_difficulty_level: parseInt(payload.target.value)})
    }

    onVolumeChange(payload) {

        this.midiSounds.setMasterVolume(payload.target.value * 1.0 / 100.0);
        var new_state = {volume: payload.target.value}
        if (payload.target.value < 10) {
            new_state.show_intervals = true
            new_state.show_note_names = true
        } else {
            new_state.volume_before_muting = payload.target.value
        }
        this.setState(new_state)
    }

    onDrumVolumeChange(payload) {

        this.midiSounds2.setMasterVolume(payload.target.value * 1.0 / 100.0);
        var new_state = {drum_volume: payload.target.value}
        this.setState(new_state)
    }

    onDroneVolumeChange(payload) {

        this.midiSounds3.setMasterVolume(payload.target.value * 1.0 / 100.0);
        var new_state = {drone_volume: payload.target.value}
        this.setState(new_state)
    }

    onDelayChange(payload) {

        this.setState({delay_between_notes: payload.target.value})
    }

    onEchoChange(payload) {

        this.midiSounds2.setEchoLevel(payload.target.value * 1.0 / 100.0);
        this.midiSounds.setEchoLevel(payload.target.value * 1.0 / 100.0);
        this.setState({echo: payload.target.value})
    }

    onAvailableReferenceNotesChange(payload) {


        var reference_note_to_change = payload.target.value
        var turning_off = !payload.target.checked
        var new_available_reference_notes = this.state.available_reference_notes

        var reference_notes_to_change = [payload.target.value]
        if (payload.target.value == 'Accidentals') {
            reference_notes_to_change = sharps_to_choose_from
        } else if (payload.target.value == 'Naturals') {
            reference_notes_to_change = naturals_to_choose_from
        }

        for (var i = 0; i < reference_notes_to_change.length; i++) {
            var reference_note_to_change = reference_notes_to_change[i]
            if (turning_off) {
                new_available_reference_notes = new_available_reference_notes.filter(val => val !== reference_note_to_change);
            } else {
                if (!new_available_reference_notes.includes(reference_note_to_change) && reference_notes_to_choose_from.includes(reference_note_to_change)) {
                    new_available_reference_notes.push(reference_note_to_change)
                }
            }
        }
        if (new_available_reference_notes.length == 0) {
            new_available_reference_notes = ['C']
        }
        if (payload.target.value == 'All') {
            new_available_reference_notes = [...reference_notes_to_choose_from]
        }
        if (payload.target.value == 'None') {
            new_available_reference_notes = ['C']
        }


        this.setState({
            tonic_input_answer_note: this.state.tonic_input_answer_note,
            tonic_input_reference_note: 'Unknown',
            scale_type_input_answer_note: this.state.scale_type_input_answer_note,
            scale_type_input_reference_note: 'Unknown'
        })
        this.handleClick(default_handleClick_payload, {
            available_reference_notes: new_available_reference_notes
        })
    }


    onAvailableScalesChange(payload) {

        var scale_to_change = payload.target.value
        var turning_off = !payload.target.checked
        var new_available_scales = []
        if (turning_off) {
            new_available_scales = this.state.available_scales.filter(val => val !== scale_to_change);
        } else {
            new_available_scales = this.state.available_scales
            if (scale_types_to_play_to_choose_from.includes(scale_to_change)) {
                new_available_scales.push(scale_to_change)
            }
        }
        if (new_available_scales.length == 0) {
            new_available_scales = [1]
        }
        if (payload.target.value == 'All') {
            new_available_scales = [...scale_types_to_play_to_choose_from]
        }
        if (payload.target.value == 'None') {
            new_available_scales = ['Major Scale']
        }

        this.handleClick(default_handleClick_payload, {available_scales: new_available_scales})
    }


    onAvailableChordTypesChange(payload) {

        var chord_type_to_change = payload.target.value
        var turning_off = !payload.target.checked
        var new_available_chord_types = this.state.available_chord_types
        if (turning_off) {
            new_available_chord_types = new_available_chord_types.filter(val => val !== chord_type_to_change);
        } else {
            if (chord_types_to_choose_from.includes(chord_type_to_change) && !new_available_chord_types.includes(chord_type_to_change)) {
                new_available_chord_types.push(chord_type_to_change)
            }
        }
        if (typeof (new_available_chord_types) == 'undefined' || new_available_chord_types.length == 0) {
            new_available_chord_types = ['Major']
        }
        if (payload.target.value == 'All') {
            new_available_chord_types = [...chord_types_to_choose_from]
        }
        if (payload.target.value == 'None') {
            new_available_chord_types = ['Major']
        }

        var chord_types_to_change = []
        if (Object.keys(map_chord_quality_to_chord_type).includes(payload.target.value)) {
            chord_types_to_change = map_chord_quality_to_chord_type[payload.target.value]
        } else if (Object.keys(map_chord_quality2_to_chord_type).includes(payload.target.value)) {
            chord_types_to_change = map_chord_quality2_to_chord_type[payload.target.value]
        }
        if (turning_off) {
            for (var i = 0; i < chord_types_to_change.length; i++) {
                chord_type_to_change = chord_types_to_change[i]
                new_available_chord_types = new_available_chord_types.filter(val => val !== chord_type_to_change);
            }
        } else {
            for (var i = 0; i < chord_types_to_change.length; i++) {
                chord_type_to_change = chord_types_to_change[i]
                if (chord_types_to_choose_from.includes(chord_type_to_change) && !new_available_chord_types.includes(chord_type_to_change)) {
                    new_available_chord_types.push(chord_type_to_change)
                }
            }
        }

        if (new_available_chord_types.length == 0) {
            new_available_chord_types = ["Major"]
        }

        this.handleClick(default_handleClick_payload, {available_chord_types: new_available_chord_types})
    }


    componentDidMount() {
        console.log('componentDidMount App');
        this.startListening(); // see MIDI stuff at end of file
        document.title = "Fret Ferret by Koyote Science, LLC"
        document.addEventListener("keydown", this.handleKeyDown);
        //document.getElementById("userIdInput").value = this.state.user_id;
        this.onVolumeChange({target: {value: this.state.volume}})
        this.onDrumVolumeChange({target: {value: this.state.drum_volume}})
        this.onDroneVolumeChange({target: {value: this.state.drone_volume}})
        this.midiSounds.cacheInstrument(this.state.selectedInstrument)
        this.midiSounds2.cacheDrum(this.state.drumHiHat)
        this.midiSounds2.cacheDrum(this.state.drumBass)
        this.midiSounds2.cacheDrum(this.state.drumClap)
        this.midiSounds2.cacheDrum(this.state.drumSnare)
        this.midiSounds2.cacheDrum(this.state.extraDrumBass)
        simpleLogAndRetrieve({bandit_results: {bandit_difficulty_level: 'test'}})

    }

    handleKeyDown(payload) {

        var map_key_to_midi = {
            a: 'C3',
            w: 'C#3',
            s: 'D3',
            e: 'D#3',
            d: 'E3',
            f: 'F3',
            t: 'F#3',
            g: 'G3',
            y: 'G#3',
            h: 'A3',
            u: 'A#3',
            j: 'B3',
            k: 'C4',
            o: 'C#4',
            l: 'D4'
        }

        if (Object.keys(map_key_to_midi).includes(payload.key)) {
            var note = map_key_to_midi[payload.key]
        } else {
            return
        }

        // console.dir('keyDown payload:')
        // console.dir(payload)
        // console.log('keyboard press sensed for key "' + payload.key + '" giving  note ' + note)
        // console.log('Note.midi(note): ' + Note.midi(note))

        this.handleClick({
            note: note,
            notes_on_fret: [
                note,
                note,
                note,
                note,
                note,
                note
            ],
            loc: {str: '', pos: ''},
            onRadioChange: false,
            fromMidi: true
        })

        this.handleOffClick(false);

    }

    onSelectInstrument(e) {
        var list = e.target;
        let n = list.options[list.selectedIndex].getAttribute("value");
        this.setState({
            selectedInstrument: n
            , cached: false
        });
        this.midiSounds.cacheInstrument(n);
        var me = this;
        this.midiSounds.player.loader.waitLoad(function () {
            me.setState({
                selectedInstrument: n
                , cached: true
            }, me.playQuestionMidi);
        });
        this.updateURL({selectedInstrument: n})

    }

    onSelectDroneInstrument(e) {
        var list = e.target;
        let n = list.options[list.selectedIndex].getAttribute("value");
        this.setState({
            selectedDroneInstrument: n,
            drone_cached: false
        });
        this.midiSounds3.cacheInstrument(n);
        var me = this;
        this.midiSounds3.player.loader.waitLoad(function () {
            me.setState({
                selectedDroneInstrument: n,
                drone_cached: true
            }, me.playQuestionMidi);
        });
        this.updateURL({selectedDroneInstrument: n})

    }

    createSelectItems() {
        if (this.midiSounds) {
            if (!(this.items)) {
                this.items = [];
                for (let i = 0; i < this.midiSounds.player.loader.instrumentKeys().length; i++) {
                    this.items.push(<option key={i}
                                            value={i}>{'' + (i + 0) + '. ' + this.midiSounds.player.loader.instrumentInfo(i).title}</option>);
                }
            }
            return this.items;
        }
    }

    playTestInstrument() {
        this.midiSounds.playChordNow(this.selectedInstrument, [60], this.state.delay_between_notes * 1.0 / 100.0 * 2);
    }

    get_processed_bandit_results(state_bandit_results) {

        if (state_bandit_results == null) {
            return ''
        }
        
        // from https://stackoverflow.com/questions/7343890/standard-deviation-javascript
        function getStandardDeviation(array) {
            if (!Array.isArray(array)) {
                return 0.0
            } 
            try {
                const n = array.length
                if (n == 1) {
                    return 0.0
                }
                const mean = (array.reduce((a, b) => a + b) * 1.0) / (n * 1.0)
                return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n) 
            } catch {
                return null
            }
        }

        function getAverage(array) {
            if (!Array.isArray(array)) {
                return parseFloat(array)
            } 
            try {
                const total = array.reduce((acc, c) => acc + c, 0);
                return (total * 1.0) / (array.length * 1.0);
            } catch {
                return null
            }
        }

        var map_action_index_to_prediction_summary_statistics = []

        
        
        for (action_index = 0; action_index < state_bandit_results.response.body.prediction_distribution_by_action_index.length; action_index++) {
            var prediction = state_bandit_results.response.body.prediction_distribution_by_action_index[action_index]

            var average_prediction = getAverage(prediction)
            var standard_deviation_prediction = getStandardDeviation(prediction)
            var deterministic_prediction = state_bandit_results.response.body.prediction_for_deterministic_model[action_index]
            map_action_index_to_prediction_summary_statistics.push({
                'average': average_prediction,
                'standard_deviation': standard_deviation_prediction,
                'deterministic': deterministic_prediction,
                'source': prediction
            })
        }


        // get additional information about coefficients
        var model_coefficients = state_bandit_results.response.body.coefficient_distribution
        // debugger
        var map_feature_index_to_coefficient_summary_statistics = []
        for (var i = 0; i < model_coefficients.length; i++) {
            var average_coefficient = getAverage(model_coefficients[i])
            var standard_deviation_coefficient = getStandardDeviation(model_coefficients[i])
            try {
                var deterministic_coefficient = state_bandit_results.response.body.coefficients_for_deterministic_model[i]
            } catch {
                var deterministic_coefficient = null
            }
            map_feature_index_to_coefficient_summary_statistics.push({
                'average': average_coefficient,
                'standard_deviation': standard_deviation_coefficient,
                'deterministic': deterministic_coefficient,
                'source': model_coefficients[i]
            })
        }


        // get additional information about intercepts]
        var intercept_distribution = state_bandit_results.response.body.intercept_distribution
        var average_intercept = getAverage(intercept_distribution)
        var standard_deviation_intercept = getStandardDeviation(intercept_distribution)
        var deterministic_intercept = state_bandit_results.response.body.intercept_for_deterministic_model
        var model_intercept_summary_statistics = {
            'average': average_coefficient,
            'standard_deviation': standard_deviation_coefficient,
            'deterministic': deterministic_intercept,
            'source': intercept_distribution
        }

        // get the fraction of actions with minimum prior counts, to get a sense of their uncertainty
        var prior_counts_by_action_index_by_feature_index = state_bandit_results.response.body.prior_counts_by_action_index_by_feature_index
        var map_min_count_to_fraction_of_input_vectors = {}
        for (var i = 0; i < prior_counts_by_action_index_by_feature_index.length; i++) {
            var prior_count = Math.min(...prior_counts_by_action_index_by_feature_index[i])
            if (!Object.keys(map_min_count_to_fraction_of_input_vectors).includes(prior_count.toString())) {
                map_min_count_to_fraction_of_input_vectors[prior_count.toString()] = 0
            }
            map_min_count_to_fraction_of_input_vectors[prior_count.toString()] += 1.0 / (prior_counts_by_action_index_by_feature_index.length * 1.0)
        }

        var bandit_results = [<br/>, <br/>]
        if (this.state.bandit_activated && state_bandit_results != null && state_bandit_results != undefined && state_bandit_results.success) {
            bandit_results.push(<i>Model ID:</i>)
            bandit_results.push(<br/>)
            bandit_results.push('"' + state_bandit_results.model_id + '"')
            bandit_results.push(<br/>)
            bandit_results.push(<br/>)
            bandit_results.push(<i>High-Level Information:</i>)
            bandit_results.push(<br/>)
            bandit_results.push(<text>Model Type: {state_bandit_results.response.body.model_type_name}</text>)
            bandit_results.push(<br/>)
            // bandit_results.push('Chosen with propensity: ' + (state_bandit_results.propensity * 100.0).toFixed(3) + '%')
            // bandit_results.push(<br/>)
            bandit_results.push('Model Store Size: ' + (state_bandit_results.response.body.model_store_size_in_kb).toFixed(3) + 'kB')
            bandit_results.push(<br/>)
            bandit_results.push('Time to choose in sec: Internal: ' + (state_bandit_results.lambda_time_to_run_in_sec).toFixed(3) + ', Network Relay: ' + (state_bandit_results.await_time_to_run_in_sec).toFixed(3) + ', Method: ' + (state_bandit_results.javascript_method_time_to_run_in_sec).toFixed(3))
            bandit_results.push(<br/>)
            bandit_results.push('Prior updates: ' + (state_bandit_results.response.body.number_of_updates))
            // bandit_results.push('Min. Det. Prior Counts: ' + (Math.min(...state_bandit_results.response.body.prior_counts_by_feature_index)))
            bandit_results.push(<br/>)
            var prediction_value = map_action_index_to_prediction_summary_statistics[state_bandit_results.action_index_with_highest_score].average
            if (prediction_value == null || state_bandit_results.response.body.should_we_assign_unknown_score_by_action_index[state_bandit_results.action_index_with_highest_score]) {
                prediction_value = 'Unknown'
            } else {
                prediction_value = prediction_value.toFixed(4)
            }
            var det_prediction_value = state_bandit_results.response.body.prediction_for_deterministic_model[state_bandit_results.action_index_with_highest_score]
            if (det_prediction_value == null) {
                det_prediction_value = 'Unknown'
            } else {
                try {
                    det_prediction_value = det_prediction_value.toFixed(4)
                } catch {
                    det_prediction_value = null
                }
            }
            bandit_results.push('Prediction: ' + prediction_value + ', Det. Prediction: ' + det_prediction_value)
            bandit_results.push(<br/>)
            // bandit_results.push('Minimum count to skip max score: ' + (state_bandit_results.min_count_to_skip_unknown_score) + ', Min overall prior counts: ' + (state_bandit_results.min_prior_count))
            // bandit_results.push(<br/>)


            // bandit_results.push('Features: ')
            // bandit_results.push(<br/>)
            // // debugger
            //
            // var map_feature_name_index_to_feature_index = {}
            // var map_feature_name_index_to_array_of_feature_indices = {}
            //
            // for (var feature_index = 0; feature_index < state_bandit_results.response.body.list_of_feature_names_detailed.length; feature_index++) {
            //     var orig_index = state_bandit_results.response.body.list_of_feature_names_detailed[feature_index].orig_index
            //     if (!Object.keys(map_feature_name_index_to_array_of_feature_indices).includes(orig_index.toString())) {
            //         map_feature_name_index_to_array_of_feature_indices[orig_index] = []
            //     }
            //     map_feature_name_index_to_array_of_feature_indices[orig_index].push(feature_index)
            //     var possible_value = state_bandit_results.response.body.list_of_feature_names_detailed[feature_index].possible_value
            //     if (possible_value == undefined || state_bandit_results.action_feature_vector_with_highest_score[orig_index] == possible_value) {
            //         map_feature_name_index_to_feature_index[orig_index] = feature_index
            //     }
            // }
            //
            // for (var feature_name_index = 0; feature_name_index < state_bandit_results.response.body.list_of_feature_names.length; feature_name_index++) {
            //     var feature_index = map_feature_name_index_to_feature_index[feature_name_index]
            //     if (state_bandit_results.response.body.model_coefficients == null || state_bandit_results.response.body.model_coefficients[feature_index] == undefined) {
            //         var show_coefficient = 0.0
            //         var coeff_difference_from_average = 0.0
            //     } else {
            //         var show_coefficient = state_bandit_results.response.body.model_coefficients[feature_index]
            //         var array_of_related_feature_indices = map_feature_name_index_to_array_of_feature_indices[feature_name_index]
            //         var array_of_related_coefficients = []
            //         for (var tmp_feature_index of array_of_related_feature_indices) {
            //             array_of_related_coefficients.push(map_feature_index_to_coefficient_summary_statistics[tmp_feature_index].average)
            //         }
            //         // array sum from https://stackoverflow.com/questions/1230233/how-to-find-the-sum-of-an-array-of-numbers
            //         var average_coefficient_for_feature_name_index = array_of_related_coefficients.reduce((a, b) => a + b) * 1.0 / (array_of_related_coefficients.length * 1.0)
            //         var coeff_difference_from_average = show_coefficient - average_coefficient_for_feature_name_index
            //
            //     }
            //     if (state_bandit_results.response.body.coefficients_for_deterministic_model == null || state_bandit_results.response.body.coefficients_for_deterministic_model[feature_index] == undefined) {
            //         var show_det_coefficient = 0.0
            //         var det_coeff_difference_from_average = 0.0
            //     } else {
            //         var show_det_coefficient = state_bandit_results.response.body.coefficients_for_deterministic_model[feature_index]
            //         var array_of_related_feature_indices = map_feature_name_index_to_array_of_feature_indices[feature_name_index]
            //         var array_of_related_det_coefficients = []
            //         for (var tmp_feature_index of array_of_related_feature_indices) {
            //             array_of_related_det_coefficients.push(state_bandit_results.response.body.coefficients_for_deterministic_model[tmp_feature_index])
            //         }
            //         // array sum from https://stackoverflow.com/questions/1230233/how-to-find-the-sum-of-an-array-of-numbers
            //         var average_det_coefficient_for_feature_name_index = array_of_related_det_coefficients.reduce((a, b) => a + b) * 1.0 / (array_of_related_det_coefficients.length * 1.0)
            //         var det_coeff_difference_from_average = show_det_coefficient - average_det_coefficient_for_feature_name_index
            //
            //     }
            //     bandit_results.push(
            //         '\u00a0\u00a0Index: ' + feature_name_index +
            //         '\u00a0\u00a0Name: ' + state_bandit_results.response.body.list_of_feature_names[feature_name_index] +
            //         '\u00a0\u00a0Value: ' + state_bandit_results.response.body.list_of_feature_names_detailed[feature_name_index].possible_value
            //     )
            //     bandit_results.push(<br/>)
            //     bandit_results.push(
            //         '\u00a0\u00a0\u00a0\u00a0Coefficient: ' + show_coefficient.toFixed(4) + ' (' + (coeff_difference_from_average > 0 ? '+' : '') + coeff_difference_from_average.toFixed(4) + ' compared to avg.)'
            //     )
            //     bandit_results.push(<br/>)
            //     bandit_results.push(
            //         '\u00a0\u00a0\u00a0\u00a0Det. Coeff.: ' + show_det_coefficient.toFixed(4) + ' (' + (det_coeff_difference_from_average > 0 ? '+' : '') + det_coeff_difference_from_average.toFixed(4) + ' compared to avg.)'
            //     )
            //     bandit_results.push(<br/>)
            //     if (state_bandit_results.response.body.prior_counts_by_feature_index[feature_index] != null) {
            //         bandit_results.push(
            //             '\u00a0\u00a0\u00a0\u00a0Prior Count: ' + state_bandit_results.response.body.prior_counts_by_feature_index[feature_index]
            //         )
            //         bandit_results.push(<br/>)
            //         // bandit_results.push(
            //         //     '\u00a0\u00a0\u00a0\u00a0Det. Prior Count: ' + state_bandit_results.response.body.prior_counts_by_feature_index[feature_index]
            //         // )
            //         // bandit_results.push(<br/>)
            //     } else {
            //         bandit_results.push(
            //             '\u00a0\u00a0\u00a0\u00a0Prior Count: ' + state_bandit_results.response.body.number_of_updates
            //         )
            //         bandit_results.push(<br/>)
            //         // bandit_results.push(
            //         //     '\u00a0\u00a0\u00a0\u00a0Det. Prior Count: ' + state_bandit_results.response.body.number_of_updates
            //         // )
            //         // bandit_results.push(<br/>)
            //     }
            //     // bandit_results.push(
            //     //     '\u00a0\u00a0\u00a0\u00a0Input Value: ' + state_bandit_results.action_feature_vector_with_highest_score[feature_name_index]
            //     // )
            //     bandit_results.push(<br/>)
            // }

            bandit_results.push(<br/>)
            bandit_results.push(<br/>)
            bandit_results.push(<i>Trailing List of Output Values
                (total: {state_bandit_results.response.body.trailing_list_of_output_values.length}):</i>)
            bandit_results.push(<br/>)
            for (var tmp_i = 0; tmp_i < Math.min(state_bandit_results.response.body.trailing_list_of_output_values.length, 5); tmp_i++) {
                bandit_results.push('\u00a0\u00a0' + state_bandit_results.response.body.trailing_list_of_output_values[tmp_i].toFixed(4))
                bandit_results.push(<br/>)
            }
            bandit_results.push(<br/>)
            bandit_results.push(<i>Coefficients:&nbsp;</i>)

            if (model_intercept_summary_statistics != null && model_intercept_summary_statistics.average != undefined) {
                bandit_results.push(<br/>)
                bandit_results.push('Intercept: ')
                if (model_intercept_summary_statistics.standard_deviation == 0) {
                    bandit_results.push(
                        ' value: ' + (
                            model_intercept_summary_statistics.average
                        ).toFixed(4)
                    )
                } else {
                    bandit_results.push(<br/>)
                    bandit_results.push(' value: ' + (
                            model_intercept_summary_statistics.average -
                            2 * model_intercept_summary_statistics.standard_deviation
                        ).toFixed(4) +
                        ' < ' + (
                            model_intercept_summary_statistics.average
                        ).toFixed(4) +
                        ' < ' + (
                            model_intercept_summary_statistics.average +
                            2 * model_intercept_summary_statistics.standard_deviation
                        ).toFixed(4)
                    )
                }
                if (state_bandit_results.response.body.intercept_for_deterministic_model != null) {
                    bandit_results.push('\u00a0\u00a0\u00a0\u00a0det. value: ' + state_bandit_results.response.body.intercept_for_deterministic_model.toFixed(4))
                }
            }

            bandit_results.push(<br/>)

            if (state_bandit_results.response.body.model_coefficients !== null) {
                // debugger
                for (var feature_index = 0; feature_index < state_bandit_results.response.body.list_of_feature_names.length; feature_index++) {
                    var orig_index = state_bandit_results.response.body.list_of_feature_names_detailed[feature_index].orig_index
                    var possible_value = state_bandit_results.response.body.list_of_feature_names_detailed[feature_index].possible_value
                    // console.log('orig_index: ' + orig_index)
                    // console.log('feature_index: ' + feature_index)
                    // console.log('possible_value: ' + possible_value)
                    if (
                        Object.keys(state_bandit_results.response.body.prior_counts_by_feature_index).map(val => parseInt(val)).includes(feature_index)
                        && state_bandit_results.response.body.prior_counts_by_feature_index[feature_index] != null
                    ) {
                        var count = state_bandit_results.response.body.prior_counts_by_feature_index[feature_index]
                        var sum = state_bandit_results.response.body.output_sums_by_feature_index[feature_index]
                        var average = (sum * 1.0) / (count * 1.0)
                        var det_average = (det_sum * 1.0) / (det_count * 1.0)
                    } else {
                        var count = Number.NaN
                        var sum = Number.NaN
                        var average = Number.NaN
                        var det_count = Number.NaN
                        var det_sum = Number.NaN
                        var det_average = Number.NaN
                    }
                    bandit_results.push(
                        'index: ' + feature_index + '\u00a0\u00a0\u00a0\u00a0'
                    )
                    bandit_results.push(
                        '' + state_bandit_results.response.body.list_of_feature_names_detailed[feature_index].name + ': ' + possible_value
                    )
                    bandit_results.push(
                        '\u00a0\u00a0\u00a0\u00a0count: ' + count +
                        '\u00a0\u00a0\u00a0\u00a0avg.: ' + average.toFixed(4)
                    )
                    bandit_results.push(<br/>)
                    bandit_results.push(
                        '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0coeff.'
                    )
                    if (state_bandit_results.response.body.coefficients_for_deterministic_model[feature_index] != null) {
                        bandit_results.push(
                            '\u00a0\u00a0det. ' + state_bandit_results.response.body.coefficients_for_deterministic_model[feature_index].toFixed(4)
                        )
                    } else {
                        bandit_results.push(
                            '\u00a0\u00a0det. None'
                        )
                    }
                    if (state_bandit_results.response.body.model_coefficients[feature_index] != null) {
                        bandit_results.push(
                            '\u00a0\u00a0used ' + state_bandit_results.response.body.model_coefficients[feature_index].toFixed(4)
                        )
                    } else {
                        bandit_results.push(
                            '\u00a0\u00a0used None'
                        )
                    }
                    if (map_feature_index_to_coefficient_summary_statistics[feature_index].average == null) {
                    } else {
                        if (map_feature_index_to_coefficient_summary_statistics[feature_index].standard_deviation == 0) {
                        } else {
                            bandit_results.push(
                                '\u00a0\u00a0dist. ' + (
                                    map_feature_index_to_coefficient_summary_statistics[feature_index].average -
                                    2 * map_feature_index_to_coefficient_summary_statistics[feature_index].standard_deviation
                                ).toFixed(4) +
                                ' < ' + map_feature_index_to_coefficient_summary_statistics[feature_index].average?.toFixed(4) +
                                ' < ' + (
                                    map_feature_index_to_coefficient_summary_statistics[feature_index].average +
                                    2 * map_feature_index_to_coefficient_summary_statistics[feature_index].standard_deviation
                                ).toFixed(4)
                            )
                        }
                    }
                    bandit_results.push(<br/>)
                }
            } else {
                bandit_results.push('Selected model hasn\'t seen any training samples yet')
                bandit_results.push(<br/>)
            }

            bandit_results.push(<br/>)
            bandit_results.push(<i>Ranked Actions:</i>)
            bandit_results.push(<br/>)


            var sorted_list_of_action_indices = argSort(map_action_index_to_prediction_summary_statistics.map(val => (val.average)))// - 2 * val.standard_deviation)))

            console.log('sorted_list_of_action_indices')
            console.dir(sorted_list_of_action_indices)


            for (var action_index = 0; action_index < sorted_list_of_action_indices.length; action_index++) {
                if (map_action_index_to_prediction_summary_statistics[sorted_list_of_action_indices[action_index]].standard_deviation == 0) {

                    var rank_text = 'Rank: ' + action_index
                    if (state_bandit_results.array_of_action_indices_used_in_selection_pool.includes(sorted_list_of_action_indices[action_index])) {
                        rank_text += ' (in selection pool)'
                    }
                    bandit_results.push(rank_text)
                    bandit_results.push(<br/>)
                    bandit_results.push(
                        'Reference: string: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].reference_string +
                        ' fret: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].reference_fret +
                        ' note: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].reference_note
                    )
                    bandit_results.push(<br/>)
                    bandit_results.push(
                        'Answer: string: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].answer_string +
                        ' fret: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].answer_fret +
                        ' note: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].answer_note
                    )
                    bandit_results.push(<br/>)
                    bandit_results.push(
                        ' Interval: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].interval
                    )
                    bandit_results.push(<br/>)
                    bandit_results.push(
                        ' Min. Prior Count: ' + (state_bandit_results.response.body.min_prior_counts_by_action_index[sorted_list_of_action_indices[action_index]])
                    )
                    bandit_results.push(
                        ' Min. Det. Prior Count: ' + (state_bandit_results.response.body.min_prior_counts_by_action_index[sorted_list_of_action_indices[action_index]])
                    )
                    bandit_results.push(<br/>)
                    bandit_results.push(
                        ' Prediction: ' + (map_action_index_to_prediction_summary_statistics[sorted_list_of_action_indices[action_index]].average == null ? 'Unknown' : map_action_index_to_prediction_summary_statistics[sorted_list_of_action_indices[action_index]].average.toFixed(4))
                    )
                } else {
                    bandit_results.push(
                        'Reference: string: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].reference_string +
                        ' fret: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].reference_fret +
                        ' note: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].reference_note
                    )
                    bandit_results.push(<br/>)
                    bandit_results.push(
                        ' Answer: string: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].answer_string +
                        ' fret: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].answer_fret +
                        ' note: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].answer_note
                    )
                    bandit_results.push(<br/>)
                    bandit_results.push(
                        ' Interval: ' + state_bandit_results.human_readable_feature_vectors[sorted_list_of_action_indices[action_index]].interval
                    )
                    bandit_results.push(<br/>)
                    if (map_action_index_to_prediction_summary_statistics[sorted_list_of_action_indices[action_index]].average != null) {
                        var string_to_push = ' Prediction: ' + (map_action_index_to_prediction_summary_statistics[sorted_list_of_action_indices[action_index]].average -
                                2 * map_action_index_to_prediction_summary_statistics[sorted_list_of_action_indices[action_index]].standard_deviation
                            ).toFixed(4) +
                            ' < ' + map_action_index_to_prediction_summary_statistics[sorted_list_of_action_indices[action_index]].average.toFixed(4) +
                            ' < ' + (
                                map_action_index_to_prediction_summary_statistics[sorted_list_of_action_indices[action_index]].average +
                                2 * map_action_index_to_prediction_summary_statistics[sorted_list_of_action_indices[action_index]].standard_deviation
                            ).toFixed(4)
                        // ' std_dev: ' + map_action_index_to_prediction_summary_statistics[sorted_list_of_action_indices[action_index]].standard_deviation.toFixed(4)
                        bandit_results.push(string_to_push)
                    }
                }
                var deterministic_prediction = state_bandit_results.response.body.prediction_for_deterministic_model[sorted_list_of_action_indices[action_index]]
                if (deterministic_prediction != null) {
                    deterministic_prediction = deterministic_prediction.toFixed(4)
                } else {
                    deterministic_prediction = 'Unknown'
                }
                bandit_results.push(
                    ' Det. Prediction: ' + deterministic_prediction
                )
                bandit_results.push(<br/>)
                if (action_index != (sorted_list_of_action_indices.length - 1)) {
                    bandit_results.push(<br/>)
                }
            }

        } else {
            if (!this.state.bandit_activated) {
                // bandit_results.push(<p>
                //     Bandit must be activated to show results: {bandit_toggle_button}
                // </p>)
            } else {


                if (state_bandit_results != null && state_bandit_results != undefined) {


                    // <button value="skip" onClick={this.onSkipButton}>Try Again</button>
                    // or
                    // <button type="button" className="bandit_restart"
                    //         onClick={this.onBanditRestartButton}>Restart Banditp</button>
                    var bandit_error_message = ''
                    try {
                        var bandit_error_message = state_bandit_results.response.stackTrace
                    } catch {
                        var bandit_error_message = state_bandit_results.response.errorMessage
                    }
                    if (bandit_error_message.includes('We need item in DynamoDB or this doesn\'t work')) {

                        var bandit_buttons = <p>
                            <button type="button" className="bandit_restart"
                                    onClick={() => this.onBanditRestartButton(false)}>Start Bandito
                            </button>
                        </p>

                        bandit_results = <p>
                            Bandito hasn't been started yet. Please click "Start Bandito".
                        </p>
                    } else if (bandit_error_message.includes('There is a mismatch in the stored feature_metadata and the supplied one')) {
                        bandit_results = <p>
                            Feature metadata has changed and Bandito needs to be restarted, if the new metadata is
                            desired. Please click "Clear & Restart Bandito".
                        </p>
                    } else {
                        bandit_results = <p>
                            Error during Bandito pull:&nbsp;
                            "{bandit_error_message}"
                        </p>
                        // debugger
                    }
                }
            }
        }
        
        var tmp_body = JSON.parse(JSON.stringify(state_bandit_results)) // deep copy from https://www.javascripttutorial.net/object/3-ways-to-copy-objects-in-javascript/
        tmp_body.response.body.covariance_matrix = null
        tmp_body.response.body.moment_matrix = null
        tmp_body.response.body.trailing_list_of_processed_feature_vectors = null
        tmp_body.response.body.list_of_feature_names = null
        tmp_body.response.body.prediction_distribution_by_action_index = null
        tmp_body.response.body.coefficient_distribution = null
        tmp_body.response.body.intercept_distribution = null
        bandit_results = [bandit_results, <div>
            <pre>{JSON.stringify(tmp_body, null, 2)}</pre>
        </div>]
        return bandit_results
    }

    render() {
        var tmp = this.getSelectedLocations()
        var force_first_note_for_interval_find_sequence;
        var i;
        var skip_button = [
            <button value="skip" onClick={this.onSkipButton}>Skip</button>,
            ' ',
            <button value="repeat" onClick={() => this.playQuestionMidi.bind(this)(true)}>Repeat</button>,
            '  ']

        if (this.state.chord_type.includes('Interval') && this.state.chord_type != 'Interval Find Sequence') {

            if (this.state.freeze && this.state.show_freeze) {


                skip_button = [
                    <button value="skip" onClick={this.onSkipButton}>Skip Question</button>,
                    ' ',
                    <button value="full_skip" onClick={this.onFullSkipButton}>Skip Reference</button>,
                    ' \u00a0\u00a0\u00a0\u00a0',
                    <button value="freeze" onClick={this.onFreezeToggle}>Unfreeze Reference</button>,
                    ' \u00a0\u00a0\u00a0\u00a0Repeat: ',
                    <button value="repeat_reference"
                            onClick={() => this.playQuestionMidi.bind(this)(false, 'undefined', 'reference')}>Reference</button>,
                    ' ',
                    <button value="repeat_both"
                            onClick={() => this.playQuestionMidi.bind(this)(true, 'undefined', 'both')}>Both</button>,
                    ' ',
                    <button value="repeat_answer"
                            onClick={() => this.playQuestionMidi.bind(this)(false, 'undefined', 'answer')}>Answer</button>,
                    <br/>,
                    <br/>
                ]

            } else if (this.state.show_freeze) {

                skip_button = [
                    <button value="skip" onClick={this.onSkipButton}>Skip Question</button>,
                    ' \u00a0\u00a0\u00a0\u00a0',
                    <button value="freeze" onClick={this.onFreezeToggle}>Freeze Reference</button>,
                    ' \u00a0\u00a0\u00a0\u00a0Repeat: ',
                    ' ',
                    <button value="repeat_reference"
                            onClick={() => this.playQuestionMidi.bind(this)(false, 'undefined', 'reference')}>Reference</button>,
                    ' ',
                    <button value="repeat_both"
                            onClick={() => this.playQuestionMidi.bind(this)(true, 'undefined', 'both')}>Both</button>,
                    ' ',
                    <button value="repeat_answer"
                            onClick={() => this.playQuestionMidi.bind(this)(false, 'undefined', 'answer')}>Answer</button>,
                    <br/>,
                    <br/>
                ]

            } else {

                skip_button = [
                    <button value="skip" onClick={this.onSkipButton}>Skip</button>,
                    ' \u00a0\u00a0\u00a0\u00a0Repeat: ',
                    <button value="repeat_reference"
                            onClick={() => this.playQuestionMidi.bind(this)(false, 'undefined', 'reference')}>Reference</button>,
                    ' ',
                    <button value="repeat_both"
                            onClick={() => this.playQuestionMidi.bind(this)(true, 'undefined', 'both')}>Both</button>,
                    ' ',
                    <button value="repeat_answer"
                            onClick={() => this.playQuestionMidi.bind(this)(false, 'undefined', 'answer')}>Answer</button>,
                    <br/>,
                    <br/>
                ]
            }
        }

        var freeze_text = "Freeze Root"
        if (this.state.freeze) {
            freeze_text = 'Unfreeze Root'
        }

        var freeze_button = <button value="freeze" onClick={this.onFreezeToggle}>{freeze_text}</button>
        var spell_button = <button value="spell" onClick={this.playQuestionMidi.bind(this)}>Repeat</button>

        var activated_text_with_chord_loop = "Play Chord Loop"
        if (this.state.chord_loop_activated) {
            activated_text_with_chord_loop = 'Stop Chord Loop'
        }

        var activated_button_with_chord_loop_text = [
            <button value="activated_with_chord_loop_text"
                    onClick={this.onChordLoopActivatedChange}>{activated_text_with_chord_loop}</button>, '\u00a0\u00a0'
        ]
        var valid_chord_loop_length = 0
        for (j = 0; j < this.state.chord_loop.length; j++) {
            if (this.state.chord_loop[j] != null) {
                valid_chord_loop_length += 1
            }
        }
        if (valid_chord_loop_length == 0) {
            activated_button_with_chord_loop_text = ''
        }

        var reference_notes_to_choose_from_with_unknown = [...reference_notes_to_choose_from]
        reference_notes_to_choose_from_with_unknown.push('Unknown')
        var list_of_tonic_input_reference_note_drop_down = []
        for (i = 0; i < reference_notes_to_choose_from_with_unknown.length; i++) {
            list_of_tonic_input_reference_note_drop_down.push(<option
                value={reference_notes_to_choose_from_with_unknown[i]}
                selected={this.state.tonic_input_reference_note == reference_notes_to_choose_from_with_unknown[i]}
            >{reference_notes_to_choose_from_with_unknown[i]}</option>)
        }
        var tonic_input_reference_note_drop_down =
            <select name="tonic_input_reference_note" id="tonic_input_reference_note"
                    onChange={(payload) => this.onTonicInputChange(payload, 'reference_note')}>
                {list_of_tonic_input_reference_note_drop_down}
            </select>


        var list_of_tonic_input_answer_note_drop_down = []
        for (i = 0; i < reference_notes_to_choose_from_with_unknown.length; i++) {
            list_of_tonic_input_answer_note_drop_down.push(<option
                value={reference_notes_to_choose_from_with_unknown[i]}
                selected={this.state.tonic_input_answer_note == reference_notes_to_choose_from_with_unknown[i]}
            >{reference_notes_to_choose_from_with_unknown[i]}</option>)
        }
        var tonic_input_answer_note_drop_down =
            <select name="tonic_input_answer_note" id="tonic_input_answer_note"
                    onChange={(payload) => this.onTonicInputChange(payload, 'answer_note')}>
                {list_of_tonic_input_answer_note_drop_down}
            </select>


        var scale_types_to_choose_from_with_unknown = [...scale_types_to_choose_from]
        scale_types_to_choose_from_with_unknown.push('Unknown')
        var list_of_scale_type_input_reference_note_drop_down = []
        for (i = 0; i < scale_types_to_choose_from_with_unknown.length; i++) {
            list_of_scale_type_input_reference_note_drop_down.push(<option
                value={scale_types_to_choose_from_with_unknown[i]}
                selected={this.state.scale_type_input_reference_note == scale_types_to_choose_from_with_unknown[i]}
            >{scale_types_to_choose_from_with_unknown[i]}</option>)
        }
        var scale_type_input_reference_note_drop_down =
            <select name="scale_type_input_reference_note" id="scale_type_input_reference_note"
                    onChange={(payload) => this.onScaleTypeInputChange(payload, 'reference_note')}>
                {list_of_scale_type_input_reference_note_drop_down}
            </select>


        var list_of_scale_type_input_answer_note_drop_down = []
        for (i = 0; i < scale_types_to_choose_from_with_unknown.length; i++) {
            list_of_scale_type_input_answer_note_drop_down.push(<option
                value={scale_types_to_choose_from_with_unknown[i]}
                selected={this.state.scale_type_input_answer_note == scale_types_to_choose_from_with_unknown[i]}
            >{scale_types_to_choose_from_with_unknown[i]}</option>)
        }
        var scale_type_input_answer_note_drop_down =
            <select name="scale_type_input_answer_note" id="scale_type_input_answer_note"
                    onChange={(payload) => this.onScaleTypeInputChange(payload, 'answer_note')}>
                {list_of_scale_type_input_answer_note_drop_down}
            </select>

        var list_of_available_game_types_checkboxes = []
        for (i = 0; i < game_types_with_meta_mode.length; i++) {
            if (!game_types_with_meta_mode[i].includes('Show')) {
                list_of_available_game_types_checkboxes.push(
                    <label>
                        <input type="checkbox"
                               name="available_game_types"
                               value={game_types_with_meta_mode[i]}
                               checked={this.state.available_game_types.includes(game_types_with_meta_mode[i])}
                               onClick={this.onAvailableGameTypesChange}
                               label={game_types_with_meta_mode[i]}
                        />&nbsp;{map_game_type_internal_to_external[game_types_with_meta_mode[i]]}</label>, ' '
                )
            }
        }


        var list_of_available_zones_checkboxes = []
        for (i = 0; i < default_available_zones.length; i++) {
            list_of_available_zones_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_answer_note_zones"
                           value={default_available_zones[i]}
                           checked={this.state.available_answer_note_zones.includes(default_available_zones[i])}
                           onClick={this.onAvailableAnswerZonesChange}
                           label={default_available_zones[i]}
                    />&nbsp;{default_available_zones[i]}</label>, ' '
            )
        }


        var list_of_available_reference_note_strings_checkboxes = []
        for (i = 0; i < default_available_reference_note_strings.length; i++) {
            list_of_available_reference_note_strings_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_reference_note_strings"
                           value={i}
                           checked={this.state.available_reference_note_strings.includes(i)}
                           onClick={this.onAvailableReferenceNoteStringsChange}
                           label={i}
                    />&nbsp;{i + 1}</label>, ' '
            )
        }

        var list_of_available_answer_note_frets_checkboxes = []
        for (i = 0; i < all_available_answer_note_frets.length; i++) {
            list_of_available_answer_note_frets_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_answer_note_frets"
                           value={all_available_answer_note_frets[i]}
                           checked={this.state.available_answer_note_frets.includes(all_available_answer_note_frets[i])}
                           onClick={this.onAvailableAnswerNoteFretsChange}
                           label={all_available_answer_note_frets[i]}
                    />&nbsp;{all_available_answer_note_frets[i]}</label>, ' '
            )
        }
        var list_of_available_reference_note_frets_checkboxes = []
        for (i = 0; i < all_available_reference_note_frets.length; i++) {
            list_of_available_reference_note_frets_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_reference_note_frets"
                           value={all_available_reference_note_frets[i]}
                           checked={this.state.available_reference_note_frets.includes(all_available_reference_note_frets[i])}
                           onClick={this.onAvailableReferenceNoteFretsChange}
                           label={all_available_reference_note_frets[i]}
                    />&nbsp;{all_available_reference_note_frets[i]}</label>, ' '
            )
        }

        var list_of_available_notes_checkboxes = []
        for (i = 0; i < notes_to_choose_from.length; i++) {
            list_of_available_notes_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_answer_notes"
                           value={notes_to_choose_from[i]}
                           checked={this.state.available_answer_notes.includes(notes_to_choose_from[i])}
                           onClick={this.onAvailableAnswerNotesChange}
                           label={notes_to_choose_from[i]}
                    />&nbsp;{notes_to_choose_from[i]}</label>, ' '
            )
        }

        var list_of_available_reference_notes_checkboxes = []
        for (i = 0; i < reference_notes_to_choose_from.length; i++) {
            list_of_available_reference_notes_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_reference_notes"
                           value={reference_notes_to_choose_from[i]}
                           checked={this.state.available_reference_notes.includes(reference_notes_to_choose_from[i])}
                           onClick={this.onAvailableReferenceNotesChange}
                           label={reference_notes_to_choose_from[i]}
                    />&nbsp;{reference_notes_to_choose_from[i]}</label>, ' '
            )
        }

        var list_of_available_scales_checkboxes = []
        for (i = 0; i < scale_types_to_play_to_choose_from.length; i++) {
            list_of_available_scales_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_scales"
                           value={scale_types_to_play_to_choose_from[i]}
                           checked={this.state.available_scales.includes(scale_types_to_play_to_choose_from[i])}
                           onClick={this.onAvailableScalesChange}
                           label={scale_types_to_play_to_choose_from[i]}
                    />&nbsp;{scale_types_to_play_to_choose_from[i]}</label>, ' '
            )
        }


        var chord_qualities_to_choose_from = Object.keys(map_chord_quality_to_chord_type)
        var list_of_available_chord_qualities_checkboxes = []
        for (var i = 0; i < chord_qualities_to_choose_from.length; i++) {
            list_of_available_chord_qualities_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_chord_types"
                           value={chord_qualities_to_choose_from[i]}
                           checked={testAll(map_chord_quality_to_chord_type[chord_qualities_to_choose_from[i]], this.state.available_chord_types)}
                           onClick={this.onAvailableChordTypesChange}
                           label={chord_qualities_to_choose_from[i]}
                    />&nbsp;{chord_qualities_to_choose_from[i]}</label>, ' '
            )
        }


        var chord_qualities2_to_choose_from = Object.keys(map_chord_quality2_to_chord_type)
        var list_of_available_chord_qualities2_checkboxes = []
        for (var i = 0; i < chord_qualities2_to_choose_from.length; i++) {
            list_of_available_chord_qualities2_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_chord_types"
                           value={chord_qualities2_to_choose_from[i]}
                           checked={testAll(map_chord_quality2_to_chord_type[chord_qualities2_to_choose_from[i]], this.state.available_chord_types)}
                           onClick={this.onAvailableChordTypesChange}
                           label={chord_qualities2_to_choose_from[i]}
                    />&nbsp;{chord_qualities2_to_choose_from[i]}</label>, ' '
            )
        }


        var list_of_available_strings_checkboxes = []
        for (i = 0; i < 6; i++) {
            list_of_available_strings_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_answer_note_strings"
                           value={i}
                           checked={this.state.available_answer_note_strings.includes(i)}
                           onClick={this.onAvailableStringsChange}
                           label={i}
                    />&nbsp;{i + 1}</label>, ' '
            )
        }

        var list_of_available_octave_jumps_checkboxes = []
        for (i = 0; i < all_available_octave_jumps.length; i++) {
            list_of_available_octave_jumps_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_octave_jumps"
                           value={all_available_octave_jumps[i]}
                           checked={this.state.available_octave_jumps.includes(all_available_octave_jumps[i])}
                           onClick={this.onAvailableOctaveJumpsChange}
                           label={all_available_octave_jumps[i]}
                    />&nbsp;{all_available_octave_jumps[i]}</label>, ' '
            )
        }

        var list_of_available_chord_types_checkboxes = []
        for (i = 0; i < chord_types_to_choose_from.length; i++) {
            list_of_available_chord_types_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_chord_types"
                           value={chord_types_to_choose_from[i]}
                           checked={this.state.available_chord_types.includes(chord_types_to_choose_from[i])}
                           onClick={this.onAvailableChordTypesChange}
                           label={chord_types_to_choose_from[i]}
                    />&nbsp;{chord_types_to_choose_from[i]}</label>, ' '
            )
        }

        var list_of_available_note_reference_note_distances_checkboxes = []
        for (i = 0; i < show_all_available_note_reference_note_distances.length; i++) {
            if (show_all_available_note_reference_note_distances[i] == '7 thru end') {
                list_of_available_note_reference_note_distances_checkboxes.push(
                    <label>
                        <input type="checkbox"
                               name="available_note_reference_note_distances"
                               value={show_all_available_note_reference_note_distances[i]}
                               checked={testAll([7, 8, 9, 10, 11], this.state.available_note_reference_note_distances)}
                               onClick={this.onAvailableNoteReferenceNoteDistancesChange}
                               label={show_all_available_note_reference_note_distances[i]}
                        />&nbsp;{show_all_available_note_reference_note_distances[i]}</label>
                )
            } else if (show_all_available_note_reference_note_distances[i] == 'end thru -7') {
                list_of_available_note_reference_note_distances_checkboxes.push(
                    <label>
                        <input type="checkbox"
                               name="available_note_reference_note_distances"
                               value={show_all_available_note_reference_note_distances[i]}
                               checked={testAll([-7, -8, -9, -10, -11], this.state.available_note_reference_note_distances)}
                               onClick={this.onAvailableNoteReferenceNoteDistancesChange}
                               label={show_all_available_note_reference_note_distances[i]}
                        />&nbsp;{show_all_available_note_reference_note_distances[i]}</label>, ' '
                )
            } else {
                list_of_available_note_reference_note_distances_checkboxes.push(
                    <label>
                        <input type="checkbox"
                               name="available_note_reference_note_distances"
                               value={show_all_available_note_reference_note_distances[i]}
                               checked={this.state.available_note_reference_note_distances.includes(show_all_available_note_reference_note_distances[i])}
                               onClick={this.onAvailableNoteReferenceNoteDistancesChange}
                               label={show_all_available_note_reference_note_distances[i]}
                        />&nbsp;{show_all_available_note_reference_note_distances[i]}</label>, ' '
                )
            }
        }

        var list_of_available_note_reference_note_string_distances_checkboxes = []
        for (i = 0; i < all_available_note_reference_note_string_distances.length; i++) {
            list_of_available_note_reference_note_string_distances_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_note_reference_note_string_distances"
                           value={all_available_note_reference_note_string_distances[i]}
                           checked={this.state.available_note_reference_note_string_distances.includes(all_available_note_reference_note_string_distances[i])}
                           onClick={this.onAvailableNoteReferenceNoteStringDistancesChange}
                           label={all_available_note_reference_note_string_distances[i]}
                    />&nbsp;{all_available_note_reference_note_string_distances[i]}</label>, ' '
            )
        }


        var list_of_available_interval_types_checkboxes = []
        for (i = 0; i < default_available_interval_types.length; i++) {
            list_of_available_interval_types_checkboxes.push(
                <label>
                    <input type="checkbox"
                           name="available_interval_types"
                           value={default_available_interval_types[i]}
                           checked={this.state.available_interval_types.includes(default_available_interval_types[i])}
                           onClick={this.onAvailableIntervalTypesChange}
                           label={default_available_interval_types[i]}
                    />&nbsp;{map_interval_int_to_show[default_available_interval_types[i]]}</label>, ' '
            )
        }

        var list_of_interval_group_lengths_drop_down = []


        for (i = 0; i < interval_group_lengths_to_choose_from.length; i++) {
            list_of_interval_group_lengths_drop_down.push(<option
                value={interval_group_lengths_to_choose_from[i]}
                selected={this.state.interval_group_length === interval_group_lengths_to_choose_from[i]}
            >{interval_group_lengths_to_choose_from[i]}</option>)
        }

        var chord_octaves_to_choose_from = [2, 2.5, 3, 3.5, 4, 4.5]
        var list_of_octaves_drop_down = []

        for (i = 0; i < chord_octaves_to_choose_from.length; i++) {
            list_of_octaves_drop_down.push(<option
                value={chord_octaves_to_choose_from[i]}
                selected={this.state.chord_octave === chord_octaves_to_choose_from[i]}
            >{chord_octaves_to_choose_from[i]}</option>)
        }

        var chord_octave_drop_down =
            <select name="chord_type" id="chord_type" onChange={this.onOctaveChange}>
                {list_of_octaves_drop_down}
            </select>


        var chord_type = this.state.chord_type
        var scale_type = this.state.chord_type
        var chord_scale_interval = 0
        var possible_array_of_info_from_state_chord_type = this.state.chord_type.split('|')
        if (possible_array_of_info_from_state_chord_type.length == 3) {
            chord_type = possible_array_of_info_from_state_chord_type[0]
            scale_type = possible_array_of_info_from_state_chord_type[1]
            chord_scale_interval = parseInt(possible_array_of_info_from_state_chord_type[2])
        }


        var list_of_chord_types_drop_down = [
            <option
                value="Root"
                selected={chord_type === "Root"}
            >Root</option>
        ]

        for (i = 0; i < chord_types_to_choose_from.length; i++) {
            list_of_chord_types_drop_down.push(<option
                value={chord_types_to_choose_from[i]}
                selected={chord_type === chord_types_to_choose_from[i]}
            >{chord_types_to_choose_from[i]}</option>)
        }

        var chord_type_drop_down =
            <select name="chord_type" id="chord_type" onChange={this.onGameTypeChange}>
                {list_of_chord_types_drop_down}
            </select>

        var list_of_scale_types_drop_down = []
        for (i = 0; i < scale_types_to_choose_from.length; i++) {
            list_of_scale_types_drop_down.push(<option
                value={scale_types_to_choose_from[i]}
                selected={scale_type === scale_types_to_choose_from[i]}
            >{scale_types_to_choose_from[i]}</option>)
        }

        var scale_type_drop_down =
            <select name="scale_type" id="scale_type" onChange={this.onGameTypeChange}>
                {list_of_scale_types_drop_down}
            </select>

        var list_of_chord_scale_intervals_drop_down = []
        for (i = 0; i < chord_scale_intervals_to_choose_from.length; i++) {
            list_of_chord_scale_intervals_drop_down.push(<option
                value={chord_scale_intervals_to_choose_from[i]}
                selected={chord_scale_interval === chord_scale_intervals_to_choose_from[i]}
            >{map_interval_int_to_pretty_show[chord_scale_intervals_to_choose_from[i]]}</option>)
        }
        var chord_scale_interval_drop_down =
            <select name="chord_scale_interval" id="chord_scale_interval" onChange={this.onGameTypeChange}>
                {list_of_chord_scale_intervals_drop_down}
            </select>


        var red_background = {"background": "#add8e6", "-webkit-appearance": "none"}
        var regular_background = {"-webkit-appearance": "button"}

        var show_roman_numeral_button = ''
        if (chord_scale_types_to_choose_from.includes(this.state.chord_type)) {
            if (!this.state.show_roman_numeral_buttons) {
                show_roman_numeral_button = [
                    <button
                        value="show_roman_numeral_buttons"
                        onClick={this.onShowRomanNumeralButtonsChange}
                    >Show Roman Numeral Buttons</button>
                ]
            } else {
                show_roman_numeral_button = [
                    <button
                        value="show_roman_numeral_buttons"
                        onClick={this.onShowRomanNumeralButtonsChange}
                        class="button red"
                    >Hide Roman Numeral Buttons</button>
                ]
            }
        }


        var show_random_top_note_button = ''
        // if (chord_scale_types_to_choose_from.includes(this.state.chord_type)) {
        //     if (!this.state.show_random_top_note_options) {
        //         show_random_top_note_button = [
        //             <button
        //                 value="show_random_top_note_buttons"
        //                 onClick={this.onShowRandomTopNoteOptionsChange}
        //             >Show Random Top Note Options</button>
        //         ]
        //     } else {
        //         show_random_top_note_button = [
        //             <button
        //                 value="show_random_top_note_buttons"
        //                 onClick={this.onShowRandomTopNoteOptionsChange}
        //                 class="button red"
        //             >Hide Random Top Note Options</button>
        //         ]
        //     }
        // }


        var show_chord_loop_button = ''
        if (chord_scale_types_to_choose_from.includes(this.state.chord_type)) {
            if (!this.state.show_chord_loop) {
                show_chord_loop_button = [
                    <button
                        value="show_chord_loop_button"
                        onClick={this.onShowChordLoopChange}
                    >Show Chord Loop Options</button>
                ]
            } else {
                show_chord_loop_button = [
                    <button
                        value="show_chord_loop_button"
                        onClick={this.onShowChordLoopChange}
                        class="button red"
                    >Hide Chord Loop Options</button>
                ]
            }
            show_chord_loop_button = [<br/>, <br/>, show_chord_loop_button]
        }


        var random_top_note_checkbox = <label>
            <input type="checkbox"
                   name="random_top_note"
                   value="random_top_note"
                   checked={this.state.random_top_note}
                   onClick={this.onRandomTopNoteChange}
                   label="random_top_note"
            />&nbsp;Chord Tone</label>

        var random_top_tension_checkbox = <label>
            <input type="checkbox"
                   name="random_top_tension"
                   value="random_top_tension"
                   checked={this.state.random_top_tension}
                   onClick={this.onRandomTopTensionChange}
                   label="random_top_tensione"
            />&nbsp;Avail. Tension</label>

        var random_top_both_checkbox = <label>
            <input type="checkbox"
                   name="random_top_both"
                   value="random_top_both"
                   checked={this.state.random_top_tension && this.state.random_top_note}
                   onClick={this.onRandomTopBothChange}
                   label="random_top_both"
            />&nbsp;Both</label>

        var random_top_note_result = [<br/>, <p></p>]
        // if (this.state.random_top_interval_being_played_against_chord_root != null) {
        //     random_top_note_result = [
        //         <br/>,
        //         <p>&nbsp;&nbsp;&nbsp;&nbsp;Played: <b>{this.state.random_top_pitch_class}</b> / <b>{this.state.random_top_interval_being_played_against_chord_root}</b> against
        //             chord root / <b>{this.state.random_top_interval_being_played_against_scale_root}</b> against scale
        //             root</p>
        //     ]
        // }

        var list_of_list_of_roman_numeral_drop_down = [[], [], [], [], [], []]
        var roman_numerals_to_choose_from_with_none = ['None']
        roman_numerals_to_choose_from_with_none.push(...roman_numerals_to_choose_from)

        for (j = 0; j < list_of_list_of_roman_numeral_drop_down.length; j++) {
            list_of_list_of_roman_numeral_drop_down[j] = []
            for (i = 0; i < roman_numerals_to_choose_from_with_none.length; i++) {
                list_of_list_of_roman_numeral_drop_down[j].push(<option
                    value={roman_numerals_to_choose_from_with_none[i]}
                    selected={
                        roman_numerals_to_choose_from_with_none[i] == 'None' ?
                            (this.state.chord_loop[j] == null ? true : false) :
                            (this.state.chord_loop[j] == null ?
                                    false :
                                    this.state.chord_loop[j].name == roman_numerals_to_choose_from_with_none[i]
                            )

                    }
                >{roman_numerals_to_choose_from_with_none[i]}</option>)
            }
        }

        var map_valid_chord_loop_index_to_original_index = {}
        var moving_valid_chord_loop_index = 0
        for (var i = 0; i < this.state.chord_loop.length; i++) {
            if (this.state.chord_loop[i] != null) {
                map_valid_chord_loop_index_to_original_index[moving_valid_chord_loop_index] = i
                moving_valid_chord_loop_index += 1
            }
        }

        var red_background = {background: "#4CB1C6"}
        var regular_background = {}
        var valid_chord_loop_index = Math.floor((this.state.valid_chord_loop_index * 1.0) / (this.state.beats_per_chord * 1.0))

        var list_of_roman_numeral_drop_down_menus = [
            <select
                style={map_valid_chord_loop_index_to_original_index[valid_chord_loop_index] == 0 ? red_background : regular_background}
                name="roman_numerals_drop_down"
                id="roman_numerals_drop_down"
                onChange={(payload) => this.onRomanNumeralDropDownChange(payload, 0)}
            >
                {list_of_list_of_roman_numeral_drop_down[0]}
            </select>,
            <select
                style={map_valid_chord_loop_index_to_original_index[valid_chord_loop_index] == 1 ? red_background : regular_background}
                name="roman_numerals_drop_down"
                id="roman_numerals_drop_down"
                onChange={(payload) => this.onRomanNumeralDropDownChange(payload, 1)}
            >
                {list_of_list_of_roman_numeral_drop_down[1]}
            </select>,
            <select
                style={map_valid_chord_loop_index_to_original_index[valid_chord_loop_index] == 2 ? red_background : regular_background}
                name="roman_numerals_drop_down"
                id="roman_numerals_drop_down"
                onChange={(payload) => this.onRomanNumeralDropDownChange(payload, 2)}
            >
                {list_of_list_of_roman_numeral_drop_down[2]}
            </select>,
            <select
                style={map_valid_chord_loop_index_to_original_index[valid_chord_loop_index] == 3 ? red_background : regular_background}
                name="roman_numerals_drop_down" id="roman_numerals_drop_down"
                onChange={(payload) => this.onRomanNumeralDropDownChange(payload, 3)}
            >
                {list_of_list_of_roman_numeral_drop_down[3]}
            </select>,
            <select
                style={map_valid_chord_loop_index_to_original_index[valid_chord_loop_index] == 4 ? red_background : regular_background}
                name="roman_numerals_drop_down" id="roman_numerals_drop_down"
                onChange={(payload) => this.onRomanNumeralDropDownChange(payload, 4)}
            >
                {list_of_list_of_roman_numeral_drop_down[4]}
            </select>,
            <select
                style={map_valid_chord_loop_index_to_original_index[valid_chord_loop_index] == 5 ? red_background : regular_background}
                name="roman_numerals_drop_down" id="roman_numerals_drop_down"
                onChange={(payload) => this.onRomanNumeralDropDownChange(payload, 5)}
            >
                {list_of_list_of_roman_numeral_drop_down[5]}
            </select>,
            '\u00a0\u00a0',
            <button value="left"
                    onClick={this.onChordLoopShift}>
                ←
            </button>,
            <button value="right"
                    onClick={this.onChordLoopShift}>
                →
            </button>
        ]

        var list_of_roman_numeral_sequences_drop_down = [
            <option
                value="None"
                selected={this.state.roman_numeral_pre_loaded_sequence == null}
            >Load Pre-Defined Sequence</option>,
            <option
                value="None"
                selected={false}
            >No Chord Loop</option>
        ]

        //
        // function getRomanNumeralSequenceFromState(state) {
        //     var valid_chord_loop = []
        //     for (var i = 0; i < state.chord_loop.length; i++) {
        //         if (state.chord_loop[i] != null) {
        //             valid_chord_loop.push(state.chord_loop[i])
        //         }
        //     }
        //     var valid_chord_loop_as_string = valid_chord_loop.map(val => val.name).join('-')
        //     console.log('render valid_chord_loop_as_string: ' + valid_chord_loop_as_string)
        //     return (valid_chord_loop_as_string)
        // }


        for (var i = 0; i < roman_numeral_sequences_to_choose_from.length; i++) {
            list_of_roman_numeral_sequences_drop_down.push(<option
                value={roman_numeral_sequences_to_choose_from[i]}
                selected={(
                    this.state.roman_numeral_pre_loaded_sequence == null ?
                        false :
                        this.state.roman_numeral_pre_loaded_sequence == roman_numeral_sequences_to_choose_from[i]
                )}
            >{roman_numeral_sequences_to_choose_from[i]}</option>)
        }

        var roman_numeral_pre_loaded_drop_down_style = {
            'width': "200px"
        }
        var roman_numeral_pre_loaded_drop_down =
            <select name="roman_numeral_pre_loaded" id="roman_numeral_pre_loaded"
                    onChange={this.onRomanNumeralPreLoadedChange} style={roman_numeral_pre_loaded_drop_down_style}>
                {list_of_roman_numeral_sequences_drop_down}
            </select>


        var chord_loop_tempos_to_choose_from = [
            30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115, 120
        ]

        var list_of_chord_loop_tempos_drop_down = []

        for (var i = 0; i < chord_loop_tempos_to_choose_from.length; i++) {
            list_of_chord_loop_tempos_drop_down.push(<option
                value={chord_loop_tempos_to_choose_from[i]}
                selected={chord_loop_tempos_to_choose_from[i] == this.state.chord_loop_tempo}
            >{chord_loop_tempos_to_choose_from[i]}</option>)
        }

        var chord_loop_tempos_drop_down =
            <select name="chord_loop_tempo" id="chord_loop_tempo"
                    onChange={this.onChordLoopTempoChange}>
                {list_of_chord_loop_tempos_drop_down}
            </select>


        var activated_text = "Play"
        if (this.state.chord_loop_activated) {
            activated_text = 'Stop'
        }

        var activated_button = <button value="activated"
                                       onClick={this.onChordLoopActivatedChange}>{activated_text}</button>


        // var chord_loop_activated_checkbox = <label>
        //     <input type="checkbox"
        //            name="chord_loop_activated"
        //            value="chord_loop_activated"
        //            checked={this.state.chord_loop_activated}
        //            onClick={this.onChordLoopActivatedChange}
        //            label="chord_loop_activated"
        //     />Activated</label>


        var strum_checkbox = <label>
            <input type="checkbox"
                   name="strum"
                   value="strum"
                   checked={this.state.reference_style_strum}
                   onClick={this.onReferenceStyleStrumChange}
                   label="strum"
            />&nbsp;Strum</label>

        var show_next_chord_checkbox = <label>
            <input type="checkbox"
                   name="show_next_chord"
                   value="show_next_chord"
                   checked={this.state.show_next_chord}
                   onClick={this.onShowNextChordChange}
                   label="show_next_chord"
            />&nbsp;Show Next Chord</label>


        var microphone_checkbox = <label>
            <input type="checkbox"
                   name="microphone"
                   value="microphone"
                   checked={this.state.show_microphone_input && this.state.microphone_works}
                   onClick={this.onShowMicrophoneInputChange}
                   label="microphone"
            />&nbsp;Use Microphone Input</label>

        if (!this.state.microphone_works) {
            microphone_checkbox = <button onClick={initializeMicrophone}>Initialize Microphone for Input</button>
        }
        // (typeof (audioContext) !== 'undefined' ? audioContext.state : 'null')

        var mute_sounds_checkbox = <label>
            <input type="checkbox"
                   name="mute_sounds"
                   value="mute_sounds"
                   checked={this.state.volume == 0}
                   onClick={this.onMuteChange}
                   label="mute"
            />&nbsp;Mute Sounds</label>

        var microphone_result = ""
        if (this.state.show_microphone_input && this.state.microphone_works && this.state.microphone_input_note !== null) {
            microphone_result = []  // + " " + (this.state.microphone_input_cents_offset > 0 ? "+" : "") + Number((this.state.microphone_input_cents_offset).toFixed(1)) + ' cents'
            microphone_result.push(this.state.microphone_input_note)
            microphone_result.push(' ')
            microphone_result.push(
                <input type="range"
                       min="-50"
                       max="50"
                       value={this.state.microphone_input_cents_offset_trailing_average}
                       class="slider"
                />
            )

        }

        var microphone_hold = ""
        if (this.state.show_microphone_input && this.state.microphone_works) {
            microphone_hold = <label>
                <input type="checkbox"
                       name="microphone_hold"
                       value="microphone_hold"
                       checked={this.state.microphone_hold && this.state.show_microphone_input && this.state.microphone_works}
                       onClick={this.onMicrophoneHoldChange}
                       label="microphone_hold"
                />&nbsp;Hold</label>
        }

        var random_root_button = <button value="random_root" onClick={this.onRandomRootButton}>Random Root</button>


        var list_of_list_of_roman_numerals = [
            ['I', 'ii', 'iii', 'IV', 'V', 'vi', 'viio'],
            ['i', 'iio', 'bIII', 'iv', 'v', 'bVI', 'bVII'],
            [],
            ['IM7', 'ii7', 'iii7', 'IVM7', 'V7', 'vi7', 'viim7b5'],
            ['i7', 'iim7b5', 'bIIIM7', 'iv7', 'v7', 'bVIM7', 'bVII7', '', 'bVIIM7'],
            [],
            ['IM9', 'ii9', 'iiib9', 'IVM9', 'V9', 'vi9', 'viim9b5'],
            ['i9', 'iim9b5', 'bIIIM9', 'iv9', 'v9', 'bVIM9', 'bVII9'],
            [],
            ['I7', 'bII7', 'II7', 'bIII7', 'III7', 'IV7', '#IV7', 'V7', 'bVI7', 'VI7', 'bVII7', 'VII7'],
            ['V7#11', 'V7#5', 'V7b5', 'V7b9', 'V7#9', 'V7b9b13', 'V7#9b13', 'V7#9#11'],
            ['V6', 'V13', 'Vsus', 'Vsus2', 'Vsus24', '', 'IVM7#11'],
            [],
            ['I6', 'I69', 'bII', 'II', 'III', 'IV6', '#IV', '#ivo7', 'VI', 'VII', 'vii'],
            ['I+', 'III+', 'V+', 'VI+', 'I+M7', 'III+7', 'V+7', 'VI+7'],
        ]

        var list_of_roman_numeral_buttons = []
        for (j = 0; j < list_of_list_of_roman_numerals.length; j++) {
            if (j != 0) {
                list_of_roman_numeral_buttons.push(<br/>)
            }
            for (i = 0; i < list_of_list_of_roman_numerals[j].length; i++) {
                if (list_of_list_of_roman_numerals[j][i] == '') {
                    list_of_roman_numeral_buttons.push('\u00a0\u00a0')
                } else {
                    list_of_roman_numeral_buttons.push(
                        <button value={list_of_list_of_roman_numerals[j][i]}
                                onPointerDown={this.onRomanNumeralButton}>{list_of_list_of_roman_numerals[j][i]}</button>
                    )
                }
            }
        }

        if (!chord_scale_types_to_choose_from.includes(this.state.chord_type)) { // that is, if we are NOT in a "Show Chord & Scale" game
            chord_scale_interval_drop_down = ''
            list_of_roman_numeral_buttons = ''
            if (!chord_types_to_choose_from.includes(this.state.chord_type) && 'Root' != this.state.chord_type) {
                chord_type_drop_down = ''
            }
            if (!scale_types_to_choose_from.includes(this.state.chord_type)) {
                scale_type_drop_down = ''
            }
        } else {
            list_of_roman_numeral_buttons = <div class="no_touch">{list_of_roman_numeral_buttons}</div>
        }

        if (!this.state.show_roman_numeral_buttons) {
            list_of_roman_numeral_buttons = ''
        }


        var list_of_meta_mode_repetitions_drop_down = [];
        for (i = 0; i < 10; i++) {
            list_of_meta_mode_repetitions_drop_down.push(<option
                value={i}
                selected={this.state.meta_mode_repetitions == i}
            >{i}</option>)
        }

        var beats_per_chord_drop_down =
            <select name="beats_per_chord" id="beats_per_chord"
                    onChange={this.onBeatsPerChordChange}>
                <option value="1" selected={this.state.beats_per_chord == 1}>1</option>
                <option value="2" selected={this.state.beats_per_chord == 2}>2</option>
                <option value="3" selected={this.state.beats_per_chord == 3}>3</option>
                <option value="4" selected={this.state.beats_per_chord == 4}>4</option>
                <option value="6" selected={this.state.beats_per_chord == 6}>6</option>
                <option value="8" selected={this.state.beats_per_chord == 8}>8</option>
            </select>

        var beat_number = mod(this.state.valid_chord_loop_index, this.state.beats_per_chord) + 1
        var beat_number_text = "Beat: " + beat_number
        if (!isFinite(beat_number) || !this.state.chord_loop_activated) {
            beat_number_text = ""
        }


        var bass_options_drone_checkbox = <label>
            <input type="checkbox"
                   name="bass_options_drone"
                   value="bass_options_drone"
                   checked={this.state.bass_options_drone}
                   onClick={this.onBassOptionsChange}
                   label="bass_options_drone"
            />&nbsp;Scale Root Drone</label>

        var bass_options_chord_root_checkbox = <label>
            <input type="checkbox"
                   name="bass_options_chord_roots"
                   value="bass_options_chord_roots"
                   checked={this.state.bass_options_chord_roots}
                   onClick={this.onBassOptionsChange}
                   label="bass_options_chord_roots"
            />&nbsp;Chord Roots</label>

        var bass_options = [
            bass_options_chord_root_checkbox,
            '\u00a0\u00a0',
            bass_options_drone_checkbox
        ]

        var chord_loop_options = <p>
            Chord Loop: {roman_numeral_pre_loaded_drop_down}
            &nbsp;&nbsp;Tempo: {chord_loop_tempos_drop_down}
            &nbsp;&nbsp;Beats per Chord: {beats_per_chord_drop_down}
            &nbsp;&nbsp;{beat_number_text}
            <br/>
            <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;{list_of_roman_numeral_drop_down_menus}
            <br/>
            <br/>
            &nbsp;&nbsp;&nbsp;&nbsp;Bass Note: {bass_options}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{show_next_chord_checkbox}
        </p>

        if (!this.state.show_chord_loop) {
            chord_loop_options = ''
        }

        var chord_or_scale_options = <p>
            &nbsp;&nbsp;Chord Octave: {chord_octave_drop_down}
            &nbsp;&nbsp;{strum_checkbox}
        </p>


        var random_top_note_options = ''
        if (
            chord_scale_types_to_choose_from.includes(this.state.chord_type) &&
            (
                this.state.show_chord_loop ||
                this.state.show_roman_numeral_buttons
            )
            // !this.state.show_random_top_note_options
        ) {
            random_top_note_options = <p>
                Random Top Note: {random_top_note_checkbox}
                &nbsp;&nbsp;{random_top_tension_checkbox}
                &nbsp;&nbsp;{random_top_both_checkbox}
                &nbsp;&nbsp;{random_top_note_result}
            </p>
        }

        var chord_scale_show_options = <p>{chord_or_scale_options}</p>

        if (
            !chord_scale_types_to_choose_from.includes(this.state.chord_type)
        ) {
            strum_checkbox = ''
            chord_scale_show_options = <p></p>
            random_top_note_options = ''
            if (
                chord_types_to_choose_from.includes(this.state.chord_type) ||
                'Root' == this.state.chord_type ||
                scale_types_to_choose_from.includes(this.state.chord_type) ||
                this.state.chord_type.includes('Spell')
            ) {
                strum_checkbox = ''
                chord_scale_show_options = chord_or_scale_options
            }
        } else if (this.state.chord_type.includes('Spell')) {
            chord_scale_show_options = <p>{strum_checkbox}</p>
        }


        var intervals_relative_to_scale_root_checkbox = <label>
            <input type="checkbox"
                   name="intervals_relative_to_scale_root"
                   value="intervals_relative_to_scale_root"
                   checked={this.state.intervals_relative_to_scale_root}
                   onClick={this.onIntervalsRelativeChange}
                   label="intervals_relative_to_scale_root"
            />&nbsp;Scale Root</label>

        var intervals_relative_to_chord_root_checkbox = <label>
            <input type="checkbox"
                   name="intervals_relative_to_chord_root"
                   value="intervals_relative_to_chord_root"
                   checked={!this.state.intervals_relative_to_scale_root}
                   onClick={this.onIntervalsRelativeChange}
                   label="intervals_relative_to_chord_root"
            />&nbsp;Chord Root</label>


        var relative_interval_options = <text>
            &nbsp;&nbsp;Display Intervals Relative
            To: {intervals_relative_to_chord_root_checkbox}&nbsp;&nbsp;{intervals_relative_to_scale_root_checkbox}
            <br/><br/>
            Chord Type: {chord_type_drop_down}&nbsp;&nbsp;Scale
            Type: {scale_type_drop_down}&nbsp;&nbsp;Interval: {chord_scale_interval_drop_down}
        </text>
        if (!chord_scale_types_to_choose_from.includes(this.state.chord_type)) {
            relative_interval_options = ''
        }


        list_of_roman_numeral_buttons = <p>{list_of_roman_numeral_buttons}</p>


        var microphone_input_options =
            <p>{microphone_checkbox}&nbsp;&nbsp;{microphone_hold}&nbsp;&nbsp;{mute_sounds_checkbox}&nbsp;&nbsp;&nbsp;&nbsp;{microphone_result}</p>
        var list_of_min_dist_drop_down = []
        for (var i = 0; i < 18; i++) {
            list_of_min_dist_drop_down.push(<option
                value={i}
                selected={this.state.available_minimum_interval_distance == i}
            >{i}</option>)
        }
        var list_of_max_dist_drop_down = []
        for (var i = 1; i < 32; i++) {
            list_of_max_dist_drop_down.push(<option
                value={i}
                selected={this.state.available_maximum_interval_distance == i}
            >{i}</option>)
        }

        var bandit_text = "Start Bandito"
        if (this.state.bandit_activated) {
            bandit_text = "Stop Bandito"
        }
        var bandit_toggle_button = <button type="button" className="bandit_toggle"
                                           onClick={this.onBanditToggleChange}>{bandit_text}</button>
        var bandit_restart_button = <button type="button" className="bandit_restart"
                                            onClick={() => this.onBanditRestartButton(true)}>Clear & Restart
            Bandito</button>
        var bandit_dashboard_button = <button id="see_dashboard"
                                              onClick={() =>
                                                  window.open(
                                                      `http://banditoapi.com/model_info.html?access_key=U2FsdGVkX182UmfFShYPvfqle%2F3UhN0NTtmj5i6cDDPQBkrPohHwWS2jTi9gFaNb6T1Kk0mcGPfxLQfMN%2FUaCw%3D%3D&model_id=${encodeURIComponent(getModelIdFromState(this.state))}&app_id=fret_ferret`,
                                                      '_blank' // <- This is what makes it open in a new window.
                                                  )}>
            Go To Dashboard
        </button>

        // console.log('bandit_results:')
        // console.dir(bandit_results)

        var progress_div_style = {'vertical-align': 'middle'}

        //                                    <button type="button" className="bandit_difficulty_default" 
        //                                    value = {100 - bandit_spread}
        //                                    onClick={payload => {
        //                                        this.onBanditDifficultyLevelChange(payload, true)
        //                                    }}>Default</button>

        if (this.state.bandit_activated) {
            var progress_bar_style = {'width': '250px'}
            progress_div_style['float'] = 'right'
            var bandit_buttons = <p>{bandit_toggle_button}&nbsp;{bandit_restart_button}&nbsp;{bandit_dashboard_button}
            </p>
        } else {
            var bandit_buttons = <p>
                {bandit_toggle_button}&nbsp;{bandit_restart_button}&nbsp;{bandit_dashboard_button}
            </p>
        }


        var bandit_results = ''

        if (this.state.processed_bandit_results != null || this.state.processed_bandit_results != undefined) {
            bandit_results = this.state.processed_bandit_results
        }

        function consoleDebug() {
            debugger
        }

        bandit_results = <p>
            <button value="hide_bandit_results" onClick={this.onShowBanditResultsChange}>Hide Diagnostic Information
            </button>
            {bandit_results}
        </p>

        if (!this.state.show_bandit_results) {
            bandit_results = <p>
                <button value="show_bandit_results" onClick={this.onShowBanditResultsChange}>Show Diagnostic
                    Information
                </button>
            </p>
        }
        if (!this.state.bandit_activated || !this.state.bandit_results_exists) {
            bandit_results = ''
        }

        var bandit_header = [
            <b>AI Acceleration Powered by <a href="https://www.koyotescience.com/products"
                                             target="_blank">Bandito</a>:</b>,
            <br/>
        ]

        if (!game_types_with_bandits.includes(this.state.chord_type)) {
            bandit_toggle_button = ''
            bandit_restart_button = ''
            bandit_dashboard_button = ''
            bandit_buttons = ''
            bandit_results = ''
            bandit_header = ''
        }

// console.log('this.state.bandit_results during render(): ' + this.state.bandit_results)
// console.log('bandit_results during render(): ' + bandit_results)
// console.log('bandit_results second element during render(): ' + bandit_results[1])

        var game_type = this.state.chord_type
        if (chord_types_to_choose_from.includes(game_type) || 'Root' == game_type) {
            game_type = 'Show Chord'
        }
        if (scale_types_to_choose_from.includes(game_type)) {
            game_type = 'Show Scale'
        }
        if (chord_scale_types_to_choose_from.includes(game_type)) {
            game_type = 'Show Chord & Scale'
        }

        var answer_or_root = 'Answer'
        if (game_type.includes('Spell')) {
            answer_or_root = 'Root'
        }

        var available_answer_note_settings = <p>
            <b>Available {answer_or_root} Notes:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_answer_notes"
                       value="All"
                       checked={testMatch(this.state.available_answer_notes, notes_to_choose_from)}
                       onClick={this.onAvailableAnswerNotesChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_answer_notes"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableAnswerNotesChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_answer_notes"
                       value="Naturals"
                       checked={testAll(naturals_to_choose_from, this.state.available_answer_notes)}
                       onClick={this.onAvailableAnswerNotesChange}
                       label="Naturals"
                />&nbsp;Naturals</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_answer_notes"
                       value="Sharps"
                       checked={testAll(sharps_to_choose_from, this.state.available_answer_notes)}
                       onClick={this.onAvailableAnswerNotesChange}
                       label="Sharps"
                />&nbsp;Sharps</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_answer_notes"
                       value="Flats"
                       checked={testAll(flats_to_choose_from, this.state.available_answer_notes)}
                       onClick={this.onAvailableAnswerNotesChange}
                       label="Flats"
                />&nbsp;Flats</label>&nbsp;&nbsp;
            {tonic_input_answer_note_drop_down}&nbsp;&nbsp;
            {scale_type_input_answer_note_drop_down}
            &nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_notes_checkboxes}
        </p>

        var available_answer_interval_settings = <p>
            <b>Available Intervals: </b><br/>
            <i>Types:</i><br/>
            <label>
                <input type="checkbox"
                       name="interval_type"
                       value="All"
                       checked={testMatch(this.state.available_interval_types, default_available_interval_types)}
                       onClick={this.onAvailableIntervalTypesChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="interval_type"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableIntervalTypesChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="3rds & 6ths"
                       value="3rds & 6ths"
                       checked={testMatch([3, 4, 8, 9], this.state.available_interval_types)}
                       onClick={this.onAvailableIntervalTypesChange}
                       label="3rds & 6ths"
                />&nbsp;3rds & 6ths</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="Major"
                       value="Major"
                       checked={testMatch([0, 2, 4, 5, 7, 9, 11], this.state.available_interval_types)}
                       onClick={this.onAvailableIntervalTypesChange}
                       label="Major"
                />&nbsp;Major</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="Minor>"
                       value="Minor"
                       checked={testMatch([0, 2, 3, 5, 7, 8, 10], this.state.available_interval_types)}
                       onClick={this.onAvailableIntervalTypesChange}
                       label="Minor"
                />&nbsp;Minor</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_interval_types_checkboxes}<br/>
            <i>Octaves:</i><br/>
            <label>
                <input type="checkbox"
                       name="available_octave_jumps"
                       value="All"
                       checked={this.state.available_octave_jumps.length == all_available_octave_jumps.length}
                       onClick={this.onAvailableOctaveJumpsChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_octave_jumps"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableOctaveJumpsChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_octave_jumps_checkboxes}
            <br/>
            <i>Minimum Interval Distance:</i>&nbsp;&nbsp;
            <select name="minimum_distance" id="minimum_distance"
                    onChange={this.onMinimumDistanceChange}>
                {list_of_min_dist_drop_down}
            </select><br/>
            <i>Maximum Interval Distance:</i>&nbsp;&nbsp;
            <select name="maximum_distance" id="maximum_distance"
                    onChange={this.onMaximumDistanceChange}>
                {list_of_max_dist_drop_down}
            </select>< br/>
            <i>Interval Group Length:</i>&nbsp;&nbsp;
            <select name="interval_group_length" id="interval_group_length"
                    onChange={this.onIntervalGroupLengthChange}>
                {list_of_interval_group_lengths_drop_down}
            </select>
        </p>

        var available_answer_string_settings = <p>
            <b>Available {answer_or_root} Strings:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_answer_note_strings"
                       value="All"
                       checked={testMatch(this.state.available_answer_note_strings, default_available_answer_note_strings)}
                       onClick={this.onAvailableStringsChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_answer_note_strings"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableStringsChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_strings_checkboxes}
        </p>

        var available_answer_fret_settings = <p>
            <b>Available {answer_or_root} Frets:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_answer_note_frets"
                       value="All"
                       checked={testMatch(this.state.available_reference_note_frets, all_available_reference_note_frets)}
                       onClick={this.onAvailableAnswerNoteFretsChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_answer_note_frets"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableAnswereNoteFretsChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_answer_note_frets"
                       value="Without Open"
                       checked={testMatch(this.state.available_answer_note_frets, default_available_answer_note_frets)}
                       onClick={this.onAvailableAnswerNoteFretsChange}
                       label="Without Open"
                />&nbsp;Without Open</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_answer_note_frets"
                       value="Middle 6"
                       checked={testAll([4, 5, 6, 7, 8, 9], this.state.available_answer_note_frets)}
                       onClick={this.onAvailableAnswerNoteFretsChange}
                       label="Middle 6"
                />&nbsp;Middle 6</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_answer_note_frets_checkboxes}
        </p>

        var available_answer_zone_size_settings = <p>
            <b>Available Answer Zone Sizes:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_zone_sizes"
                       value="1"
                       checked={this.state.available_zone_sizes.includes(1)}
                       onClick={this.onAvailableZoneSizesChange}
                       label="1"
                />&nbsp;1</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_zone_sizes"
                       value="2"
                       checked={this.state.available_zone_sizes.includes(2)}
                       onClick={this.onAvailableZoneSizesChange}
                       label="2"
                />&nbsp;2</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_zone_sizes"
                       value="3"
                       checked={this.state.available_zone_sizes.includes(3)}
                       onClick={this.onAvailableZoneSizesChange}
                       label="3"
                />&nbsp;3</label>&nbsp;&nbsp;
        </p>

        var available_reference_note_string_settings = <p>
            <b>Available Reference Note Strings:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_reference_note_strings"
                       value="All"
                       checked={testMatch(this.state.available_reference_note_strings, default_available_reference_note_strings)}
                       onClick={this.onAvailableReferenceNoteStringsChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_reference_note_strings"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableReferenceNoteStringsChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_reference_note_strings_checkboxes}
        </p>

        var available_reference_note_fret_settings = <p>
            <b>Available Reference Note Frets:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_reference_note_frets"
                       value="All"
                       checked={testMatch(this.state.available_reference_note_frets, all_available_reference_note_frets)}
                       onClick={this.onAvailableReferenceNoteFretsChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_reference_note_frets"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableReferenceNoteFretsChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_reference_note_frets"
                       value="Without Open"
                       checked={testMatch(this.state.available_reference_note_frets, default_available_reference_note_frets)}
                       onClick={this.onAvailableReferenceNoteFretsChange}
                       label="Without Open"
                />&nbsp;Without Open</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_reference_note_frets"
                       value="Middle 6"
                       checked={testAll([4, 5, 6, 7, 8, 9], this.state.available_reference_note_frets)}
                       onClick={this.onAvailableReferenceNoteFretsChange}
                       label="Middle 6"
                />&nbsp;Middle 6</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_reference_note_frets_checkboxes}
        </p>

        var available_answer_note_reference_note_fret_distance_settings = <p>
            <b>Available Fret Distances:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_note_reference_note_distances"
                       value="All"
                       checked={testMatch(this.state.available_note_reference_note_distances, all_available_note_reference_note_distances)}
                       onClick={this.onAvailableNoteReferenceNoteDistancesChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_note_reference_note_distances"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableNoteReferenceNoteDistancesChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_note_reference_note_distances"
                       value="Default"
                       checked={testMatch(this.state.available_note_reference_note_distances, default_available_note_reference_note_distances)}
                       onClick={this.onAvailableNoteReferenceNoteDistancesChange}
                       label="Default"
                />&nbsp;Default</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_note_reference_note_distances"
                       value="Extremes"
                       checked={testMatch(this.state.available_note_reference_note_distances, extremes_available_note_reference_note_distances)}
                       onClick={this.onAvailableNoteReferenceNoteDistancesChange}
                       label="Extremes"
                />&nbsp;Extremes</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_note_reference_note_distances_checkboxes}
        </p>

        var available_answer_note_reference_note_string_distance_settings = <p>
            <b>Available String Distances:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_note_reference_note_string_distances"
                       value="All"
                       checked={testMatch(this.state.available_note_reference_note_string_distances, all_available_note_reference_note_string_distances)}
                       onClick={this.onAvailableNoteReferenceNoteStringDistancesChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_note_reference_note_string_distances"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableNoteReferenceNoteStringDistancesChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_note_reference_note_string_distances"
                       value="Default"
                       checked={testMatch(this.state.available_note_reference_note_string_distances, default_available_note_reference_note_string_distances)}
                       onClick={this.onAvailableNoteReferenceNoteStringDistancesChange}
                       label="Default"
                />&nbsp;Default</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_note_reference_note_string_distances"
                       value="Extremes"
                       checked={testMatch(this.state.available_note_reference_note_string_distances, extremes_available_note_reference_note_string_distances)}
                       onClick={this.onAvailableNoteReferenceNoteStringDistancesChange}
                       label="Extremes"
                />&nbsp;Extremes</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_note_reference_note_string_distances_checkboxes}
        </p>

        var available_reference_note_settings = <p>
            <b>Available Reference Notes:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_reference_notes"
                       value="All"
                       checked={testMatch(this.state.available_reference_notes, reference_notes_to_choose_from)}
                       onClick={this.onAvailableReferenceNotesChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_reference_notes"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableReferenceNotesChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_reference_notes"
                       value="Naturals"
                       checked={testAll(naturals_to_choose_from, this.state.available_reference_notes)}
                       onClick={this.onAvailableReferenceNotesChange}
                       label="Naturals"
                />&nbsp;Naturals</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_reference_notes"
                       value="Accidentals"
                       checked={testAll(sharps_to_choose_from, this.state.available_reference_notes)}
                       onClick={this.onAvailableReferenceNotesChange}
                       label="Accidentals"
                />&nbsp;Accidentals</label>&nbsp;&nbsp;
            {tonic_input_reference_note_drop_down}&nbsp;&nbsp;
            {scale_type_input_reference_note_drop_down}
            &nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_reference_notes_checkboxes}
        </p>

        var available_chord_quality_settings = <p>
            <b>Available Chord Qualities:</b><br/>
            <i>Overall:</i>&nbsp;&nbsp;<br/>
            <label>
                <input type="checkbox"
                       name="available_chord_types"
                       value="All"
                       checked={testMatch(this.state.available_chord_types, chord_types_to_choose_from)}
                       onClick={this.onAvailableChordTypesChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_chord_types"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableChordTypesChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            <i>Category 1:</i>&nbsp;&nbsp;<br/> {list_of_available_chord_qualities_checkboxes}<br/>
            <i>Category 2:</i>&nbsp;&nbsp;<br/>{list_of_available_chord_qualities2_checkboxes}<br/>
            <i>Detailed:</i><br/>{list_of_available_chord_types_checkboxes}
        </p>

        var available_scale_settings = <p>
            <b>Available Scales:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_scales"
                       value="All"
                       checked={testMatch(this.state.available_scales, scale_types_to_play_to_choose_from)}
                       onClick={this.onAvailableScalesChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_scales"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableScalesChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_scales_checkboxes}
        </p>

        var available_game_settings = <p>
            <b>Available Games:</b><br/>
            <label>
                <input type="checkbox"
                       name="available_game_types"
                       value="All"
                       checked={testMatch(this.state.available_game_types, game_types_with_meta_mode)}
                       onClick={this.onAvailableGameTypesChange}
                       label="All"
                />&nbsp;All</label>&nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="available_game_types"
                       value="None"
                       checked={false}
                       onClick={this.onAvailableGameTypesChange}
                       label="None"
                />&nbsp;None</label>&nbsp;&nbsp;&nbsp;&nbsp;<br/>
            {list_of_available_game_types_checkboxes}
        </p>

// var default_available_game_types = [
//     "Interval Find", "Interval Find 1", "Interval Find Zone",
//     "Interval Find Sequence", "Interval Find 3", "Note Find", "Note Find Zone", "Note Find 1", "Note Find 5",
//     "Note + Interval Find", "Note + Interval Find Zone", "Note + Interval Find 1",
//     "Note + Interval Find 5", "Interval Group Spell", "Chord Spell", "Scale Spell",
//     "Interval Chord Spell", "Interval Scale Spell", "Show Chord", "Show Scale", "Show Chord & Scale"
// ]


        var answer_note_settings = [
            'available_answer_string_settings',
            'available_answer_fret_settings',
            'available_answer_note_settings'
        ]

        var reference_note_settings = [
            'available_reference_note_string_settings',
            'available_reference_note_fret_settings',
            'available_reference_note_settings',
            'available_answer_note_reference_note_fret_distance_settings',
            'available_answer_note_reference_note_string_distance_settings'
        ]

        var note_interval_settings = [
            'available_answer_interval_settings',
            'available_interval_to_answer_separator',
            'available_answer_string_settings',
            'available_answer_fret_settings',
            'available_answer_note_settings',
            'available_reference_note_settings'
        ]

        var note_interval_anywhere_settings = [
            'available_answer_interval_settings',
            'available_interval_to_answer_separator',
            'available_reference_note_settings'
        ]

        var interval_settings = answer_note_settings.concat(reference_note_settings).concat([
            'available_answer_interval_settings',
            'available_interval_to_answer_separator',
            'available_answer_to_reference_separator'
        ])

        var interval_only_settings = [
            'available_answer_interval_settings',
            'available_interval_to_answer_separator',
            'available_reference_note_string_settings',
            'available_reference_note_fret_settings',
            'available_reference_note_settings',
        ]

        var map_game_type_to_available_settings = {
            "Interval Find": interval_settings,
            "Interval Find 1": interval_only_settings,
            "Interval Find Zone": interval_settings.concat([
                'available_answer_zone_size_settings',
                'available_reference_to_zone_separator'
            ]),
            "Interval Find Sequence": ['available_answer_interval_settings'],
            "Interval Find 3": interval_only_settings,
            "Note Find": answer_note_settings,
            "Note Find Zone": answer_note_settings.concat([
                'available_answer_zone_size_settings',
                'available_reference_to_zone_separator'
            ]),
            "Note Find 1": ['available_answer_note_settings'],
            "Note Find 5": ['available_answer_note_settings'],
            "Note + Interval Find": note_interval_settings,
            "Note + Interval Find Zone": note_interval_settings.concat([
                'available_answer_zone_size_settings',
                'available_reference_to_zone_separator']),
            "Note + Interval Find 1": note_interval_anywhere_settings,
            "Note + Interval Find 5": note_interval_anywhere_settings,
            "Interval Group Spell": interval_only_settings,
            "Chord Spell": [
                'available_answer_note_settings',
                'available_chord_quality_settings',
                'available_reference_to_zone_separator'
            ],
            "Scale Spell": [
                'available_answer_note_settings',
                'available_scale_settings',
                'available_reference_to_zone_separator'
            ],
            "Interval Chord Spell": answer_note_settings.concat([
                'available_chord_quality_settings',
                'available_reference_to_zone_separator'
            ]),
            "Interval Scale Spell": answer_note_settings.concat([
                'available_scale_settings',
                'available_reference_to_zone_separator'
            ]),
            "Show Chord": [],
            "Show Scale": [],
            "Show Chord & Scale": []
        }


        var available_interval_to_answer_separator = <hr/>
        var available_answer_to_reference_separator = <hr/>
        var available_reference_to_zone_separator = <hr/>
        var available_zone_to_game_seperator = <hr/>
        if (!map_game_type_to_available_settings[game_type].includes('available_answer_interval_settings')) {
            available_answer_interval_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_answer_string_settings')) {
            available_answer_string_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_answer_fret_settings')) {
            available_answer_fret_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_answer_zone_size_settings')) {
            available_answer_zone_size_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_answer_note_settings')) {
            available_answer_note_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_reference_note_string_settings')) {
            available_reference_note_string_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_reference_note_fret_settings')) {
            available_reference_note_fret_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_answer_note_reference_note_fret_distance_settings')) {
            available_answer_note_reference_note_fret_distance_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_answer_note_reference_note_string_distance_settings')) {
            available_answer_note_reference_note_string_distance_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_reference_note_settings')) {
            available_reference_note_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_chord_quality_settings')) {
            available_chord_quality_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_scale_settings')) {
            available_scale_settings = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_interval_to_answer_separator')) {
            available_interval_to_answer_separator = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_answer_to_reference_separator')) {
            available_answer_to_reference_separator = ''
        }
        if (!map_game_type_to_available_settings[game_type].includes('available_reference_to_zone_separator')) {
            available_reference_to_zone_separator = ''
        }

        if (!this.state.meta_mode) {
            available_game_settings = ''
            available_zone_to_game_seperator = ''
        }

// from https://www.w3schools.com/howto/howto_js_copy_clipboard.asp
        function copyToClipboard() {
            /* Get the text field */
            var copyText = document.getElementById("userIdInput");

            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Copy the text inside the text field */
            document.execCommand("copy");

            /* Alert the copied text */
            alert("Copied the User ID: " + copyText.value);
        }

        function getInputValue() {
            /* Get the text field */
            var copyText = document.getElementById("userIdInput");

            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /* For mobile devices */

            /* Extract the value field */
            return copyText.value
        }

        var bandit_user_id_input = <p>User ID: <input type="text" id='userIdInput'></input>&nbsp;
            <button onClick={() => {
                this.setState(
                    {user_id: getInputValue()},
                    this.onSkipButton
                )
            }}>Enter New User ID
            </button>
            <br/>
            <br/>
            <button onClick={copyToClipboard}>Copy User ID to Clipboard</button>
            &nbsp;&nbsp;
            <label>
                <input type="checkbox"
                       name="add_user_id_to_url"
                       value="add_user_id_to_url"
                       checked={this.state.add_user_id_to_url}
                       onClick={this.onUserIdUrlChange}
                       label="add_user_id_to_url"
                />&nbsp;Add User ID to URL</label>&nbsp;&nbsp;&nbsp;&nbsp;
            <button style={{
                'background': 'transparent',
                'border': 'none',
                'color': 'transparent'
            }} onClick={this.onBanditResultsToggle}>A
            </button>
        </p>

        var bandit_settings =
            <div>
                {bandit_header}
                {bandit_buttons}
                {bandit_user_id_input}
                {bandit_results}
            </div>

        var randomizer_settings =
            <div>
                {available_answer_interval_settings}
                {available_answer_note_reference_note_fret_distance_settings}
                {available_answer_note_reference_note_string_distance_settings}
                {available_interval_to_answer_separator}
                {available_answer_string_settings}
                {available_answer_fret_settings}
                {available_answer_note_settings}
                {available_answer_to_reference_separator}
                {available_reference_note_string_settings}
                {available_reference_note_fret_settings}
                {available_reference_note_settings}
                {available_reference_to_zone_separator}
                {available_answer_zone_size_settings}
                {available_chord_quality_settings}
                {available_scale_settings}
                {available_game_settings}
            </div>

        var main_settings_button_text;
        if (this.state.show_main_settings) {
            main_settings_button_text = "Hide Main Settings"
        } else {
            main_settings_button_text = "Show Main Settings"
        }


        var randomizer_settings_button_text;
        if (this.state.show_randomizer_settings) {
            randomizer_settings_button_text = "Hide Randomizer Settings"
        } else {
            randomizer_settings_button_text = "Show Randomizer Settings"
        }

        var list_of_game_types_drop_down = []

        var default_available_game_types_drop_down = [];
        for (i = 0; i < default_available_game_types.length; i++) {
            default_available_game_types_drop_down.push(<option
                value={default_available_game_types[i]}
                selected={game_type == default_available_game_types[i]}
            >{map_game_type_internal_to_external[default_available_game_types[i]]}</option>)
        }


        var list_of_reference_styles_drop_down = [];
        for (i = 0; i < reference_styles_to_choose_from.length; i++) {
            list_of_reference_styles_drop_down.push(<option
                value={reference_styles_to_choose_from[i]}
                selected={this.state.reference_style == reference_styles_to_choose_from[i]}
            >{reference_styles_to_choose_from[i]}</option>)
        }
        var list_of_meta_mode_repetitions_drop_down = [];
        for (i = 0; i < 10; i++) {
            list_of_meta_mode_repetitions_drop_down.push(<option
                value={i}
                selected={this.state.meta_mode_repetitions == i}
            >{i}</option>)
        }

        var meta_mode_repetitions_drop_down = [
            "Avg. # of Repetitions: ",
            <select name="meta_mode_repetitions" id="meta_mode_repetitions"
                    onChange={this.onMetaModeRepetitionsChange}>
                {list_of_meta_mode_repetitions_drop_down}
            </select>
        ]
        if (!this.state.meta_mode) {
            meta_mode_repetitions_drop_down = ''
        }

        var meta_mode_checkbox = <label>
            <input type="checkbox"
                   name="meta_mode"
                   value="meta_mode"
                   checked={this.state.meta_mode}
                   onClick={this.onMetaModeToggle}
                   label="meta_mode"
            />&nbsp;Meta Mode</label>
        if (!game_types_with_meta_mode.includes(this.state.chord_type)) {
            meta_mode_checkbox = ''
        }
        var main_settings;

        var chord_loop_instrument_options = <div>
            <i>Chord Loop Instrument Options</i>:
            <br/>
            <label htmlFor="drone_instrument">Drone Instrument: </label>
            <select value={this.state.selectedDroneInstrument}
                    onChange={this.onSelectDroneInstrument.bind(this)}>{this.createSelectItems()}</select>
            <br/>
            <label>Drone Volume: <input type="range" min="1" max="100" value={this.state.drone_volume}
                                        onChange={this.onDroneVolumeChange}/></label>
            <br/>
            <label>Drum Volume: <input type="range" min="1" max="100" value={this.state.drum_volume}
                                       onChange={this.onDrumVolumeChange}/></label>
            <br/><br/></div>

        if (!chord_scale_types_to_choose_from.includes(this.state.chord_type)) {
            chord_loop_instrument_options = ''
        }
        var radio_buttons =
            <div>
                <br/>
                <label for="game_type">Game: </label>
                <select name="game_type" id="game_type" onChange={this.onGameTypeChange}>
                    {default_available_game_types_drop_down}
                </select>
                &nbsp;&nbsp;{meta_mode_checkbox}&nbsp;
                {meta_mode_repetitions_drop_down}
                {show_chord_loop_button}&nbsp;&nbsp;{show_roman_numeral_button}&nbsp;&nbsp;{show_random_top_note_button}
                {chord_scale_show_options}
                {microphone_input_options}
                <label>
                    <input type="checkbox"
                           name="show_intervals"
                           value="show_intervals"
                           checked={this.state.show_intervals}
                           onClick={this.onShowIntervalsChange}
                           label="show_intervals"
                    />&nbsp;Show Intervals, Chord Qualities, and Scale Types</label>
                <br/>
                <label>
                    <input type="checkbox"
                           name="show_note_names"
                           value="show_note_names"
                           checked={this.state.show_note_names}
                           onClick={this.onShowNoteNamesChange}
                           label="show_note_names"
                    />&nbsp;Show Note Names</label>
                <br/>
                <label>
                    <input type="checkbox"
                           name="show_hints"
                           value="show_hints"
                           checked={this.state.show_hints}
                           onClick={this.onShowHintsChange}
                           label="show_hints"
                    />&nbsp;Show Hints</label>

                <br/>
                <br/>
                <label>
                    <input type="checkbox"
                           name="cut_audio"
                           value="cut_audio"
                           checked={!this.state.cut_audio}
                           onClick={this.onCutAudioChange}
                           label="show_hints"
                    />&nbsp;Allow Audio Overlap</label>

                <br/>
                <br/>
                <label for="tuning">Tuning: </label>
                <select name="tuning" id="tuning" onChange={this.onTuningChange}>
                    <option value="guitarlele" selected={this.state.tuning_name === "guitarlele"}>Guitarlele
                    </option>
                    <option value="guitarlele with guitar note names"
                            selected={this.state.tuning_name === "guitarlele with guitar note names"}>Guitarlele with
                        Guitar Note Names
                    </option>
                    <option value="guitar" selected={this.state.tuning_name === "guitar"}>Standard Guitar
                    </option>
                </select>
                <br/>
                <br/>
                <label for="reference_style">Reference Style: </label>
                <select name="reference_style" id="reference_style" onChange={this.onReferenceStyleChange}>
                    <option value="note" selected={this.state.reference_style === "note"}>Single Note</option>
                    {list_of_reference_styles_drop_down}
                    <option value="Random" selected={this.state.reference_style === "Random"}>Random</option>
                </select>

                <br/><br/><label for="instrument">Audio Instrument: </label>
                <select value={this.state.selectedInstrument}
                        onChange={this.onSelectInstrument.bind(this)}>{this.createSelectItems()}</select>
                <br/>
                <label>Volume: <input type="range" min="1" max="100" value={this.state.volume}
                                      onChange={this.onVolumeChange}/></label>
                <br/>
                <label>Echo: <input type="range" min="1" max="100" value={this.state.echo}
                                    onChange={this.onEchoChange}/></label>
                <br/>
                <br/>
                <label>Duration and delay between played notes: <input type="range" min="1" max="100"
                                                                       value={this.state.delay_between_notes}
                                                                       onChange={this.onDelayChange}/></label>
                <br/>
                <br/>{chord_loop_instrument_options}
            </div>


        if (!this.state.show_main_settings) {
            main_settings =
                <div style={{'width': "600px"}}>
                    <button type="button" class="padded_button"
                            onClick={this.onMainSettingsToggle}>Show Main Settings
                    </button>
                </div>

        } else {
            //<span style={{float: 'right'}}> <button type="button" onClick={this.onMainSettingsObliterate}>Obliterate</button>  </span>
            main_settings = <div class="settings_panel">
                <button type="button" onClick={this.onMainSettingsToggle}>Hide Main Settings</button>
                {radio_buttons}</div>
        }


        if (!this.state.show_randomizer_settings) {
            randomizer_settings =
                [<button type="button" class="padded_button"
                         onClick={this.onRandomizerSettingsToggle}>Show Randomizer Settings</button>, <br/>, <br/>]
        } else {
            //<span style={{float: 'right'}}> <button type="button" onClick={this.onRandomizerSettingsObliterate}>Obliterate</button>  </span>
            randomizer_settings = [<div class="settings_panel">
                <button type="button"
                        onClick={this.onRandomizerSettingsToggle}>Hide Randomizer Settings
                </button>

                {randomizer_settings}
            </div>, <br/>]
        }


        if (!this.state.show_bandit_settings) {
            bandit_settings =
                <button type="button" class="padded_button"
                        onClick={this.onBanditSettingsToggle}>Show Bandito Settings</button>
        } else {
            //<span style={{float: 'right'}}> <button type="button" onClick={this.onBanditSettingsObliterate}>Obliterate</button>  </span>
            bandit_settings = [<div class="settings_panel">
                <button type="button"
                        onClick={this.onBanditSettingsToggle}>Hide Bandito Settings
                </button>
                <br/><br/>
                {bandit_settings}
            </div>, <br/>]
        }

        var header;
        var interval_text = '';
        if (this.state.prev_correct_guess_prev_interval != 'undefined' && typeof (this.state.prev_correct_guess_prev_interval) != 'undefined') {
            interval_text = ' (' + this.state.prev_correct_guess_prev_interval + ')'
        }
        if (this.state.prev_correct_guess_prev_interval == 'undefined' ||
            typeof (this.state.prev_correct_guess_prev_interval) == 'undefined') {
            interval_text = ''
        }
        var hint_text = 'You chose ' + Note.pitchClass(this.state.prev_correct_guess_prev_note) + interval_text
        var tmp_time_diff = (this.state.prev_correct_guess_time_diff * 1.0 / 1000.0)
        var hint_text2 = ' and it took ' + tmp_time_diff.toFixed(1) + 's\u00a0\u00a0\u00a0'
        if (tmp_time_diff < 0.5 || tmp_time_diff > 100) {
            hint_text2 = '\u00a0\u00a0\u00a0'
        }
        hint_text = hint_text + hint_text2
        if (this.state.prev_correct_guess_prev_note == 'undefined' ||
            typeof (this.state.prev_correct_guess_prev_note) == 'undefined') {
            hint_text = '\u00a0\u00a0\u00a0'
        }

        var simpler_hint_text = 'Last guess took: ' + tmp_time_diff.toFixed(1) + 's\u00a0\u00a0\u00a0'


        var time_text = ' Current time to guess: ' + (this.state.show_time_diff_since_correct * 1.0 / 1000.0).toFixed(1) + 's'
        if (this.state.show_time_diff_since_correct < 0 || this.state.show_time_diff_since_correct > 100000) {
            time_text = ' Current time to guess: 0.0s'
        }

// if (
//     !(
//         "Root" == this.state.chord_type ||
//         chord_types_to_choose_from.includes(this.state.chord_type) ||
//         scale_types_to_choose_from.includes(this.state.chord_type) ||
//         chord_scale_types_to_choose_from.includes(this.state.chord_type)
//     )
// ) {
//     header = <div class="around_fretboard">
//         <p>{'Score: ' + this.state.number_correct_guesses + '\u00a0\u00a0\u00a0\u00a0Bad guesses: ' + this.state.number_bad_guesses + '\u00a0(' + this.state.number_bad_guesses_on_try + ')\u00a0\u00a0\u00a0\u00a0' + hint_text} </p>
//         <p>{time_text}</p>
//     </div>
// } else {
//     header = <div class="around_fretboard">
//         <p></p>
//     </div>
// }

        var header = []
        if (
            !(
                "Root" == this.state.chord_type ||
                chord_types_to_choose_from.includes(this.state.chord_type) ||
                scale_types_to_choose_from.includes(this.state.chord_type) ||
                chord_scale_types_to_choose_from.includes(this.state.chord_type)
            )
        ) {
            header.push(
                <p style={{'padding-left': '10px'}}>{'Score: ' + this.state.number_correct_guesses + '\u00a0\u00a0\u00a0\u00a0Bad guesses: ' +
                this.state.number_bad_guesses +
                (
                    this.state.number_correct_guesses + this.state.number_bad_guesses > 0 ?
                        ' (' + (this.state.number_bad_guesses * 100.0 / (this.state.number_correct_guesses + this.state.number_bad_guesses)).toFixed(0) + '%)' :
                        ''
                ) + '\u00a0\u00a0\u00a0\u00a0' + simpler_hint_text} <span style={{'float': 'right'}}>{
                    time_text}</span></p>
            )
        }

        if (this.state.bandit_activated && this.state.bandit_results != null) {
            // <br />
            //         <label><input type="checkbox"
            //                name="bandit_difficulty_default"
            //                value={100 - bandit_spread}
            //                checked={this.state.bandit_difficulty_level == 100 - bandit_spread}
            //                onClick={payload => this.onBanditDifficultyLevelChange(payload, true)} 
            //                label="Default"
            //         /> Default
            //         ({Math.round((100 - bandit_spread + bandit_spread) * 100.0 / (2 * bandit_spread + 100.0))}%)</label>
            header.push(<p>
                <div style={{'width': '300px', 'float': 'left', 'padding-left': '10px'}}>
                    Difficulty
                    Level: {Math.round((this.state.bandit_difficulty_level + bandit_spread) * 100.0 / (2 * bandit_spread + 100.0))}%
                    <br/>
                    <input type="range"
                           min={-bandit_spread} max={100 + bandit_spread}
                           value={this.state.bandit_difficulty_level}
                           style={progress_bar_style}
                           onChange={this.onBanditDifficultyLevelChange}
                           onPointerUp={payload => this.onBanditDifficultyLevelChange(payload, true)}
                    />

                </div>
                <div
                    style={{'vertical-align': 'middle', 'float': 'right'}}>
                    Training
                    Progress: {Math.round(this.state.bandit_results == null ? 0 : this.state.bandit_results.response.body.progress * 100)}%
                    <br/>
                    <progress id="bandit_progress"
                              value={this.state.bandit_results == null ? 0 : this.state.bandit_results.response.body.progress * 100}
                              style={{'width': '250px'}}
                              max="100"></progress>
                </div>
                <br/><br/><br/></p>)
        }

        header = <div style={{'min-width': '680px'}}>{header}</div>

        console.log('render this.state.show_chord_loop: ' + this.state.show_chord_loop)


        var list_of_roots_drop_down = []
        var tmp_prev_note = this.state.prev_note
        if (this.state.tuning_name != 'guitarlele with guitar note names') {
            for (i = 0; i < possible_pitch_class_values_simplified.length; i++) {
                list_of_roots_drop_down.push(<option
                    value={possible_pitch_class_values_simplified[i]}
                    selected={getPitchClassInt(this.state.prev_note) == getPitchClassInt(possible_pitch_class_values_simplified[i])}
                >{possible_pitch_class_values_simplified[i]}</option>)
            }
        } else {
            for (i = 0; i < possible_pitch_class_values_simplified.length; i++) {
                list_of_roots_drop_down.push(<option
                    value={map_enharmonics[Note.simplify(Note.pitchClass(Note.transpose(possible_pitch_class_values_simplified[i], 'P4')))]}
                    selected={getPitchClassInt(Note.transpose(this.state.prev_note, 'P5')) == getPitchClassInt(possible_pitch_class_values_simplified[i])}
                >{possible_pitch_class_values_simplified[i]}</option>)
            }
        }
        var root_drop_down =
            <select name="root_drop_down" id="root_drop_down"
                    onChange={this.onRootChange}>
                {list_of_roots_drop_down}
            </select>

        if (chord_scale_types_to_choose_from.includes(this.state.chord_type)) {
            header = <div class="around_fretboard">
                <p>{random_root_button}&nbsp;&nbsp;Root: {root_drop_down}&nbsp;&nbsp;{relative_interval_options}{random_top_note_options}{chord_loop_options}{list_of_roman_numeral_buttons}</p>
            </div>
        } else if (chord_types_to_choose_from.includes(this.state.chord_type) || this.state.chord_type == 'Root') {
            header =
                <div class="around_fretboard"><p>{random_root_button}&nbsp;&nbsp;Root: {root_drop_down}&nbsp;&nbsp;Chord
                    Type: {chord_type_drop_down}</p></div>
        } else if (scale_types_to_choose_from.includes(this.state.chord_type)) {
            header =
                <div class="around_fretboard"><p>{random_root_button}&nbsp;&nbsp;Root: {root_drop_down}&nbsp;&nbsp;Scale
                    Type: {scale_type_drop_down}</p></div>
        }


//    if (this.state.reset_after_won_sequence) {
//        header = [header, <div><p>Great job!</p></div>]
//    }

        if (!this.state.main_settings_exists) {
            main_settings = ''
        }
        if (!this.state.randomizer_settings_exists) {
            randomizer_settings = ''
        }
        if (!this.state.bandit_settings_exists) {
            bandit_settings = ''
        }
        if (!game_types_with_bandits.includes(this.state.chord_type)) {
            bandit_settings = ''
        }

        if ('Root' == this.state.chord_type ||
            chord_types_to_choose_from.includes(this.state.chord_type) ||
            scale_types_to_choose_from.includes(this.state.chord_type) ||
            chord_scale_types_to_choose_from.includes(this.state.chord_type)
        ) {
            var footer = [header, main_settings]
        } else {
            var footer = [header, main_settings, <br/>, randomizer_settings, bandit_settings]
        }
        footer.push(<p
            style={{'text-align': 'center'}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <a href="http://www.koyotescience.com" target="_blank">
                <img src={footer_logo} style={{"max-width": "200px", "height": "auto"}}></img>
            </a></p>)

        for (i = 0; i < 100; i++) {
            footer.push(<br/>)
        }


// var logo_and_name = <h3>Fretboard Visualization Tool by <a href="http://www.koyotescience.com">Koyote
//         Science,
//         LLC</a></h3>
        var logo_style = {'max-width': '100%', 'height': 'auto'}
        var logo_and_name = <a href='https://www.koyotescience.com/fret-ferret' target="_blank"><img style={logo_style}
                                                                                                     src={logo}
                                                                                                     alt="Fret Ferret"/></a>

        var header = []
        var logo_style = {"min-width": "680px", "max-width": "700px"}
        if (this.state.main_settings_exists) {
            header.push(<div class="around_fretboard">{logo_and_name}
                <h4>{map_game_type_internal_to_external[game_type]}</h4></div>)
        } else {
            header.push(<div class="around_fretboard">{logo_and_name}</div>)
        }

        if (this.state.tuning_name == 'guitarlele with guitar note names') {
            var scale_root_pitch_class = Note.pitchClass(Note.transpose(this.state.prev_note, 'P5'))
        } else {
            var scale_root_pitch_class = Note.pitchClass(this.state.prev_note)
        }
        var show_prev_note = map_enharmonics[Note.simplify(scale_root_pitch_class)]
        if (show_prev_note == '' || show_prev_note == 'undefined' || show_prev_note == undefined) {
            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var show_prev_note = 'G'
            } else {
                var show_prev_note = 'C'
            }
        }
// {freeze_button}&nbsp;&nbsp;
// {map_chord_type_to_message[this.state.chord_type]}<br/><br/>
        if (chord_types_to_choose_from.includes(this.state.chord_type)) {
            header.push(<div class="around_fretboard"><p>
                {spell_button}&nbsp;&nbsp;
                <b>{'' + show_prev_note + ' ' + this.state.chord_type}</b> on the {this.state.tuning_name}</p>
            </div>)
        } else if (chord_scale_types_to_choose_from.includes(this.state.chord_type)) {

            var possible_array_of_info_from_state_chord_type = this.state.chord_type.split('|')
            var chord_type = possible_array_of_info_from_state_chord_type[0]
            var scale_type = possible_array_of_info_from_state_chord_type[1]
            var chord_scale_interval = parseInt(possible_array_of_info_from_state_chord_type[2])

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var scale_root_pitch_class = map_enharmonics[Note.simplify(Note.pitchClass(Note.transpose(this.state.prev_note, 'P5')))]
            } else {
                var scale_root_pitch_class = map_enharmonics[Note.simplify(Note.pitchClass(this.state.prev_note))]
            }
            if (scale_root_pitch_class == undefined || scale_root_pitch_class == 'undefined' || scale_root_pitch_class == '') {
                if (this.state.tuning_name == 'guitarlele with guitar note names') {
                    var scale_root_pitch_class = 'G'
                } else {
                    var scale_root_pitch_class = 'C'
                }
            }

            var chord_root_pitch_class = Note.transpose(
                scale_root_pitch_class + '3',
                Interval.fromSemitones(chord_scale_interval)
            )
            var show_chord_root = Note.simplify(Note.pitchClass(chord_root_pitch_class))

            show_chord_root = map_tonic_to_interval_int_to_note_name[scale_root_pitch_class][chord_scale_interval]


            // if (scale_root_pitch_class.includes('b') || scale_root_pitch_class == 'F') {
            //     show_chord_root = map_enharmonics_flats[show_chord_root]
            // } else if (scale_root_pitch_class == 'C') {
            //     show_chord_root = map_enharmonics[show_chord_root]
            // } else {
            //     show_chord_root = map_enharmonics_sharps[show_chord_root]
            // }

            var message_to_show = map_chord_type_to_message[this.state.chord_type]
            if (this.state.show_roman_numeral_buttons) {
                message_to_show += ". Use the roman numeral buttons to change the chord relative to the scale"
            }
            if (valid_chord_loop_length > 0) {
                message_to_show += ". You can also start and stop the pre-selected chord loop"
            }
            message_to_show += '.'
            // {freeze_button}&nbsp;&nbsp;
            // {message_to_show}<br/><br/>
            header.push(<div class="around_fretboard"><p>
                {activated_button_with_chord_loop_text}{spell_button}&nbsp;&nbsp;
                <b>{'' + show_prev_note + ' ' + scale_type + ' with ' + show_chord_root + ' ' + chord_type}</b></p>
            </div>)
        } else if ('Root' == this.state.chord_type) {
            // {freeze_button}&nbsp;&nbsp;
            // {map_chord_type_to_message[this.state.chord_type]}<br/><br/>
            header.push(<div class="around_fretboard">
                <p>{spell_button}&nbsp;&nbsp;
                    <b>{'' + show_prev_note}</b> on
                    the {this.state.tuning_name}</p></div>)
        } else if (scale_types_to_choose_from.includes(this.state.chord_type)) {
            // {freeze_button}&nbsp;&nbsp;
            // {map_chord_type_to_message[this.state.chord_type]}<br/><br/>
            header.push(<div class="around_fretboard">
                <p>
                    <b>{spell_button}&nbsp;&nbsp;{'' + show_prev_note + ' ' + this.state.chord_type}</b> on
                    the {this.state.tuning_name}</p></div>)
        } else {
            // {map_chord_type_to_message[this.state.chord_type]} on
            //                 the {this.state.tuning_name}
            header.push(<div class="around_fretboard"><p></p>
            </div>)
        }
        var color = "#117083";


        header.unshift(
            <style>{"div {max-width:700px; user-select: none; -webkit-user-select: none;} div.wrapper {padding: 10px} div.around_fretboard {margin-left: 10px; min-width: 680px; max-width: 700px} div.no_touch {min-width: 680px; max-width:700px; touch-action: none} div.hidden {display: none;} div.settings_panel{min-width: 665px; margin-left: 5px; padding: 5px; outline: 1px solid black;}"}</style>)
        header.unshift(<style>{"label {white-space: nowrap;}"}</style>)
        header.unshift(<style>{".button.red {color: #117083;}"}</style>)
        header.unshift(<style>{"input.slider[type=range] {\n" +
        "  -webkit-appearance: none;\n" +
        "  margin: 5px 0;\n" +
        "}\n" +
        "input.slider[type=range]:focus {\n" +
        "  outline: none;\n" +
        "}" + "\n" +
        "input.slider[type=range]::-webkit-slider-runnable-track {\n" +
        "  width: 100%;\n" +
        "  height: 5px;\n" +
        "  cursor: pointer;\n" +
        "  background: " + hsl_col_perc(Math.min(Math.abs(this.state.microphone_input_cents_offset_trailing_average) * 4, 100), 120, 0) + ";\n" + //3071A9
        "  border-radius: 2px;\n" +
        "  border: 1px solid #000000;\n" +
        "}\n" +
        "input.slider[type=range]::-webkit-slider-thumb {\n" +
        "  border: 1px solid #000000;\n" +
        "  height: 15px;\n" +
        "  width: 10px;\n" +
        "  border-radius: 2px;\n" +
        "  background: " + hsl_col_perc(Math.min(Math.abs(this.state.microphone_input_cents_offset_trailing_average) * 4, 100), 120, 0) + ";\n" + //3071A9
        "  cursor: pointer;\n" +
        "  -webkit-appearance: none;\n" +
        "  margin-top: -7px;\n" +
        "}"}</style>)
        header.unshift(<style>{"button.padded_button {margin-left: 10px; margin-top: 5px;}"}</style>)
        footer.push(
            <div class='hidden'>
                <MIDISounds ref={(ref) => (this.midiSounds = ref)} appElementName="root" instruments={[254]}/>
            </div>
        )
        footer.push(
            <div className='hidden'>
                <MIDISounds ref={(ref) => (this.midiSounds2 = ref)} appElementName="root" instruments={[254]}/>
            </div>
        )
        footer.push(
            <div className='hidden'>
                <MIDISounds ref={(ref) => (this.midiSounds3 = ref)} appElementName="root" instruments={[254]}/>
            </div>
        )

        var interval_to_show = this.state.interval_to_play
        if (!this.state.show_intervals) {
            interval_to_show = default_speakers_text
        }
        var bad_candidate_warning;
        if (this.state.failed_to_get_candidate) {
            bad_candidate_warning =
                <div class="around_fretboard"><p>(no candidate matching randomizer settings could be found)</p></div>
        }
        if (this.state.chord_type == 'Interval Find Zone') {
            var fret1 = this.state.zone_to_play
            var fret2 = fret1 + this.state.zone_size_to_play - 1
            var color = "red"
            contents = [
                header,
                <div class="around_fretboard"><p>{skip_button}Choose the interval&nbsp;<b>{interval_to_show}</b> from
                    the red dot in the
                    red zone</p></div>,
                bad_candidate_warning,
                <div class="no_touch">
                    <Fretboard
                        tuning={this.state.tuning}
                        skinType="strings"
                        clickAction={this.handleClick}
                        clickOffAction={this.handleOffClick}
                        onTouchMoveAction={this.handleTouchMove}
                        selectedLocations={this.getSelectedLocations()}
                        selectedNotes={this.getSelectedNotesRender()}
                        theme={{statusMap: statusMapStatic}}
                        fretHighlight={[fret1, fret2]}
                        fretHighlightColor="red"
                    /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Note Find Zone') {
            var fret1 = this.state.zone_to_play
            var fret2 = fret1 + this.state.zone_size_to_play - 1
            var color = "red"


            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play, 'P5')))
            } else {
                var pitch_class_to_play = Note.pitchClass(this.state.pitch_class_to_play)
            }

            var pitch_class_to_show = pitch_class_to_play

            if (!this.state.show_note_names) {
                pitch_class_to_show = default_speakers_text
            }

            contents = [
                header,
                <div class="around_fretboard"><p> {skip_button}Choose the note <b>{pitch_class_to_show}</b> in the red
                    zone</p></div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                    fretHighlight={[fret1, fret2]}
                    fretHighlightColor="red"
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Note + Interval Find Zone') {
            var fret1 = this.state.zone_to_play
            var fret2 = fret1 + this.state.zone_size_to_play - 1
            var color = "red"

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play, 'P5')) + '8', Interval.fromSemitones(-this.state.interval_to_play_raw))))
            } else {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play + '8', Interval.fromSemitones(-this.state.interval_to_play_raw))))
            }

            var pitch_class_to_show = pitch_class_to_play
            if (!this.state.show_note_names) {
                pitch_class_to_show = default_speakers_text
            }
            var contents;

            contents = ([
                header,
                <div class="around_fretboard">
                    <p>{skip_button}Choose the interval&nbsp;<b>{interval_to_show}</b>
                        &nbsp;from the note&nbsp;
                        <b>{pitch_class_to_show}</b> in the red zone</p>
                </div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                    fretHighlight={[fret1, fret2]}
                    fretHighlightColor="red"
                /></div>,
                footer
            ])
        } else if (this.state.chord_type == 'Note Find') {

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play, 'P5')))
            } else {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(this.state.pitch_class_to_play))
            }

            var pitch_class_to_show = pitch_class_to_play

            if (!this.state.show_note_names) {
                pitch_class_to_show = default_speakers_text
            }
            contents = [
                header,
                <div class="around_fretboard"><p>{skip_button}Choose the note <b>{pitch_class_to_show}</b> on the red
                    string</p></div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedStrings={[this.getSelectedStrings()]}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Chord Spell') {

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play, 'P5')))
            } else {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(this.state.pitch_class_to_play))
            }

            var pitch_class_to_show = pitch_class_to_play
            if (!this.state.show_note_names) {
                pitch_class_to_show = default_speakers_text
            }
            var chord_type_to_show = this.state.chord_type_to_play
            if (!this.state.show_intervals) {
                chord_type_to_show = default_speakers_text
            }
            var chord_name = '' + pitch_class_to_show + ' ' + chord_type_to_show
            var exclusion_text = ''
            if (!chord_types_without_P5.concat(chord_types_with_necessary_P5).includes(this.state.chord_type_to_play)) {
                exclusion_text = " (5ths excluded)"
            }
            contents = [
                header,
                <div class="around_fretboard">
                    <p>{skip_button}{"Choose the " + this.getSelectedNotes(this.state.pitch_class_to_play, this.state.chord_type_to_play).length + " notes for "}
                        <b>{chord_name}</b>{exclusion_text}</p>
                </div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Scale Spell') {

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play, 'P5')))
            } else {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(this.state.pitch_class_to_play))
            }

            var pitch_class_to_show = pitch_class_to_play
            if (!this.state.show_note_names) {
                pitch_class_to_show = default_speakers_text
            }
            var chord_type_to_show = this.state.chord_type_to_play
            if (!this.state.show_intervals) {
                chord_type_to_show = default_speakers_text
            }
            var chord_name = '' + pitch_class_to_show + ' ' + chord_type_to_show
            contents = [
                header,
                <div class="around_fretboard">
                    <p>{skip_button}{"Choose the " + (this.getSelectedNotes(this.state.pitch_class_to_play, this.state.chord_type_to_play).length) + " notes for "}
                        <b>{chord_name}</b></p>
                </div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Interval Group Spell') {

            var chord_type_to_show = "[Random Interval Group]"
            var chord_name = chord_type_to_show
            contents = [
                header,
                <div class="around_fretboard">
                    <p>{skip_button}{"Choose the other " + (new Set(this.getSelectedNotes(Note.pitchClass(this.state.selected_notes), this.state.interval_group_array)).size - 1) + " notes for the given interval group"}</p>
                </div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Interval Chord Spell') {

            var chord_type_to_show = this.state.chord_type_to_play
            if (!this.state.show_intervals) {
                chord_type_to_show = default_speakers_text
            }
            var chord_name = chord_type_to_show
            var exclusion_text = ''
            if (!chord_types_without_P5.concat(chord_types_with_necessary_P5).includes(this.state.chord_type_to_play)) {
                exclusion_text = " (5ths excluded)"
            }
            contents = [
                header,
                <div class="around_fretboard">
                    <p>{skip_button}{"Choose the other " + (this.getSelectedNotes(Note.pitchClass(this.state.selected_notes), this.state.chord_type_to_play).length - 1) + " notes for the chord "}
                        <b>{chord_name}</b>{exclusion_text}</p>
                </div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Interval Scale Spell') {

            var chord_type_to_show = this.state.chord_type_to_play
            if (!this.state.show_intervals) {
                chord_type_to_show = default_speakers_text
            }
            var chord_name = chord_type_to_show
            contents = [
                header,
                <div class="around_fretboard">
                    <p>{skip_button}{"Choose the other " + (this.getSelectedNotes(this.state.pitch_class_to_play, this.state.chord_type_to_play).length - 1) + " notes for the scale "}
                        <b>{chord_name}</b>{exclusion_text}</p>
                </div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Note + Interval Find') {

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play, 'P5')) + '8', Interval.fromSemitones(-this.state.interval_to_play_raw))))
            } else {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play + '8', Interval.fromSemitones(-this.state.interval_to_play_raw))))
            }

            var pitch_class_to_show = pitch_class_to_play

            if (!this.state.show_note_names) {
                pitch_class_to_show = default_speakers_text
            }
            contents = [
                header,
                <div class="around_fretboard"><p>{skip_button}Choose the interval&nbsp;
                    <b>{interval_to_show}</b>&nbsp;from
                    the
                    note&nbsp;<b>{pitch_class_to_show}</b> on the red string</p></div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedStrings={[this.getSelectedStrings()]}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Note + Interval Find 1') {

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play, 'P5')) + '8', Interval.fromSemitones(-this.state.interval_to_play_raw))))
            } else {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play + '8', Interval.fromSemitones(-this.state.interval_to_play_raw))))
            }

            var pitch_class_to_show = pitch_class_to_play

            if (!this.state.show_note_names) {
                pitch_class_to_show = default_speakers_text
            }
            contents = [
                header,
                <div class="around_fretboard"><p>{skip_button}Choose the interval&nbsp;
                    <b>{interval_to_show}</b>&nbsp;from
                    the
                    note&nbsp;<b>{pitch_class_to_show}</b> anywhere on the fretboard</p></div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Note Find 1') {

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var pitch_class_to_play = Note.pitchClass(Note.transpose(this.state.pitch_class_to_play, 'P5'))
            } else {
                var pitch_class_to_play = Note.pitchClass(this.state.pitch_class_to_play)
            }

            var pitch_class_to_show = pitch_class_to_play

            if (!this.state.show_note_names) {
                pitch_class_to_show = default_speakers_text
            }
            contents = [
                header,
                <div class="around_fretboard"><p>{skip_button}Choose the note <b>{pitch_class_to_show}</b> anywhere on
                    the fretboard</p></div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Interval Find') {
            contents = [
                header,
                <div class="around_fretboard"><p>{skip_button}Choose the interval&nbsp;<b>{interval_to_show}</b> from
                    the red dot on the
                    red string</p></div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedStrings={[this.getSelectedStrings()]}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Interval Find 1') {
            contents = [
                header,
                <div class="around_fretboard"><p>{skip_button}Choose the interval&nbsp;
                    <b>{interval_to_show}</b> from the red dot anywhere on the fretboard</p></div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Interval Find 3') {
            contents = [
                header,
                <div class="around_fretboard"><p>{skip_button}Choose 3 copies of the
                    interval <b>{interval_to_show}</b> from the red dot anywhere on the fretboard</p>
                </div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Interval Find Sequence') {
            contents = [
                header,
                <div class="around_fretboard"><p>{skip_button}Find the interval <b>{interval_to_show}</b> from the red
                    dot anywhere on
                    the fretboard</p></div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Note Find 5') {

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play, 'P5')))
            } else {
                var pitch_class_to_play = Note.pitchClass(this.state.pitch_class_to_play)
            }

            var pitch_class_to_show = pitch_class_to_play
            if (!this.state.show_note_names) {
                pitch_class_to_show = default_speakers_text
            }
            contents = [
                header,
                <div class="around_fretboard"><p>{skip_button}Choose 5 copies of the
                    note <b>{pitch_class_to_show}</b> anywhere on the fretboard</p>
                </div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedStrings={[this.getSelectedStrings()]}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (this.state.chord_type == 'Note + Interval Find 5') {

            if (this.state.tuning_name == 'guitarlele with guitar note names') {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play, 'P5')) + '8', Interval.fromSemitones(-this.state.interval_to_play_raw))))
            } else {
                var pitch_class_to_play = Note.simplify(Note.pitchClass(Note.transpose(this.state.pitch_class_to_play + '8', Interval.fromSemitones(-this.state.interval_to_play_raw))))
            }

            var pitch_class_to_show = pitch_class_to_play

            if (!this.state.show_note_names) {
                pitch_class_to_show = default_speakers_text
            }
            contents = [
                header,
                <div class="around_fretboard">
                    <p>{skip_button}Choose 5 copies of the
                        interval <b>{interval_to_show}</b>&nbsp;from the
                        note&nbsp;<b>{pitch_class_to_show}</b> anywhere on the fretboard</p>
                </div>,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedStrings={[this.getSelectedStrings()]}
                    selectedLocations={this.getSelectedLocations()}
                    selectedNotes={this.getSelectedNotesRender()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else if (chord_scale_types_to_choose_from.includes(this.state.chord_type)) { // For Show Chord, Show Scale, and Show Chord & Scale, comment added for help searching
            contents = [
                header,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedNotes={this.getSelectedNotesChordScale()}
                    selectedLocations={this.getSelectedLocations()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        } else { // For Show Chord, Show Scale, and Show Chord & Scale, comment added for help searching
            contents = [
                header,
                bad_candidate_warning,
                <div class="no_touch"><Fretboard
                    tuning={this.state.tuning}
                    skinType="strings"
                    clickAction={this.handleClick}
                    clickOffAction={this.handleOffClick}
                    onTouchMoveAction={this.handleTouchMove}
                    selectedNotes={this.getSelectedNotesChordScale()}
                    selectedLocations={this.getSelectedLocations()}
                    theme={{statusMap: statusMapStatic}}
                /></div>,
                footer
            ]
        }

        return (<div class="wrapper">{contents}</div>)

    }

// All the MIDI stuff goes here, comes from Example 10 in https://github.com/surikov/midi-sounds-react-examples

    midiOnMIDImessage(event) {
        var data = event.data;
        var cmd = data[0] >> 4;
        var channel = data[0] & 0xf;
        var type = data[0] & 0xf0;
        var pitch = data[1];
        var velocity = data[2];

        switch (type) {
            case 144:
                console.log('MIDI key press sensed at note ' + Note.fromMidi(pitch))
                this.handleClick({
                    note: Note.fromMidi(pitch),
                    notes_on_fret: [
                        Note.fromMidi(pitch),
                        Note.fromMidi(pitch),
                        Note.fromMidi(pitch),
                        Note.fromMidi(pitch),
                        Note.fromMidi(pitch),
                        Note.fromMidi(pitch)
                    ],
                    loc: {str: '', pos: ''},
                    onRadioChange: false,
                    fromMidi: true
                })
                break;
            case 128:
                console.log('MIDI key release sensed at note ' + Note.fromMidi(pitch))
                this.handleOffClick(false);
                break;
        }
    }

    onMIDIOnStateChange(event) {
        this.setState({status: event.port.manufacturer + ' ' + event.port.name + ' ' + event.port.state});
    }

    requestMIDIAccessSuccess(midi) {
        console.log(midi);
        var inputs = midi.inputs.values();
        for (var input = inputs.next(); input && !input.done; input = inputs.next()) {
            input.value.onmidimessage = this.midiOnMIDImessage.bind(this);
        }
        midi.onstatechange = this.onMIDIOnStateChange.bind(this);
    }

    requestMIDIAccessFailure(e) {
        console.log('requestMIDIAccessFailure', e);
        this.setState({status: 'MIDI Access Failure'});
    }

    startListening() {
        this.setState({status: 'waiting'});
        if (navigator.requestMIDIAccess) {
            navigator.requestMIDIAccess().then(this.requestMIDIAccessSuccess.bind(this), this.requestMIDIAccessFailure.bind(this));
        } else {
            this.setState({status: 'navigator.requestMIDIAccess undefined'});
        }
    }
}
;


function App() {
    return (

        <div class="wrapper">
            <FretboardClick
                tuning={default_tuning}
                skinType="strings"
                selectedNotes={['C']}
                selectedStrings={['1']}
            />
        </div>
    );
}

export default App;
